import { Box, Typography } from "@mui/material";
import { AgMuiSelect2 } from "./Controlled";

export function DetailsSelect({ label, control, name, options, values, onChange }) {
    return <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "balck" }}>{label}</Typography>
        <AgMuiSelect2  rules={{required : "Required"}} values={values} onChangeHandler={(value) => {
            console.log("the value is ",value)
        }} fontSize="12px" width="100%" fullwidth options={options} control={control} name={name} />
    </Box>
}