import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { taskStore } from "./task-store"
import { OneCell } from "./act-common"
import Utils from "../util/Utils"

function AddDelButtons(props) {
    useSnapshot(taskStore)
    let task = taskStore.current
    let line = props.line
    let len = props.length
    return (
        <div className="field has-addons">
            {
                (line >= task.preNum) ?
                    <p className="control">
                        <button className="button is-danger is-small is-inverted"
                            onClick={() => {
                                let list = [...taskStore.current.igmDetails]
                                list.splice(line, 1)
                                taskStore.current.igmDetails = list
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-trash"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
            {
                (line === len - 1) ?
                    <p className="control">
                        <button className="button is-success is-small is-inverted"
                            onClick={() => {
                                taskStore.current.igmDetails.push({ name: '', text: '', dt: '' })
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-plus"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
        </div>
    )
}
export default function Igmmanifesttable(props) {
    const [rowNum, setRowNum] = useState(1)
    useSnapshot(taskStore)
    let task = taskStore.current

    return (
        <>
            <div style={{ boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
                <header className="card-header">
                    <p className="card-header-title">
                        IGM Manifest Details
                    </p>
                </header>
                <div className="card-content" style={{ paddingTop: '0px' }}>
                    <div className="content">
                        <div className="table-container">
                            <table className="table is-narrow is-bordered is-fullwidth is-small"
                                style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Manifest Number</th>
                                        <th>Filing Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        task.igmDetails.map((e, i) => (
                                            <tr key={i}>
                                                <td>
                                                    {
                                                        (!props.viewOnly) ?
                                                            <OneCell record={e} name='name' />
                                                            :<></>
                                                    }
                                                    {
                                                        ( props.viewOnly && e.text.length > 0 ) ?
                                                            e.name
                                                            : <></>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (!props.viewOnly ) ?
                                                            <OneCell record={e} name='text' />
                                                            : <></>
                                                    }
                                                    {
                                                        (props.viewOnly && e.text.length > 0 ) ?
                                                            e.text
                                                            : <></>
                                                    }
                                                </td>
                                            
                                                <td>
                                                    {
                                                        (!e.name.startsWith('TP') && !e.name.startsWith('SMTP')) ?
                                                            <>
                                                                {
                                                                    (!props.viewOnly) ?
                                                                        <OneCell type='date' record={e} name='dt' />
                                                                        : Utils.formatToDateTime1(e.dt, '')
                                                                }                                                        </>
                                                            :
                                                            <></>
                                                    }

                                                </td>
                                                <td>
                                                    {
                                                        (!props.viewOnly) ?
                                                            <AddDelButtons line={i} length={taskStore.current.igmDetails.length} />
                                                            : <></>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}