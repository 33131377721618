import { useState } from "react"
import { useSnapshot } from "valtio"
import Agproducttab from "../components/Agproducttab"
import PricingMarkups from "./pricing-markups"
import AirpricingMarkups from "./airpricing-markups"

let menuList = ['LCL Margins', 'Air Margins']

export default function PricingConfig() {
    const [currentMenu, setCurrentMenu] = useState('LCL Margins')
    const [data, setData] = useState({})
    let Comp = PricingMarkups
    if (currentMenu == 'Air Margins') Comp = AirpricingMarkups
    return (
        <div>
            <h4 className="title is-3">Config</h4>
            <div className="columns">
                <div className="column is-12" style={{zoom: '1.1'}}>
                <Agproducttab productList={menuList}
                        activeProduct={currentMenu} setActiveProduct={setCurrentMenu} />
                </div>
            </div>
            <Comp />
        </div>
    )
}