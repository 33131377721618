import React, {createContext, useContext, useState, useEffect} from 'react'
import chartStore from './charts/chart.store'
import { enquiryStore } from './enquiry/enquiry-data'
import menuListData, { clientMenuList } from './menus'
import { taskStore } from './mytasks/task-store'
import {quoteData, newCargoData, editCargoData, cargoListData}  from './QuotationData'
import { bookingsStore, configStore, contractsStore, loginStore, routerStore } from './state/CommonState'
import Newapi from './state/new-api'
import { dtactionStore } from './dtactions/dtaction-store'
import { rtpStore } from './pricing/pricing-store'

const globalContext = createContext()
export const useGlobalState = () => useContext(globalContext)


export default function GlobalState ({children}) {
    const [quoteState, setQuoteState] = useState(quoteData)
    const [newCargoDataState, setNewCargoDataState] = useState(newCargoData)
    const [editCargoDataState, setEditCargoDataState] = useState(editCargoData)
    const [cargoListState, setCargoListState] = useState(cargoListData)

    let menuList = menuListData
    //if (loginStore.isClient()) menuList = clientMenuList

// For temporary disable dashboard screen for client login
    // const [currentMenu, setCurrentMenu] = useState(configStore.currentMainMenu)
    const [currentMenu, setCurrentMenu] = useState(loginStore.isClient() ? 'Home'  : configStore.currentMainMenu)

    const changeCurrentMenu = (menu) => {
        configStore.quoteCurrentView = 'list'
        configStore.dtCurrentView = 'list'
        routerStore.agusersCurrentView = 'list'
        routerStore.entityCurrentView = 'list'
        routerStore.entityCurrentViewNew = 'list'
        routerStore.driverCurrentView = 'list'
        routerStore.vehicleCurrentView = 'list'
        routerStore.branchCurrentView = 'list'
        routerStore.creditApplicationView = 'list'
        configStore.quoteClientCurrentView = 'list'
        configStore.homescreenCurrentView = 'list'
        enquiryStore.currentEnquiryListComponent = 'list'
        bookingsStore.currentBookingsListComponent = 'list'
        dtactionStore.view = 'list'
        rtpStore.markupView = 'list'
        rtpStore.airmarkupView = 'list'
        chartStore.activeProduct = 'LCL'
        taskStore.triggerActionVisible = false
        if (contractsStore.lockedContract.length > 0) {
            Newapi.releaseContractLock(contractsStore.lockedContract)
            contractsStore.lockedContract = ''
        }
        let menuList = menuListData
        if (loginStore.isClient()) menuList = clientMenuList
        if(loginStore.isClient() && menu=="Enquiries"){
            configStore.homescreenCurrentShipmentType = "LCL"
            configStore.homescreenCurrentView = 'new'
        }
        console.log('SETTING CURRENT MENU=',loginStore.isClient(), menu, menuList.length)
        setCurrentMenu(menu)
        for (let i = 0; i < menuList.length; i++) {
            if(menuList[i]['children'] != undefined && menuList[i]['children'].length>0){
                let a = 0
                for (let j = 0; j < menuList[i]['children'].length; j++) {
                    if(menuList[i]['children'][j]['key']==menu){
                        configStore.currentMainComponent = menuList[i]['children'][j]["component"]
                        j = menuList[i]['children'].length + 1
                        a=1
                        
                    }
                }
                if(a==1){
                    i = menuList.length + 1
                }
            }else{
                if(menuList[i]['key'] === menu){
                    configStore.currentMainComponent = menuList[i]["component"]
                    i = menuList.length+1
                }
            }
        }
        // configStore.currentMainComponent = menuList.filter(m => m.name === menu ).map(m => m.component)
        console.log('NEW SET CURRENT COMPONENT=', ''+configStore.currentMainComponent)
    }



    
    const [name, setName] = useState('Uma')
    const [email, setEmail] = useState('')
    const [leftMenuHidden, setLeftMenuHidden] = useState(false)
    const shipmentTypes = [
        '','LCL Export','LCL Import','FCL Export (Factory Stuffing)',
        'FCL Export (Dock Stuffing)','FCL Import','Air Export','Air Import'
    ]
    const shipmentScopes = [
        '','Port-to-Port', 'Port-to-Door', 'Door-to-Port', 'Door-to-Door'
    ]
    useEffect(() => {
        // Update the document title using the browser API
        console.log('Value of email changed=', email)
      }, [email]);
    return (
        <globalContext.Provider value={
            {
                name, setName, 
                email, setEmail,
                leftMenuHidden, setLeftMenuHidden,
                shipmentTypes, shipmentScopes,
                menuList, clientMenuList, currentMenu, setCurrentMenu, changeCurrentMenu,
                quoteState, setQuoteState,
                newCargoDataState, setNewCargoDataState,
                editCargoDataState, setEditCargoDataState, 
                cargoListState, setCargoListState
            }}>
            {children}
        </globalContext.Provider>
    )
}

