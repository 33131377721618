import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import LabeledTextField from "../mui-components/LabeledTextField";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import { ServerLists, commodityList, incoTermsList } from "../StaticData";
import { ChooseFile } from "../mui-components/ChooseFile";


const labelStyle = {fontSize:"12px",fontWeight:'bold',color:"black"}

function ShipperDetails({form}){
    const {control} = form;
    return <Box sx={{display:"flex",flexDirection:"column",backgroundColor:"white",flex:1,borderRadius:"8px",width:"50%",padding:"10px 14px",justifyContent:"space-between",overflowY:"auto"}}>
             <Box sx={{display:"flex",flexDirection:"column",gap:1.5}}>
                <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",fontSize:"14px",color:"black"}}>Shipper Contact Details</Typography>
                <Grid container rowGap={1.5} columnGap={0.3}  justifyContent="space-between">
                    <Grid md={5.7} item>
                        <LabeledTextField placeholder="Enter first name" labelStyle={labelStyle} fontSize="12px" control={control} name="firstName" label="First Name*" rules={{required:"First name is required"}} />
                    </Grid>
                    <Grid md={5.7} item>
                        <LabeledTextField placeholder="Enter last name"  labelStyle={labelStyle} fontSize="12px" control={control} name="lastname" label="Last Name*" rules={{required:"Last name is required"}} />
                    </Grid>
                    <Grid md={5.7} item>
                        <LabeledTextField placeholder="Enter email"  labelStyle={labelStyle} fontSize="12px" control={control} name="emailId" label="Email Id*" rules={{required:"Email id is required"}} />
                    </Grid>
                    <Grid md={5.7} item>
                        <LabeledTextField placeholder="Enter contact number"  labelStyle={labelStyle} fontSize="12px" control={control} name="contactnumber" label="Contact Number*" rules={{required:"Contact number is required"}} />
                    </Grid>
                </Grid>
             </Box>
             <Box sx={{display:"flex",flexDirection:"column",gap:1.5}}>
                <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",fontSize:"14px",color:"black"}}>Shipper Details</Typography>
                <Grid container rowGap={1} columnGap={0.3}  justifyContent="space-between">
                    <Grid md={12} item>
                        <LabeledTextField placeholder="Enter shipper name" labelStyle={labelStyle} fontSize="12px" control={control} name="shippername" label="Shipper Name*" rules={{required:"Shipper name is required"}} />
                    </Grid>
                    <Grid md={12} item>
                        <LabeledTextField placeholder="Enter shipper address"  labelStyle={labelStyle} fontSize="12px" control={control} name="shipperaddress" label="Shipper Address*" rules={{required:"Shipper address is required"}} />
                    </Grid>
                    <Grid md={5.7} item>
                        <LabeledTextField placeholder="Enter pin-code / zip-code"  labelStyle={labelStyle} fontSize="12px" control={control} name="pincode" label="Pin/Zip code*" rules={{required:"Pin/Zip is required"}} />
                    </Grid>
                    <Grid md={5.7} item justifyContent="end" alignSelf="end" alignItems="end">
                        <LabeledSelect rules={{required:"Country is required"}} label="Country*" control={control} name="country" 
                        options={ServerLists.countryNameList}  />
                    </Grid>
                </Grid>
             </Box>
    </Box>
}

function LabeledSelect({ label, control, name, options,rules }) {
    return <Box sx={{ width: "100%" }}>
        <Typography sx={{ textAlign:"start",fontFamily:"Figtree",...labelStyle }}>{label}</Typography>
        <AgMuiSelect2 rules={rules} onChangeHandler={(value) => {
        }} fontSize="12px" width="100%" fullwidth options={options} control={control} name={name} />
    </Box>
}


function CommodityDetails({form}){
    const {control} = form;
    return <Box sx={{display:"flex",flexDirection:"column",backgroundColor:"white",borderRadius:"8px",height:"100%",width:"50%",padding:"10px 14px",justifyContent:"space-between",overflowY:"auto"}}>
         <Box sx={{display:"flex",flexDirection:"column",gap:1.5}}>
         <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",fontSize:"14px",color:"black"}}>Commodity Details</Typography>
         <Grid container justifyContent="space-between" rowGap={1}>
            <Grid md={5.7} item>
                <LabeledSelect rules={{required : "Incoterms is required"}} control={control} label="Incoterms*" name="incoterms" options={incoTermsList}/>
            </Grid>
            <Grid md={5.7} item>
                <LabeledSelect  rules={{required : "Commodity type is required"}}  control={control} label="Commodity Type*" name="commoditytype"  options={commodityList}/>
            </Grid>
            <Grid md={12} item>
                <LabeledTextField placeholder="Type here" height="80px" multiline={true} maxRows={3}  labelStyle={labelStyle} label="Special Instructions"  control={control} name="specialinstructions"/>
            </Grid>
            <Grid md={5.7} item>
                <ChooseFile title="Upload Commercial Invoice"/>
            </Grid>
             <Grid md={5.7} item>
                <ChooseFile title="Upload Packaging List"/>
            </Grid>
         </Grid>

         </Box>
    </Box>
}

export function ShippingDetails(){
    const defaultForm = useForm({
        defaultValues : {
            firstName : "",
            lastname : "",
            emailId : "",
            contactnumber : "",
            shippername : "",
            shipperaddress : "",
            pincode : "",
            country : "",
            incoterms : "",
            commoditytype : "",
            specialinstructions:"",

        }
    })

    const {handleSubmit,reset} = defaultForm

    return <Box sx={{height:"100%",width:"100%", display:"flex", flexDirection:"column",gap:1}}>
        <form noValidate onSubmit={handleSubmit((data)=>{

        })} style={{height:"100%",gap:1,display:"flex",flexDirection:"column",gap:1}}>
            <Box sx={{height:"92%",width:"100%", display:"flex", flexDirection:"row",gap:1,overflowY:"auto"}}>
                <ShipperDetails form={defaultForm}/>
                
                <CommodityDetails form={defaultForm}/>
              
            </Box>
            <Box sx={{display:"flex",flexDirection:"row",justifyContent:"end",height:"8%",alignItems:"center",gap:1,paddingTop:'15px'}}>
                    <Button onClick={()=>{
                        reset();
                    }} variant="contained" sx={{backgroundColor:"white",textTransform:"capitalize",color:"#2B6ED4","&:hover":{
                        cursor : "pointer"
                    }}}>Cancel</Button>
                    <Button type="submit" sx={{textTransform:"capitalize"}} variant="contained">Book Now</Button>
                </Box>
                </form>
    </Box>
}