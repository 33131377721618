export const VerticalDashedLine = ({ stroke = "black", height, dashArray }) => {
    return (
      <svg width="1" height={height} xmlns="http://www.w3.org/2000/svg">
        <line
          x1="0"
          y1="0"
          x2="0"
          y2={height}
          stroke={stroke}
          strokeWidth="1"
          strokeDasharray={dashArray}
        />
      </svg>
    );
  };
  