import { Typography } from "@mui/material";
import LabeledTextField from "./LabeledTextField";

export function DetailsText({ width, control, rules, name, endAdornmentText, type, allowNegative = false }) {
     return <LabeledTextField
          fontSize="12px"
          type={type || "number"}
          onKeyDown={(e) => {
              
                    ["e", "E", "+"].includes(e.key) && e.preventDefault();
               
               if(!allowNegative){
                    ["-"].includes(e.key) && e.preventDefault();
               }
          }
          }
          width={width} control={control} rules={{required : "Required", ...rules }} name={name} endAdornment={endAdornmentText ? <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "12px", whiteSpace: "nowrap" }}>{endAdornmentText}</Typography> : undefined} />
}
