import { Box, Typography } from "@mui/material";
import LabeledTextField from "../mui-components/LabeledTextField";
import { useSnapshot } from "valtio";
import { enquiryStore } from "../enquiry/enquiry-data";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import { useEffect } from "react";
import { DetailsText } from "../mui-components/DetailsText";
import { AntSwitch } from "../mui-components/AntSwitch";
import { getHazardousClassList, hazardousList, packingGroupList } from "../StaticData";
import { DetailsSelect } from "../mui-components/DetailsSelect";


function DetailsSwitch({ title, isChecked, register, formKey }) {
    return <span style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}><AntSwitch onChange={(e) => {
        console.log("the change of event is ", e.target.checked);
        console.log("the event ui ", e);
        console.log("the change ", e.target.value);
    }} checked={isChecked} {...register(formKey)} style={{ scale: "0.8" }} /><Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px", fontWeight: "bold" }}>{title}</Typography></span>
}



export function CargoTypes({ form }) {
    const enquiry = useSnapshot(enquiryStore);
    const { control, register, formState: { errors }, handleSubmit, resetField, watch, setValue } = form;
    let hazardous = watch("hazardous");
    let nonstackable = watch("nonStackable");
    let temperatureControlled = watch("temperatureControlled");
    let minTemperature = watch("minTemperature");
    let maxTemperature = watch("maxTemperature");
    const stuffingType = watch("stuffingType")
    const destuffingType = watch("destuffingType")

    useEffect(()=>{
       if(stuffingType==="Dock" && destuffingType === "Dock"){
           setValue("temperatureControlled",false)
           enquiryStore.current.temperatureControlled = "No"
       }
    },[stuffingType,destuffingType])

    useEffect(() => {
        enquiryStore.current.hazardous = hazardous ? 'Yes' : "No"
        if(!hazardous){
            enquiryStore.current.quoteClass = "";
            enquiryStore.current.packingGroup = "";
            enquiryStore.current.unnumber = "";
            resetField("quoteClass")  
            resetField("packingGroup") 
            resetField("unnumber")
        }
    }, [hazardous])

    useEffect(() => {
        enquiryStore.current.nonStackable = nonstackable ? 'Yes' : "No"
    }, [nonstackable])

    useEffect(() => {
        enquiryStore.current.temperatureControlled = temperatureControlled ? 'Yes' : "No"
        if(!temperatureControlled){
             if(enquiry.current.shipmentType.toLowerCase() === 'fcl'){
                enquiryStore.current.temperature = 0;
                enquiryStore.current.cargoValue = 0;
                resetField("cargoValue");
                resetField("temperature");
             }else{
                enquiryStore.current.minTemperature = 0;
                enquiryStore.current.maxTemperature = 0;
                resetField("minTemperature");
                resetField("maxTemperature");
             }
        }
    }, [temperatureControlled])

const showStackForFcl = ()=>{
    let origin = enquiryStore.current.shipmentScope.split("-")[0];
       
    let destination = enquiryStore.current.shipmentScope.split("-")[2];
    
        if((origin?.toLowerCase()==="door" && stuffingType.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && destuffingType.toLowerCase() === "dock" ) || (enquiryStore.current.shipmentScope!=="Port-to-Port" && stuffingType.toLowerCase() === "dock" && destuffingType.toLowerCase() === "dock") ){
           return true
        }
        return false
}

    return <Box sx={{ height: "100%", width: "20%", backgroundColor: "white", borderRadius: "10px", padding: "8px 14px", flexDirection: "column", display: "flex", gap: 0.5, justifyContent: "start" }}>
        {!temperatureControlled && <DetailsSwitch isChecked={hazardous} register={register} formKey={"hazardous"} title={"Hazardous"} />}
        {(enquiry.current.shipmentType.toLowerCase() === "lcl" || enquiry.current.shipmentType.toLowerCase() === 'air' || showStackForFcl())  &&  <DetailsSwitch isChecked={nonstackable} register={register} formKey={"nonStackable"} title={"Non-Stackable"} />}
        {(enquiry.current.shipmentType.toLowerCase() === "fcl" || enquiry.current.shipmentType.toLowerCase() === 'air') && !hazardous &&  ( stuffingType!=="Dock" || destuffingType !== "Dock" )  && <DetailsSwitch isChecked={temperatureControlled} register={register} formKey={"temperatureControlled"} title={"Temperature Controlled"} />}
        {hazardous && <DetailsSelect control={control} name={"quoteClass"} label="Class" options={getHazardousClassList(enquiry.current.shipmentType.toUpperCase())} />}
        {hazardous && <DetailsSelect control={control} name={"packingGroup"} label="Packing Group" options={packingGroupList} />}
        {hazardous && <Box sx={{ width: "100%" }}>
            <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "black" }}>UN Number</Typography>
            <DetailsText 
                fontSize="12px" size="small" fullwidth control={control} name={"unnumber"} rules={{
                    validate:(value)=> {
                        if(value?.length!=4){
                            return 'UN number must be 4 numbers'
                        }
                    }
                }}  />
        </Box>}
        {temperatureControlled && !hazardous && enquiry.current.shipmentType.toLowerCase() === 'fcl' &&
            <>
                <Box sx={{ width: "100%" }}>
                    <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "black" }}>Temperature</Typography>
                    <DetailsText fontSize="12px" size="small" fullwidth control={control} name={"temperature"}
                        rules={{
                            pattern: {
                                value: /^-3[0-5]$|^-?[0-2]?[0-9]$|^30$/,
                                message: 'Temperature must be between -35 to 30'
                            },
                        }}
                        allowNegative={true} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "black" }}>Cargo Value(USD)</Typography>
                    <DetailsText fontSize="12px" size="small" fullwidth control={control} name={"cargoValue"}
                        rules={{
                            validate:(value)=> {
                                if(value<=0){
                                    return "Invalid value"
                                }
                                if(value?.length!=5){
                                    return 'Cargo Value must be 5 numbers'
                                }
                            }
                        }} />
                </Box>
            </>
        }
        {temperatureControlled && !hazardous && enquiry.current.shipmentType.toLowerCase() === 'air' &&
            <>
                <Box sx={{ width: "100%" }}>
                    <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "black" }}>Minimum Temperature</Typography>
                    <DetailsText fontSize="12px" size="small" fullwidth control={control} name={"minTemperature"} rules={{
                        pattern: {
                            value: /^(\+|-)?([0-9]|[1-2][0-9]|3[0-5])$/,
                            message: 'Temperature must be between -35 to 35'
                        },
                        validate: (value) => {
                            if (value <= maxTemperature) {
                                return true
                            } else {
                                return "Minimum temp should not be greater than Maximum temperature"
                            }
                        }

                    }} allowNegative={true} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "black" }}>Maximum Temperature</Typography>
                    <DetailsText fontSize="12px" size="small" fullwidth control={control} name={"maxTemperature"}
                        rules={{
                            pattern: {
                                value: /^(\+|-)?([0-9]|[1-2][0-9]|3[0-5])$/,
                                message: 'Temperature must be between -35 to 35'
                            },
                            validate: (value) => {
                                if (value >= minTemperature) {
                                    return true
                                } else {
                                    return "Maximum temp should not be less than Minimum temperature"
                                }
                            }

                        }} allowNegative={true} />
                </Box>
            </>
        }
        {enquiry.current.shipmentType.toLowerCase() === 'fcl' && <> <DetailsSelect control={control} name={"stuffingType"} label="Stuffing Type?" values={["Factory", "Dock"]} options={["Factory", "Cfs"]} />
            <DetailsSelect control={control} name={"destuffingType"} label="De-Stuffing Type?" values={["Factory", "Dock"]} options={["Factory", "Cfs"]} />
        </>
        }
    </Box>
}