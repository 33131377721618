import { proxy } from "valtio";
import { loginStore } from "../state/CommonState";

let taskStore = proxy({
    list: [],
    current: {},
    previous: null,
    currentFormData: {}, // used in VGM decl due to multiple forms within one action
    currentContainer: '',
    entity: {},
    booking: {},
    triggerActionVisible: false,
    myActionVisible: false,
    modalVisible: false,
    isViewAll : false,
    setComplete: () => {
        taskStore.current.status = 'COMPLETED'
        taskStore.current.completedBy = loginStore.email
        taskStore.current.completedOn = Date.now()
    },
    setCustomerComplete: () => {
        taskStore.current.customerStatus = 'COMPLETED'
        taskStore.current.customerCompletedBy = loginStore.email
        taskStore.current.customerCompletedOn = Date.now()
    },
    triggerModalVisible: false,
    modalMessage: '',
    setModalMessage: (message) => {
        taskStore.modalMessage = message
        taskStore.triggerModalVisible = true
    },
    setModalEnd: (message) => {
        taskStore.modalMessage = message
        taskStore.triggerModalVisible = true
        setTimeout(() => {
            taskStore.triggerModalVisible = false
        }, 2000);
    }
})
export {taskStore}