    import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { AgBtnmongofile } from "../components/AgFile";
import { Col1, Col11, Col12, Col2, Col3, Col4, Col6, Cols } from "../Controls";
import { bookingsStore, configStore, entityStore, loginStore, modalStore } from "../state/CommonState";
import { OneInput, OneSelect } from "./act-common";
import SITopForm from "./act-si-topform";
import BolDetails from "./bol-details";
import BolDetailsFCL from "./bol-details-fcl";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay, { Taskfiledisplay1 } from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { getAction } from "./tasks-static-data"
import { incoTermsList } from "../StaticData";
import MonApi from "../state/mongo-api";

export default function ShipinstructionsFCL(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(modalStore)
    let task = taskStore.current
    let stage = task.stage
    const [disableButton, setDisableButton] = useState(true)
    const [isValidfield, setisValidfield] = useState([])
    let actionName = getAction(taskStore.current.actionName)
    function checkfile(){
        let disable = false
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        setDisableButton(disable)
    }
    function check(){
        let disable = false
        let isValidfield = []
        let non_mandatory = ['lcDate','party1Name','party1Address','setValue','humidityPercentage','ventillationSettings','party1City','party1State','party1Zip','party1Country',,'party1ContactPerson','party1ContactNumber','party1Email','setTemperature','party2Name','party2Address','party2City','party2State','party2Zip','party2Country','party2ContactPerson','party2ContactNumber','party2Email','termsDeliveryLocation','pda','preCarriage','preCarriageRail']
        Object.keys(actionName.payload).map((e) =>{
            if(e != 'bolDetails'){
                
                if(task[e] == undefined && non_mandatory.indexOf(e) == -1){
                    disable = true
                    isValidfield.push(e)
                } 
                if(task[e] != undefined && non_mandatory.indexOf(e) == -1){
                    // console.log(e,task[e],task[e].length)
                    if(task[e].length==0){
                        disable = true
                        isValidfield.push(e)
                    }
                }           
            }else{
                    Object.keys(actionName.bolDetail).map((e1) =>{
                        for (let i = 0; i < task['bolDetails'].length; i++) {
                            if(task['bolDetails'][i][e1] && e1!='description' && e1!='dimensions'){
                                if(task['bolDetails'][i][e1].length==0){
                                    disable = true
                                    isValidfield.push(e1)
                                }
                            }else{
                                if(e1!='description' && e1!='dimensions'){
                                    disable = true
                                    isValidfield.push(e1)
                                }
                            } 
                        }            
                    })
            }      
        })
        setisValidfield(isValidfield)
        setDisableButton(disable)
        
        if (taskStore.current.isValidfield.join(',').length!=0) {
            if(isValidfield.join(',')!=taskStore.current.isValidfield.join(',')){
                taskStore.current.isValidfield = isValidfield
            }
        }
    }
    function checkstage(){
        if([2,11,12].indexOf(taskStore.current.stage)!=-1){
            check()
        }
        if(taskStore.current.stage==3){
            checkfile()
        }
        
    }
    useEffect(() => {
        checkstage()
        let unsubscribe = subscribe(taskStore, () => {
            checkstage()
        })
        return unsubscribe
    }, [taskStore])

    if(taskStore.current.customerStatus == 'COMPLETED' && loginStore.isClient()){
        return (<><Taskfiledisplay/></>)
    }

    if (stage === 1) {
        return (
            <div style={{textAlign:"center"}}>        
            <div class="columns"  id="ta6">
            <div class="column is-12">
            <AgBtnmongofile disabled={false} btnname={"Upload Shipping Instructions"} parent="ta6"
                                        label={taskStore.current.files[0].label} filekey={task.bookingNum}
                                        callback={async (data) => {
                                            taskStore.current.files[0].filename = data.key.filename
                                            // taskStore.current.stage = 10
                                            // taskStore.current.pendingWith = 'CUSTOMER'
                                        }} />
            </div>
            </div>
            <div class="columns">
            <div class="column is-12">
                Or
            </div>
            </div>
            <div class="columns">
            <div class="column is-12">
            <button class="button islink" style={{width:"25rem"}}
            onClick={async (e) => {
                
                taskStore.current.stage = 2
            }}
            >
                Generate Shipping Instruction with template
            </button>
            </div>
            </div> {(taskStore.current.files[0].filename) ? (
                    <div className="columns" style={{ textAlign: "center" }}>
                        <div className="column is-12">
                            <div className="columns">
                                <div className="column is-12 has-text-centered">Selected file : {taskStore.current.files[0].filename}</div>
                            </div>
                            <div className="columns">
                                <div className="column is-12" style={{ textAlign: "right" }}>
                                    <button className="button islink" onClick={async () => {
                                        taskStore.current.stage = 13;
                                        taskStore.setCustomerComplete();
                                        taskStore.current.pendingWith = 'AGRAGA';
                                        await Taskapi.updateTask(taskStore.current);
                                    }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <></>}</div>
        )
    }
    if (stage === 2) {
        return (
            <div>
                <InputForm />
                <p>
                    <button style={{ float: 'left' }} className="button islink"
                        onClick={async (e) => {
                            if(!disableButton){
                                let ret = await Taskapi.genSIinstructions_fcl(taskStore.current)
                                if (ret.error) {
                                    configStore.setModalMessage(ret.error)
                                    return
                                }
                                task['hsCodes']=task['hsCodes1']
                                task['preCarriage']=task['preCarriage']
                                if(modalStore.prevStage == 10 || modalStore.prevStage == 11 || modalStore.prevStage == 12){
                                    taskStore.current.files[0] = taskStore.current.files[0]
                                    taskStore.current.files[1] = ({label:"Shipping",filename:ret.key.filename,approved:'',rejectReason:''})
                                }else{
                                    taskStore.current.files[0].filename = ret.key.filename
                                }
                                // console.log(ret.key.filename)
                                // console.log(taskStore.current.files)
                                if(modalStore.prevStage == 10){
                                    taskStore.current.stage = 10
                                }else if(modalStore.prevStage == 11){
                                    taskStore.current.stage = 11
                                }else if(modalStore.prevStage == 12){
                                    taskStore.current.stage = 12
                                }else{
                                    taskStore.current.stage = 3
                                }
                            //    console.log("adsadas",taskStore.current.files[1])
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.isValidfield = isValidfield
                                await Taskapi.updateTask(taskStore.current)
                            }else{
                                task['hsCodes']=task['hsCodes1']
                                task['preCarriage']=task['preCarriage']
                                // task['termsDeliveryLocation'] = task['termsDeliveryLocation']
                                taskStore.current.isValid = false
                                taskStore.current.isValidfield = isValidfield
                                taskStore.setModalMessage('Please check the fields')
                            } 
                            // console.log(taskStore.current)
                            //taskStore.myActionVisible = false
                        }}>Submit</button>
                </p>
            </div>
        )
    }
    if (stage === 3) {
        return (
            <>
                <div>
                    <div>
                        <Taskfiletable />
                    </div>
                    <div style={{textAlign:"center"}}>
                    <button class="button islink" style={{width:"25rem"}}
            onClick={async (e) => {
                modalStore.prevStage = 3
                taskStore.current.stage = 2
               // check()
            }}
            >
                Generate Shipping Instruction with template
            </button>
            </div>
            <hr />
                </div>
                <button style={{ float: 'left' }} className="button islink"
                    disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 40
                        taskStore.current.pendingWith = 'AGRAGA'
                        taskStore.setCustomerComplete()
                        await Taskapi.updateTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Submit</button>
            </>
        )
    }
    if (stage === 40 && taskStore.current.pendingWith == 'AGRAGA') {
        return (
            <>
                <div>
                    <span><strong>Shipping Instructions</strong></span>
                </div>
                <div>
                    <div>
                        <Taskfiledisplay />
                    </div>
                </div>
                {
                    (stage === 40) ?
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 5
                                taskStore.current.status = 'COMPLETED'
                                taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.setComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Acknowledge</button>
                        : <></>
                }
            </>
        )
    }
    if (stage === 10) {
        return (
            <>
                <div>
                    <div>
                        <Taskfiletable />
                    </div>
                    <div style={{textAlign:"center"}}>
                    <button class="button islink" style={{width:"25rem"}}
            onClick={async (e) => {
                modalStore.prevStage = 10
                taskStore.current.stage = 2
               // check()
            }}
            >
                Generate Shipping Instruction with template
            </button>
            </div>
            <hr />
                </div>
                <button style={{ float: 'left' }} className="button islink"
                    // disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 11
                        taskStore.current.pendingWith = 'AGRAGA'
                        taskStore.current.customerStatus = 'COMPLETED'
                        // taskStore.setCustomerComplete()
                        await Taskapi.updateTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Submit</button>
            </>
        )
    }
    if (stage === 11) {
        return (
            <>
                
                    <div>
                        <Taskfiledisplay />
                    </div>

                    <div>
                <InputForm />
                <p>
                    <button style={{ float: 'left' }} className="button islink"
                        onClick={async (e) => {
                            if(!disableButton){
                                let ret = await Taskapi.genSIinstructions_fcl(taskStore.current)
                                if (ret.error) {
                                    configStore.setModalMessage(ret.error)
                                    return
                                }
                                task['hsCodes']=task['hsCodes1']
                                task['preCarriage']=task['preCarriage']
                                    taskStore.current.files[0] = taskStore.current.files[0]
                                    taskStore.current.files[1] = ({label:"Shipping",filename:ret.key.filename,approved:'',rejectReason:''})
                                    taskStore.current.stage = 12

                                
                            //    console.log("adsadas",taskStore.current.files[1])
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.customerStatus = 'PENDING'
                                taskStore.current.isValidfield = isValidfield
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }else{
                                task['hsCodes']=task['hsCodes1']
                                task['preCarriage']=task['preCarriage']
                                // task['termsDeliveryLocation'] = task['termsDeliveryLocation']
                                taskStore.current.isValid = false
                                taskStore.current.isValidfield = isValidfield
                                taskStore.setModalMessage('Please check the fields')
                            } 
                            // console.log(taskStore.current)
                            //taskStore.myActionVisible = false
                        }}>Submit</button>
                </p>
            </div>

</>
                    
           
        )
    }
    if (stage === 12) {
        return (
            <>
                
                    <div>
                        <Taskfiledisplay1 />
                    </div>
                    <div>
                <InputForm />
                <p>
                    <button style={{ float: 'left' }} className="button islink"

                        onClick={async (e) => {
                            if(!disableButton){
                                let ret = await Taskapi.genSIinstructions_fcl(taskStore.current)
                                if (ret.error) {
                                    configStore.setModalMessage(ret.error)
                                    return
                                }
                                task['hsCodes']=task['hsCodes1']
                                task['preCarriage']=task['preCarriage']
                                taskStore.current.files[0] = taskStore.current.files[0]
                                taskStore.current.files[1] = ({label:"Shipping",filename:ret.key.filename,approved:'',rejectReason:''})
                                taskStore.current.stage = 13
                                // taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.setCustomerComplete()
                                // taskStore.current.status = 'COMPLETED'
                                taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.current.isValidfield = isValidfield
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }else{
                                task['hsCodes']=task['hsCodes1']
                                task['preCarriage']=task['preCarriage']
                                // task['termsDeliveryLocation'] = task['termsDeliveryLocation']
                                taskStore.current.isValid = false
                                taskStore.current.isValidfield = isValidfield
                                taskStore.setModalMessage('Please check the fields')
                            } 
                            //taskStore.myActionVisible = false
                        }}>Submit</button>
                </p>
            </div>
            </>
        )
    }
    if (stage === 13) {
        return (
            <>
                <div>
                    <div>
                        <Taskfiledisplay1 />
                    </div>
            <hr />
                </div>
                <button style={{ float: 'left' }} className="button islink"
                    // disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 5
                        // taskStore.current.pendingWith = 'AGRAGA'
                        // taskStore.setCustomerComplete()
                        taskStore.current.status = 'COMPLETED'
                        taskStore.current.pendingWith = 'AGRAGA'
                        taskStore.setComplete()
                        await Taskapi.updateTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Acknowledge</button>
            </>
        )
    }
    if(stage == 5){
        return (
            <>
            <div>
                    <span><strong>Shipping Instructions</strong></span>
                </div>
                <div>
                    <div>
                        <Taskfiledisplay />
                    </div>
                </div>
            </>
        )
    }
   
    
}
export function ShipinstructionsFCLTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    return (
        <>
            <TaskDatetime />
            <hr />
            <p>
                <button className="button islink"
                    onClick={async (e) => {
                        modalStore.prevStage = 1
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        prepopulate()
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}
 async function prepopulate() {
    let isporentitycountry = false
    let b = bookingsStore.current
    let c = b.contract
    let t = taskStore.current
    let e = entityStore.entityRec

    let actions1 = await MonApi.find('Agusers',
     { "entityList.gst": c.gst },{ 'content': 0 }
     )
    
    if (c.finalPlaceOfDelivery && c.finalPlaceOfDelivery?.toLowerCase().indexOf(e.country.toLowerCase()) >= 0) isporentitycountry = true
    else if (c.destinationAirport && c.destinationAirport?.toLowerCase().indexOf(e.country.toLowerCase()) >= 0) isporentitycountry = true
    if (c.temperatureControlled.toLowerCase() === 'yes') {
        if (c.minTemperature < 0) {
            t.setTemperatureMin = 'Minus'
            t.setValueMin = c.minTemperature * -1
        } else {
            t.setTemperatureMin = 'Plus'
            t.setValueMin = c.minTemperature
        }
        if (c.maxTemperature < 0) {
            t.setTemperatureMax = 'Minus'
            t.setValueMax = c.maxTemperature * -1
        } else {
            t.setTemperatureMax = 'Plus'
            t.setValueMax = c.maxTemperature
        }
    }
    t.expName = ''+b.shipper
    t.expAddress = b.address
    t.expCity = e.city
    t.expPin = b.pin
    t.expState = e.state
    t.expCountry = e.country
    t.termsDelivery = b.incoTerms
    t.por = c.portOfReceipt
    t.pol = c.portOfLoading
    t.pod = c.portOfDischarge
    t.fpod = c.finalPlaceOfDelivery
    t.expEmail = b.emailid ? b.emailid : b.email
    t.expContactPerson = b.firstName +" "+ b.lastName
    t.expContactNumber = b.phone
    if (isporentitycountry) {
        t.impName = e.entityName
        t.impAddress = e.address
        t.impCity = e.city
        t.impState = e.state
        t.impCountry = e.country
        t.impPin = e.pinCode
        t.impEmail = actions1[0]?.entityList[0]?.email.length > 0 ? actions1[0]?.entityList[0]?.email : ""
        t.impContactPerson = actions1[0]?.firstName + " "+ actions1[0]?.lastName
        t.impContactNumber = actions1[0]?.phonePrefix + actions1[0]?.phone
    }
}
const BlankRow = () => {
    return (
        <tr>
            <td style={{ marginRight: '5px', marginTop: '3px', color: 'white' }}>
                <div style={{ marginTop: '10px' }}>Blank</div>
            </td>
            <td></td>
        </tr>
    )
}
function InputForm(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    let p = {
        borderBottom: '1px solid #eeeeee'
    }
    let bold = {
    }

    return (
        <div>
            {
                ([11,12].indexOf(stage)==-1)?<hr />:<></>
            }
            
            <SITopForm />
            <Cols>
                <Col6>
                    <table className="table is-fullwidth is-bordered is-narrow" style={{ fontSize: '0.85rem' }}>
                        <tr>
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'left' }}>Shipment Information</th>
                        </tr>
                        <tr>
                            {/* <OneInput label='Terms of Delivery' name='termsDelivery' /> */}
                            <OneSelect label='Terms of Delivery' name='termsDelivery' dataList={incoTermsList}/>
                        </tr>
                        {/* <tr>
                            <OneInput label='Terms of Delivery Location' name='termsDeliveryLocation' />
                        </tr> */}
                        <tr>
                            <OneInput label='Place of Receipt' name='por' />
                        </tr>
                        <tr>
                            <OneInput label='Port of Loading' name='pol' />
                        </tr>
                        <tr>
                            <OneInput label='Port of Discharge' name='pod' />
                        </tr>
                        <tr>
                            <OneInput label='Final Place of Delivery' name='fpod' />
                        </tr>
                        <tr>
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'left' }}>Product / Routing Information</th>
                        </tr>
                        <tr>
                            <OneInput label='HS codes' name='hsCodes1' />
                        </tr>
                        <tr>
                            <OneInput label='Invoice Number' name='invoiceNumber' />
                        </tr>
                        <tr>
                            <OneInput label='Invoice Date' name='invoiceDate' />
                        </tr>
                        <tr>
                            <OneInput label='Fumigation required' name='fumigationRequired' />
                        </tr>
                        <tr>
                            <OneInput label='PO Number (If Any)' name='poNumber' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Pre-carriage' name='preCarriage' /> */}
                            <OneSelect label='Pre-carriage' name='preCarriage' dataList={['','Rail','Road']}/>
                       </tr>
                        <tr>
                            {
                                (taskStore.current.preCarriage === 'Rail') ? <OneInput label='Pre-carriage (if Rail)' name='preCarriageRail' /> : <></>

                            }
                        </tr>
                        <tr>
                            <OneInput label='Cargo Value (In USD)' name='cargoValue' />
                        </tr>
                    </table>
                </Col6>
                <Col6>
                    <table className="table is-fullwidth is-bordered is-narrow" style={{ fontSize: '0.85rem' }}>
                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>
                                <div style={{ color: '#ccccff' }}>Blank</div>
                            </th>
                        </tr>
                        {/* <BlankRow />
                        <BlankRow />
                        <BlankRow /> */}
                        <tr>
                            <OneInput label='Hazardous Cargo' name='hazardous' />
                        </tr>
                        <tr>
                            <OneInput label='Cargo Readiness Date' name='cargoReadinessDate' />
                        </tr>
                        <tr>
                            <OneInput label='L/C Number' name='lcNumber' />
                        </tr>
                        <tr>
                            <OneSelect label='PDA' name='pda' dataList={['','Shipper PDA','Carrier PDA']}/>
                        </tr>
                        
                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'left' }}>
                                <div style={{ color: 'red' }}>For  Reefers Container Please fill below</div>
                            </th>
                        </tr>
                        <tr>
                            <OneSelect label='Set Temperature' name='setTemperature' dataList={['','Minus','Plus']}/>
                        </tr>
                        <tr>
                            <OneInput label='Set Value' name='setValue' />
                        </tr>
                        <tr>
                            <OneInput label='Humidity (%)' name='humidityPercentage' />
                        </tr>
                        <tr>
                            <OneInput label='Ventilation settings' name='ventillationSettings' />
                        </tr>
                        {/* <BlankRow />
                        <BlankRow />
                        <BlankRow />
                        <BlankRow /> */}
                    </table>
                </Col6>
            </Cols>
            <Cols>
                <Col11>
                    <BolDetailsFCL />
                </Col11>
            </Cols>
        </div>
    )
}