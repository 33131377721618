import { subscribe, useSnapshot } from "valtio";
import { useEffect,useState } from "react";
import { configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Emptypending(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage >= 1) {
        return (
            <div className="box">
                <p>Please note that you have not yet picked-up the Empty Container.</p>
                <p>Your CRO is Valid Till {task.validTill}</p>
                <p>
                    <span>
                        {
                            (stage === 1) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 2
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </div>
        )
    }
}
export function EmptypendingTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [disableButton,setDisableButton] = useState(true)
    let task = taskStore.current
    let stage = task.stage
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            if (taskStore.current.validTill.length > 0) {
                setDisableButton(false)
            }else{
                setDisableButton(true)
            }
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <h5 className="title is-6">Delay in Empty Container Pick-up</h5>
            <hr />
            <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
                <tr>
                    <td>CRO Valid Till</td>
                    <td><input type='date' value={task.validTill} onChange={(e) => task.validTill = e.target.value} /></td>
                </tr>
            </table>
            <p>
                <button className="button islink"
                    disabled = {disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}