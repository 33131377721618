import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { bookingsStore, configStore, modalStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval from "./task-file-approval";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { getAction } from "./tasks-static-data"
export default function Pickupappointment(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage === 1) {
        return <Customer />
    }
    if (stage >= 2) {
        return <Agraga />
    }
}
export function PickupappointmentTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [disableButton, setDisableButton] = useState(true)
    let task = taskStore.current
    let stage = task.stage
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            if (taskStore.current.dueDate.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
            }
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr />
            <p>
                <button className="button islink"
                    disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}
function Customer(props) {
    useSnapshot(taskStore)
    let task = taskStore.current
    let prefix = 'Pick-up '
    const [disableButton, setDisableButton] = useState(true)
    let actionName = getAction(taskStore.current.actionName)
    function check() {
        let disable = false
        if (task.shipmentType.toLowerCase().indexOf('fcl') != -1 && task.containersList && task.containersList.length > 0) {
            let array = task.containersList
            for (let i = 0; i < array.length; i++) {
                console.log("@#@#@", array[i].container, array[i].pickupDate.length, array[i].pickupTimeFrom.length, array[i].pickupTimeTo.length)
                if (array[i].pickupDate.length == 0 || array[i].pickupTimeFrom.length == 0 || array[i].pickupTimeTo.length == 0) {
                    disable = true
                }
            }
            Object.keys(actionName.payload).slice(0, 5).map((e) => {
                console.log(e, task[e])
                if (task[e].length == 0) {
                    disable = true
                }
            })
        } else {
            console.log(actionName.payload)
            Object.keys(actionName.payload).map((e) => {
                console.log(e, task[e])
                if (task[e].length == 0) {
                    disable = true
                }
            })
        }

        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    if (task.actionName.toLowerCase().startsWith('delivery')) prefix = 'Delivery '

    return (
        <>
            {
                (task.shipmentType.toLowerCase().indexOf('fcl') != -1 && task.containersList && task.containersList.length > 0) ?
                    <div className="box">
                        <tr>
                            <td>{prefix} address:</td>
                            <td><input value={task.address} onChange={(e) => {
                                task.address = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Pin Code:</td>
                            <td><input value={task.pinCode} onChange={(e) => {
                                task.pinCode = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Contact Name:</td>
                            <td><input value={task.contactName} onChange={(e) => {
                                task.contactName = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Contact Number:</td>
                            <td><input value={task.contactNumber} onChange={(e) => {
                                task.contactNumber = e.target.value
                            }} onKeyDown={(e) =>
                                ["e", "E", "+"].includes(e.key) && e.preventDefault()
                            }
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                                }} /></td>
                        </tr>
                        <tr>
                            <td>Contact Email:</td>
                            <td><input value={task.contactEmail} onChange={(e) => {
                                task.contactEmail = e.target.value
                            }} /></td>
                        </tr>
                        {
                            task.containersList.map((li, i) => {
                                return <>
                                    {(i == 0) ? <>
                                        <hr />
                                        <p><strong>Please provide the details for the {prefix} {li.container}</strong></p>
                                        <hr />

                                        <tr>
                                            <td>{prefix} date:</td>
                                            <td><input type='date' value={li.pickupDate} onChange={(e) => {
                                                li.pickupDate = e.target.value
                                                for (let k = 1; k < task.containersList.length; k++) {
                                                    task.containersList[k].pickupDate = e.target.value

                                                }
                                            }} /></td>
                                        </tr>
                                        <tr>
                                            <td>{prefix} Time (from):</td>
                                            <td>
                                                <select style={{ width: '3rem' }} onChange={(e) => {
                                                    li.pickupTimeFrom = e.target.value
                                                    for (let k = 1; k < task.containersList.length; k++) {
                                                        task.containersList[k].pickupTimeFrom = e.target.value

                                                    }
                                                }}>
                                                    {
                                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                                    }
                                                </select> hrs
                                            </td>
                                            <td>{prefix} Time (to):</td>
                                            <td>
                                                <select style={{ width: '3rem' }} onChange={(e) => {
                                                    li.pickupTimeTo = e.target.value
                                                    for (let k = 1; k < task.containersList.length; k++) {
                                                        task.containersList[k].pickupTimeTo = e.target.value

                                                    }
                                                }}>
                                                    {
                                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                                    }
                                                </select> hrs
                                            </td>
                                        </tr>
                                    </> : <>
                                        <hr />
                                        <p><strong>Please provide the details for the {prefix} {li.container}</strong></p>
                                        <hr />

                                        <tr>
                                            <td>{prefix} date:</td>
                                            <td><input type='date' value={li.pickupDate} onChange={(e) => {
                                                li.pickupDate = e.target.value
                                            }} /></td>
                                        </tr>
                                        <tr>
                                            <td>{prefix} Time (from):</td>
                                            <td>
                                                <select style={{ width: '3rem' }} value={li.pickupTimeFrom} onChange={(e) => {
                                                    li.pickupTimeFrom = e.target.value
                                                }}>
                                                    {
                                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                                    }
                                                </select> hrs
                                            </td>
                                            <td>{prefix} Time (to):</td>
                                            <td>
                                                <select style={{ width: '3rem' }} value={li.pickupTimeTo} onChange={(e) => {
                                                    li.pickupTimeTo = e.target.value
                                                }}>
                                                    {
                                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                                    }
                                                </select> hrs
                                            </td>
                                        </tr>

                                    </>}

                                </>
                            })
                        }

                        <hr />
                        <button className="button islink"
                            disabled={disableButton}
                            onClick={(e) => {
                                taskStore.current.stage = 2
                                taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.setCustomerComplete()
                                Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                    </div> : <div className="box">
                        <p><strong>Please provide the details for the {prefix}</strong></p>
                        <hr />
                        <tr>
                            <td>{prefix} address:</td>
                            <td><input value={task.address} onChange={(e) => {
                                task.address = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Pin Code:</td>
                            <td><input value={task.pinCode} onChange={(e) => {
                                task.pinCode = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Contact Name:</td>
                            <td><input value={task.contactName} onChange={(e) => {
                                task.contactName = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Contact Number:</td>
                            <td><input value={task.contactNumber} onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                            }
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                                }} onChange={(e) => {
                                    task.contactNumber = e.target.value
                                }} /></td>
                        </tr>
                        <tr>
                            <td>Contact Email:</td>
                            <td><input value={task.contactEmail} onChange={(e) => {
                                task.contactEmail = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>{prefix} date:</td>
                            <td><input type='date' value={task.pickupDate} onChange={(e) => {
                                task.pickupDate = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>{prefix} Time (from):</td>
                            <td>
                                <select style={{ width: '3rem' }} onChange={(e) => {
                                    task.pickupTimeFrom = e.target.value
                                }}>
                                    {
                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                    }
                                </select> hrs
                            </td>
                            <td>{prefix} Time (to):</td>
                            <td>
                                <select style={{ width: '3rem' }} onChange={(e) => {
                                    task.pickupTimeTo = e.target.value
                                }}>
                                    {
                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                    }
                                </select> hrs
                            </td>
                        </tr>
                        <hr />
                        <button className="button islink" disabled={disableButton} onClick={(e) => {
                            taskStore.current.stage = 2
                            taskStore.current.pendingWith = 'AGRAGA'
                            taskStore.setCustomerComplete()
                            Taskapi.updateTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                        }}>Submit</button>
                    </div>
            }
        </>

    )
}
function Agraga(props) {
    useSnapshot(taskStore)
    let task = taskStore.current
    let prefix = 'Pick-up '
    if (task.actionName.toLowerCase().startsWith('delivery')) prefix = 'Delivery '
    console.log(task.containersList)
    return (
        <>
            {
                ((task.shipmentType.toLowerCase().includes("fcl") && task.containersList && task.containersList.length > 0) ? <>
                    <hr />
                    <tr>
                        <td>{prefix} address:</td>
                        <td>{task.address}</td>
                    </tr>
                    <tr>
                        <td>Pin Code:</td>
                        <td>{task.pinCode}</td>
                    </tr>
                    <tr>
                        <td>Contact Name:</td>
                        <td>{task.contactName}</td>
                    </tr>
                    <tr>
                        <td>Contact Number:</td>
                        <td>{task.contactNumber}</td>
                    </tr>
                    <tr>
                        <td>Contact Email:</td>
                        <td>{task.contactEmail}</td>
                    </tr>
                    <hr />
                    {
                        task.containersList.map((li) => {
                            return <>
                                <div className="box">
                                    <p><strong>Details for the {prefix}{li.container}</strong></p>
                                    <tr>
                                        <td>{prefix} date:</td>
                                        <td>{li.pickupDate}</td>
                                    </tr>
                                    <tr>
                                        <td>{prefix} Time (from):</td>
                                        <td>{li.pickupTimeFrom}</td>
                                        <td>{prefix} Time (to):</td>
                                        <td>{li.pickupTimeTo}</td>
                                    </tr>
                                </div>
                            </>
                        })
                    }
                    {
                        (taskStore.current.stage === 2) ?
                            <button className="button islink" onClick={(e) => {
                                taskStore.setComplete()
                                taskStore.current.stage = 3
                                Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Acknowledge</button>
                            : <></>
                    }
                </> : <>
                    <div className="box">
                        <p><strong>Details for the {prefix}</strong></p>
                        <hr />
                        <tr>
                            <td>{prefix} address:</td>
                            <td>{task.address}</td>
                        </tr>
                        <tr>
                            <td>Pin Code:</td>
                            <td>{task.pinCode}</td>
                        </tr>
                        <tr>
                            <td>Contact Name:</td>
                            <td>{task.contactName}</td>
                        </tr>
                        <tr>
                            <td>Contact Number:</td>
                            <td>{task.contactNumber}</td>
                        </tr>
                        <tr>
                            <td>Contact Email:</td>
                            <td>{task.contactEmail}</td>
                        </tr>
                        <tr>
                            <td>{prefix} date:</td>
                            <td>{task.pickupDate}</td>
                        </tr>
                        <tr>
                            <td>{prefix} Time (from):</td>
                            <td>{task.pickupTimeFrom}</td>
                            <td>{prefix} Time (to):</td>
                            <td>{task.pickupTimeTo}</td>
                        </tr>
                        <hr />
                        {
                            (taskStore.current.stage === 2) ?
                                <button className="button islink" onClick={(e) => {
                                    taskStore.setComplete()
                                    taskStore.current.stage = 3
                                    Taskapi.updateTask(taskStore.current)
                                    taskStore.triggerActionVisible = false
                                    taskStore.myActionVisible = false
                                }}>Acknowledge</button>
                                : <></>
                        }
                    </div>
                </>)
            }
        </>

    )
}