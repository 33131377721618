const airlineCodes = [
    { code: 'M3', name: 'ABSA Cargo Airline / LATAM Cargo Brasil (M3)' },
    { code: 'GB', name: 'ABX Air (GB)' },
    { code: 'MR', name: 'ACTC (MR)' },
    { code: 'A3', name: 'Aegean Airlines (A3)' },
    { code: 'SU', name: 'Aeroflot  (SU)' },
    { code: 'JK', name: 'Aerolinea Del Caribe / Aercaribe (JK)' },
    { code: 'AR', name: 'Aerolineas Argentinas (AR)' },
    { code: 'AM', name: 'Aeromexico Cargo  (AM)' },
    { code: '6R', name: 'AeroUnion (6R)' },
    { code: '00', name: 'Agraga (00)' },
    { code: 'ZI', name: 'Aigle Azur (ZI)' },
    { code: 'AH', name: 'Air Algerie (AH)' },
    { code: 'G9', name: 'Air Arabia (G9)' },
    { code: 'KC', name: 'Air Astana (KC)' },
    { code: 'BT', name: 'Air Baltic (BT)' },
    { code: 'KF', name: 'Air Belgium (KF)' },
    { code: 'BP', name: 'Air Botswana (BP)' },
    { code: 'AC', name: 'Air Canada (AC)' },
    { code: 'CA', name: 'Air China (CA)' },
    { code: 'UX', name: 'Air Europa Cargo (UX)' },
    { code: 'AF', name: 'Air France  (AF)' },
    { code: 'GL', name: 'Air Greenland (GL)' },
    { code: 'LD', name: 'Air Hong Kong (LD)' },
    { code: 'KJ', name: 'Air Incheon (KJ)' },
    { code: 'AI', name: 'Air India (AI)' },
    { code: 'IG', name: 'Air Italy (IG)' },
    { code: 'NX', name: 'Air Macau (NX)' },
    { code: 'MD', name: 'Air Madagascar (MD)' },
    { code: 'KM', name: 'Air Malta (KM)' },
    { code: 'MK', name: 'Air Mauritius  (MK)' },
    { code: '9U', name: 'Air Moldova (9U)' },
    { code: 'SW', name: 'Air Namibia (SW)' },
    { code: 'NZ', name: 'Air New Zealand (NZ)' },
    { code: 'PX', name: 'Air Niugini (PX)' },
    { code: 'YP', name: 'Air Premia (YP)' },
    { code: 'JU', name: 'Air Serbia  (JU)' },
    { code: 'HM', name: 'Air Seychelles (HM)' },
    { code: 'TN', name: 'Air Tahiti Nui (TN)' },
    { code: 'TS', name: 'Air Transat  (TS)' },
    { code: 'D7', name: 'AirAsia (D7)' },
    { code: 'AK', name: 'AirAsia Berhad (AK)' },
    { code: 'I5', name: 'AirAsia India (I5)' },
    { code: 'RU', name: 'AirBridge Cargo (RU)' },
    { code: 'VZ', name: 'Airclass Lineas Aereas (VZ)' },
    { code: 'X8', name: 'Airmax Cargo (X8)' },
    { code: 'AS', name: 'Alaska Airlines (AS)' },
    { code: 'AZ', name: 'Alitalia  (AZ)' },
    { code: '4W', name: 'Allied Air (4W)' },
    { code: 'KH', name: 'Aloha Air Cargo (KH)' },
    { code: 'AA', name: 'American Airlines (AA)' },
    { code: 'M6', name: 'Amerijet International (M6)' },
    { code: 'NH', name: 'ANA All Nippon Cargo (NH)' },
    { code: 'OZ', name: 'Asiana Airlines (OZ)' },
    { code: '3V', name: 'ASL Airlines Belgium  (3V)' },
    { code: '8V', name: 'Astral Aviation (8V)' },
    { code: '5Y', name: 'Atlas Air (5Y)' },
    { code: 'OS', name: 'Austrian Cargo  (OS)' },
    { code: 'AV', name: 'Avianca Cargo (AV)' },
    { code: 'J2', name: 'Azerbaijan Airlines (J2)' },
    { code: 'AD', name: 'Azul Cargo (AD)' },
    { code: 'UP', name: 'Bahamasair (UP)' },
    { code: 'PG', name: 'Bangkok Airways (PG)' },
    { code: 'B2', name: 'Belavia Belarusian Airlines (B2)' },
    { code: 'BG', name: 'Biman Bangladesh (BG)' },
    { code: 'E6', name: 'Bringer Air Cargo (E6)' },
    { code: 'BA', name: 'British Airways (BA)' },
    { code: 'SN', name: 'Brussels Airlines (SN)' },
    { code: 'FB', name: 'Bulgaria Air (FB)' },
    { code: '5C', name: 'CAL Cargo Air Lines (5C)' },
    { code: 'MO', name: 'Calm Air (MO)' },
    { code: 'QC', name: 'Camair-Co (QC)' },
    { code: 'CP', name: 'Canadian Airlines Int´l (CP)' },
    { code: 'W8', name: 'Cargojet Airways (W8)' },
    { code: 'P3', name: 'Cargologicair (P3)' },
    { code: 'CV', name: 'Cargolux Airlines (CV)' },
    { code: 'C8', name: 'Cargolux Italia (C8)' },
    { code: 'BW', name: 'Caribbean Airlines (BW)' },
    { code: 'CX', name: 'Cathay Pacific Airways (CX)' },
    { code: 'KX', name: 'Cayman Airways (KX)' },
    { code: '5J', name: 'Cebu Air (5J)' },
    { code: 'CQ', name: 'Charterlines (CQ)' },
    { code: 'CI', name: 'China Airlines (CI)' },
    { code: 'CK', name: 'China Cargo Airlines  (CK)' },
    { code: 'MU', name: 'China Eastern Airlines (MU)' },
    { code: 'CZ', name: 'China Southern Airlines  (CZ)' },
    { code: '2C', name: 'CMA CGM Air Cargo (2C)' },
    { code: 'CO', name: 'Cobaltair (CO)' },
    { code: 'DE', name: 'Condor Flugdienst (DE)' },
    { code: 'CM', name: 'Copa Airlines Cargo (CM)' },
    { code: 'SS', name: 'Corsair (SS)' },
    { code: '7C', name: 'Coyne Airways (7C)' },
    { code: 'OU', name: 'Croatia Airlines (OU)' },
    { code: 'CU', name: 'Cubana de Aviacion (CU)' },
    { code: 'CY', name: 'Cyprus Airways (CY)' },
    { code: 'OK', name: 'Czech Airlines  (OK)' },
    { code: 'DL', name: 'Delta Air Lines  (DL)' },
    { code: 'D5', name: 'DHL Aero Expreso (D5)' },
    { code: 'QY', name: 'DHL Aviation / European Air Transport (QY)' },
    { code: 'ER', name: 'DHL Aviation/DHL Airways (ER)' },
    { code: 'KA', name: 'Dragonair (KA)' },
    { code: 'MS', name: 'Egyptair  (MS)' },
    { code: 'LY', name: 'EL AL (LY)' },
    { code: 'EK', name: 'Emirates (EK)' },
    { code: 'E7', name: 'Estafeta Carga Aerea (E7)' },
    { code: 'OV', name: 'Estonian Air (OV)' },
    { code: 'ET', name: 'Ethiopian Airlines (ET)' },
    { code: 'EY', name: 'ETIHAD Airways (EY)' },
    { code: '3S', name: 'European Air Transport (3S)' },
    { code: 'BR', name: 'Eva Airways (BR)' },
    { code: 'FX', name: 'Fedex (FX)' },
    { code: 'FJ', name: 'Fiji Airways (FJ)' },
    { code: 'AY', name: 'Finnair (AY)' },
    { code: '8D', name: 'FITS AIR (8D)' },
    { code: '5F', name: 'Fly One (5F)' },
    { code: 'FZ', name: 'Flydubai Cargo (FZ)' },
    { code: 'GY', name: 'Gabon Airlines (GY)' },
    { code: 'GA', name: 'Garuda Indonesia (GA)' },
    { code: '5S', name: 'Global Aviation and Services (5S)' },
    { code: 'G3', name: 'Gol Airlines  (G3)' },
    { code: 'GF', name: 'Gulf Air (GF)' },
    { code: 'HU', name: 'Hainan Airlines (HU)' },
    { code: 'HA', name: 'Hawaiian Airlines (HA)' },
    { code: 'RH', name: 'Hong Kong Air Cargo (RH)' },
    { code: 'HX', name: 'Hong Kong Airlines (HX)' },
    { code: 'UO', name: 'Hongkong Express (UO)' },
    { code: 'IB', name: 'Iberia (IB)' },
    { code: 'FI', name: 'Icelandair (FI)' },
    { code: '6E', name: 'IndiGo CarGo (6E)' },
    { code: '7I', name: 'Insel Air Cargo (7I)' },
    { code: 'IR', name: 'Iran Air (IR)' },
    { code: 'JL', name: 'Japan Airlines (JL)' },
    { code: 'J9', name: 'JAZEERA AIRWAYS (J9)' },
    { code: '7C', name: 'Jeju Air (7C)' },
    { code: '9W', name: 'Jet Airways (9W)' },
    { code: 'QJ', name: 'Jet Airways Inc.  (QJ)' },
    { code: '0J', name: 'Jet Club (0J)' },
    { code: 'TB', name: 'Jetairfly (TB)' },
    { code: '3K', name: 'Jetstar Asia Airways (3K)' },
    { code: '3J', name: 'Jubba Airways (3J)' },
    { code: 'K4', name: 'Kalitta Air (K4)' },
    { code: 'KQ', name: 'Kenya Airways (KQ)' },
    { code: 'KL', name: 'KLM Cargo  (KL)' },
    { code: 'KE', name: 'Korean Air  (KE)' },
    { code: 'KU', name: 'Kuwait Airways (KU)' },
    { code: 'LR', name: 'LACSA Airlines of Costa Rica (LR)' },
    { code: 'LA', name: 'LAN Airlines  (LA)' },
    { code: 'UC', name: 'LAN Chile Cargo (UC)' },
    { code: 'XL', name: 'LAN Ecuador (XL)' },
    { code: 'JJ', name: 'LATAM Airlines Brasil / TAM Linhas Aereas (JJ)' },
    { code: 'L7', name: 'LATAM CARGO Colombia (L7)' },
    { code: 'LI', name: 'LIAT Airlines (LI)' },
    { code: 'LO', name: 'LOT Polish Airlines (LO)' },
    { code: 'LH', name: 'Lufthansa Cargo AG (LH)' },
    { code: 'W5', name: 'Mahan Airlines (W5)' },
    { code: 'MH', name: 'Malaysian Airline System (MH)' },
    { code: 'OD', name: 'Malindo Airways (OD)' },
    { code: 'AE', name: 'Mandarin Airlines (AE)' },
    { code: 'MP', name: 'Martinair Cargo (MP)' },
    { code: 'M7', name: 'MASAir (M7)' },
    { code: 'T8', name: 'MCS Aerocarga de Mexico (T8)' },
    { code: '4X', name: 'Mercury Americas (4X)' },
    { code: 'ME', name: 'Middle East Airlines (ME)' },
    { code: 'MB', name: 'MNG Airlines (MB)' },
    { code: 'N8', name: 'National Air Cargo  (N8)' },
    { code: 'NO', name: 'Neos SPA (NO)' },
    { code: 'NO', name: 'Niger Air Cargo (NO)' },
    { code: 'KZ', name: 'Nippon Cargo Airlines (KZ)' },
    { code: 'DD', name: 'Nok Air (DD)' },
    { code: 'NC', name: 'Northern Air Cargo / StratAir  (NC)' },
    { code: 'DY', name: 'Norwegian Air Shuttle (DY)' },
    { code: 'DI', name: 'Norwegian Air UK (DI)' },
    { code: 'OA', name: 'Olympic Airways (OA)' },
    { code: 'WY', name: 'Oman Air (WY)' },
    { code: 'PK', name: 'Pakistan Int´l Airlines (PK)' },
    { code: 'PC', name: 'Pegasus Cargo (PC)' },
    { code: 'PR', name: 'Philippine Airlines (PR)' },
    { code: 'PO', name: 'Polar Air Cargo (PO)' },
    { code: 'QF', name: 'Qantas Airways (QF)' },
    { code: 'QR', name: 'Qatar Airways (QR)' },
    { code: 'TH', name: 'Raya Airways (TH)' },
    { code: 'AT', name: 'Royal Air Maroc (AT)' },
    { code: 'BI', name: 'Royal Brunei Airlines (BI)' },
    { code: 'RJ', name: 'Royal Jordanian (RJ)' },
    { code: 'R4', name: 'RUS Aviation (R4)' },
    { code: 'WB', name: 'RwandAir (WB)' },
    { code: 'S6', name: 'SAC South American Airways (S6)' },
    { code: 'SK', name: 'SAS-Scandinavian Airlines System (SK)' },
    { code: 'SP', name: 'SATA Air Acores (SP)' },
    { code: 'S4', name: 'SATA International (S4)' },
    { code: 'SV', name: 'Saudi Arabian Airlines  (SV)' },
    { code: 'O3', name: 'SF Airlines (O3)' },
    { code: 'SC', name: 'Shandong Airlines (SC)' },
    { code: 'FM', name: 'Shanghai Airlines (FM)' },
    { code: 'ZH', name: 'Shenzhen Airlines  (ZH)' },
    { code: 'S7', name: 'Siberia Airlines (S7)' },
    { code: '3U', name: 'Sichuan Airlines (3U)' },
    { code: 'MI', name: 'Silk Air (MI)' },
    { code: 'ZP', name: 'Silk Way Airlines (ZP)' },
    { code: '7L', name: 'Silk Way West Airlines (7L)' },
    { code: 'SQ', name: 'Singapore Airlines (SQ)' },
    { code: 'OO', name: 'Sky West Airlines (OO)' },
    { code: 'GW', name: 'Skygreece Airlines (GW)' },
    { code: 'KY', name: 'Skylease Cargo (KY)' },
    { code: '4S', name: 'Solar Cargo (4S)' },
    { code: 'SA', name: 'South African Airways (SA)' },
    { code: 'WN', name: 'Southwest Airlines (WN)' },
    { code: 'SG', name: 'SpiceJet (SG)' },
    { code: 'UL', name: 'SriLankan Cargo (UL)' },
    { code: '4E', name: 'Stabo Air (4E)' },
    { code: 'QP', name: 'Starlight Airlines (QP)' },
    { code: 'JX', name: 'Starlux Cargo (JX)' },
    { code: 'DK', name: 'Sunclass Airlines (DK)' },
    { code: 'Y8', name: 'Suparana / Jinpeng / Yangtze River Express Airlines (Y8)' },
    { code: 'LX', name: 'Swiss (LX)' },
    { code: 'RB', name: 'Syrian Arab Airlines (RB)' },
    { code: 'DT', name: 'TAAG Angola Airlines (DT)' },
    { code: 'B1', name: 'TAB Transportes Aereos Bolivianos (B1)' },
    { code: 'TA', name: 'TACA (TA)' },
    { code: 'QT', name: 'Tampa Airlines (QT)' },
    { code: 'TP', name: 'TAP Air Portugal (TP)' },
    { code: 'RO', name: 'Tarom (RO)' },
    { code: 'UK', name: 'TATA SIA Airlines/Vistara Cargo (UK)' },
    { code: 'TG', name: 'Thai Airways (TG)' },
    { code: 'GS', name: 'TianJin Airlines (GS)' },
    { code: 'T0', name: 'Trans American Airways/TACA Peru (T0)' },
    { code: 'TB', name: 'TUI Airlines (TB)' },
    { code: 'TU', name: 'Tunisair (TU)' },
    { code: 'TK', name: 'Turkish Airlines (TK)' },
    { code: 'PS', name: 'Ukraine Int´l Airlines (PS)' },
    { code: 'B7', name: 'Uni Airways (B7)' },
    { code: 'UA', name: 'United Airlines Cargo (UA)' },
    { code: 'UW', name: 'Uni-Top Airlines (UW)' },
    { code: '5X', name: 'UPS Air Cargo (5X)' },
    { code: 'U6', name: 'Ural Airlines Cargo (U6)' },
    { code: 'HY', name: 'Uzbekistan Airways (HY)' },
    { code: 'RG', name: 'VARIG (RG)' },
    { code: 'V4', name: 'Vensecar Internacional (V4)' },
    { code: 'VN', name: 'Vietnam Airlines (VN)' },
    { code: 'VS', name: 'Virgin Atlantic (VS)' },
    { code: 'WS', name: 'Westjet Cargo (WS)' },
    { code: 'WW', name: 'WOW Air (WW)' },
    { code: 'MF', name: 'XiamenAir (MF)' },
    { code: 'SE', name: 'XL Airways France (SE)' },
    { code: 'IY', name: 'Yemenia Yemen Airways (IY)' },
    { code: 'YG', name: 'YTO Cargo Airlines (YG)' }
]

export default airlineCodes