import { useEffect, useState } from "react"
import { subscribe, useSnapshot } from "valtio"
import { AgAddress, AgUnlocde } from "../components/ag-address/ag-address"
import { AgInput, AgUnloc } from "../components/AgComponents"
import AgLabel from "../components/AgLabel"
import NewAddress from "../components/new-address"
import { useGlobalState } from "../GlobalState"
import Taskapi from "../mytasks/tasks-api"
import BookingsApi from "../state/bookings-api"
import { configStore, contractsStore, bookingsStore, loginStore } from "../state/CommonState"
import Utils from "../util/Utils"
import { enquiryStore } from "./enquiry-data"
import MonApi from "../state/mongo-api"
import { rtpStore } from "../pricing/pricing-store"


export default function VehicleScreen(props) {
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(rtpStore)

    const rec = enquiryStore.current.vehicleDetails

    const [vehicles, setVehicles] = useState(rec.fieldkeys)
    useEffect(() => {
        rec.initialize()
        let r = /\[.*,([^,]*)\]/
        let route = enquiryStore.current.enqRoute
        let url
        let locType
        if (route.origin.includes('[IN')) {
            locType = 'Pick-up'
            let arr = route.origin.match(r)
            let pin = arr[1]
            url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getvehicles4pin/pickup/${pin}`
            if (props.activeProduct == 'Air') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/airpricing/getvehicles4pin/pickup/${pin}`
        } else if (route.destination.includes('[IN')) {
            locType = 'Delivery'
            let arr = route.destination.match(r)
            let pin = arr[1]
            url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getvehicles4pin/delivery/${pin}`
            if (props.activeProduct == 'Air') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/airpricing/getvehicles4pin/delivery/${pin}`
        }
        let ret = MonApi.aget(url).then(data => {
            if (locType && (!data || !data.length) && !rtpStore.distanceButtonVisible) {
                configStore.setModalMessage(`Unfortunately, we do not have transport rates for this ${locType} Address. We are still expanding our Geographical presence and will update you once the facility gets activated. Meantime, please feel free to place an Enquiry with us and we will ensure a quotation is provided at the earliest.`, [], false)
                if (locType == 'Pick-up') route.origin = ''
                else route.destination = ''
            } else {
                setVehicles(data)
            }
        })
    }, [enquiryStore.current.enqRoute.origin, enquiryStore.current.enqRoute.destination])

    if (!contractsStore.current) return <></>

    return (
        <div style={loginStore.isClient() ? {backgroundColor:"white",borderRadius:"10px"} : { boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
            <header className="card-header">
                <p className="card-header-title">
                    <span className="icon is-small mr-2">
                        <i className="fas fa-truck-moving"></i>
                    </span>
                    Vehicles
                </p>
            </header>
            <div className="card-content">
                <div className="content">
                    <table className="table is-narrow">
                        <tbody>
                            {
                                vehicles.map(e => {
                                    let label = rec.fields[e]
                                    return <tr>
                                        <td>{label}</td>
                                        <td><AgInput style={{ width: '3rem' }} record={rec} name={e} type='number' /></td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}