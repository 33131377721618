import { useEffect, useLayoutEffect, useState } from "react"
import { breadCrumbStore, cargoStore, configStore, enquiryFormState, enquiryState, filtersState, loginStore, vehicleStore } from "../state/CommonState";
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, Typography } from "@mui/material";
import { HorizontalDashedLine, HorizontalLine } from "../mui-components/HorizontalDashedLine";
import { AgMuiSelect } from "../mui-components/AgMuiSelect";
import { useFieldArray, useForm } from "react-hook-form";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
import { CenteredBox } from "../mui-components/CentereedBox";
import deleteIcon from "../assets/images/delete.svg";
import addIcon from "../assets/images/add.svg";
import { DetailsText } from "../mui-components/DetailsText";
import { containerTypeList, containerTypeListTC, lclWeightForPort, maxDimensions, maxWeightPerContainerFcl, maxcargoWeightPerContainer, packageTypeList, weightPerPackage } from "../StaticData";
import submitIcon from "../assets/images/submit.svg";
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data";
import { loader } from "../util/loader";
import { AgLoader } from "../components/ag-loader/ag-loader";
import { useSnapshot } from "valtio";
import Utils from "../util/Utils";
import closeIcon from "../assets/images/close.svg";
import PricingUtils from "../pricing/pricing.utils";
import MonApi from "../state/mongo-api";
import EnquiriesApi from "../state/enq-api";
import EnquiryConfirmation from "../enquiry/enquiry-confirmation";


function CargoDetails({ form }) {


    const { control, watch, getValues, trigger, setValue } = form;
    const { fields: cargodetails, append: appendCargo, remove: removeCargo } = useFieldArray({
        control: control,
        name: "cargodetails"
    })
    const mode = enquiryStore.current.shipmentType.toLowerCase();

    const totalWeight = watch("cargodetails.0.totalWeight.unit");
    const totalVolume = watch("cargodetails.0.totalVolume.unit");
    const dimensionsUnit = watch("cargoDimensionUnit");
    const weight = watch("cargodetails.0.weight.unit");



  useEffect(()=>{
      for(let i = 1; i < cargodetails.length;i++){
             setValue(`cargodetails.${i}.weight.unit`,weight)
      }
  },[weight])

  useEffect(()=>{
    for(let i = 1; i < cargodetails.length;i++){
           setValue(`cargodetails.${i}.totalWeight.unit`,totalWeight)
    }
},[totalWeight])

useEffect(()=>{
    for(let i = 1; i < cargodetails.length;i++){
           setValue(`cargodetails.${i}.totalVolume.unit`,totalVolume)
    }
},[totalVolume])

    let isDimension = false;
    const validateGreaterThanZero = (value) => {
        return parseFloat(value) > 0 || 'Required';
    };

    if ((mode === "lcl" && enquiryStore.current.shipmentScope.includes("Door")) || mode === "air" || mode === "fcl") {
        isDimension = true;
    }

    return <Box sx={{ height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems:"center",padding:"6px",borderRadius: "5px", backgroundColor: "#F5F5F5", width: "100%" }}>
            <CenteredBox sx={{ flex: 1, fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }}>Package Type *</CenteredBox>
            <CenteredBox sx={{ flex: 1, fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }}>Quantity *</CenteredBox>
            <CenteredBox sx={{ flex: 1, flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }} >{
                    (isDimension)
                        ? mode === "lcl" ? "Weight per package *" : "Weight Per Piece *" : "Total Weight *"} x</Typography>
                <AgMuiSelect2 padding={0} control={control} name={mode === "air" ? "cargodetails.0.weight.unit" : "cargodetails.0.totalWeight.unit"} size="small" variant="standard" disableUnderline={true} options={["Kgs", "Tons", "Lbs"]} fontColor="#555555" iconColor="#555555" width="60px" style={{ fontSize: "12px", fontFamily: "Figtree" }} />
            </CenteredBox>
            <Box sx={{flex: (isDimension) ? 2 : 1,display:"flex",flexDirection:"column",gap:1}}>
            <CenteredBox sx={{ flex: 1, flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }}>{(isDimension) ? "Dimensions *" : "Total Volume *"} x</Typography>
                <AgMuiSelect2 padding={0} control={control} name={(isDimension) ? "cargoDimensionUnit" : "cargodetails.0.totalVolume.unit"} size="small" variant="standard" disableUnderline={true} values={(isDimension) ? ['Cms', 'MM', 'Inches', "Meters", 'Feet'] : ['CBM', 'cu ft']} options={(isDimension) ? ['Cms', 'MM', 'Inches', "Meters", 'Feet'] : ['CBM', 'Cu. ft']} fontColor="#555555" iconColor="#555555" width="60px" style={{ fontSize: "12px", fontFamily: "Figtree" }} />
            </CenteredBox>
                {isDimension &&   <Box sx={{display:"block",flex:2,flexDirection:"row",justifyContent:"end"}}>
            <Box sx={{display:"flex",flexDirection:"row",justifyContent:"end"}}>
                <Typography sx={{flex:1,fontSize:"10px",textAlign:"center"}}>Length</Typography>
                <Typography sx={{flex:1,fontSize:"10px",textAlign:"center"}}>Width</Typography>
                <Typography sx={{flex:1,fontSize:"10px",textAlign:"center",marginRight:"10px"}}>Height</Typography>
            </Box>
            <CenteredBox sx={{width:"68px"}}/>
                </Box>}
            </Box>
           
           
            <CenteredBox sx={{ width: "68px", height: "100%" }}></CenteredBox>
        </Box>
       
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, padding: "8px" }}>
            {cargodetails.map((cargodetail, i) => <Box sx={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start",gap:3 }}>
                {/* {enquiryStore.current.shipmentType.toLowerCase() === "air" &&  */}
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <AgMuiSelect2 onBlur={()=>{
                    }} rules={{ required: "Required" }} key={cargodetail.id + i} size="small" fontSize="12px" options={packageTypeList} width="100%" control={control} name={`cargodetails.${i}.packageType`} />
                </Box>
                {/* } */}
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <DetailsText rules={{ required: "Required", validate: validateGreaterThanZero }} key={cargodetail.id} width="100%" control={control} name={`cargodetails.${i}.quantity`} />
                </Box>
                {(isDimension) ? <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <DetailsText rules={{
                        required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            

                            if (typeof isValue === "string") {
                                return isValue
                            } 
                            else if(mode!=="fcl") {
                                let unit = mode === "air" ? weight : totalWeight;
                                console.log("the selceted value is  ", unit, "for the mode ",mode);
                                let maxValue = weightPerPackage[mode][unit.toLowerCase()];
                                if (parseFloat(value) > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue + " " + unit} `;
                                }
                            }
                        }
                    }} key={cargodetail.id} width="100%" control={control} name={`cargodetails.${i}.weight.value`} endAdornmentText={mode === "air" ? weight : totalWeight} />
                </Box> : <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <DetailsText rules={{ required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                           
                            if (typeof isValue === "string") {
                                return isValue
                            } 
                            else if(mode!=="fcl") {
                                let maxValue = weightPerPackage[mode][totalWeight.toLowerCase()]
                                if (parseFloat(value) > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue + " " + totalWeight} `;
                                }
                            }
                        } }} key={cargodetail.id} width="100%" control={control} name={`cargodetails.${i}.totalWeight.value`} endAdornmentText={totalWeight} />
                </Box>}
                {(isDimension) ? <Box sx={{ flex: 2, display: "flex", justifyContent: "center", alignItems: 'start', flexDirection: "row" }}>
                    <DetailsText rules={{
                        required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            if (typeof isValue === "string") {
                                return isValue
                            }
                             else if(mode!=="fcl"){
                               
                                let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].length
                                
                                if (value > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                                }
                            }
                        }
                    }} key={cargodetail.id + "0"} width="30%" control={control} name={`cargodetails.${i}.dimensions.length`} endAdornmentText={dimensionsUnit} />
                    <CenteredBox sx={{ paddingX: "4px" }}>x</CenteredBox>
                    <DetailsText rules={{
                        required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            if (typeof isValue === "string") {
                                return isValue
                            } 
                            
                            else if(mode!=="fcl"){
                               
                                let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].width
                                
                                if (value > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                                }
                            }
                        }
                    }} key={cargodetail.id + "1"} width="30%" control={control} name={`cargodetails.${i}.dimensions.width`} endAdornmentText={dimensionsUnit} />
                    <CenteredBox sx={{ paddingX: "4px" }}>x</CenteredBox>
                    <DetailsText rules={{
                        required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            if (typeof isValue === "string") {
                                return isValue
                            } 
                            else if(mode!=="fcl"){
                               
                                let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].height
                                
                                if (value > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                                }
                            }
                        }   
                    }} key={cargodetail.id + "3"} width="30%" control={control} name={`cargodetails.${i}.dimensions.height`} endAdornmentText={dimensionsUnit} />
                </Box> : <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <DetailsText rules={{ required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            if (typeof isValue === "string") {
                                return isValue
                            } 
                            else if(mode!=="fcl"){
                               
                                let maxValue = lclWeightForPort["totalvolume"][totalVolume.toLowerCase()]
                                
                                if (value > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue+" "+totalVolume} `;
                                }
                            }
                        } }} key={cargodetail.id} width="100%" control={control} name={`cargodetails.${i}.totalVolume.value`} endAdornmentText={totalVolume} />
                </Box>}
                {cargodetails.length - 1 === i ?
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "end", width: "68px" }}>
                        {cargodetails.length > 1 && <CenteredBox onClick={() => {
                            removeCargo(i);
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={deleteIcon} />
                        </CenteredBox>}
                        <CenteredBox onClick={() => {
                            let newCargo = new Cargo();
                            newCargo.totalVolume.unit = totalVolume;
                            newCargo.totalWeight.unit = totalWeight;
                            newCargo.weight.unit = weight;
                            appendCargo({ ...newCargo });
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={addIcon} />
                        </CenteredBox>
                    </Box>

                    : <Box sx={{ width: "68px", display: "flex", justifyContent: "start", flexDirection: "row" }}>
                        <CenteredBox onClick={() => {
                            removeCargo(i);
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={deleteIcon} />
                        </CenteredBox>
                    </Box>
                }
            </Box>)}
        </Box>
    </Box>
}

export function ContainerDetails({ form }) {
    const { control, watch, getValues, resetField, setValue } = form;
    const { fields: containerDetails, append: appendContainer, remove: removeContainer } = useFieldArray({
        control: control,
        name: "containerdetails"
    })
    const [typeList, setTypeList] = useState(containerTypeList)
    const temperatureControlled = watch("temperatureControlled");
    const stuffingType = watch("stuffingType")
    const destuffingType = watch("destuffingType")

    useEffect(() => {
        console.log("the tempereature controlled is ", temperatureControlled)
        if (temperatureControlled) {
            setTypeList(containerTypeListTC);
        // } else if (stuffingType.toLowerCase() !== "factory" || destuffingType.toLowerCase() !== "factory") {
        //     setTypeList(containerTypeList.slice(0, 4))
          
        } else {
            setTypeList(containerTypeList)
           
        }

    }, [temperatureControlled, stuffingType, destuffingType]);

    const validateGreaterThanZero = (value) => {
        return parseFloat(value) > 0 || 'Invalid Value';
    };


    const weightPerContainer = watch("containerdetails.0.unit")

    useEffect(()=>{
        for(let i = 1; i < containerDetails.length;i++){
               setValue(`containerdetails.${i}.unit`,weightPerContainer)
        }
    },[weightPerContainer])

    return <Box sx={{ height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "row", height: '35px', borderRadius: "5px", backgroundColor: "#F5F5F5", width: "100%" }}>
            <CenteredBox sx={{ flex: 1, fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }}>Container Type *</CenteredBox>
            <CenteredBox sx={{ flex: 1, flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }}>Number of Containers *</Typography>
            </CenteredBox>
            <CenteredBox sx={{ flex: 1, flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }}>Weight Per Container * x</Typography>
                <AgMuiSelect2 padding={0} control={control} name="containerdetails.0.unit" size="small" variant="standard" disableUnderline={true} options={["Kgs", "Tons", "Lbs"]} fontColor="#555555" iconColor="#555555" width="60px" style={{ fontSize: "12px", fontFamily: "Figtree" }} />
            </CenteredBox>
            <CenteredBox sx={{ minWidth: "68px", height: "100%" }}></CenteredBox>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, padding: "8px" }}>
            {containerDetails.map((cargodetail, i) => <Box sx={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", gap:3 }}>
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <AgMuiSelect2 rules={{required:"Required",validate: validateGreaterThanZero}} key={cargodetail.id+i} size="small" fontSize="12px" options={typeList}  width="100%" control={control} name={`containerdetails.${i}.containerType`} />
                </Box>
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <DetailsText  rules={{required:"Required",validate: validateGreaterThanZero}}  key={cargodetail.id+i} width="100%" control={control} name={`containerdetails.${i}.numContainers`}  />
                </Box>
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <DetailsText rules={{required:"Required" ,validate: (value)=>{
                        let containerType = getValues(`containerdetails.${i}.containerType`);
                       
                           if(parseFloat(value) <= 0){
                              return "Invalid value"
                           }
                           if(!containerType){
                            return
                        }
                        let maxValue = maxWeightPerContainerFcl[containerType][weightPerContainer.toLowerCase()];

                           if (parseFloat(value) >maxValue) {
                            return `value can't be greater than ${maxValue} ${weightPerContainer}.`
                           }
                           return;

                        }
                    }} key={cargodetail.id + i} width="100%" control={control} name={`containerdetails.${i}.wtPerContainer`} endAdornmentText={weightPerContainer} />
                </Box>
                {containerDetails.length - 1 === i ?
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "end", width: "68px" }}>
                        {containerDetails.length > 1 && <CenteredBox onClick={() => {
                            removeContainer(i);
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={deleteIcon} />
                        </CenteredBox>}
                        <CenteredBox onClick={() => {
                            let newContainer = new Container();
                            newContainer.unit = weightPerContainer;
                            appendContainer({ ...newContainer })
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={addIcon} />
                        </CenteredBox>
                    </Box>
                    :
                    <Box sx={{ width: "68px", display: "flex", justifyContent: "start", flexDirection: "row" }}>
                        <CenteredBox onClick={() => {
                            removeContainer(i);
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={deleteIcon} />
                        </CenteredBox>
                    </Box>
                }
            </Box>)}
        </Box>
    </Box>
}


export function VehicleDetails({ form }) {
    const { control } = form;
    const { fields: vehicledetails, append: appendVehicle, remove: removeVehicle } = useFieldArray({
        control: control,
        name: "vehicledetails"
    })



    const vehicles = {
        'tataAce': 'Tata Ace',
        'bolero': 'Bolero',
        'trailer14': '14 Foot Trailer',
        'tata407': 'Tata 407',
        'trailer20': '20 Foot Trailer',
        'trailer24': '24 Foot Trailer',
        'sxl32': '32 Foot SXL',
        'mxl32': '32 Foot MXL',
    }

    return <Box sx={{ height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "row", height: '35px', borderRadius: "5px", backgroundColor: "#F5F5F5", width: "100%" }}>
            <CenteredBox sx={{ flex: 1, fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }}>Select Vehicle Type</CenteredBox>
            <CenteredBox sx={{ flex: 1, flexDirection: "row", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: "black" }}>No. of Vehicles</Typography>
            </CenteredBox>
            <CenteredBox sx={{ minWidth: "68px", height: "100%" }}></CenteredBox>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, padding: "8px" }}>
            {vehicledetails.map((vehicledetail, i) => <Box sx={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start" }}>
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <AgMuiSelect2 key={vehicledetail.id + i} rules={{ required: "Required" }} fontSize="12px" values={Object.keys(vehicles)} options={Object.values(vehicles)} width="70%" control={control} name={`vehicledetails.${i}.vType`} />
                </Box>
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <DetailsText key={vehicledetail.id} rules={{ required: "Required" }} width="70%" control={control} name={`vehicledetails.${i}.numVehicles`} />
                </Box>
                {vehicledetails.length - 1 === i ?
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "end", width: "68px" }}>
                        {vehicledetails.length > 1 && <CenteredBox onClick={() => {
                            removeVehicle(i);
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={deleteIcon} />
                        </CenteredBox>}
                        <CenteredBox onClick={() => {
                            appendVehicle({
                                vType: "",
                                numVehicles: ""
                            })
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={addIcon} />
                        </CenteredBox>
                    </Box>
                    :
                    <Box sx={{ width: "68px", display: "flex", justifyContent: "start", flexDirection: "row" }}>
                        <CenteredBox onClick={() => {
                            removeVehicle(i);
                        }} sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "30px", height: "30px" }} src={deleteIcon} />
                        </CenteredBox>
                    </Box>
                }
            </Box>)}
        </Box>
    </Box>
}


function EnquiryConfirmationDialog({ open, cancelHandler, successHandler }) {
    return <Dialog open={open} >
        <DialogContent>
            We do not have these routes real-time but, would love to provide a customized solution.
            We will get in touch with your shortly over Phone or E-mail.
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                if (cancelHandler) {
                    cancelHandler();
                }
            }}>No</Button>
            <Button onClick={() => {
                if (successHandler) {
                    successHandler();
                }
            }}>Yes</Button>
        </DialogActions>
    </Dialog>
}

export function DetailsPage({ mode, form, onSubmit }) {
    useSnapshot(loader);
    const [open,setOpen] = useState(false);
    const { watch, resetField, setValue } = form
    const temperatureControlled = watch("temperatureControlled");
 const stuffing = watch("stuffingType")
 const deStuffing = watch("destuffingType")


    const container = { title: "Container Details", component : <ContainerDetails form={form} />, handler : (data)=>{
        enquiryStore.current.containerDetails = data.containerdetails;
    } }

    const cargo = { title: "Cargo Details", component : <CargoDetails form={form} />, handler : (data)=>{
         enquiryStore.current.cargoDetails = data.cargodetails;
         enquiryStore.current.cargoDimensionUnit = data.cargoDimensionUnit;
    } };

    const vehicle = { title: "Vehicle Details", component :  <VehicleDetails form={form}/>, handler : (data)=>{
        enquiryStore.current.vehicles = data.vehicledetails;
        enquiryStore.current.vehicles.forEach((vehicle)=>{
            enquiryStore.current.vehicleDetails[vehicle.vType] = vehicle.numVehicles;
        })
    } }

    let steppers = mode.toLowerCase() === "fcl" ?  [{...container}] : [ {...cargo}];
    // let [steppers, setStepperComponents] = useState(mode.toLowerCase() === "fcl" ?  [{...container}] : [ {...cargo}]) 
  
    if(enquiryStore.current.shipmentType.toLowerCase() === "fcl"){
        let origin = enquiryStore.current.shipmentScope.split("-")[0];
           
        let destination = enquiryStore.current.shipmentScope.split("-")[2];
        
            if((origin?.toLowerCase()==="door" && stuffing.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && deStuffing.toLowerCase() === "dock" )){
               steppers.push({...cargo})
            }
       }

    // if(enquiryStore.current.shipmentScope.includes("Door") && (enquiryStore.current.enqRoute.origin.includes('[IN') || enquiryStore.current.enqRoute.destination.includes('[IN'))){
    //     steppers.push({...vehicle});
    // }
    const { handleSubmit } = form;
    const [selectedStepperIndex, setStepper] = useState(0);
    const [custRefBox, setCustRefBox] = useState(false);
    const displayVehicleTable = () => {
        let otype = enquiryStore.current.shipmentScope.split("-")[0];

        let dtype = enquiryStore.current.shipmentScope.split("-")[2];

        let flag = false
        let origin = enquiryStore.current.enqRoute.origin
        let destination = enquiryStore.current.enqRoute.destination

        if (otype != 'Port' && origin.includes(',India,')) flag = true
        else if (dtype != 'Port' && destination.includes(',India,')) flag = true
        return flag
    }

    const saveEnquiry = () => {
        EnquiriesApi.saveEnquiry(() => {
            loader.hide();
            breadCrumbStore.setCurrentCrumb(0);
        });
    }


    // useEffect(()=>{
    //     if(enquiryStore.current.shipmentType.toLowerCase() === "fcl"){
    //         let origin = enquiryStore.current.shipmentScope.split("-")[0];
               
    //         let destination = enquiryStore.current.shipmentScope.split("-")[2];
            
    //             if((origin?.toLowerCase()==="door" && stuffing.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && deStuffing.toLowerCase() === "dock" )){
    //                 setStepperComponents((prev)=>{
    //                     return [...prev,{...cargo}]
    //                 })
    //             }
    //        }
    // },[stuffing,deStuffing])

    
 useEffect(()=>{
    if (temperatureControlled) {
        setValue("containerdetails",[new Container()])
        setStepper(0)
    } else if (stuffing.toLowerCase() !== "factory" || deStuffing.toLowerCase() !== "factory") {
        setStepper(0)
        setValue("containerdetails",[new Container()])
    } else {
        setStepper(0)
        setValue("containerdetails",[new Container()])
    }
 },[temperatureControlled, stuffing, deStuffing])
   

    useEffect(()=>{
         if(stuffing?.toLowerCase()==="factory"){
            // setStepperComponents((prev)=>{
            //     return prev.slice(0,1)
            // })
            setStepper(0);
            enquiryStore.current.cargoDetails = [new Cargo()];
            // resetField("cargodetails")
         } 
    },[stuffing])

    useEffect(()=>{
       if(deStuffing?.toLowerCase()==="factory"){
        // setStepperComponents((prev)=>{
        //     return prev.slice(0,1)
        // })
        setStepper(0);
          enquiryStore.current.cargoDetails = [new Cargo()];
        //   resetField("cargodetails")
       }
    },[deStuffing])

    useEffect(()=>{
       console.log("the steppers length is ", steppers.length)
    },[steppers])
     
    const submit = async (data) => {
        steppers[selectedStepperIndex]?.handler(data);
        if (selectedStepperIndex === steppers.length - 1) {
            enquiryStore.current.unnumber = data.unnumber;
            enquiryStore.current.packingGroup = data.packingGroup
            enquiryStore.current.quoteClass = data.quoteClass
            enquiryStore.current.stuffingType = data.stuffingType
            enquiryStore.current.destuffingType = data.destuffingType
            enquiryStore.current.minTemperature = data.minTemperature
            enquiryStore.current.maxTemperature = data.maxTemperature
            enquiryStore.current.temperature = data.temperature
            enquiryStore.current.cargoValue = data.cargoValue;
            // loader.show();
            let pricingStore = {
                gst: '',
                origin: '',
                destination: ''
            }
            let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getroutes`
            if (enquiryStore.current.shipmentType.toUpperCase() == 'FCL') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/getroutes`
            if (loginStore.isClient()) pricingStore.gst = configStore.currentSelectedEntity.gst;
            enquiryStore.current.gst = pricingStore.gst;
            enquiryStore.current.entity = pricingStore.gst;
            enquiryStore.current.createdBy = loginStore.userRec.email;
            let curDate = Utils.getCurrentDateString();
            let crd = '' + enquiryStore.current.enqRoute.cargoReadinessDate
            crd = crd.trim();
            let otype = enquiryStore.current.shipmentScope.split("-")[0];
            let dtype = enquiryStore.current.shipmentScope.split("-")[2];
            if (crd == '') {
                crd = curDate
                enquiryStore.current.enqRoute.cargoReadinessDate = curDate
            }
            let payload = {
                entity: pricingStore.gst,
                origin: enquiryStore.current.enqRoute.origin,
                destination: enquiryStore.current.enqRoute.destination,
                sscope: `${otype}-to-${dtype}`,
                crd: enquiryStore.current.enqRoute.cargoReadinessDate,
                curDate,
                cargoDetails: enquiryStore.current.cargoDetails,
                vehicleDetails: enquiryStore.current.vehicleDetails,
                enquiry: enquiryStore.current
            }

            // if (!PricingUtils.getTotalVehicleCount(payload.vehicleDetails) && displayVehicleTable()) {
            //     configStore.setModalMessage('At least one vehicle needs to be selected for India transport')
            //     return
            // }
            // if(mode.toLowerCase()==="air"){

            //     saveEnquiry();

            //     return;
            // }
            // let ret;
            // if (enquiryStore.current.hazardous == 'Yes' || enquiryStore.current.nonStackable == 'Yes') {
            //     ret = []
            // } else {
            // ret = await MonApi.apost(url, payload);
            // }
            // console.log("the route is  . . . . ",ret);

            // loader.hide();
            // if (ret?.error) {
            //     configStore.setModalMessage('' + ret.error, [], true, [], '', 5000)
            //     return;
            // } else if(!ret || !ret.length) {
            //      setOpen(true);
            //     return;
            // }
            // filtersState.routes = ret;
            // filtersState.filteredList = [...ret];
            if (onSubmit) {
                onSubmit();
        }
        const uniqueContainerType = [];
        const duplicateContainerType = [];
        data.containerdetails.forEach((obj) => {
            const value = obj['containerType'];
            if (uniqueContainerType.includes(value)) {
                duplicateContainerType.push(value);
            } else {
                uniqueContainerType.push(value);
            }
        });
        if(duplicateContainerType.length>0) {
            return configStore.setModalMessage(`Same ContainerType Is Not Allowed.`)
        }

        let scope = enquiryStore.current.shipmentScope
        if(enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()==="lcl" ){
            let totalWeight = 0;
            let totalVolume = 0;
            for(let i=0;i<enquiryStore.current.cargoDetails.length;i++){
                let cargo =  enquiryStore.current.cargoDetails[i];
                if( scope === 'Port-to-Port'){
                    totalWeight += parseFloat(cargo?.totalWeight?.value || 0.0);
                    totalVolume += parseFloat(cargo?.totalVolume?.value || 0.0)
                }else{
                    totalWeight += parseFloat(cargo?.weight?.value || 0.0);
                }
            }
            let weightUnit ;
            if(scope === "Port-to-Port"){
                weightUnit = enquiryStore.current.cargoDetails[0].totalWeight.unit;
            }else{
                weightUnit =  enquiryStore.current.cargoDetails[0].weight.unit;
            }
            let volumeUnit = enquiryStore.current.cargoDetails[0].totalVolume.unit;
            let maxWeight = lclWeightForPort["totalweight"][weightUnit.toLowerCase()];
            let maxVolume = lclWeightForPort["totalvolume"][volumeUnit.toLowerCase()]
            if(totalWeight>maxWeight){
                configStore.setModalMessage(`Total weight per shipment can't be greater than ${maxWeight} ${weightUnit}`)
                return;
            }else if( scope === "Port-to-Port" && totalVolume>maxVolume){
                configStore.setModalMessage(`Total volume per shipment can't be greater than ${maxVolume} ${volumeUnit}`)
                return;
            }
        }
        
        setCustRefBox(true)
            // breadCrumbStore.addCrumb("Choose Schedule");
        }else{
            console.log("the steppers executed in ",selectedStepperIndex);
            if(selectedStepperIndex<steppers.length-1){
                setStepper((prev)=>++prev);
            }
            
            // breadCrumbStore.addCrumb("Choose Schedule");
        } 
    }
    return <Box sx={{ maxHeight: "100%", width: "100%", flex: 1, display: "flex", flexDirection: "column", gap: 0.8, alignItems: "end", padding: "8px 8px 10px 10px" }}>
        <AgLoader />
        <EnquiryConfirmation />
        <EnquiryConfirmationDialog open={open} cancelHandler={() => {
            setOpen(false);
        }} successHandler={() => {
            setOpen(false);
            saveEnquiry();
        }} />
        <form noValidate onSubmit={handleSubmit(submit)} style={{ width: "100%", justifyContent: "space-between", display: "flex", flexDirection: "column", flex: 1, alignItems: "center", gap: 5 }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, width: '100%', height: "30px", overflow: "hidden" }}>
                {steppers.map((stepper, i) => (
                    <Box onClick={() => {
                        if (selectedStepperIndex > i) {
                            setStepper(i);
                        }
                    }} sx={{
                        display: "flex", flexDirection: 'row', gap: 1, justifyContent: "center", alignItems: "center"
                    }}>
                       { steppers.length > 1 && <Box sx={{ width: "18px", height: "18px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: selectedStepperIndex >= i ? "#2A6ED433" : "#AAAAAA33", color: selectedStepperIndex >= i ? "black" : "#AAAAAA", fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold" }}>{i + 1}</Box>}
                        <Typography sx={{
                            fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", color: selectedStepperIndex >= i ? "black" : "#AAAAAA", "&:hover": {
                                cursor: selectedStepperIndex > i ? "pointer" : "not-allowed"
                            }
                        }}>{stepper.title}</Typography>
                        {i !== steppers.length - 1 && <HorizontalDashedLine stroke={selectedStepperIndex >= i + 1 ? "#2A6ED4" : "#AAAAAA"} width="60px" dashArray="2,1" />}
                    </Box>
                ))}
            </Box>
            <Box sx={{ flex: 1, width: "100%", overflowY: "auto", display: "flex", flexDirection: "column" }}>
                {steppers[selectedStepperIndex]?.component || steppers[0].component}
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Button 
                // startIcon={selectedStepperIndex === steppers.length - 1 ? <img src={submitIcon} /> : <></>} 
                type="submit" size="small" variant="contained" sx={{ textTransform: "capitalize" }}>{selectedStepperIndex === steppers.length - 1 ? "Submit" : "Next"}</Button>
            </Box>
        </form>
        <CustomerReferencePopup custRefBox={custRefBox}
            handleClose={() => {
                setCustRefBox(false);
            }} successHandler={() => {
                setCustRefBox(false);
                
                saveEnquiry();
            }} laterHandler={() => {
                setCustRefBox(false)
                saveEnquiry();
            }} />

    </Box>
}

function CustomerReferencePopup({ custRefBox, handleClose, successHandler, laterHandler }) {
    const [refNumberValid, setRefNumberValid] = useState(false);
    const [customerReference, setCustomerReference] = useState('');
    // const [saveClicked, setSaveClicked] = useState(false);
    const handleSave = () => {
        // setSaveClicked(true)
        // Check if the field is empty
        if (customerReference.trim() === '') {
            setRefNumberValid(true);
        } else {
            // Proceed with the save action or call successHandler
            enquiryStore.current.enqRoute.customerReferenceNum = customerReference;
            successHandler();
        }
    };

    return (
        <Dialog open={custRefBox}>
            <DialogContent sx={{ backgroundColor: "#F5F5F5" }}>
                <Box sx={{ display: "flex", flexDirection: 'column' }}>
                    <Box onClick={() => handleClose()} sx={{
                        display: "flex", alignSelf: "end", justifyContent: "center", alignItems: "center", backgroundColor: "#2B6ED4", borderRadius: "5px", width: "max-content", padding: "8px", "&:hover": {
                            cursor: "pointer"
                        }
                    }}>
                        <img style={{ width: "18px", height: "18px" }} src={closeIcon} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: 'column', gap: 2, padding: "10px 20px" }}>
                        <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", gap: 1 }}>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "start", gap: 1 }}>
                            <Typography sx={{ fontFamily: "Figtree" }}>Enter Customer Reference</Typography>
                            <CustomStyledTextField
                                size="small"
                                fullWidth
                                error={refNumberValid}
                                value={customerReference}
                                onChange={(e) => {
                                    setCustomerReference(e.target.value);
                                    setRefNumberValid(e.target.value.trim() === ''); // Update refNumberValid based on the input value
                                }}
                            />
                        </Box>

                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: "#F5F5F5" }}>
                <Button onClick={() => laterHandler()} variant="contained" sx={{
                    color: "#2B6ED4", textTransform: "capitalize",
                    fontFamily: "Figtree", backgroundColor: "white", "&:hover": {
                        backgroundColor: "white"
                    }
                }}>Add Later</Button>
                <Button onClick={handleSave} variant="contained" sx={{
                    textTransform: "capitalize",
                    fontFamily: "Figtree"
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

