import Api from "../../state/Api";
import RoleUtils from "../../util/RoleUtils";

const GETLCLSCHEDULESMASTER = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/pg_getschedules/lcl`;
const GETMYACTIONLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/pg_getmyaction`;
const GETUSERS = `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/pg_getagusers`;
const GETENTITIES = `${process.env.REACT_APP_API_SERVER}/api/v1/entity/pg_entities`;
const GETBOOKINGSLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/pg_getmybooks`;
const GETQUOTATIONSLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/pg_getmyquotes`;
const GETUPLOADSCHEDULESLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/get/pg_getmyuploadschedules`;
const GETDISPLAY = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/pg_get4display`;
const GETVISIBILTYDISPLAY = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/getAllBookings`
const GETDTQUOTATIONSLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/pg_dtlist`;
const GETDTBOOKINGLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/pg_dtbookinglist`;
const GETDTCONTROCTLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/pg_dtcontractlist`;
const GETENTITIESV2 = `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/list/entities`;
const GETVEHICLES = `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles`;
const GETDRIVERS = `${process.env.REACT_APP_API_SERVER}/api/v1/drivers`;
const GETAPPROVALLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/credits`;
const GETBRANCHES = `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/list/branches`;
const GETUSERSLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/listusers`;
const GETHITLOGLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/siteinformation/list/pghitlog`;
const GETTRYSIGNUPLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/siteinformation/list/pgtrysignup`;
const GETNOUSERENTITYLIST = `${process.env.REACT_APP_API_SERVER}/api/v1/siteinformation/list/pgnouserentity`;
const GETUSERCUSNO = `${process.env.REACT_APP_API_SERVER}/api/v1/siteinformation/list/pgnloginuserlogs/customer/no`;
const GETUSERCUSYES = `${process.env.REACT_APP_API_SERVER}/api/v1/siteinformation/list/pgnloginuserlogs/customer/yes`;
const GETUSERAGRNO = `${process.env.REACT_APP_API_SERVER}/api/v1/siteinformation/list/pgnloginuserlogs/agraga/no`;
const GETUSERAGRYES = `${process.env.REACT_APP_API_SERVER}/api/v1/siteinformation/list/pgnloginuserlogs/agraga/yes`;
const GETDTACTIONS = `${process.env.REACT_APP_API_SERVER}/api/v1/dtactions/pg`

export default class ApiAgTable {
  static async fetchData(url, page, pageSize, pageFilter, pageSortBy, payload) {
    payload["page"] = page + 1;
    payload["limit"] = pageSize;
    payload["keyword"] = pageFilter;
    if (pageSortBy.length > 0) {
      const sortParams = pageSortBy[0];
      const sortyByDir = sortParams.desc ? -1 : 1;
      payload["sort"] = {};
      payload["sort"][sortParams.id] = sortyByDir;
    }
    try {
      const requestOptions = {
        method: "POST",
        headers: Api.H(),
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      let a = response.clone()
      a = await a.json()
      return response;
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }

  static async fetchDataQueryParams(
    url,
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    const queryParams = new URLSearchParams({
      type: payload.type || "all",
      category: payload.category || "all",
      status : payload.status || "all",
      frieghtforward: payload.frieghtforward || "all",
      countryfilter : payload.countryfilter || "all",
      page: page + 1,
      limit : pageSize ,
      keyword: pageFilter || "",
      // category: category || "all",
      // type: type || "all"
    }).toString();
    console.log("queryy" , payload);
    const sortParams = pageSortBy.length > 0 ? pageSortBy[0] : {};
    const sortyByDir = sortParams.desc ? -1 : 1;
    const sortField = sortParams.id;
    payload.sort = { [sortField]: sortyByDir };

    try {
      const requestOptions = {
        method: "GET",
        headers: Api.H(),
      };

      const URL = `${url}${queryParams ? `?${queryParams}` : ""}`;

      const response = await fetch(URL, requestOptions);
      return response;
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }

  static async fetchDataVehicle(
    url,
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    payload["page"] = page + 1;
    payload["limit"] = pageSize;
    payload["keyword"] = pageFilter;
    if (pageSortBy.length > 0) {
      const sortParams = pageSortBy[0];
      const sortyByDir = sortParams.desc ? -1 : 1;
      payload["sort"] = {};
      payload["sort"][sortParams.id] = sortyByDir;
    }
    try {
      const requestOptions = {
        method: "GET",
        headers: Api.H(),
        params: {
          status: "Pending",
          limit: 20,
        },
      };
      let URL = `${url}?status=${payload.filter}&limit=${payload.limit}&page=${payload.page}&keyword=${payload.keyword}`;

      const response = await fetch(URL, requestOptions);
      //  console.log("ressiii",response)
      return response;
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }
  
  static async fetchDataBranch(
    url,
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload,
    id
  ) {
    payload["page"] = page + 1;
    payload["limit"] = pageSize;
    payload["keyword"] = pageFilter;
    if (pageSortBy.length > 0) {
      const sortParams = pageSortBy[0];
      const sortyByDir = sortParams.desc ? -1 : 1;
      payload["sort"] = {};
      payload["sort"][sortParams.id] = sortyByDir;
    }
    try {
      const requestOptions = {
        method: "GET",
        headers: Api.H(),
        params: {
          status: "Pending",
          limit: 20,
        },
      };
      let URL = `${url}/${id}/?status=${payload.filter}&limit=${payload.limit}&page=${payload.page}&keyword=${payload.keyword}`;

      const response = await fetch(URL, requestOptions);
      //  console.log("ressiii",response)
      return response;
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }
  static async fetchDataDrivers(
    url,
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    payload["page"] = page + 1;
    payload["limit"] = pageSize;
    payload["keyword"] = pageFilter;
    if (pageSortBy.length > 0) {
      const sortParams = pageSortBy[0];
      const sortyByDir = sortParams.desc ? -1 : 1;
      payload["sort"] = {};
      payload["sort"][sortParams.id] = sortyByDir;
    }
    try {
      const requestOptions = {
        method: "GET",
        headers: Api.H(),
      };
      let URL = `${url}?status=${payload.filter}&limit=${payload.limit}&page=${payload.page}&keyword=${payload.keyword}`;

      const response = await fetch(URL, requestOptions);
      return response;
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }

  static async fetchDataUsers(
    url,
    entityId,
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    console.log("list api" , payload);
    payload["page"] = page + 1;
    payload["limit"] = pageSize;
    payload["keyword"] = pageFilter;
    if (pageSortBy.length > 0) {
      const sortParams = pageSortBy[0];
      const sortyByDir = sortParams.desc ? -1 : 1;
      payload["sort"] = {};
      payload["sort"][sortParams.id] = sortyByDir;
    }
    try {
      const requestOptions = {
        method: "GET",
        headers: Api.H(),
      };
      let URL = `${url}?entityId=${entityId}&status=${payload.status}&limit=${payload.limit}&page=${payload.page}&keyword=${payload.keyword}&branch=${payload.branch}`;

      const response = await fetch(URL, requestOptions);
      return response;
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }
  static async fetchDataEntityApprovals(
    url,
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    payload["page"] = page + 1;
    payload["limit"] = pageSize;
    payload["keyword"] = pageFilter;
    payload["status"] = payload.status || "Pending";
    payload["userRole"] = payload.userRole ;
    if (pageSortBy.length > 0) {
      const sortParams = pageSortBy[0];
      const sortyByDir = sortParams.desc ? -1 : 1;
      payload["sort"] = {};
      payload["sort"][sortParams.id] = sortyByDir;
    }
    try {
      const requestOptions = {
        method: "GET",
        headers: Api.H(),
      };
      let URL = `${url}?status=${payload.status}&limit=${payload.limit}&page=${payload.page}&keyword=${payload.keyword}&userRole=${payload.userRole}`;

      const response = await fetch(URL, requestOptions);
      return response;
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }

  static async lclschedulesData(page, pageSize, pageFilter, pageSortBy) {
    return ApiAgTable.fetchData(
      GETLCLSCHEDULESMASTER,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      {}
    );
  }

  static async myactionsData(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETMYACTIONLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }

  static async usersData(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETUSERS,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }

  static async entitiesData(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETENTITIES,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }

  static async bookingsData(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETBOOKINGSLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async quotationData(page, pageSize, pageFilter, pageSortBy, payload) {
    console.log(payload,"payload");
    return ApiAgTable.fetchData(
      GETQUOTATIONSLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async uploadCheduleData(
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    return ApiAgTable.fetchData(
      GETUPLOADSCHEDULESLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async getAllVisibility(
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    return ApiAgTable.fetchData(
      GETVISIBILTYDISPLAY,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async getAllDsr4Display(
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    return ApiAgTable.fetchData(
      GETDISPLAY,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async dtquotationData(
    page,
    pageSize,
    pageFilter,
    pageSortBy,
    payload
  ) {
    return ApiAgTable.fetchData(
      GETDTQUOTATIONSLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async dtBookingData(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETDTBOOKINGLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async dtContractData(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETDTCONTROCTLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async entitiesV2Data(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchDataQueryParams(
      GETENTITIESV2,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async vehiclesData(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchDataVehicle(
      GETVEHICLES,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async branchData(page, pageSize, pageFilter, pageSortBy, payload , id) {
    return ApiAgTable.fetchDataBranch(
      GETBRANCHES,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload,
      id
    );
  }
  static async driversData(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchDataDrivers(
      GETDRIVERS,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }

  static async approvalsList(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchDataEntityApprovals(
      GETAPPROVALLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }

  static async usersList(entityId,page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchDataUsers(
      GETUSERSLIST,
      entityId,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }

  static async hitlog(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETHITLOGLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async trysignup(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETTRYSIGNUPLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async nouserentity(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETNOUSERENTITYLIST,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async cusno(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETUSERCUSNO,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async cusyes(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETUSERCUSYES,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async argno(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETUSERAGRNO,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async argyes(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETUSERAGRYES,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
  static async dtactions(page, pageSize, pageFilter, pageSortBy, payload) {
    return ApiAgTable.fetchData(
      GETDTACTIONS,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    );
  }
}
