import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { bookingsStore, configStore, entityStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { addVgmdata, myactionsConstructUploads, vgmData } from "./tasks-static-data";
import MonApi from "../state/mongo-api";
import { loader } from "../util/loader";

let fieldMapping = {
    billOfLading: 'Booking Note/Bill of Lading No.',
    shipperName: 'Shipper Name',
    shipperLicense: 'Shipper Registration/ License No.',
    authorizedOfficial: 'Name of the official authorised by Shipper to sign the document',
    authorizedDesignation: 'Designation of the official authorised by Shipper to sign the document',
    authorizedContactDetails: '24 X 7 Contact Details of authorised official of Shipper',
    containerNum: 'Container No.',
    containerSize: 'Container Size (TEU/FEU/other)',
    maxWeight: 'Maximum permissible weight of Container as per the CSC plate (in Kgs)',
    verifiedGrossWeight: 'Verified Gross Mass Weight (in Kgs)',
    weightMethod: 'Method of ascertaining Verified Gross Mass (Method 1/Method 2)',
    wbRegnum: 'Weighbridge Registration Number',
    wbAddress: 'Address of Weighbridge',
    wbDate: 'Weighment Date',
    wbTime: 'Weighment Time',
    wbSlipnum: 'Weighment Slip No.',
    hazType: 'Type'
    
}
export function VgmForm(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task1 = taskStore.current
    const [alreadyExists, setAlreadyExists] = useState('No')
    let task = taskStore.currentFormData
    let cn = taskStore.currentContainer.split('.')
    if(cn.length>1){
        cn = cn[1].trim()
    }
    task.containerSize = cn
    function check() {
            let already_exists = 'No'

            
                Object.keys(task1.data).map((e) =>{
                    Object.keys(task1.data[e]).map((k) => {
                        if(k==['containerNum'] && taskStore.currentContainer != e){
                            if(task1.data[e][k].length>0 && task1.data[e][k]==task.containerNum){
                                already_exists = 'Yes'
                            }
                        }
                        
                    })
                    
                })

            
            
            setAlreadyExists(already_exists)
    }
    useEffect(() => {
        
        
        let unsubscribe = subscribe(taskStore, () => {
            check()
        })
        return unsubscribe
    }, [taskStore])
    return (
        <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
            <tr>
                <td>Booking Note/Bill of Lading No.</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.billOfLading} onChange={(e) => task.billOfLading = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Shipper Name</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.shipperName} onChange={(e) => task.shipperName = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Shipper Registration/ License No.</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.shipperLicense} onChange={(e) => task.shipperLicense = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Name of the official authorised by Shipper to sign the document</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.authorizedOfficial} onChange={(e) => task.authorizedOfficial = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Designation of the official authorised by Shipper to sign the document</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.authorizedDesignation} onChange={(e) => task.authorizedDesignation = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>24 X 7 Contact Details of authorised official of Shipper</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.authorizedContactDetails} onChange={(e) => task.authorizedContactDetails = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Container No.</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.containerNum} onChange={(e) => task.containerNum = e.target.value} />
                    {(alreadyExists=='Yes')?<>Container No is already exists</>:<></>}
                </td>
            </tr>
            <tr>
                <td>Container Size (TEU/FEU/other)</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.containerSize} onChange={(e) => task.containerSize = e.target.value} disabled/>
                </td>
            </tr>
            <tr>
                <td>Maximum permissible weight of Container as per the CSC plate (in Kgs)</td>
                <td>
                    <input style={{ width: '20rem' }} type="number" value={task.maxWeight} onChange={(e) => task.maxWeight = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Verified Gross Mass Weight (in Kgs)</td>
                <td>
                    <input style={{ width: '20rem' }} type="number" value={task.verifiedGrossWeight} 
                    onChange={(e) => {
                        if(Number(task.maxWeight)>=Number(e.target.value)){
                            task.verifiedGrossWeight = e.target.value
                        }else{
                            task.verifiedGrossWeight = task.maxWeight
                        }
                    }}
                     disabled={task.maxWeight==undefined || task.maxWeight.trim().length==0}/>
                </td>
            </tr>
            <tr>
                <td>Method of ascertaining Verified Gross Mass (Method 1/Method 2)</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.weightMethod} onChange={(e) => task.weightMethod = e.target.value} disabled/>
                </td>
            </tr>
            <tr>
                <td>Weighbridge Registration Number</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.wbRegnum} onChange={(e) => task.wbRegnum = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Address of Weighbridge</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.wbAddress} onChange={(e) => task.wbAddress = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Weighment Date</td>
                <td>
                    {
                        (task1.version_v2!=undefined && task1.version_v2==true)?
                        <input type="date" style={{ width: '20rem' }} value={task.wbDate} onChange={(e) => task.wbDate = e.target.value} />:
                        <input style={{ width: '20rem' }} value={task.wbDate} onChange={(e) => task.wbDate = e.target.value} />
                    }
                </td>
            </tr>
            <tr>
                <td>Weighment Time</td>
                <td>
                    {
                        (task1.version_v2!=undefined && task1.version_v2==true)?
                        <>
                        <div className="columns mx-0" style={{ width: '20rem' }}>
                        <div class="column is-2" style={{paddingLeft:"0",paddingRight:"0",margin:"0"}}>
                            <input type="number"  style={{ width: '100%' }} value={task.wbTimehh} placeholder="hh"
                            onKeyDown={(e) =>{
                                if(["e", "E","-","+"].includes(e.key) || ((["0","1","2","3","4","5","6","7","8","9"].indexOf(e.key)!=-1) && e.target.value.trim().length==2)){
                                    e.preventDefault()
                                }
                            }}
                            onChange={(e) => {
                                if(+e.target.value>12){
                                    task.wbTimehh=12
                                }else{
                                    task.wbTimehh = e.target.value
                                }
                                }} />
                        </div>
                        <div class="column is-2" style={{paddingLeft:"0",paddingRight:"0",margin:"0"}}>
                            <input type="number" style={{ width: '100%' }}  value={task.wbTimemm} placeholder="mm"
                            onKeyDown={(e) =>{
                                if(["e", "E","-","+"].includes(e.key) || ((["0","1","2","3","4","5","6","7","8","9"].indexOf(e.key)!=-1) && e.target.value.trim().length==2)){
                                    e.preventDefault()
                                }
                            }}
                            onChange={(e) => {
                                if(+e.target.value>59){
                                    task.wbTimemm=59
                                }else{
                                    task.wbTimemm = e.target.value
                                }
                                }} />
                        </div>
                        <div class="column is-2" style={{paddingLeft:"0",paddingRight:"0",margin:"0"}}>
                        <select style={{ width: '100%' }} value={task.wbTimeAP} onChange={(e) => {
                                task.wbTimeAP = e.target.value
                        }}>
                    {
                        ['AM','PM'].map(e=> <option value={e}>{e}</option>)
                    }
                </select>
                        </div>
                        <div class="column is-6"></div>
                        </div>
                        </>
                        :
                        <input style={{ width: '20rem' }} value={task.wbTime} onChange={(e) => task.wbTime = e.target.value} />
                    }
                    
                </td>
            </tr>
            <tr>
                <td>Weighment Slip No.</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.wbSlipnum} onChange={(e) => task.wbSlipnum = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Type</td>
                <td>
                <select style={{width:'20rem'}} value={task.hazType} onChange={(e) => {
                    task.hazType = e.target.value
                }}>
                    {
                        ['General','Hazardous','Temperature Controlled'].map(e=> <option value={e}>{e}</option>)
                    }
                </select>
                   
                </td>
            </tr>
            {
                (task.hazType == 'Hazardous')?<>
<tr>
                <td>IMDG Class</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.imdgClass} onChange={(e) => task.imdgClass = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>Packing Group</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.packingGroup} onChange={(e) => task.packingGroup = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>UN Number</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.unnumber} onChange={(e) => task.unnumber = e.target.value} />
                </td>
            </tr>
            <tr>
                <td>UN No.</td>
                <td>
                    <input style={{ width: '20rem' }} value={task.unno} onChange={(e) => task.unno = e.target.value} />
                </td>
            </tr>
                </>:<></>
            }
            
        </table>
    )
}
export default function Vgmdecl(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(loader)
    const [resubmit, setResubmit] = useState(false)
    let task = taskStore.current
    let stage = task.stage
    let containers = Object.keys(task.data)
    console.log('INSIDE FORM', taskStore.currentContainer, '/')
    let submitEnabled = true
    containers.map(e => {
        if (task.data[e].status === 'PENDING') submitEnabled = false
    })
    if(stage==4){
        let l = false
        containers.map(e => {
            if(task.data[e].cntnrStatus != undefined && task.data[e].cntnrStatus.trim().length>0 && ["VERIFIED","PENDING"].indexOf(task.data[e].cntnrStatus) == -1){
                l = true
            }
        })
        if(l != resubmit){
            setResubmit(l)
        }
    }
    const fu_resubmit = async () =>{
        containers.map(e => {
            if(task.data[e].cntnrStatus != undefined && task.data[e].cntnrStatus.trim().length>0 && ["VERIFIED","PENDING"].indexOf(task.data[e].cntnrStatus) == -1){
                task.data[e]["status"] = "PENDING"
                for (let i = 0; i < task.files.length; i++) {
                    let k = task.files[i];
                    if(k.label.replace(' - VGM Declaration','') == e){
                        k.approved = "Reject"
                        k.rejectReason = task.data[e].cntnrStatus
                    }
                    if(k.label.replace(' - Weighment Slip','') == e){
                        k.approved = ""
                        k.rejectReason = ""
                    }
                    task.files[i] = k
                }
                delete task.data[e]['vgmId']
                delete task.data[e]['cntnrStatus']
            }
        })
        task.status = "PENDING"
        task.pendingWith = "CUSTOMER"
        task.customerStatus = "PENDING"
        delete task.odexStatus
        task.stage = 1
        console.log(task)
        //await Taskapi.updateTask(task)
    }

    const [disableButton, setDisableButton] = useState(true)
    
    function check() {
        let disable = false
            let checkfile = []
            if (stage === 1 && taskStore.currentContainer.length > 0) {
                
                let containerNum = ""
                Object.keys(taskStore.currentFormData).map((e) =>{
                    if(e == 'containerNum'){
                        containerNum = taskStore.currentFormData[e]
                    }
                    if(taskStore.current.version_v2!=undefined && taskStore.current.version_v2==true){
                        if(taskStore.currentFormData['hazType']=='Hazardous'){
                            if(taskStore.currentFormData[e].length==0 && e!="wbTime"){
                                disable = true
                            }
                        }else{
                            if(taskStore.currentFormData[e].length==0 && e!='imdgClass' && e!='packingGroup' && e!='unno' && e!='unnumber' && e!="wbTime"){
                                disable = true
                            }
                        }
                    }else{
                        if(taskStore.currentFormData['hazType']=='Hazardous'){
                            if(taskStore.currentFormData[e].length==0 && e!="wbTimehh" && e!="wbTimemm" && e!="wbTimeAP"){
                                disable = true
                            }
                        }else{
                            console.log(taskStore.current.version_v2)
                            if(taskStore.currentFormData[e].length==0 && e!='imdgClass' && e!='packingGroup' && e!='unno' && e!='unnumber' && e!="wbTimehh" && e!="wbTimemm" && e!="wbTimeAP"){
                                disable = true
                            }
                        }
                    }
                    
                    
                }) 
                Object.keys(task.data).map((e) =>{
                    Object.keys(task.data[e]).map((k) => {
                        if(k==['containerNum'] && taskStore.currentContainer != e){
                            if(task.data[e][k].length>0 && task.data[e][k]==containerNum){

                                disable = true
                            }
                        }
                        
                    })
                    
                })

            }
            if (stage === 2) {
                taskStore.current.files.map((e) =>{
                    if(checkfile.indexOf(e.label) == -1){
                        if(e.filename.length==0){
                            disable = true
                        }
                    }
                })
            }
            setDisableButton(disable)
    }
    if(stage==2 && !disableButton){
        
        let disable = false
            let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                    if(e.filename.length==0){
                        disable = true
                    }
                
            }
        })
        if(disable){
            setDisableButton(disable)
        }
        
    }
    useEffect(() => {
        
        
        let unsubscribe = subscribe(taskStore, () => {
            check()
        })
        return unsubscribe
    }, [taskStore])
    useEffect(() => {
        check()
    }, [task])
    if (stage === 1 && taskStore.currentContainer.length === 0) {
        return (
            <>
                <hr />
                <div><strong>Please enter Verified Gross Mass details for various containers</strong></div>
                <hr />
                <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
                    {
                        containers.map(e => {
                            let d = task.data[e]
                            let disable = false
                            let approved = ''
                            let rejectReason = ''
                            task.files.map((k)=>{
                                if(k.label.replace(' - VGM Declaration','') == e){
                                    approved = k.approved
                                    rejectReason = k.rejectReason
                                    if(approved=='Approve'){
                                        disable = true
                                    }
                                }
                            })
                            return (
                                <tr>
                                    <td>{e}{(Number(d.containerNum)>0)?<> / {d.containerNum}</>:<></>}</td>
                                    <td>
                                        <button className="button is-small islink" disabled={disable}
                                            onClick={() => {
                                                taskStore.currentFormData = d
                                                taskStore.currentContainer = e
                                            }}>
                                            Click here to fill the input fields
                                        </button>
                                    </td>
                                    <td>
                                        {d.status}
                                    </td>
                                    {(approved=="Reject")?
                                    <>
                                    <td>
                                        {approved}
                                    </td>
                                    <td>
                                        {rejectReason}
                                    </td>
                                    </>:<></>}
                                </tr>
                            )
                        })
                    }
                </table>
                <hr />
                <button disabled={!submitEnabled} className="button is-small islink"
                    onClick={async () => {
                        await Taskapi.genvgmdecl(taskStore.current, fieldMapping)
                        taskStore.current.stage = 2
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.updateTask(taskStore.current)
                        //taskStore.myActionVisible = false
                        //configStore.setModalMyacticecompleted("Download the VGM Document, and upload the document printed on the letterhead.",false)       
                    }}>Submit</button>
            </>
        )
    }
    if (stage === 1 && taskStore.currentContainer.length > 0) {
        taskStore.currentFormData = taskStore.current.data[taskStore.currentContainer]
        return (
            <>
                <hr />
                <div><strong>VERIFIED GROSS MASS for "{taskStore.currentContainer}"</strong></div>
                <hr />
                <VgmForm />
                <hr />
                <button className="button is-small islink" disabled={disableButton}
                    onClick={async () => {
                        loader.show()
                        let t = {}
                        let c = taskStore.currentFormData
                        t.authorizedOfficial = c.authorizedOfficial
                        t.authorizedDesignation = c.authorizedDesignation
                        t.authorizedContactDetails = c.authorizedContactDetails
                        t.wbRegnum = c.wbRegnum
                        t.wbAddress = c.wbAddress
                        await Taskapi.setEntityVgmDetails(entityStore.entityRec._id, t)
                        taskStore.currentFormData.status = 'COMPLETED'
                        taskStore.current.data[taskStore.currentContainer] = { ...taskStore.currentFormData }
                        await Taskapi.updateTask(taskStore.current)
                        taskStore.currentContainer = ''
                        loader.hide()
                    }}>Submit</button>
            </>
        )
    }

    if (stage === 2) {
        return (
            <>
                <div>
                    <span><strong>Download generated VGM Declaration and upload attested copy</strong></span>
                </div>
                <div>
                    <div>
                        <Taskfiletable />
                    </div>
                </div>
                <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 3
                        taskStore.current.pendingWith = 'AGRAGA'
                        taskStore.setCustomerComplete()
                        await Taskapi.updateTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Submit</button>
            </>
        )
    }
    if (stage >= 3) {
        return (
            <>
                <div>
                    <span><strong>VGM Declaration</strong></span>
                </div>
                <div>
                    <div>
                    <Taskfileapproval />
                    </div>
                </div>
                {
                    (stage === 3) ?
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {

                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected) {
                                    //await Taskapi.updateTask(taskStore.current)
                                    //taskStore.current.stage = 1
                                    // taskStore.current.pendingWith = 'CUSTOMER'
                                    taskStore.current.customerStatus = 'PENDING'
                                    taskStore.current.stage = 2
                                    taskStore.current.pendingWith = 'CUSTOMER'
                                    await Taskapi.updateTask(taskStore.current)
                                }
                                else {
                                    taskStore.current.stage = 4
                                    taskStore.current.status = 'COMPLETED'
                                    taskStore.current.pendingWith = 'AGRAGA'
                                    taskStore.setComplete()
                                    
                                }
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                        : <></>
                }
                {
                (stage==4 && task && task.data && taskStore.current.version_v2!=undefined && taskStore.current.version_v2==true)?<>
                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Container No</th>
                            <th>VGM ID</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(task.data).map((e,i) =>{
                                return <tr>
                                <td>{i+1}</td>
                                <td>{task.data[e].containerNum}</td>
                                <td>{(task.data[e]['vgmId'] && task.data[e]['vgmId'].length>0)?task.data[e]['vgmId']:""}</td>
                                <td>{(task.data[e]['cntnrStatus'] && task.data[e]['cntnrStatus'].length>0)?task.data[e]['cntnrStatus']:""}</td>
                            </tr>
                            })
                        }
                    </tbody>
                    </table>
                    <hr />
                    {(resubmit)?<button className="button is-small islink"
                    onClick={async () => {
                        fu_resubmit()
                    }}>Resubmit</button>:""}
                    
                </>:""
                }
            </>
        )
    }
}
export function VgmdeclTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    let task = taskStore.current
    let containerTable = bookingsStore.current.contract.containerList
    if(taskStore.current.actionName=='VGM Declaration'){
        taskStore.current.entitycountry = entityStore.entityRec.country.toLowerCase()
    }
    //console.log('inside vgm trigger', containerTable)
    /*if (!containerTable || !containerTable.length) {
        configStore.setModalMessage('Booking does not have any container detail')
        return <></>
    }*/

    let stage = task.stage
    return (
        <>
            <TaskDatetime />
            <hr />
            <p>
                <button className="button islink"
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        let data = await populateVgm()
                        console.log(data)
                        task.data = {}
                        task.version_v2 = true
                        let files = []
                        containerTable.map(e =>{
                            for (let i = 0; i < Number(e.numContainers); i++) {
                                task.data[(i+1)+'. '+e.containerType] = { ...data }
                                files.push((i+1)+'. '+e.containerType+' - VGM Declaration')
                                files.push((i+1)+'. '+e.containerType+' - Weighment Slip')
                            }
                        }
                             
                             )
                        task.files = myactionsConstructUploads(files)
                        task.original_file = Object.keys(files)
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}
let fieldMapping1 = {
    billOfLading: 'Booking Note/Bill of Lading No.',
    shipperName: 'Shipper Name',
    shipperLicense: 'Shipper Registration/ License No.',
    authorizedOfficial: 'Name of the official authorised by Shipper to sign the document',
    authorizedDesignation: 'Designation of the official authorised by Shipper to sign the document',
    authorizedContactDetails: '24 X 7 Contact Details of authorised official of Shipper',
    containerNum: 'Container No.',
    containerSize: 'Container Size (TEU/FEU/other)',
    maxWeight: 'Maximum permissible weight of Container as per the CSC plate (in Kgs)',
    verifiedGrossWeight: 'Verified Gross Mass Weight (in Kgs)',
    weightMethod: 'Method of ascertaining Verified Gross Mass (Method 1/Method 2)',
    wbRegnum: 'Weighbridge Registration Number',
    wbAddress: 'Address of Weighbridge',
    wbDate: 'Weighment Date',
    wbTime: 'Weighment Time',
    wbSlipnum: 'Weighment Slip No.',
    hazType: 'Type',
    imdgClass: 'IMDG Class',
    packingGroup: 'Packing Group',
    unno: 'UN No.'
}
async function populateVgm() {
    let b = bookingsStore.current
    let c = b.contract
    let isporentitycountry = false
    let t = { ...vgmData }
    let e = entityStore.entityRec

    if (c.portOfReceipt.toLowerCase().indexOf(e.country.toLowerCase()) >= 0) isporentitycountry = true
    console.log('INSIDE PRE-POPULATE VGM=',c.portOfReceipt, e.country, isporentitycountry)
    if (isporentitycountry) {
        t.shipperLicense = e.IECCode
        console.log('INSIDE ISPORENTITY', e.vgm)
        if (e.vgm) {
            t.authorizedOfficial = e.vgm.authorizedOfficial
            t.authorizedDesignation = e.vgm.authorizedDesignation
            t.authorizedContactDetails = e.vgm.authorizedContactDetails
            t.wbRegnum = e.vgm.wbRegnum
            t.wbAddress = e.vgm.wbAddress
        }
    }
    if (b.carrierMBL === 'No') t.billOfLading = b.erpJobId
    t.shipperName = b.shipper
    t.hazType = 'General'
    if (c.hazardous === 'Yes') t.hazType = 'Hazardous'
    else if (c.temperatureControlled === 'Yes') t.hazType = 'Temperature Controlled'
    if (t.hazType === 'Hazardous') {
        t.imdgClass = c.quoteClass
        t.packingGroup = c.packingGroup
        t.unnumber = c.unnumber
    }


    if(b.contract.containerList.length==1){
        if(b.contract.containerList[0].numContainers==1){
            let ret = await MonApi.find('Bookingdsr', { _id:b._id })
            if(ret.length>0){
                console.log(ret[0]['container_number'])
                t.containerNum =  ret[0]['container_number']
            }
        }
     }
    

    return t
}


export function VgmdeclInit() {

}