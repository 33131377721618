import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import logo from '../aglogo.svg'
import Api from '../state/Api'
import { bookingsStore, configStore, contractsStore, entityStore, loginStore } from '../state/CommonState'
import Utils from '../util/Utils'
import LCLChargeClientView from '../enquiry/LCLChargeClientView'
import ShipmentGraph, { graphNode } from '../my-actions/shipment-graph'
import BookingSchedule from './booking.schedule'
import { rtpStore } from './pricing-store'
import { Button } from '@mui/material'
import { ArrowDownwardRounded, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import airlogoMap from '../schedules/air-logo'
import { enquiryStore } from '../enquiry/enquiry-data'
import BranchApi from '../state/branch-api'
import EntityApi from '../state/entity-api'
import { BRANCH_STATUS } from '../util/constants'

function _g(node) {
    let e = new graphNode()
    //Object.assign(e, { r1: 'r1', r2: 'r2', r3: 'r3', r4: 'r4' })
    Object.assign(e, node)
    e.isActive = true
    e.activelines = 2
    return e
}


export default function LCLPricingSchedule(props) {
    const [showCharges, setShowCharges] = useState(false)
    const [ptbVisible, setptbVisible] = useState(true)

    useSnapshot(contractsStore)
    useSnapshot(bookingsStore)
    let brec = bookingsStore.current
    useSnapshot(configStore)
    useSnapshot(rtpStore)
    let hideme = props.hideme
    let cwfVisible = props.cwfVisible
    //if (!cwfVisible && !ptbVisible) setptbVisible(true)
    let rec = contractsStore.current
    if (props.contract) rec = props.contract
    let subscript = -1
    if (props.subscript >= 0) subscript = props.subscript

    if (!rec) return <></>

    let e = { ...props.data }
    let isDummy = props.isDummy ? true : false
    //if (props.isDummy) return <div>{JSON.stringify(rec)}</div>
    if (props.isDummy) {
        e = {
            'por': rec.portOfReceipt,
            'pol': rec.portOfLoading,
            'pod': rec.portOfDischarge,
            'fpod': rec.finalPlaceOfDelivery,
            'rep': rec.reworkingPort,
            'VESSEL NAME': '',
            'VOYAGE': '',
            'STUFFING CUT OFF DATE': '2020-01-01',
            'ETD (POL)': '2020-01-01',
            'ETA (POD)': '2020-01-01',
            'CO-LOADER': ''
        }
    }
    let theSchedule = e
    if (!e['POR (CFS / ICD / PORT)']) e['POR (CFS / ICD / PORT)'] = e['POL']
    let srec = {
        por: e.por,
        pol: e.pol,
        pod: e.pod,
        vessel: e['VESSEL NAME'],
        voyage: e['VOYAGE'],
        cutOffDt: e['STUFFING CUT OFF DATE'],
        etdPol: e['ETD (POL)'],
        etaPod: e['ETA (POD)'],
        coLoader: e['CO-LOADER']
    }
    const pod = { location: srec.pod }
    const por = { location: srec.por }
    const pol = { location: srec.pol }

    let showOrigin = false
    let showDestination = false
    if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
    if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
    let etaDate = Utils.newDate(e.etapod)
    if (e.pod != e.fpod) etaDate = Utils.newDate(e.etafpod)
    let etdDate = Utils.newDate(e.etdpol)
    let days = Utils.getDays(etdDate, etaDate)

    let _date = (dt) => {
        return Utils.formatDateShort(new Date(dt))
    }

    //console.log('LCLCOMPONENT***',srec, srec.por, srec.pol, por.location, pol.location)
    let graphnodes = []
    if (showOrigin) {
        graphnodes.push(_g({
            r2: rec.origin,
            graphic: 'origin'
        }))
    }
    graphnodes.push(_g({
        r1: (props.isDummy) ? '' : 'Cut-off ' + _date(e.cutoffpor),
        r2: por.location,
        r3: (por.location == pol.location && !props.isDummy) ? 'ETD ' + Utils.formatDateShort(etdDate) : ''
    }))
    if (por.location !== pol.location) {
        graphnodes.push(_g({
            r2: pol.location,
            r3: (props.isDummy) ? '' : 'ETD ' + _date(e.etdpol)
        }))
    }
    if (!isDummy) {
        graphnodes.push(_g({
            r1: e['VESSEL NAME'] + '-' + e['VOYAGE'],
            transparent: true
        }))
    }
    if (e.etdrw && e.rep) {
        graphnodes.push(_g({
            r3: 'ETD ' + _date(e.etdrw),
            r2: e.rep
        }))
    }
    if (e.etarw && e.rep) {
        graphnodes.push(_g({
            r1: 'ETA ' + _date(e.etarw),
            r2: e.rep
        }))
    }
    if (e.rep && props.isDummy) {
        graphnodes.push(_g({
            r2: e.rep
        }))
    }
    graphnodes.push(_g({
        r1: (props.isDummy) ? '' : 'ETA ' + _date(e.etapod),
        r2: pod.location
    }))
    if (e.pod != e.fpod) {
        graphnodes.push(_g({
            r1: (props.isDummy) ? '' : 'ETA ' + _date(e.etafpod),
            r2: e.fpod
        }))
    }
    if (showDestination) {
        graphnodes.push(_g({
            r2: rec.destination,
            graphic: 'destination'
        }))
    }

    let grandTotal = 0.0
    let voldis = 0.0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
            //grandTotal -= voldis
        }
        else if (e.isIncluded == 'Yes')
            grandTotal += e.total * erate
    })
    let usdRate = configStore.getExchangeRate('USD')
    let grandusd = grandTotal / usdRate
    let rrr = props.pricingRec
    let sss = props.pricingRec?.dummyContract

    let airlogo = logo
    async function checkBranchGst(data){
        let isGstAvail = false
      await  data.map((r,i)=>{
        if( r.status === BRANCH_STATUS.VERIFIED && r.gst.length > 0 && r.branchAddresses[0].address.length > 0) {
          isGstAvail= true
         return isGstAvail
        } 
        } 
        )
        return isGstAvail
    
       }
  if (brec && brec.iata) airlogo = airlogoMap[brec.iata]
    return (
        <div className="box">
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                <div className="column is-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    <img src={airlogo} />
                </div>
                <div className="column is-9">
                    {
                        (days) ?
                            <>
                                <div style={{ color: 'grey', width: '100%', textAlign: 'center' }}>Tentative Ocean Transit Time</div>
                                <div style={{ fontSize: '0.9rem', width: '100%', textAlign: 'center' }}><strong>{e.transitTime} days</strong></div>
                            </>
                            :
                            <></>
                    }
                    <div >
                        <ShipmentGraph insidepricing={true} graphNodes={graphnodes} />
                    </div>
                </div>
                <div className='column is-2' style={{ textAlign: 'center' }}>
                    {
                        (rec.quoteValue <= 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(grandTotal)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(grandusd, 'USD')}</div>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                            </>
                    }
                    {/*
                        (voldis > 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <></>
                */}
                    {/*
                        (voldis > 0 && grandTotal - voldis > 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}>(Final Discounted Offer <strong>{Utils.N(grandTotal - voldis)}</strong></div>
                            </> : <></>
            */}
                    <div style={{ fontSize: '0.6rem', color: 'grey' }}>(Taxes, as Applicable)</div>
                    {
                        (!props.nobutton && props.pricingRec) &&
                        <>
                            {
                                (!loginStore.isClient()) &&
                                <button className='button is-rounded is-small is-info' onClick={() => {
                                    Api.saveQuoteNoTransform(props.pricingRec)
                                }}>Save Quote</button>
                            }
                            {
                                (loginStore.isClient()) &&
                                <button className='button is-rounded is-small is-info' onClick={async () => {
                                    await Api.saveQuoteAndContract(props.pricingRec, isDummy)
                                }}>Save Contract</button>
                                // <Button sx={{textTransform:"capitalize"}}>Save Contract</Button>
                            }
                        </>
                    }
                    {
                        (!props.nobutton) ?
                            <>
                                <button className='button is-rounded islink is-small' onClick={async () => {


                                   rtpStore.routeSubscript = subscript
                                    bookingsStore.initialize()
                                    bookingsStore.current.schedule = theSchedule
                                    bookingsStore.current.graphNodes = graphnodes
                                    let rec1 = { ...rec }
                                    bookingsStore.current.contract = { ...rec }
                                    let filteredCharges = bookingsStore.current.contract.chargesList.filter(e => e.isIncluded == 'Yes')
                                    bookingsStore.current.contract.chargesList = [...filteredCharges]
                                    bookingsStore.current.contract.quotationNum = 'SPOT'
                                    bookingsStore.current.contractNum = 'SPOT'
                                    bookingsStore.current.contractRefNum = 'SPOT'
                                    bookingsStore.current.hasToShowDummySchedule = false
                                    bookingsStore.current.emailid = loginStore.email
                                    bookingsStore.current.createdFromPricing = true
                                    bookingsStore.current.contract.entityId = contractsStore.current.entityId
                                    console.log('\n\n***TC length=', bookingsStore.current.contract.tc.length, '***\n')
                                    if (isDummy) bookingsStore.current.hasToShowDummySchedule = true

                                    contractsStore.current = rec
                                    console.log('polki', rec)
                                    hideme(true)
                                    setptbVisible(false)




            //                          await BranchApi.getAllBranches(props.pricingRec.dummyContract.entityId , async (data)=> {
            //                         // let results = await checkBranchGst(data)
            //                         // if (results=== true) {
            //                         rtpStore.routeSubscript = subscript
            //                         bookingsStore.initialize()
            //                         bookingsStore.current.schedule = theSchedule
            //                         bookingsStore.current.graphNodes = graphnodes
            //                         let rec1 = { ...rec }
            //                         bookingsStore.current.contract = { ...rec }
            //                         let filteredCharges = bookingsStore.current.contract.chargesList.filter(e => e.isIncluded == 'Yes')
            //                         bookingsStore.current.contract.chargesList = [...filteredCharges]
            //                         bookingsStore.current.contract.quotationNum = 'SPOT'
            //                         bookingsStore.current.contractNum = 'SPOT'
            //                         bookingsStore.current.contractRefNum = 'SPOT'
            //                         bookingsStore.current.hasToShowDummySchedule = false
            //                         bookingsStore.current.emailid = loginStore.email
            //                         bookingsStore.current.createdFromPricing = true
            //                         bookingsStore.current.contract.entityId = contractsStore.current.entityId
            //                         console.log('\n\n***TC length=', bookingsStore.current.contract.tc.length, '***\n')
            //                         if (isDummy) bookingsStore.current.hasToShowDummySchedule = true

            //                         contractsStore.current = rec
            //                         hideme(true)
            //                         setptbVisible(false)
            // //    }else {
            //     // if(loginStore.isClient()){
            //     //   // let entityData = EntityV2Store.list.filter((r,i)=> r.entityId === rec.entityId)
            //     //   await EntityApi.getEntity(rec.entityId)
            //     //   let entityData = entityStore.entityRec
            //     //   configStore.setModalMessage(`The GST Number has not been updated.
            //     //   Please contact the Reporting manager or Customer Success to update and proceed Booking .
            //     //   Contact ID :

            //     //   Reporting Manager - ${entityData?.customer?.crossBorder?.relationshipManager}
            //     //   Customer Success - ${entityData?.customer?.crossBorder?.customerService }
            //     //   Customer ID ${rec.entityId}. Please check and update to proceed booking`)
            //     // }else
            //     // configStore.setModalMessage(`The GST Number has not been updated for the below ID.Customer ID ${rec.entityId}. Please check and update to proceed booking`)
            // //    }
            //  } )




                                    // old code 

                            
                                }}>
                                    Book Now
                                </button>
                            </> : <></>
                    }
                    <button style={{ marginTop: '5px' }} className="button is-inverted is-rounded is-small is-link" onClick={() => {
                        let rec1 = JSON.parse(JSON.stringify(rec))
                        rec1.tc.push(...rec.temptc)
                        configStore.setModalMessage("", [], false, rec1.tc)
                    }}>Terms and Conditions</button>

                </div>
            </div>
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                {
                    (showCharges) ?
                       loginStore.isClient() ? <Button size='small'  onClick={() => {
                        setShowCharges(false)
                    }} variant='contained' endIcon={ <ArrowDropUp/>} fullWidth sx={{display:"flex",textTransform:"capitalize",alignItems:"center",backgroundColor:"#ffdc7d",color:"black","&:hover":{
                        backgroundColor:"#ffdc7d"
                    }}} >Hide Detailed Pricing</Button>  : <button className='button is-warning is-small is-fullwidth' onClick={() => setShowCharges(false)}>
                            Hide Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> :
                       loginStore.isClient() ?  <Button size="small"  onClick={() => {
                        setShowCharges(true)
                    }} variant='contained' endIcon={ <ArrowDropDown />} fullWidth sx={{textTransform:"capitalize",alignItems:"center"}} >View Detailed Pricing</Button> : <button className='button is-info is-small is-fullwidth'
                            onClick={() => {
                                setShowCharges(true)
                            }}>
                            View Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }

            </div>
            {
                (showCharges) ?
                    <div className="columns" style={{
                        fontSize: '0.8rem',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                        {
                            (rec.shipperchargesList?.length > 0 || rec.consigneechargesList?.length > 0) ?
                                <>
                                    <div className='column is-6'>
                                        <LCLChargeClientView dummyContract={rec} subscript={subscript} />
                                    </div>
                                    <div className='column is-6'>
                                        {
                                            rec.shipperchargesList?.length > 0 ?
                                                <LCLChargeClientView dummyContract={rec} chargesName='shipperchargesList' />
                                                :
                                                <LCLChargeClientView dummyContract={rec} chargesName='consigneechargesList' />
                                        }
                                    </div>
                                </>
                                :
                                <div className='column is-12'>
                                    <LCLChargeClientView dummyContract={rec} subscript={subscript} />
                                </div>
                        }
                    </div> : <></>
            }
        </div>
    )
}