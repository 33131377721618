import { useSnapshot } from "valtio"
import { breadCrumbStore, configStore, contractsStore, entityStore, loginStore, modalStore } from "../state/CommonState"
import { enquiryStore } from "./enquiry-data"
import { useState } from "react"
import Api from "../state/Api"
import EnquiriesApi from "../state/enq-api"
import { loader } from "../util/loader"



export default function  ActrejectModal6(props) {
    useSnapshot(enquiryStore)
    useSnapshot(modalStore)
    useSnapshot(configStore)
    useSnapshot(loginStore)
    useSnapshot(entityStore)
    useSnapshot(contractsStore)
    let {id} = props
    const [reason, setReason] = useState("");
    const [activeProduct, setActiveProduct] = useState('LCL')
    let modalClass = 'modal'
    
    if (configStore.quotationModalVisible4) modalClass += ' is-active'

    const NmO_onClick = () => {
        // configStore.quotationModalVisible4 = true
        
        let stat =   EnquiriesApi.saveEnquiry()
        EnquiriesApi.getAllEnquiries()
        
        if (stat) {setActiveProduct("LCL")
        configStore.displaySchedules = false
        configStore.showEnquiries = true
        contractsStore.current = {}
        modalStore.checkingDestination = false
        configStore.homescreenCurrentShipmentType = "LCL"
        enquiryStore.current.entityName = ""
        enquiryStore.current.gst = ""
    }
     
    
        
    }
    const submit = async () => {
        loader.show()
        configStore.agragaEnquiryreason = reason
        enquiryStore.current.agragareason = reason
        const callback = (data) =>{
            loader.hide()
            configStore.setEnquiryModalMessage(configStore.enquiryModalNum)
            configStore.quotationModalVisible4 = false
        }
        let stat =   EnquiriesApi.saveEnquiry(callback)
        EnquiriesApi.getAllEnquiries()
        
        if (stat) {setActiveProduct("LCL")
        configStore.displaySchedules = false
        configStore.showEnquiries = true
        contractsStore.current = {}
        modalStore.checkingDestination = false
        configStore.homescreenCurrentShipmentType = "LCL"
        enquiryStore.current.entityName = ""
        enquiryStore.current.gst = ""
    }
    
        
       
        // configStore.acceptMail = true
        
    }

    return (
        <div className={modalClass} 
        // onClick={() => { configStore.quotationModalVisible4 = false }}
        >
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>Special Instructions to Procurement:</h2>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)}></textarea>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                            // disabled = {reason.length==0}
                                                // onClick={submit}
                                                onClick={async ()=>{
                                                    // await NmO_onClick()
                                                    submit()
                                                    
                                                }}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => {configStore.quotationModalVisible4 = false;
                                            // configStore.setEnquiryModalMessage(configStore.enquiryModalNum)
                                                // enquiryStore.currentEnquiryListComponent = "list"
                                                configStore.buttondisable = false
                                                }} >cancel</button>
                </section>
            </div>
        </div>
    )
}