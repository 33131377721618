import React, { useEffect, useState, useReducer } from "react";
import { configStore, loginStore } from "../state/CommonState";
import Agproducttab from "../components/Agproducttab";
import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";
import { DRS_COLUMNS } from "../components/ag-table/columns";
import TrackMyBookingsModal from "./TrackMyBookingsModal";
import { OceanVisibity } from "../trackmybookings/oceanVisibility";
import { reducer, PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAGE_FILTER_CHANGED, TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED } from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import { useTable, usePagination, useSortBy } from "react-table"
import { VisibilityOcean } from "../trackmybookings/visibilityOcean";
import { subscribe, useSnapshot } from "valtio";
import { ChartNewHome } from "../charts/chart.new.home";
import { DtVisibity } from "./dtVisibility";
import { visibilityStore } from "./visibilityStore";
import { Nodata } from "../mui-components/nodatafound";
import { loader } from "../util/loader";
import { AirVisibity } from "./airVisibility";


export default function TrackMyBooking() {
    useSnapshot(configStore)
    useSnapshot(visibilityStore)
    const initialState = {
        queryPageIndex: 0,
        queryPageSize: 10,
        totalCount: 0,
        queryPageFilter: "",
        queryPageSortBy: [],
        queryPayload: {}
    };
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState)
    const totalPageCount = Math.ceil(totalCount / queryPageSize)
    const [data1, setData1] = useState({ results: [] })
    let columns = DRS_COLUMNS
    const [search, setSearch] = useState('')
    const [list, setList] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [graphList, setGraphList] = useState([])
    const [elist, setElist] = useState([])
    const [refresh, setRefresh] = useState(false)
    let statusList = ['Shipments In-Progress', 'Completed']
    const [activeStatus, setActiveStatus] = useState(statusList[0])

    let productList = []
    if (configStore.currentSelectedEntity.isOceanChecked) {
        productList.push('Ocean')
    }
    if (configStore.currentSelectedEntity.isAirChecked) {
        productList.push('Air')
    }
    if (configStore.currentSelectedEntity.isDTChecked) {
        productList.push('Domestic Transport')
    }
    const [activeProduct, setActiveProduct] = useState(productList[0])

    const handleRefresh = () => {
        setRefresh(!refresh)
        loader.hide()
        setSearch('')
        setActiveStatus('Shipments In-Progress')
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const searchBooking = (e) => {
        loader.hide()
        let str;
        if (e.data.type === 'Ocean') {
            str = e.data.carrierBookingRefNumber || e.data.carrierMBLNumber || e.data.containerNumber
        } else {
            str = e.data.MAWBNumber
        }
        e.data.tsUpdatedBy === 'completed' || e.data.tsUpdatedBy === 'Delivered' ? setActiveStatus('Completed') : setActiveStatus('Shipments In-Progress')
        setSearch(str)
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );

    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { search: search, branchId: configStore.currentSelectedBranch._id, type: activeProduct, bookingStatus: activeStatus } });
        gotoPage(0);
    }, [search, gotoPage, configStore.currentSelectedEntity, activeProduct, activeStatus]);

    function setlist() {
        let eli = []
        let graphList1 = []
        for (let i = 0; i < visibilityStore.list.length; i++) {
            let rec = visibilityStore.list[i]
            let oneGraph = []
            oneGraph = activeProduct === 'Ocean' ?
                VisibilityOcean.GraphLine(rec) : VisibilityOcean.AirLine(rec)
            let activeOn = false
            let lastactive = -1
            for (let i = oneGraph.length - 1; i >= 0; i--) {
                let node = oneGraph[i]
                if (rec?.tsUpdatedBy) {
                    const status = rec.tsUpdatedBy
                    if (status.includes("completed")) node.isActive = true
                }
                if (node.isActive && !activeOn) {
                    node.activelines = 1
                    lastactive = i
                }
                if (node.isActive) activeOn = true
                if (activeOn && !node.isActive) node.isActive = true
                if (node.isActive && i < lastactive) node.activelines = 2
            }
            graphList1.push(oneGraph)
            eli.push(rec)
            setGraphList(graphList1)
        }
        setElist(eli)
    }

    useEffect(() => {
        if (data1?.count) {
            dispatch({ type: TOTAL_COUNT_CHANGED, payload: data1.count });
        }
    }, [data1?.count]);

    useEffect(() => {
        const unsubscribe = subscribe(visibilityStore, () => {
            setlist()
        })
        return unsubscribe
    }, [visibilityStore, data1, activeProduct, activeStatus])

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response
                let data
                if (queryPayload.branchId) {
                    response = await ApiAgTable.getAllVisibility(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload);
                    data = await response.json()
                    if (data?.count > 0 && data?.results?.length > 0) {
                        if (visibilityStore.list !== data.results) {
                            for (let i = 0; i < data?.results?.length; i++) {
                                if (data.results[i]['containershow'] === undefined) {
                                    data.results[i]['containershow'] = -1
                                }
                            }
                        }
                        if (data1 !== data) {
                            visibilityStore.setList(data.results)
                            setList(data)
                            setData1({ results: data.results, count: data.count })
                        }
                    } else {
                        visibilityStore.setList(data.results)
                        setData1({ results: data.results, count: data.count })
                    }
                }
            } catch (error) {
                configStore.setModalMessage('Something went wrong')
            }
        };
        fetchData();
    }, [queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload, refresh, visibilityStore.recheck]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            {configStore.currentSelectedEntity.isTrackMyBooking === 'Yes' ? <div className="box is-fullwidth" style={{ height: "100%" }}>

                <nav className="level">
                    <div className="level-left">
                        <p className="level-item">
                            <button className="button is-normal is-responsive" style={{ backgroundColor: '#2A6ED4', color: 'white' }} onClick={openModal}>New</button>
                            {/* <a className="button is-link"
                                onClick={openModal}>New</a> */}
                        </p>

                        <div class="level-item">
                            <input
                                className='input'
                                value={search}
                                onChange={handleSearch}
                                type="text"
                                pattern="^[a-zA-Z0-9]+$"
                                placeholder="Search"
                            />
                            {/* <TableFilter onClickFilterCallback={handleSearch} defaultKeyword={search} /> */}
                        </div>
                    </div>
                </nav>
                <div style={{ width: loginStore.isClient() ? "max-content" : "100%", height: "100%" }}>
                    <Agproducttab isGreyBackground={loginStore.isClient()} productList={productList} activeProduct={activeProduct} setActiveProduct={setActiveProduct}
                    />
                </div>
                <div style={{ width: loginStore.isClient() ? "max-content" : "100%", height: "100%" }}>
                    {activeProduct === 'Ocean' || activeProduct === 'Air'
                        || activeProduct === 'Domestic Transport'
                        ? <Agproducttab isGreyBackground={loginStore.isClient()} productList={statusList} activeProduct={activeStatus} setActiveProduct={setActiveStatus}
                        /> : <></>}
                </div>
                {activeProduct === 'Ocean' ?
                    graphList?.map((e, i) => {
                        let e1 = elist[i]
                        if (e1 && e1?.type === 'Ocean') {
                            return <OceanVisibity graphNodes={e} dsrRec={e1} callBack={handleRefresh} open={openModal} />
                        }

                    }) : activeProduct === 'Air' ?
                        graphList.map((e, i) => {
                            let e1 = elist[i]
                            if (e1 && e1?.type === 'Air') {
                                return <AirVisibity graphNodes={e} dsrRec={e1} callBack={handleRefresh} open={openModal} />
                            }

                        }) :
                        visibilityStore?.list[0]?.type === 'Domestic Transport' && visibilityStore?.list?.map((e) => {
                            return <DtVisibity dsrRec={e} refresh={handleRefresh} />
                        })}
                {(activeProduct === 'Ocean') || (visibilityStore?.list[0]?.type === 'Domestic Transport' && activeProduct === 'Domestic Transport') || (visibilityStore?.list[0]?.type === 'Air' && activeProduct === 'Air') ? (rows.length > 0) && (
                    <>
                        <div className="columns" style={{ width: "100%" }}>
                            <div className="column is-6 py-2">
                                <ReactTablePagination
                                    page={page}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageSize={pageSize}
                                    pageIndex={pageIndex}
                                    pageCount={pageCount}
                                    setPageSize={setPageSize}
                                    manualPageSize={manualPageSize}
                                    dataLength={totalCount}
                                />
                            </div>
                            <div className="column is-6 py-2" style={{ textAlign: "right" }}>
                                <div className="select">
                                    <select
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[5, 10, 20, 50, 100].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </>
                ) : <></>}
                {rows.length === 0 && visibilityStore?.list?.length === 0 && graphList?.length === 0 && <Nodata width="250px" height="250px" />}
                <TrackMyBookingsModal type={activeProduct} isOpen={isModalOpen} onClose={closeModal} callback={handleRefresh} search={searchBooking} />
            </div> : <ChartNewHome />}
        </div>
    )
}
