import { configStore, contractsStore, loginStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import SpotBookingv3 from "./spot.booking.v3"
import HomeScreenFCL from "./home-screen-fcl"
import HomeScreenAir from "./home-screen-air"

export default function HomeScreen(props) {
    useSnapshot(contractsStore)
    const rec = contractsStore.current
    //if (!rec || !rec.shipmentType) return <></>
    const stype = configStore.homescreenCurrentShipmentType
    console.log(props,"****",stype);
    switch(stype) {
        case 'LCL': return <SpotBookingv3 shipmentType={stype} {...props}/>
        case 'FCL': return <SpotBookingv3 shipmentType={stype} {...props}/>
        case 'Air': return <SpotBookingv3 shipmentType={stype} {...props}/>
    }
}