import logo6E from './airimg/6E.JPG'
import logo9W from './airimg/9W.JPG'
import logoAC from './airimg/AC.JPG'
import logoAF from './airimg/AF.JPG'
import logoAI from './airimg/AI.JPG'
import logoBA from './airimg/BA.JPG'
import logoCA from './airimg/CA.JPG'
import logoCX from './airimg/CX.JPG'
import logoD5 from './airimg/D5.JPG'
import logoD7 from './airimg/D7.JPG'
import logoEK from './airimg/EK.JPG'
import logoET from './airimg/ET.JPG'
import logoEY from './airimg/EY.JPG'
import logoFX from './airimg/FX.JPG'
import logoHX from './airimg/HX.JPG'
import logoI5 from './airimg/I5.JPG'
import logoKL from './airimg/KL.JPG'
import logoLH from './airimg/LH.JPG'
import logoO3 from './airimg/O3.JPG'
import logoQR from './airimg/QR.JPG'
import logoSG from './airimg/SG.JPG'
import logoSQ from './airimg/SQ.JPG'
import logoTG from './airimg/TG.JPG'
import logoUK from './airimg/UK.JPG'
import logoUL from './airimg/UL.JPG'
import logoVS from './airimg/VS.JPG'
import logoAK from './airimg/AK.JPG'
import logoQY from './airimg/QY.JPG'
import logoRH from './airimg/RH.JPG'
import logoMH from './airimg/MH.JPG'
import logoSV from './airimg/SV.JPG'
import logoTK from './airimg/TK.JPG'
import logoKU from './airimg/KU.JPG'
import logoWY from './airimg/WY.JPG'
import logoUA from './airimg/UA.JPG'
import logoYG from './airimg/YG.JPG'
import logoQF from './airimg/QF.jpg'
import logoQT from './airimg/QT.JPG'
import logoTP from './airimg/TP.JPG'
import logo3S from './airimg/3S.JPG'
import logoLD from './airimg/LD.JPG'
import logoIG from './airimg/IG.JPG'
import logoMK from './airimg/MK.JPG'
import logoNZ from './airimg/NZ.JPG'
import logoPG from './airimg/PG.JPG'
import logoCP from './airimg/CP.JPG'
import logoCI from './airimg/CI.JPG'
import logoCK from './airimg/CK.JPG'
import logoCY from './airimg/CY.JPG'
import logoMS from './airimg/MS.JPG'
import logoOV from './airimg/OV.JPG'
import logoIR from './airimg/IR.JPG'
import logoJL from './airimg/JL.JPG'
import logoKQ from './airimg/KQ.JPG'
import logoKE from './airimg/KE.JPG'
import logoPK from './airimg/PK.JPG'
import logoPR from './airimg/PR.JPG'
import logoFM from './airimg/FM.JPG'
import logoSA from './airimg/SA.JPG'
import logoPS from './airimg/PS.JPG'
import logoVN from './airimg/VN.JPG'
import logoBG from './airimg/BG.JPG'
import logo2C from './airimg/2C.JPG'
import logoOU from './airimg/OU.JPG'
import logoFJ from './airimg/FJ.JPG'
import logoGF from './airimg/GF.JPG'
import logoME from './airimg/ME.JPG'
import logoRJ from './airimg/RJ.JPG'
import logo5X from './airimg/5X.JPG'
import logoHY from './airimg/HY.JPG'
import logoMF from './airimg/MF.JPG'
import logoJ9 from './airimg/J9.JPG'
import logoAS from './airimg/AS.JPG'
import logoAH from './airimg/AH.JPG'
import logoG9 from './airimg/G9.JPG'
import logoKC from './airimg/KC.JPG'
import logoBT from './airimg/BT.JPG'
import logoKF from './airimg/KF.JPG'
import logoBP from './airimg/BP.JPG'
import logoNX from './airimg/NX.JPG'
import logoMD from './airimg/MD.JPG'
import logoKM from './airimg/KM.JPG'
import logo9U from './airimg/9U.JPG'
import logoSW from './airimg/SW.JPG'
import logoJU from './airimg/JU.JPG'
import logoBW from './airimg/BW.JPG'
import logoOK from './airimg/OK.JPG'
import logoDL from './airimg/DL.JPG'
import logoAY from './airimg/AY.JPG'
import logoFZ from './airimg/FZ.JPG'
import logoPO from './airimg/PO.JPG'
import logoUX from './airimg/UX.jpg'
import logoKJ from './airimg/KJ.jpg'
import logoAZ from './airimg/AZ.jpg'
import logoOZ from './airimg/OZ.jpg'
import logo5Y from './airimg/5Y.jpg'
import logoSN from './airimg/SN.jpg'
import logoER from './airimg/ER.jpg'
import logoIY from './airimg/IY.jpg'
import logoWW from './airimg/WW.jpg'
import logoWS from './airimg/WS.jpg'
import logoB7 from './airimg/B7.jpg'
import logoTU from './airimg/TU.jpg'
import logoTB from './airimg/TB.jpg'
import logoGS from './airimg/GS.jpg'
import logoDT from './airimg/DT.jpg'
import logoRB from './airimg/RB.jpg'
import logoDK from './airimg/DK.jpg'
import logoJX from './airimg/JX.jpg'
import logoGW from './airimg/GW.jpg'
import logoOO from './airimg/OO.jpg'
import logo7L from './airimg/7L.jpg'
import logoZP from './airimg/ZP.jpg'
import logoM3 from './airimg/M3.jpg'
import logoGB from './airimg/GB.jpg'
import logoMR from './airimg/MR.jpg'
import logoA3 from './airimg/A3.jpg'
import logoSU from './airimg/SU.jpg'
import logoJK from './airimg/JK.jpg'
import logoAR from './airimg/AR.jpg'
import logoAM from './airimg/AM.jpg'
import logo6R from './airimg/6R.jpg'
import logoZI from './airimg/ZI.jpg'
import logoGL from './airimg/GL.jpg'
import logoPX from './airimg/PX.jpg'
import logoYP from './airimg/YP.jpg'
import logoHM from './airimg/HM.jpg'
import logoTN from './airimg/TN.jpg'
import logoTS from './airimg/TS.jpg'
import logoRU from './airimg/RU.jpg'
import logoVZ from './airimg/VZ.jpg'
import logoX8 from './airimg/X8.jpg'
import logo4W from './airimg/4W.jpg'
import logoKH from './airimg/KH.jpg'
import logoAA from './airimg/AA.jpg'
import logoNH from './airimg/NH.jpg'
import logo3V from './airimg/3V.jpg'
import logo8V from './airimg/8V.jpg'
import logoOS from './airimg/OS.jpg'
import logoAV from './airimg/AV.jpg'
import logoJ2 from './airimg/J2.jpg'
import logoAD from './airimg/AD.jpg'
import logoUP from './airimg/UP.jpg'
import logoB2 from './airimg/B2.jpg'
import logoE6 from './airimg/E6.jpg'
import logoFB from './airimg/FB.jpg'
import logo5C from './airimg/5C.jpg'
import logoMO from './airimg/MO.jpg'
import logoQC from './airimg/QC.jpg'
import logoW8 from './airimg/W8.jpg'
import logoP3 from './airimg/P3.jpg'
import logoCV from './airimg/CV.jpg'
import logoC8 from './airimg/C8.jpg'
import logoKX from './airimg/KX.jpg'
import logo5J from './airimg/5J.jpg'
import logoCQ from './airimg/CQ.jpg'
import logoMU from './airimg/MU.jpg'
import logoCZ from './airimg/CZ.jpg'
import logoCO from './airimg/CO.jpg'
import logoDE from './airimg/DE.jpg'
import logoCM from './airimg/CM.jpg'
import logoSS from './airimg/SS.jpg'
import logoCU from './airimg/CU.jpg'
import logoKA from './airimg/KA.jpg'
import logoLY from './airimg/LY.jpg'
import logoE7 from './airimg/E7.jpg'
import logoBR from './airimg/BR.jpg'
import logo5F from './airimg/5F.jpg'
import logoGY from './airimg/GY.jpg'
import logoGA from './airimg/GA.jpg'
import logoG3 from './airimg/G3.jpg'
import logoHU from './airimg/HU.jpg'
import logoHA from './airimg/HA.jpg'
import logoUO from './airimg/UO.jpg'
import logoIB from './airimg/IB.jpg'
import logoFI from './airimg/FI.jpg'
import logo7I from './airimg/7I.jpg'
import logo7C from './airimg/7C.jpg'
import logoQJ from './airimg/QJ.jpg'
import logo3K from './airimg/3K.jpg'
import logo3J from './airimg/3J.jpg'
import logoK4 from './airimg/K4.jpg'
import logoLR from './airimg/LR.jpg'
import logoLA from './airimg/LA.jpg'
import logoUC from './airimg/UC.jpg'
import logoXL from './airimg/XL.jpg'
import logoJJ from './airimg/JJ.jpg'
import logoL7 from './airimg/L7.jpg'
import logoLI from './airimg/LI.jpg'
import logoLO from './airimg/LO.jpg'
import logoW5 from './airimg/W5.jpg'
import logoOD from './airimg/OD.jpg'
import logoAE from './airimg/AE.jpg'
import logoMP from './airimg/MP.jpg'
import logoM7 from './airimg/M7.jpg'
import logoT8 from './airimg/T8.jpg'
import logoMB from './airimg/MB.jpg'
import logoN8 from './airimg/N8.jpg'
import logoNO from './airimg/NO.jpg'
import logoKZ from './airimg/KZ.jpg'
import logoDD from './airimg/DD.jpg'
import logoNC from './airimg/NC.jpg'
import logoDY from './airimg/DY.jpg'
import logoOA from './airimg/OA.jpg'
import logoPC from './airimg/PC.jpg'
import logoTH from './airimg/TH.jpg'
import logoBI from './airimg/BI.jpg'
import logoR4 from './airimg/R4.jpg'
import logoWB from './airimg/WB.jpg'
import logoSK from './airimg/SK.jpg'
import logoSP from './airimg/SP.jpg'
import logoS4 from './airimg/S4.jpg'
import logoSC from './airimg/SC.jpg'
import logoZH from './airimg/ZH.jpg'
import logoS7 from './airimg/S7.jpg'
import logo3U from './airimg/3U.jpg'
import logoMI from './airimg/MI.jpg'
import logoKY from './airimg/KY.jpg'
import logo4S from './airimg/4S.jpg'
import logoWN from './airimg/WN.jpg'
import logo4E from './airimg/4E.jpg'
import logoQP from './airimg/QP.jpg'
import logoY8 from './airimg/Y8.jpg'
import logoLX from './airimg/LX.jpg'
import logoB1 from './airimg/B1.jpg'
import logoTA from './airimg/TA.jpg'
import logoRO from './airimg/RO.jpg'
import logoUW from './airimg/UW.jpg'
import logoU6 from './airimg/U6.jpg'
import logoRG from './airimg/RG.jpg'
import logoSE from './airimg/SE.jpg'
import logo8D from './airimg/8D.jpg'
import logo00 from './airimg/KMG.png'

const airlogoMap = {
    'KU': logoKU,
    'WY': logoWY,
    'UA': logoUA,
    'YG': logoYG,
    '6E': logo6E,
    '9W': logo9W,
    'AC': logoAC,
    'AF': logoAF,
    'AI': logoAI,
    'BA': logoBA,
    'CA': logoCA,
    'CX': logoCX,
    'D5': logoD5,
    'D7': logoD7,
    'EK': logoEK,
    'ET': logoET,
    'EY': logoEY,
    'FX': logoFX,
    'HX': logoHX,
    'I5': logoI5,
    'KL': logoKL,
    'LH': logoLH,
    'O3': logoO3,
    'QR': logoQR,
    'SG': logoSG,
    'SQ': logoSQ,
    'TG': logoTG,
    'UK': logoUK,
    'UL': logoUL,
    'VS': logoVS,
    'AK': logoAK,
    'QY': logoQY,
    'RH': logoRH,
    'MH': logoMH,
    'SV': logoSV,
    'TK': logoTK,
    'QF': logoQF,
    'QT': logoQT,
    'TP': logoTP,
    '3S': logo3S,
    'LD': logoLD,
    'IG': logoIG,
    'MK': logoMK,
    'NZ': logoNZ,
    'PG': logoPG,
    'CP': logoCP,
    'CI': logoCI,
    'CK': logoCK,
    'CY': logoCY,
    'MS': logoMS,
    'OV': logoOV,
    'IR': logoIR,
    'JL': logoJL,
    'KQ': logoKQ,
    'KE': logoKE,
    'PK': logoPK,
    'PR': logoPR,
    'FM': logoFM,
    'SA': logoSA,
    'PS': logoPS,
    'VN': logoVN,
    'BG': logoBG,
    '2C': logo2C,
    'OU': logoOU,
    'FJ': logoFJ,
    'GF': logoGF,
    'ME': logoME,
    'RJ': logoRJ,
    '5X': logo5X,
    'HY': logoHY,
    'MF': logoMF,
    'J9': logoJ9,
    'AS': logoAS,
    'AH': logoAH,
    'G9': logoG9,
    'KC': logoKC,
    'BT': logoBT,
    'KF': logoKF,
    'BP': logoBP,
    'NX': logoNX,
    'MD': logoMD,
    'KM': logoKM,
    '9U': logo9U,
    'SW': logoSW,
    'JU': logoJU,
    'BW': logoBW,
    'OK': logoOK,
    'DL': logoDL,
    'AY': logoAY,
    'FZ': logoFZ,
    'PO': logoPO,
    'UX': logoUX,
    'KJ': logoKJ,
    'AZ': logoAZ,
    'OZ': logoOZ,
    '5Y': logo5Y,
    'SN': logoSN,
    'ER': logoER,
    'IY': logoIY,
    'WW': logoWW,
    'WS': logoWS,
    'B7': logoB7,
    'TU': logoTU,
    'TB': logoTB,
    'GS': logoGS,
    'DT': logoDT,
    'RB': logoRB,
    'DK': logoDK,
    'JX': logoJX,
    'GW': logoGW,
    'OO': logoOO,
    '7L': logo7L,
    'ZP': logoZP,
    'M3': logoM3,
    'GB': logoGB,
    'MR': logoMR,
    'A3': logoA3,
    'SU': logoSU,
    'JK': logoJK,
    'AR': logoAR,
    'AM': logoAM,
    '6R': logo6R,
    'ZI': logoZI,
    'GL': logoGL,
    'PX': logoPX,
    'YP': logoYP,
    'HM': logoHM,
    'TN': logoTN,
    'TS': logoTS,
    'RU': logoRU,
    'VZ': logoVZ,
    'X8': logoX8,
    '4W': logo4W,
    'KH': logoKH,
    'AA': logoAA,
    'NH': logoNH,
    '3V': logo3V,
    '8V': logo8V,
    'OS': logoOS,
    'AV': logoAV,
    'J2': logoJ2,
    'AD': logoAD,
    'UP': logoUP,
    'B2': logoB2,
    'E6': logoE6,
    'FB': logoFB,
    '5C': logo5C,
    'MO': logoMO,
    'QC': logoQC,
    'W8': logoW8,
    'P3': logoP3,
    'CV': logoCV,
    'C8': logoC8,
    'KX': logoKX,
    '5J': logo5J,
    'CQ': logoCQ,
    'MU': logoMU,
    'CZ': logoCZ,
    'CO': logoCO,
    'DE': logoDE,
    'CM': logoCM,
    'SS': logoSS,
    'CU': logoCU,
    'KA': logoKA,
    'LY': logoLY,
    'E7': logoE7,
    'BR': logoBR,
    '5F': logo5F,
    'GY': logoGY,
    'GA': logoGA,
    'G3': logoG3,
    'HU': logoHU,
    'HA': logoHA,
    'UO': logoUO,
    'IB': logoIB,
    'FI': logoFI,
    '7I': logo7I,
    '7C': logo7C,
    'QJ': logoQJ,
    '3K': logo3K,
    '3J': logo3J,
    'K4': logoK4,
    'LR': logoLR,
    'LA': logoLA,
    'UC': logoUC,
    'XL': logoXL,
    'JJ': logoJJ,
    'L7': logoL7,
    'LI': logoLI,
    'LO': logoLO,
    'W5': logoW5,
    'OD': logoOD,
    'AE': logoAE,
    'MP': logoMP,
    'M7': logoM7,
    'T8': logoT8,
    'MB': logoMB,
    'N8': logoN8,
    'NO': logoNO,
    'KZ': logoKZ,
    'DD': logoDD,
    'NC': logoNC,
    'DY': logoDY,
    'OA': logoOA,
    'PC': logoPC,
    'TH': logoTH,
    'BI': logoBI,
    'R4': logoR4,
    'WB': logoWB,
    'SK': logoSK,
    'SP': logoSP,
    'S4': logoS4,
    'SC': logoSC,
    'ZH': logoZH,
    'S7': logoS7,
    '3U': logo3U,
    'MI': logoMI,
    'KY': logoKY,
    '4S': logo4S,
    'WN': logoWN,
    '4E': logo4E,
    'QP': logoQP,
    'Y8': logoY8,
    'LX': logoLX,
    'B1': logoB1,
    'TA': logoTA,
    'RO': logoRO,
    'UW': logoUW,
    'U6': logoU6,
    'RG': logoRG,
    'SE': logoSE,
    '8D': logo8D,
    '00': logo00
}
export default airlogoMap