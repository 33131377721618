import { Box, Typography, Button, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails, Collapse, FormControl, FormLabel, FormControlLabel, Checkbox, FormGroup, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { ShipmentScopeTag } from "../mui-components/ShipmentScope";
import calender from "../assets/images/calender.svg"
import { Search, ExpandMoreRounded } from "@mui/icons-material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { SHIPMENT_SCOPE } from "../util/quotationUtil";
import AgAutoComplete, { AgMuiUn } from "../mui-components/AgAutoComplete";
import { useSnapshot } from "valtio";
import { breadCrumbStore, enquiryFormState, enquiryState, filtersOptions, filtersState } from "../state/CommonState";
import { useForm } from "react-hook-form";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
import closeIcon from "../assets/images/close.svg";
import { AntSwitch } from "../mui-components/AntSwitch";
import routing from "../assets/images/routing.svg";
import container from "../assets/images/container.svg";
import company from "../assets/images/company.svg";
import { ChooseScheduleCard, ContractCard, ScheduleCard } from "../mui-components/ScheduleCard";
import { OptionBox } from "../mui-components/OptionBox";
import { EnquiryDetailsView } from "../mui-components/EnquiryDetailsView";
import { enquiryStore } from "../enquiry/enquiry-data";
import Utils from "../util/Utils";
import { CargoTypes } from "./CargoTypes";
import { DetailsPage } from "./DetailsPage";

const SelectOption = forwardRef((props, ref) => {
    const { children, width, popupContent, isMenu = true } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (e) => {
        e?.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(() => {
        if (ref) {
            ref.current = { handleClose };
        }
    }, [props, ref])

    return <Box onClick={isMenu ? (e) => {
        handleClick(e);
    } : undefined} sx={{
        boxShadow: "3px 3px 15px 0px #0000001A", backgroundColor: "white", borderRadius: "8px", padding: "6px 12px", width: width || "fit-content", height: "55px", "&:hover": {
            cursor: isMenu ? "pointer" : ""
        }
    }}>
        {children}
        <Menu

            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "center", horizontal: "left" }}
        >
            {/* <Box sx={{height:"100px",width:"100%",backgroundColor:"yellow"}}>treddhgghredfjhkjj</Box> */}
            {popupContent}
        </Menu>
    </Box>
});



const items = [{ title: "LCL" }, { title: "FCL" }, { title: "AIR" }]




function GetMenuItems({ menuItems, onClick }) {
    return <>{menuItems?.map((item, i) => <MenuItem
        onClick={(e) => {
            if (onClick) {
                onClick(e, i);
            }
        }}
        sx={{
            textAlign: "start",
            wordBreak: "break-word",
            whiteSpace: "normal",
            maxWidth: "300px", margin: "8px", borderRadius: "4px",
            color: "#555555",
            "&:hover": {
                backgroundColor: "#2B3688",
                color: "white"
            }
        }}  >{item.title}</MenuItem>)}</>
}

function ShipmentDetails() {
    const modeRef = useRef();
    const scopeRef = useRef();
    const originRef = useRef();
    const destinationRef = useRef();
    const crdRef = useRef();
    const formState = useSnapshot(enquiryFormState);
    const enquiry = useSnapshot(enquiryState);
    const enquiryStoreState = useSnapshot(enquiryStore);
    // const {control,getValues,setValue,watch} = formState.form;

    const defaultForm = useForm(({
        defaultValues: {
            origin: "",
            destination: "",
            cargoreadinessdate: "",
            scope: ""
        }
    }))
    const { control, getValues, setValue, watch, clearErrors } = defaultForm;
    const scope = watch("scope");
    useEffect(() => {

    }, [scope])

    const scopes = Object.values(SHIPMENT_SCOPE);
    return <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <SelectOption isMenu={false} ref={modeRef} width="7%" popupContent={<GetMenuItems menuItems={items} onClick={(e, i) => {
            enquiryState.mode = items[i].title;
            modeRef?.current?.handleClose(e);
        }} />}>
            <OptionBox title="Mode" content={<span><ShipmentScopeTag width="40%" scope={enquiryStoreState.current.shipmentType} iconPlacement="start" /></span>} />
        </SelectOption>
        <SelectOption ref={scopeRef} popupContent={<GetMenuItems menuItems={scopes.map((scope) => ({ title: scope }))} onClick={(e, i) => {
            enquiryStore.current.shipmentScope = scopes[i];
            scopeRef?.current?.handleClose(e);
        }} />} width="10%">
            <OptionBox key={scope} title="Scope" content={<Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree" }}>{enquiryStoreState.current.shipmentScope}</Typography>} />
        </SelectOption>
        <SelectOption ref={originRef} width="18%" popupContent={<Box sx={{ width: "300px", height: "300px", paddingX: "8px" }}><AgMuiUn clearErrors={clearErrors} placeholder="choose origin" selectedValue={(e, val) => {
            enquiryStore.current.enqRoute.origin = val;
            originRef?.current?.handleClose(e);
        }} control={control} isDoor={enquiryStoreState.current.shipmentScope  === SHIPMENT_SCOPE.D2D || enquiryStoreState.current.shipmentScope  === SHIPMENT_SCOPE.D2P} name="origin" /></Box>} >
            <OptionBox title="Origin" content={<Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px" }}>{enquiryStoreState.current.enqRoute.origin}</Typography>} />
        </SelectOption>
        <SelectOption ref={destinationRef} width="18%" popupContent={<Box sx={{ width: "300px", height: "300px", paddingX: "8px" }}><AgMuiUn clearErrors={clearErrors} placeholder="choose destination" selectedValue={(e, val) => {
             enquiryStore.current.enqRoute.destination = val;
            destinationRef?.current?.handleClose(e);
        }} control={control} isDoor={enquiryStoreState.current.shipmentScope === SHIPMENT_SCOPE.D2D || enquiryStoreState.current.shipmentScope  === SHIPMENT_SCOPE.P2D} name="destination" /></Box>}>
            <OptionBox title="Destination" content={<Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px" }}>{enquiryStoreState.current.enqRoute.destination}</Typography>} />
        </SelectOption>
        <SelectOption width="16%">
            <OptionBox title="Choose Departure" content={<span style={{ display: "flex", flexDirection: "row", gap: 1, height: "100%", alignItems: "center", width: "200px", width: "100%", justifyContent: "space-between" }}><img src={calender} alt="calender" /><Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "180px" }}>Dec 7, 2023 to Dec 17, 2023</Typography></span>} />
        </SelectOption>
        <SelectOption ref={crdRef} width="10%" popupContent={<CustomStyledTextField value={enquiryStoreState.current.enqRoute.cargoReadinessDate} size="small" type="date" onChange={(e) => {
             enquiryStore.current.enqRoute.cargoReadinessDate = e.target.value;
            crdRef?.current?.handleClose(e);
        }} />} >
            <OptionBox title="Cargo Readiness Date" content={<span style={{ display: "flex", flexDirection: "row", gap: 1, height: "100%", width: "100%", alignItems: "center", justifyContent: "space-between" }}><img src={calender} alt="calender" /><Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enquiry.cargoReadinessDate}</Typography></span>} />
        </SelectOption>
        <Button sx={{ textTransform: "capitalize" }} size="small" startIcon={<Search />} variant="contained" >Search</Button>
    </Box>
}



const HorizontalDashedLine = ({ width, dashArray }) => {
    return (
        <svg width={width} height="1" xmlns="http://www.w3.org/2000/svg">
            <line
                x1="0"
                y1="0"
                x2={width}
                y2="0"
                stroke="black"
                strokeWidth="2"
                strokeDasharray={dashArray}
            />
        </svg>
    );
};




const ScheduleTabs = [{ title: "Transit Time" }, { title: "Earliest Departure" }]

function ScheduleTabComponent() {
    const [selectedIndex, setIndex] = useState(0);
    return <Box sx={{ minHeight: "40px", width: "270px", borderRadius: "22px", backgroundColor: "white", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "5px 8px" }}>
        {ScheduleTabs.map((tab, i) => <Box onClick={() => {
            if (selectedIndex !== i) {
                setIndex(i);
            }
        }} sx={{
            backgroundColor: selectedIndex === i ? "#2A6ED4" : "transparent", color: selectedIndex === i ? "white" : "#555555", paddingX: "10px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "22px", "&:hover": {
                cursor: "pointer"
            }
        }}>
            <Typography sx={{ fontSize: '14px', fontFamily: "Figtree" }}>{tab.title}</Typography>
        </Box>)}
    </Box>
}

const filters = [{ title: "Routing", src: routing, content: <RoutingFilters /> },
// { title: "Carriers", src: container, content: <CarriersFilter options={["Maesrk", "Cisco", "Evergreen", "Cisc"]} /> },
// { title: "Services", src: company, content: <ServiceFilters options={["Indamex", "Express"]} /> }
]

function Accordions() {
    const [selectedIndex, setIndex] = useState(-1);
    return <>
        {filters.map((filter, i) =>
            <CustomAccordion title={filter.title} src={filter.src} content={filter.content} open={i === selectedIndex} setAccordion={() => {
                if (i === selectedIndex) {
                    setIndex(-1)
                } else {
                    setIndex(i);
                }
            }} />
        )}
    </>
}

function CheckBoxGroup({ options }) {
    const [checkedItems, setCheckedItems] = useState(options.reduce((acc, cur) => {
        if (!acc[cur]) {
            acc[cur] = false;
        }
        return acc;
    }, {}));

    const handleCheckboxChange = (event) => {
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <FormControl component="fieldset">
            <FormGroup>
                {Object.keys(checkedItems).map((item) =>
                    <FormControlLabel

                        sx={{ fontFamily: "Figtree", fontSize: "14px" }}
                        control={<Checkbox size="small" sx={{ fontFamily: "Figtree", fontSize: "14px" }} checked={checkedItems[item]} onChange={handleCheckboxChange} name={item} />}
                        label={<Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{item}</Typography>}
                    />
                )}
            </FormGroup>
        </FormControl>
    );
}


function CarriersFilter({ options }) {
    return <CheckBoxGroup options={options} />
}

function ServiceFilters({ options }) {
    return <CheckBoxGroup options={options} />
}

function CustomAccordion({ title, src, content, open, setAccordion }) {

    return <Box sx={{ display: "flex", flexDirection: "column", paddingY: "5px" }}>
        <Box onClick={() => {
            setAccordion();
        }} sx={{
            backgroundColor: "#F5F5F5", "&:hover": {
                cursor: "pointer"
            }, borderRadius: '5px', border: "0.5px solid #AAAAAA", display: 'flex', flexDirection: "row", justifyContent: "space-between", padding: "4px 8px"
        }}>
            <span style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}><img src={src} /><Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{title}</Typography></span>
            <ExpandMoreRounded />
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit={false}><Box sx={{ maxHeight: '180px', overflowY: "auto", padding: "8px" }}>{content}</Box></Collapse>
    </Box>
}

function FilterBox({ title, content }) {
    return <Box sx={{ display: "flex", flexDirection: "column", paddingY: "6px" }}>
        <Typography sx={{ fontFamily: "Figtree", color: "black" }}>{title}</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.8, padding: "4px 8px" }}>
            {content}
        </Box>
    </Box>
}

function RoutingFilters() {
    const options = useSnapshot(filtersOptions);
    return <Box sx={{ display: "flex", flexDirection: 'column' }}>
        <FilterBox title="Place of Receipt" content={<>
           {options.routing.por.map((title)=><FilterSwitch routeKey="por" title={title} />)}
        </>} />
        <FilterBox title="Port of loading" content={<>
            {options.routing.pol.map((title)=><FilterSwitch routeKey="pol" title={title} />)}
        </>} />
        <FilterBox title="Port of Discharge" content={<>
            {options.routing.pod.map((title)=><FilterSwitch routeKey="pod" title={title} />)}
        </>} />
        <FilterBox title="Final Place of Delivery" content={<>
            {options.routing.fpod.map((title)=><FilterSwitch routeKey="fpod" title={title} />)}
        </>} />
    </Box>
}



function FilterSwitch({ title, routeKey }) {
    return <span style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}><AntSwitch onChange={(value)=>{
        let {checked} = value.target
         if(checked){
            filtersState.addFilterOption("routing",routeKey,title)
         }else{
            filtersState.removeFilterOption("routing",routeKey,title)
         }

    }} style={{ scale: "0.8" }} /><Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{title}</Typography></span>
}

export function ViewSchedule({chooseSchedule=false}) {
    const availableRoutes = useSnapshot(filtersState);
    const defaultForm = useForm({
        defaultValues: {
            shipmentType: enquiryStore.current.shipmentType,
            originType: false,
            destinationType: false,
            hazardous: enquiryStore.current.hazardous === "No" ? false : true,
            quoteClass: enquiryStore.current.quoteClass,
            packingGroup: enquiryStore.current.packingGroup,
            temperature: enquiryStore.current.temperature,
            cargoValue: enquiryStore.current.cargoValue,
            unnumber: enquiryStore.current.unnumber,
            nonStackable:  enquiryStore.current.nonStackable === "No" ? false : true,
            temperatureControlled:  enquiryStore.current.temperatureControlled === "No" ? false : true,
            minTemperature: enquiryStore.current.minTemperature,
            maxTemperature: enquiryStore.current.maxTemperature,
            stuffingType: enquiryStore.current.stuffingType,
            destuffingType: enquiryStore.current.destuffingType,
            status: "ENQUIRY",
            cargoDimensionUnit: "Cms",
            enqRoute: enquiryStore.current.enqRoute,
            containerdetails:enquiryStore.current.containerDetails,
            cargodetails: enquiryStore.current.cargoDetails,
            vehicledetails : enquiryStore.current.vehicles
        }
    })

    // let chooseRoutes = [];

    // if(chooseSchedule){
        
    // }


    const enquiry = useSnapshot(enquiryStore)
    let mode = enquiry.current.shipmentType.toLowerCase();
    const [open,setOpen] = useState(false);
    const [isContract,setContract] = useState(false);
    let cargo;
    let container;
    if(chooseSchedule){
       if(mode === "air"){
        cargo = Utils.AirCargoCalculation(enquiry.current.cargoDetails);
       }else{
        cargo = Utils.CalculateCargo(enquiry.current.cargoDetails);
       }
    }
    return <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1 }}>
      {chooseSchedule ?  <EnquiryDetailsView/>  :  <ShipmentDetails />}
        <Box sx={{ height: "88%", width: "100%", display: "flex", flexDirection: "row", gap: 1 }}>
            <Box sx={{ height: "100%", width: "20%", backgroundColor: "white", borderRadius: "10px", padding: "4px 8px" }}>
                <Box sx={{ width: "100%", height: "8%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ fontSize: '14px', fontFamily: "Figtree", fontWeight: "bold", color: "black" }}>Filters:</Typography>
                    <Button disableRipple disableFocusRipple disableTouchRipple variant="text" sx={{ color: "#2A6ED4", textTransform: "capitalize", fontSize: "12px", textDecoration: "underline", fontFamily: "Figtree", "&:hover": { backgroundColor: "transparent", textDecoration: "underline", } }}>Select all</Button>
                </Box>
                <Box sx={{ height: "91%", display: "flex", flexDirection: "column", overflowY: "auto" }}>
                    <FilterSwitch title="Direct only" />
                    <Accordions />
                </Box>
            </Box>
            <Box sx={{ height: "100%", width: "80%", display: "flex", flexDirection: "column", gap: 1 }}>
                {  (chooseSchedule ||  isContract) 
                && <>
                { enquiryStore.current.shipmentType.toLowerCase() === "fcl" ? 
                <Box sx={{display:"flex",flexDirection:"row",backgroundColor:"white",borderRadius:"5px",width:"100%",alignItems:"center",justifyContent:"space-between",padding:'6px 12px'}}>
                <Box sx={{display:"flex",flexDirection:"row",gap:2}}>
                 <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:"14px"}}>Container Details</Typography>
                 <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Container Type - <span style={{color:"#555555"}}>20'STD</span></Typography>
                 <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Weight - <span style={{color:"#555555"}}>20</span></Typography>
                 <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Volume - <span style={{color:"#555555"}}>200 Kgs</span></Typography>
                </Box>
                 <Button onClick={()=>{
                        setOpen(true);
                       }} size="small" sx={{alignSelf:"end",fontSize:"14px",textTransform:"capitalize",padding:0.4}} variant="contained">Edit</Button>
          </Box> 
                :   <Box sx={{display:"flex",flexDirection:"row",backgroundColor:"white",borderRadius:"5px",width:"100%",alignItems:"center",justifyContent:"space-between",padding:'6px 12px'}}>
                      <Box sx={{display:"flex",flexDirection:"row",gap:2}}>
                       <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:"14px"}}>Cargo Details</Typography>
                       { mode === "air" && <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Package Type - <span style={{color:"#555555"}}>{cargo?.productType?.join(", ")}</span></Typography>}
                       <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Quantity - <span style={{color:"#555555"}}>{cargo?.quantity}</span></Typography>
                       {mode === "air" && <>
                       <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Weight - <span style={{color:"#555555"}}>{cargo?.totalWeight} {enquiryStore.current.cargoDetails[0].weight.unit}</span></Typography>
                       <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Dimensions - <span style={{color:"#555555"}}>{cargo?.dimensions?.totalLength} x {cargo?.dimensions?.totalWidth} x {cargo?.dimensions?.totalHeight} {enquiryStore.current.cargoDetails[0].dimensions.unit}</span></Typography>
                       </>}
                      { mode === "lcl" && 
                      <>
                      <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Weight - <span style={{color:"#555555"}}>{cargo?.totalWeight} {enquiryStore.current.cargoDetails[0].totalWeight.unit}</span></Typography>
                       <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Volume - <span style={{color:"#555555"}}>{cargo?.totalVolume} {enquiryStore.current.cargoDetails[0].totalVolume.unit}</span></Typography>
                       </>
                       }
                      </Box>
                       <Button onClick={()=>{
                        setOpen(true);
                       }} size="small" sx={{alignSelf:"end",fontSize:"14px",textTransform:"capitalize",padding:0.4}} variant="contained">Edit</Button>
                </Box> } 
                </>  
                }
                {!chooseSchedule && !isContract &&
                   <ContractCard onClick={()=>{
                       setContract(true)
                   }}/>
                }
                <ScheduleTabComponent />
                <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: 1, paddingRight: "5px" }}>
                   { chooseSchedule ? <>
                     { availableRoutes.filteredList.map((route)=>{
                        let routes = [];
                        let por =  Utils.getCountry(route.por,true);
                        let pol =  Utils.getCountry(route.pol,true);
                        let pod =  Utils.getCountry(route.pod,true);
                        let fpod =  Utils.getCountry(route.fpod,true);
                        routes.push({ value : por, subValue : "Por"})
                        if(!filtersOptions.routing.por.includes(por)){
                            filtersOptions.routing.por.push(por)
                        }
                        routes.push({ value : pol,  subValue : "Pol"})
                        if(!filtersOptions.routing.pol.includes(pol)){
                            filtersOptions.routing.pol.push(pol)
                        }
                        routes.push({ value :pod, subValue :"Pod" })
                        if(!filtersOptions.routing.pod.includes(pod)){
                            filtersOptions.routing.pod.push(pod)
                        }
                        routes.push({ value : fpod, subValue : "FPod"});
                        if(!filtersOptions.routing.fpod.includes(fpod)){
                            filtersOptions.routing.fpod.push(fpod);
                        }
                        return <ChooseScheduleCard rate={route.charges["Final Total"]["total"]}  routes={routes}/>
                     }) }
                   </> : <>
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                   </>}
                </Box>
            </Box>
        </Box>
        <Dialog fullScreen fullWidth open={open} sx={{padding:"5% 8%"}}>
            <DialogTitle sx={{backgroundColor:"#E8E8E8"}}>
                <Box sx={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                    <Typography>Edit Details</Typography>
                    <Box onClick={()=>{
                    setOpen(false);
                }} sx={{display:"flex",alignSelf:"end",justifyContent:"center",alignItems:"center",backgroundColor:"#2B6ED4",borderRadius:"5px",width:"max-content",padding:"8px","&:hover":{
                    cursor : "pointer"
                }}}>
                    <img style={{width:"18px",height:"18px"}} src={closeIcon} />
                </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{backgroundColor:"#E8E8E8",height:"100%",width:"100%"}} >
               <Box sx={{height:"100%", width : "100%",  display: "flex", flexDirection: "row", gap: 1}}>
               <CargoTypes form={defaultForm}/>
               <Box sx={{flex:1,width:"100%",display:"flex",flexDirection:"column",backgroundColor:"white",borderRadius: "10px",overflowY:"auto"}}>
               <DetailsPage mode={enquiry.current.shipmentType} form={defaultForm} onSubmit={()=>{
                  setOpen(false);
               }}/>
               </Box>
               </Box>
            </DialogContent>
        </Dialog>
    </Box>
}