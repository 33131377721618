import Api from "./Api"
import {
  configStore,
  routerStore,
  cususerStore,
  aguserStore,
  loginStore,
} from "./CommonState"
import { CustomeruserType } from "./Types"


const USERSAPI = {
  POST: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/adduser`,
  SENDINVITE: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/senduserinvite`,
  GETUSER: (emailId) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/checkuser/${emailId}`,
   GETPROFILE: (emailId) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getuserprofile/${emailId}`,
   USERLOGIN: (data) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/loginuser`,
   VIEWUSER: (emailId) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/viewuser/${emailId}`,
   UPDATEUSERSTATUS: (data) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/updateuserstatus/${data.email}`,
}
export default class UsersApis {
    static async createUser(data) {
        console.log("about to call api.post")
        let DATA = {...cususerStore.cususerRec , ...data}
        Api.post(USERSAPI.POST, DATA, (data) => {
          configStore.setModalMessage("User Creation Successful")
          cususerStore.cususerRec.set(new CustomeruserType())
          routerStore.entityUserManageView = "list"
        })
      }

      static async checkUser(email , setUserdata) {
        console.log("about to call api.post")
        Api.fetch1(USERSAPI.GETUSER(email) , (data) => {
        if(data){
          setUserdata(data)
        }
        })
      }

      static async sendInviteMail(data) {
        console.log("about to call api.post")
        Api.post(USERSAPI.SENDINVITE , data, (datas) => {
        if(datas){
          if(datas.status === 402){
            configStore.setModalMessage("Invite cannot be sent")
          }else {
            configStore.setModalMessage("Invite sent succesfully")
          }

        }
        })
      }

      static async viewUser(email) {
        console.log("about to call api.post")
        Api.fetch1(USERSAPI.VIEWUSER(email) , (data) => {
        if(data){
          cususerStore.cususerRec.set(data.data)
          routerStore.agusersCurrentView = 'view'
        }
        })
      }

      static async getProfile(email , setUserProfile) {
        console.log("about to call api.post")
        Api.fetch1(USERSAPI.GETPROFILE(email) , (data) => {
        if(data){
          setUserProfile(data.data)
        }
        })
      }

      static async loginUser(data , datacallback = (f) => f) {
        console.log("about to call api.post")
        Api.post(USERSAPI.USERLOGIN(data) , data , (dataa) => {
          if(!dataa.userFound){
          configStore.setModalMessage(dataa.message)
          }
       else  if(dataa.userFound){
          loginStore.userRec = dataa;
          loginStore.email = dataa.email;
          datacallback(dataa)
        }
        })
      }

      static async deactivateUser(data , setremarksPopup) {
        console.log("about to call api.post")
        Api.put(USERSAPI.UPDATEUSERSTATUS(data) , data , (datas) => {
        if(datas){
          // datacallback(data)
          cususerStore.cususerRec.set(new CustomeruserType())
          routerStore.entityUserManageView = "list"
          setremarksPopup(false)
        }
        })
      }
}
