import { useEffect, useState, useRef } from "react"
import { Buffer } from 'buffer'
import { Col, Col1, Col2, Col3, Col4, Col5, Col6, Columns } from "../../Controls"
import { ReactComponent as Loader } from "../../assets/loader.svg"
import { BiPlus } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import { commodityList, currencyList } from "../../StaticData"
import { useSnapshot } from "valtio"
import CreditsApis from "../../state/credit-api"
import { AiFillEdit } from "react-icons/ai"
import { MdDownload } from "react-icons/md"
import { TbEditOff } from "react-icons/tb";
import { ImCancelCircle } from "react-icons/im"
import InputField from "../../drivers/components/input/InputField"
import { EntityV2Store, configStore, cususerStore, routerStore } from "../../state/CommonState"
import SelectField from "../../drivers/components/select/SelectField"
import BranchApi from "../../state/branch-api"
import UsersApis from "../../state/users-api"
import { USERS_STATUS } from "../../util/constants"
import { CustomeruserType } from "../../state/Types"
import EntityUtils from "../../util/entityUtils"



export default function EditUsers(props) {
  useSnapshot(cususerStore)
 
   let user = cususerStore.cususerRec
 const Entity = EntityV2Store.EntityRec;
const [checkedAdminuser , setcheckedAdminuser] = useState(false)
const [BranchList , setBranchList] = useState([])
const [SelectedBranchList , setSelectedBranchList] = useState([])
const [userdata , setUserdata] = useState({})
const [errors, setErrors] = useState({})
const [userType , setUserType] = useState("")
const [Usersstatus , setUsersstatus] = useState({})
const [roleList , setRoleList] = useState({})

// useSnapshot(roleList)
 let usertypes =["Entity" , "Branch"]
  const handleChange = (e , r )=> {
    
    if(e.target.value.length > 15 && r === "phone") {
    return null 
     }
    user[r]=e.target.value
    clearFields()
    errors[r]=""
    setErrors(errors)
    switch (r) {
      case "email":
        user[r]=e.target.value.toLowerCase()
        if(e.target.value.toLowerCase().includes("agraga.com")){
        configStore.setModalMessage("agraga mail id not allowed for creating client users")
        cususerStore.cususerRec.set(CustomeruserType)
        }else if(!e.target.value.toLowerCase().includes("agraga.com") && (e.target.value.includes(".") && e.target.value.includes("@"))  ){
        UsersApis.checkUser(e.target.value.trim(), setUserdata)
       }
       
    break  
    default :
      break
      }
  }

  const handleChange1 = (e , r )=> {
    let Error = errors
    Error[r]=""
    setErrors(Error)
    switch (r) {
      case "designation":
        roleList.designation = e.target.value
       setRoleList(roleList)
       let name = user.firstName
       user.firstName = "n"
       user.firstName = name
       break
      case "userType":
        if( e.target.value.toLowerCase() === "entity") {
          setUserType("Entity")
        }else if( e.target.value.toLowerCase() === "branch") {
          setUserType("Branch")
          BranchApi.getAllBranches(Entity.entityId , setBranchList)
        } 
        break 
      default:
      break
  }
}
  let title = ""
  switch (props.mode) {
    case "edit": //(${Branch?._id}) ${Branch?.status}
      title = `User Details`
      break
    case "view":
      title = `User Details`
      break
    case "new":
      title = "Create New User"
      break
    default:
      title = "User Details"
      break
  }
  function clearFields() {
   if(isEntityUser()){
    user.entityList = user.entityList.filter(
      (a) => a.entityId !== Entity.entityId
    );
   }else if (isBranchUser()){
    user.mainEntityList = user.mainEntityList.filter(
      (a) => a.entityId  !== Entity.entityId
    );
   }

  }
  useEffect(()=> {
    if(props.mode === "edit" || props.mode === "view"){
      setUsersDetailsfn()
    }
    let status = JSON.stringify(USERS_STATUS)
    let userStatus = JSON.parse(status)
    delete userStatus['DEACTIVATE'];
    if(user.status !== USERS_STATUS.NEW_REG){
      delete userStatus['NEW_REG'];
    }
    // Usersstatus.
    setUsersstatus(userStatus)
  },[])



 useEffect(()=> {
   if(userdata.status === 200){
    const listEntities= userdata.data.entityList?.filter((r)=>r.entityId === Entity.entityId )
    const listMainEntities= userdata.data.mainEntityList?.filter((r)=>r.entityId === Entity.entityId )
    if(listEntities?.length > 0 || listMainEntities?.length > 0) {
      cususerStore.cususerRec.set(CustomeruserType)
      routerStore.entityUserManageView = "new"
      configStore.setModalMessage("User  already registered in this entity update it if required but can't create a new one")
      setRoleList({})
      setUserType("")
    }else {
      cususerStore.cususerRec.set(userdata.data)
      setUsersDetailsfn()
    }
   }
 }, [setUserdata , userdata])

  // useEffect(setUserdata()=> {
  //    BranchApi.getAllBranches(Entity.entityId , setBranchList)
  // },[])

  function setUsersDetailsfn (){
    const i = user.mainEntityList.findIndex(e => e.entityId === Entity.entityId)
      const r = user.entityList.findIndex(e => e.entityId === Entity.entityId)
      if (i >= 0){
        setRoleList(user.mainEntityList[i])
       if(user.mainEntityList[i].role === "Entity Admin"){
        setcheckedAdminuser(true)
        setUserType("Entity")
       }else if (user.mainEntityList[i].role === "Entity User") {
        setcheckedAdminuser(false)
        setUserType("Entity")
       }else {
        setcheckedAdminuser(false)
       }
      }else if (r >= 0){
        setRoleList(user.entityList[r])
        if(EntityUtils.isIndia(Entity)){
          setSelectedBranchList(user.entityList[r].gstList)
        }else {
          setSelectedBranchList(user.entityList[r].branchList)
        }
        setUserType("Branch")
        BranchApi.getAllBranches(Entity.entityId , setBranchList)
      }
  }

  const ckAdminuserckbox = (e) => {
    if (e.target.checked){
      setcheckedAdminuser(!checkedAdminuser)
    }
  }
  function isEntityUser() {
    if(userType === "Entity"){
      return true
    }
    return false
  }

  function isBranchUser() {
    if(userType === "Branch"){
      return true
    }
    return false
  }

  const addBranchList = (e, r) => {
    errors.gstList=""
    setErrors(errors)
    if (e.target.checked === true) {
      SelectedBranchList.push(r.gst)
      setSelectedBranchList(SelectedBranchList)
    } else if (e.target.checked === false) {
      const index = SelectedBranchList.indexOf(r.gst);
      if(index > -1){
        SelectedBranchList.splice(index, 1)
      }
      setSelectedBranchList(SelectedBranchList)
    }

  };
  const addBranchListNI = (e, r) => {
    errors.gstList=""
    setErrors(errors)
    if (e.target.checked === true) {
      SelectedBranchList.push(r.branchId)
      // SelectedBranchList.push(r.state)
      setSelectedBranchList(SelectedBranchList)
    } else if (e.target.checked === false) {
      const index = SelectedBranchList.indexOf(r.branchId);
      if(index > -1){
        SelectedBranchList.splice(index, 1)
      }
      setSelectedBranchList(SelectedBranchList)
    }

  };

  function isNewMode () {
    return props.mode === "new"
  }
  function isViewMode () {
    return props.mode === "view"
  }
  function isEditMode () {
    return props.mode === "edit"
  }

  function checkedBranchesfn (r) {
   let entityList =  SelectedBranchList.filter(
      (a) => a === r.gst
    );
    if(entityList.length > 0){
     return true 
    }
    return false
  }
  const onFormSubmit = async() => {
    const fieldsCheck = await dovalidationcheck()
    if (fieldsCheck.isError === false) {
    if(isEntityUser() && !isViewMode()){
      let designation = document.getElementById("designationId").value
    let obj = {entityIdentifier : Entity.panNum , entityId : Entity.entityId, isAdmin : checkedAdminuser , role : checkedAdminuser? "Entity Admin" : "Entity User" , designation : designation , status : "ACTIVE" , remarks : ""}
    if(isEditMode()){
      user.entityList = user.entityList.filter((r)=>r.entityId !== Entity.entityId)
      user.mainEntityList = user.mainEntityList.filter((r)=>r.entityId !== Entity.entityId)
    }
    user.mainEntityList.push(obj) 
    }else if (isBranchUser() && !isViewMode()){
      let designation = document.getElementById("designationId").value
      let obj = {}
      if(EntityUtils.isIndia(Entity)){
        obj = {entityIdentifier : Entity.panNum , entityId : Entity.entityId,  role : "Branch User" , designation : designation , gstList :SelectedBranchList , status : "ACTIVE" , remarks : ""}
      }else {
        obj = {entityIdentifier : Entity.tinNum, entityId : Entity.entityId,  role : "Branch User" , designation : designation , gstList :[] , status : "ACTIVE" , remarks : "" , branchList : SelectedBranchList}
      }
   
    if(isEditMode()){
      user.entityList = user.entityList.filter((r)=>r.entityId !== Entity.entityId)
      user.mainEntityList = user.mainEntityList.filter((r)=>r.entityId !== Entity.entityId)
    }
    user.entityList.push(obj)
    }
    console.log(roleList);
    console.log(user);
    if(user.mainEntityList.length>0){
      user.role ="Client Admin"
    }else if (user.entityList.length>0) {
      user.role ="Client User"
    }
   
    let data = {
     from : user.firstName + ' ' + user.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
    }
    if(user.email.includes("agraga")){
      user.isAgragaUser = "Yes"
    }else 
    user.country = Entity.entityCountry
    await UsersApis.createUser(data)
  }
}

  const dovalidationcheck = async () => {
    function isValid(value) {
      return (
        (!Array.isArray(value) &&
          value &&
          value.trim().length > 0 &&
          value !== "default") ||
        (Array.isArray(value) &&
          value.some((item) => item.trim() !== "default"))
      )
    }
    let errors = {}
    let fieldsCheck = { isError: false }
    const emailRegexPattern =
   /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const namePattern = /^[A-Za-z0-9 ]{1,100}$/;
    const designationPattern = /^[A-Za-z0-9./ ]{1,100}$/;
    // let ErrorsSubmit = {}
    let fieldsToSubmit = [
      { title: "FirstName", field: "firstName", required: true , maxLenth : 100 ,pattern:namePattern , example : "No Special Characters allowed , only Alphanumeric"},
      {
        title: "LastName",
        field: "lastName",
        // required: true,
        maxLenth : 100,
        pattern:namePattern ,
        example : "No Special Characters allowed , only Alphanumeric"
      },
      {
        title: "Contact Number",
        field: "phone",
        required: false,
        minLenth : 7,
        maxLenth: 15,
      },
      {
        title: "Email",
        field: "email",
        required: true,
        pattern : emailRegexPattern,
        example : "abc@gmail.com",
      },
      {
        title: "UserType",
        field: "userType",
        required: true,
      },
      {
        title: "Designation",
        field: "designation",
        maxLenth : 100,
        pattern:designationPattern ,
        example : "No Special Characters allowed , only Alphanumeric"
      },

    ]
    fieldsToSubmit.forEach(({ title, field, required, dropdown, maxValue ,minLenth , pattern ,example , maxLenth}) => {
      let value = user[field] 
      if(title === "Designation"){
        value = roleList.designation
      }
      if( title === "UserType"){
        if(!isEntityUser() && !isBranchUser()){
          errors[field] = `Please select a valid ${title}.`
          fieldsCheck.isError = true
        }
      }else {
        if (required && !isValid(value)) {
          errors[field] = `${title} is required.`
          fieldsCheck.isError = true
        }
        if (required && value > maxValue) {
          errors[field] = `${title} value should be between 0 and ${maxValue}`
          fieldsCheck.isError = true
        }
        if (isValid(value) && value.length > maxLenth) {
          errors[field] = `${title} should contain only ${maxLenth} characters`
          fieldsCheck.isError = true
        }
        if (isValid(value) && value.length < minLenth) {
          errors[field] = `${title} should contain atleast ${minLenth} characters`
          fieldsCheck.isError = true
        }

        if (dropdown && value === "default") {
          errors[field] = `Please select a valid ${title}.`
          fieldsCheck.isError = true
        }
        if ((title === "Email" || title === 'FirstName' || title === 'LastName' ||  title === 'Designation')  && isValid(value)  && !pattern.test(value.trim())) {
          errors[field] = `Invalid ${title} pattern eg: ${example}.`
          fieldsCheck.isError = true
        }
      }
      }
    )
    let obj = []
    if(isBranchUser()){
      obj.push(
        {
          title: "GstList",
          field: "gstList",
          value: SelectedBranchList,
        },
   
      )
    }
    
    obj?.forEach(({ field, value }) => {
      if (value.length < 1 ) {
        errors[field] = `Please select atleast any one branch`
        fieldsCheck.isError = true
      }
    })



    setErrors(errors)
    return fieldsCheck
  }

  console.log(user , "props" , props);


  const sendmailInvite = async() => {
    let data = {
      from : user.firstName + ' ' + user.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
     }
     data.email = user.email
     await UsersApis.sendInviteMail(data)
  }
  // console.log(SelectedBranchList);
  return (
    <>
        <div
         className="adduserpg p-5"
        >
          <div className="is-flex is-justify-content-space-between pb-4 conwd_80">
            <span className="is-size-5 has-text-weight-bold">{title}</span>
            {(user.status?.toLowerCase() === "deactivated" && roleList.status?.toLowerCase() !== USERS_STATUS.DEACTIVATE )  && 
              <button className="is-size-5 has-text-weight-bold statusbtn" id ="userdacbtn">{user.status}</button>
            } {(roleList.status === USERS_STATUS.DEACTIVATE )  && 
              <button className="is-size-5 has-text-weight-bold statusbtn" id ="userdacbtn">{roleList.status}</button>
            }
            {isViewMode() ? <> <span className="exit_icon">
              <ImCancelCircle
                // onClick={() => closeaddUser()}
                onClick={async (e) => {
                  e.preventDefault()
                  // props.fn.fn(false)
                  routerStore.entityUserManageView = "list"
                }}
                className="is-clickable exit_icon"
              />
            </span></> : null

            }
           
          </div>
          <Columns className="is-flex">
            <Col5>
            <InputField
              type={"text"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"First Name"} 
              classname={"input is-small"} 
              value={user.firstName} 
              pattern={"[A-Z0-9]"} 
              errorsmsg={errors.firstName}
              callback={(e) => handleChange(e, "firstName")}
                    children={{...(isViewMode() 
                      ? { disabled: true }
                      : null)}}

              ></InputField>
            </Col5>
            <Col1></Col1>
            <Col5>
            <InputField
              type={"text"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"Last Name"} 
              classname={"input is-small"} 
              value={user.lastName} 
              pattern={"[A-Z0-9]"} 
              callback={(e) => handleChange(e, "lastName")}
              errorsmsg={errors.lastName}
              mandatory={false}
              children={{...(isViewMode() 
                ? { disabled: true }
                : null)}}

              ></InputField>
            </Col5>
          </Columns>
          <Columns className="is-flex">
          <Col5>
          <InputField
              type={"number"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"Contact Number*"} 
              classname={"input is-small"} 
              value={user.phone} 
              pattern={"[A-Z0-9]"} 
              callback={(e) => handleChange(e, "phone")}
              errorsmsg={errors.phone}
              mandatory={false}
              onkeydown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              children={{...(isViewMode() 
                ? { disabled: true }
                : null)}
              
              }

              ></InputField>

            </Col5>
            <Col1></Col1>
            <Col5>
            <InputField
              type={"text"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"Email"} 
              classname={"input is-small"} 
              value={user.email} 
              pattern={"[A-Z0-9]"} 
              callback={(e) => handleChange(e, "email")}
              errorsmsg={errors.email}
              children={{...((isViewMode() || isEditMode()) 
                ? { disabled: true }
                : null)}}

              ></InputField>
            </Col5>
          </Columns>
          <Columns className="is-flex">
            <Col5>
              {/* <p>Role*</p>
              <p>
                <input placeholder="" className="p-1 wd_80"></input>
              </p> */}
                <InputField
              type={"text"} 
              label_class={"label is-small"}
              label_style={{marginBottom: "0px" }}
              placeholder={"Designation"} 
              classname={"input is-small"} 
              value={roleList.designation} 
              pattern={"[A-Z0-9]"} 
              callback={(e) => handleChange1(e, "designation")}
              id = "designationId"
              errorsmsg={errors.designation}
              mandatory={false}
              children={{...(isViewMode() 
                ? { disabled: true }
                : null)}}

              ></InputField>
            </Col5>
            <Col1></Col1>
            <Col5>
              <SelectField
                            placeholder={"Role"}
                            classname="select is-small "
                            data={usertypes?.map((r,i)=> {
                              return <option>{r}</option>
                            })}
                            label_class={"label is-small"}
                            label_style={{marginBottom: "0px" }}
                            // ref={ref}
                            input_style={{width: "100%", border: "1px solid #d1d1e0" }}
                            value={userType}
                            callback={(e) => handleChange1(e , "userType")}
                            // children={ {...(isViewMode() ? { disabled: true } : {})}}
                            first_value={""}
                            default_label={"select user type"}
                            errorsmsg={errors.userType}
                            children={{...(isViewMode() 
                              ? { disabled: true }
                              : null)}}
                          
              >
              </SelectField>
            </Col5>
          </Columns>
          <Columns>
        
          <Col5>

{isBranchUser() && EntityUtils.isIndia(Entity) && (<>
    <p
      className="label is-small"
      style={{ marginBottom: "0px" }}
    >
      Select branches
    </p>
{SelectedBranchList.length > -1  && BranchList.map((r, i) => {
        return (
          <>
            <p className="is-flex is-flex-direction-row">
              <input
                id="scopeOfActivityRT"
                type="checkbox"
                onChange={(e)=>addBranchList(e,r)}
                {...(((isEditMode()|| isViewMode() ) && SelectedBranchList.includes(r.gst) )  ? {
                  checked:true
                 } : null)}
                // checked={checkedBranchesfn(r)}
                {...(isViewMode() 
                  ? { disabled: true }
                  : null)}
              ></input>
              <span className="is-size-7 ml-2">{r.state}--{r.gst}</span>
            </p>
          </>
        );
      })}
      {errors.gstList && (
                  <p className="help is-input-danger-message">
                    {errors.gstList}
                  </p>
                )}
</>
)
}
{isBranchUser() && !EntityUtils.isIndia(Entity) && (<>
    <p
      className="label is-small"
      style={{ marginBottom: "0px" }}
    >
      Select branches
    </p>
{SelectedBranchList.length > -1  && BranchList.map((r, i) => {
        return (
          <>
            <p className="is-flex is-flex-direction-row">
              <input
                id="scopeOfActivityRT"
                type="checkbox"
                onChange={(e)=>addBranchListNI(e,r)}
                {...(((isEditMode()|| isViewMode() ) && SelectedBranchList.includes(r.branchId) )  ? {
                  checked:true
                 } : null)}
                // checked={checkedBranchesfn(r)}
                {...(isViewMode() 
                  ? { disabled: true }
                  : null)}
              ></input>
              <span className="is-size-7 ml-2">{r.state}--{r.uid}</span>
            </p>
          </>
        );
      })}
      {errors.gstList && (
                  <p className="help is-input-danger-message">
                    {errors.gstList}
                  </p>
                )}
</>
)
}
{isEntityUser() && (<>
            <p>Is Admin User*</p>
            <p>
              <input type="radio" checked={checkedAdminuser} id="inpAdminCkbox" onClick={(e)=>ckAdminuserckbox(e) }   {...(isViewMode() 
                  ? { disabled: true }
                  : null)}></input><span>Yes</span>
              <input type="radio" checked={!checkedAdminuser} className="ml-3"  onClick={(e)=>ckAdminuserckbox(e)}   {...(isViewMode() 
                  ? { disabled: true }
                  : null)}></input><span>No</span>
            </p>
            </>
            )

            }
</Col5>
        <Col1>
        </Col1>
        <Col5>
        {(isEditMode()) && (<>
          <SelectField
                            placeholder={"Status"}
                            classname="select is-small "
                            data={Object.values(Usersstatus)?.map((r,i)=> {
                              return <option>{r}</option>
                            })}
                            label_class={"label is-small"}
                            label_style={{marginBottom: "0px" }}
                            // ref={ref}
                            input_style={{width: "100%", border: "1px solid #d1d1e0" }}
                            value={user.status}
                            callback={(e) => handleChange(e , "status")}
                            // children={ {...(isViewMode() ? { disabled: true } : {})}}
                            first_value={""}
                            default_label={"Select status"}
                            children={{...(isViewMode() 
                              ? { disabled: true }
                              : null)}}
                          
              >
              </SelectField>
        </>)}

        </Col5>
        
          </Columns>
          
          <Columns>
          {roleList?.remarks?.length > 0 && 
            <div className="mt-4">
              <p>
                <span className="has-text-weight-bold pl-4">Remarks :</span>
                <span className="ml-4">{roleList?.remarks}</span>
              </p>
            </div>
          }
            
          </Columns>

          {(isEditMode()|| isNewMode()) &&
            <div className="">
            {(isEditMode() && user.status === USERS_STATUS.NEW_REG ) && <>
                <button
                className="is-clickable"
                style={{
                  backgroundColor: "rgb(50, 168, 147)",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  marginLeft: "70%",
                }}
                onClick={sendmailInvite}
              >
                Invite
              </button>
              </>}

              <button
                className="is-clickable"
                style={{...((isEditMode() && user.status === USERS_STATUS.NEW_REG )) ? 
                 {
                    marginLeft : "5%",
                    backgroundColor: "#00ccff",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  }  : {
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  marginLeft: "75%",
                }}}
                onClick={async (e) => {
                  e.preventDefault()
                  routerStore.entityUserManageView = "list"
                }}
              >
                CANCEL
              </button>
             
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  marginLeft: "3%",
                }}
                onClick={onFormSubmit}
               
              >
                SUBMIT
              </button>
        
          
          </div>
            
          }
       
        </div>
    </>
  )
}
