import { configStore, contractsStore, loginStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import HomeList from "./home-list"
import HomeScreen from "./home-screen"
import { useEffect } from "react"
import Newapi from "../state/new-api"

export default function HomeMain() {
    const a = useSnapshot(configStore)
    useSnapshot(contractsStore)
    useEffect(() => {
        if (!contractsStore.navigateFromCharts) Newapi.getContracts()
    },[configStore.currentSelectedEntity,configStore.currentSelectedBranch])
    return <HomeScreen/>
    switch(configStore.homescreenCurrentView) {
        case 'list':
            configStore.displaySchedules = false 
            return <HomeList/>
        case 'view': 
            //configStore.displaySchedules = true
            return <HomeScreen viewonly='1'/>
        case 'new': return <HomeScreen/>
    }
}