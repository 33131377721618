import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import {
    contractsStore, configStore
} from '../state/CommonState'
import Utils from "../util/Utils"

export default function FCLChargeClientView() {
    useSnapshot(contractsStore)
    const rec = contractsStore.current
    const numContainers = rec.containerList.length
    const containerNames = rec.containerList.map(e => e.containerType)

    let [space, setSpace] = useState('')

    const Tn = ({ i, name }) => {
        const e = rec.chargesList[i]
        let num = e[name]
        num = isNaN(num) ? 0.0 : Number(num)
        return num > 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
    }

    let grandTotal = {}
    let grantTotaloftotals = 0.0
    rec.containerList.map(c => {
        grandTotal[c.containerType] = 0.0
    })
    rec.chargesList.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        rec.containerList.map(c => {
            let totalField = c.containerType + 'total'
            let value = e[totalField] * erate
            grandTotal[c.containerType] += value
            grantTotaloftotals += value
        })
    })
    let counter = 1
    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            {containerNames.map(e => (
                                <td className="has-text-centered has-text-weight-bold" colSpan={3}>{e}</td>
                            ))}
                            <th></th>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th>Charge Description</th>
                            <th>Currency</th>
                            {containerNames.map(e => (
                                <>
                                    <th style={{ textAlign: 'center' }}>Per Container</th>
                                    <th style={{ textAlign: 'center' }}>Per BL</th>
                                    <th style={{ textAlign: 'right' }}>Total</th>
                                </>
                            ))}
                        </tr>
                    </thead>    
                    <tbody>
                        {
                            rec.chargesList.map((e, i) => (
                                <tr key={e.id}>
                                    <td >{counter++}</td>
                                    <td>{e.chargeDescription}</td>
                                    <td>{e.currency}</td>
                                    {containerNames.map(e1 => (
                                        <>
                                            <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perWM'} /></td>
                                            <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perShipment'} /></td>
                                            <td style={{ textAlign: 'right' }}>
                                                {Utils.N(e[e1 + 'total'], e.currency)}
                                            </td>
                                        </>
                                    ))}
                                </tr>
                            )
                            )
                        }
                        <tr>
                            <td colSpan={3}></td>
                            {
                                rec.containerList.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                    {Utils.N(grandTotal[c.containerType])}
                                                </strong>
                                            </td>
                                        </>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td colSpan={3 + rec.containerList.length * 3}
                                style={{ textAlign: 'right', fontSize: '1.1rem' }}>
                                <strong>
                                    {Utils.N(grantTotaloftotals)}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3 + rec.containerList.length * 3} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}
