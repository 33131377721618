import { useSnapshot } from "valtio"

import AguserApi from "../state/AguserApi"
import { Buffer } from 'buffer'
import {
  EntityV2Store,
  configStore,
  creditAppStore,
  creditformStore,
  loginStore,
  routerStore,
} from "../state/CommonState"
import { EntityTypeV2 } from "../state/Types"
import EntityApi from "../state/entities-api"
import { City, Country, State } from "country-state-city"
import { useEffect, useRef, useState } from "react"
import { BsFillEyeFill } from "react-icons/bs"
import { MdDelete } from "react-icons/md"
import { HiMiniDocumentCheck } from "react-icons/hi2"
import { ImCancelCircle } from "react-icons/im"
import { BiPlus } from "react-icons/bi"
import off from "../../src/components/off.png"
import on from "../../src/components/on.png"
import { dtactionStore } from "../dtactions/dtaction-store"

//import { Col12, Col1,Col2, Col3, Col4, Col5, Col6, Columns } from "../Controls";
import {
  Col12,
  Col1,
  Col2,
  Col3,
  Col4,
  Col8,
  Col7,
  Col5,
  Cols,
  Col6,
  Columns,
} from "../Controls"

import { loader } from "../util/loader"
import { currencyList } from "../StaticData"
import CreditsApis from "../state/credit-api"
import { RiFolderDownloadFill } from "react-icons/ri"
import { LuUpload } from "react-icons/lu"
import Api from "../state/Api"
import { CREDIT_STATUS, CREDIT_APPLICATION_ACTIONS } from "../util/constants"
import RoleUtils from "../util/RoleUtils"


export default function EditCreditApproval(props) {
  const Entity = EntityV2Store.EntityRec
  const Form = creditformStore.CreditformRec
  const userRole = creditAppStore.role
  const [FormUserStatus, setFormUserStatus] = useState("")
  const [errors, setErrors] = useState({})
  const [pendingErrors, setPendingErrors] = useState({})
  const [approveCheckbox, setApproveCheckbox] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState(null)
  const [message, setMessage] = useState("")
  const [downloadFormData, setDownloadFormData] = useState({})
  const [cursor, setCursor] = useState(0)
  const [subbtnstatus, setsubbtnstatus] = useState(false)
  const ref = useRef(null)
  const hiddenFileInput1 = useRef()
  let title = ""
  switch (props.mode) {
    case "edit":
      title = `Modify (${Entity?._id}) ${Entity?.status}`
      break
    case "view":
      title = `View (${Entity?._id}) ${Entity?.status}`
      break
    case "new":
      title = "Create Entity"
      break
    default:
      title = "Entity"
      break
  }
  useSnapshot(EntityV2Store)
  useSnapshot(creditformStore)
  useSnapshot(loader)
  useEffect(() => {
    const input = ref.current
  }, [ref, cursor])

  useEffect(() => {
    // console.log("kkkc",creditAppStore.role);
    const CreditFormStatus = creditformStore.CreditformRec
    if (CreditFormStatus.role === "SALES") {
      setFormUserStatus(CreditFormStatus.action)
    } else if (CreditFormStatus.role === "FINANCE") {
      setFormUserStatus(CreditFormStatus.action)
    }
  }, [])
  const handleVerifyRadioChange = (event) => {
    Form.remarks = ""
    setApprovalStatus(event.target.value)
    setApproveCheckbox(true)
    setMessage("")
  }

  const handleMessageChange = (e) => {
    Form.remarks = e.target.value
  }

  function isSalesPending() {
    return Form.action === CREDIT_APPLICATION_ACTIONS.APP_PEN_SALES
  }
  function isFinancePending() {
    return Form.action === CREDIT_APPLICATION_ACTIONS.APP_PEN_FINANCE
  }
  function isSignedCopyUploadPen() {
    return Form.action === CREDIT_APPLICATION_ACTIONS.UPL_SGN_COPY
  }
  function isUserSales() {
    return Form.role === "SALES"
  }
  function isUserFinance() {
    return Form.role === "FINANCE"
  }

  console.log("salespen", isSalesPending(), isUserSales())
  const timelinepushfn = (r) => {
    let timeline = {
      date: Date.now(),
      event: r,
      user: loginStore.email,
      role: RoleUtils.getUserRole(),
    }
    Form.timeline.push(timeline)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setsubbtnstatus(true)
    if (isSalesPending()) {
      if (approvalStatus === "approve") {
        const chkBox = document.getElementById("approveChkbox").checked
        if (chkBox) {
          let statuses = {
            status: CREDIT_STATUS.APPPENFINANCE,
            action: Form.action,
            updatedBy: loginStore.email,
          }
          // Form.salesstatus = CREDIT_STATUS.APPSALES
          // Form.status = CREDIT_STATUS.APPPENFINANCE;
          // Form.finstatus = CREDIT_STATUS.APPPENFINANCE;
          await timelinepushfn(CREDIT_APPLICATION_ACTIONS.APPROVED_SALES)
          let dataUpdate = {
            formdata: Form,
            statuses: statuses,
          }
          await CreditsApis.updateStatus(dataUpdate, setsubbtnstatus)
        } else {
          setsubbtnstatus(false)
        }

      } else if (approvalStatus === "reject") {
        if (Form.remarks.trim().length < 1) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Please enter remarks , it should contain atleast 3 characters")
        }
        if (Form.remarks.trim().length < 3) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Remarks should contain atleast 3 characters")
        }
        let statuses = {
          status: CREDIT_STATUS.REJECTED,
          action: CREDIT_APPLICATION_ACTIONS.APP_PEN_SALES,
          updatedBy: loginStore.email,
          rejectedrole: Form.role,
        }
        await timelinepushfn(CREDIT_APPLICATION_ACTIONS.REJ_SALES)
        let dataUpdate = {
          formdata: Form,
          statuses: statuses,
        }
        await CreditsApis.updateStatus(dataUpdate, setsubbtnstatus)
      }

    } else if (isFinancePending()) {
      if (approvalStatus === "approve") {
        let statuses = {
          status: CREDIT_STATUS.SIGCPYUPLOAD,
          action: Form.action,
          updatedBy: loginStore.email,
        }
        // Form.finstatus = CREDIT_STATUS.APPFINANCE
        //   Form.status = CREDIT_STATUS.SIGCPYUPLOAD;
        //   Form.salesstatus = CREDIT_STATUS.SIGCPYUPLOAD;
        await timelinepushfn(CREDIT_APPLICATION_ACTIONS.APPROVED_FINANCE)
        let dataUpdate = {
          formdata: Form,
          statuses: statuses,
        }
        await CreditsApis.updateStatus(dataUpdate, setsubbtnstatus)
      } else if (approvalStatus === "reject") {
        if (Form.remarks.trim().length < 1) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Please provide remarks or comments for the rejection.")
        }
        if (Form.remarks.trim().length < 3) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Remarks should contain atleast 3 characters")
        }
        let statuses = {
          status: CREDIT_STATUS.REJECTED,
          action: CREDIT_APPLICATION_ACTIONS.APP_PEN_FINANCE,
          updatedBy: loginStore.email,
          rejectedrole: Form.role,
        }
        await timelinepushfn(CREDIT_APPLICATION_ACTIONS.REJ_FINANCE)
        let dataUpdate = {
          formdata: Form,
          statuses: statuses,
        }
        await CreditsApis.updateStatus(dataUpdate, setsubbtnstatus)
      }

    } else if (isSignedCopyUploadPen()) {
      if (Form.documents[0].fileLink.length > 0) {
        let statuses = {
          status: CREDIT_STATUS.VERIFIED,
          action: Form.action,
          updatedBy: loginStore.email,
        }
        await timelinepushfn(CREDIT_APPLICATION_ACTIONS.VERIFIED)
        let dataUpdate = {
          formdata: Form,
          statuses: statuses,
        }
        await CreditsApis.updateStatus(dataUpdate, setsubbtnstatus)
      } else {
        configStore.setModalMessage("Please Upload the signed copy")
        setsubbtnstatus(false)
      }
    }
    setsubbtnstatus(false)
  }
  const delDoc = () => {
    Form.documents[0].fileLink = ""
    Form.documents[0].fileKey = ""
  }
  console.log(Form)
  const handleChange = (e, r, z, a) => {
    setCursor(e.target.selectionStart)
    if (r === "creditagency" || r === "creditscore" || r === "overallcreditscore") {
      let re = new RegExp("^([A-Za-z0-9- ]{0,100})$")
      if (!re.test(e.target.value)) {
        return null
      }
    }

    Form[r] = e.target.value
  }

  function change(files, filetype, i) {
    const formData = new FormData()
    formData.append("myFile", files[0])
    formData.append("label", filetype)
    formData.append("key", i)
    fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/filestore/storefile`, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          Form.documents[0].fileLink = data?.key?.filename
          Form.documents[0].pkey = data?.key?.pkey
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  console.log(RoleUtils.getUserRole())
  function isAgragaAdmin() {
    return RoleUtils.getUserRole().toLowerCase() === "agraga admin"
  }
  function isAgragaSales() {
    return RoleUtils.getUserRole().toLowerCase() === "sales"
  }
  function isAgragaFinance() {
    return RoleUtils.getUserRole().toLowerCase() === "finance"
  }
  const toggleSoleProp = () => {
    return (Entity.vendor.isSoleProp = !Entity.vendor.isSoleProp)
  }
  return (
    <>
      <div class="main-content" style={{ zoom: "0.9" }}>
        <div className="header-wrapper">
          <div className="left-header">
            <div className="page-heading" >

              <span className="heading">
                {Form.entityId}/{FormUserStatus}
              </span>

            </div>
          </div>
          <div style={{ marginRight: "3%" }}>
            <span>
              <button
                onClick={async (e) => {
                  e.preventDefault()
                  EntityV2Store.EntityRec.set(new EntityTypeV2())
                  routerStore.creditApplicationView = "list"
                }}
                className="delete is-large is-info "
                style={{ backgroundColor: "#485fc7", marginRight: "15%" }}
              ></button>
            </span>
          </div>
        </div>
        <section
          class="section section-padding"
          style={{ backgroundColor: "#eee", paddingTop: "0px" }}
        >
          <Cols>
            <Col7 style={{ zoom: "0.9" }}>
              <Cols>
                <Col4 style={{ color: "darkblue" }}>Entity Name</Col4>
                <Col7>{Form.entityName}</Col7>
              </Cols>
              <Cols>
                <Col4 style={{ color: "darkblue" }}>PAN Number</Col4>
                <Col7>{Form?.panNum}</Col7>
              </Cols>
              
              <Cols>
                <Col4 style={{ color: "darkblue" }}>GST Numbers</Col4>
                <Col7>
                  {Form.gstList?.map((r, i) => {
                    return (
                      <button className="pd-2 button-caf-gst">{r.gst}</button>
                    )
                  })}
                </Col7>
              </Cols>
              {Form?.customer?.crossBorder?.customerService.length > 3 && <>
                <p className="is-size-5 has-text-weight-bold" >Cross-Border</p>
                  <Cols>
                <Col4 style={{ color: "darkblue" }}>Agraga Branch</Col4>
                <Col7>{Form?.customer?.crossBorder?.agragaBranch}</Col7>
              </Cols>
              <Cols>
                <Col4 style={{ color: "darkblue" }}>Relationship Manager</Col4>
                <Col7>{Form?.customer?.crossBorder?.relationshipManager}</Col7>
              </Cols>
              <Cols>
                <Col4 style={{ color: "darkblue" }}>Customer Service</Col4>
                <Col7>{Form?.customer?.crossBorder?.customerService}</Col7>
              </Cols>
            
              </>
              }
              {Form?.customer?.domesticTransport?.customerService.length > 3 && <>
                <p className="is-size-5 has-text-weight-bold" >Domestic-Transport</p>
                <Cols>
                <Col4 style={{ color: "darkblue" }}>Agraga Branch</Col4>
                <Col7>{Form?.customer?.domesticTransport?.agragaBranch} </Col7>
              </Cols>
              <Cols>
                <Col4 style={{ color: "darkblue" }}>Relationship Manager</Col4>
                <Col7>{Form?.customer?.domesticTransport?.relationshipManager}</Col7>
              </Cols>
              <Cols>
                <Col4 style={{ color: "darkblue" }}>Customer Service</Col4>
                <Col7>{Form?.customer?.domesticTransport?.customerService}</Col7>
              </Cols>
              <Cols>
                <Col4 style={{ color: "darkblue" }}>Branch Operation Manager</Col4>
                <Col7>{Form?.customer?.domesticTransport?.branchOperationManager} </Col7>
              </Cols>
              </>
              }
            </Col7>
            <Col5>
              <div
                style={{
                  fontWeight: "bold",
                  color: "purple",
                  fontSize: "1.1rem",
                }}
                className=""
              >
                TimeLine
              </div>
              <table
                className="table is-fullwidth is-narrow timeline_table"
                style={{ zoom: "0.8" }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Event</th>
                    <th>User</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {Form.timeline.map((r, i) => {
                    return <>
                      <tr key={i}>
                        <td>{new Date(Form.timeline[i].date).toLocaleString()}</td>
                        <td>{Form.timeline[i].event}</td>
                        <td>{Form?.timeline[i]?.user}</td>
                        <td>{Form.timeline[i].role}</td>
                      </tr>
                    </>
                  })}
                </tbody>
              </table>
            </Col5>
          </Cols>
        </section>
        {
          // (
          // function () {
          //     if (action._id.actionName == 'Document Verification') return <DtactionsDocverify setSubmitCallback={setSubmitCallback} />
          //     else if (action._id.actionName == 'Final Document Verification') return <DtactionsFinaldocverify setSubmitCallback={setSubmitCallback} />
          //     else if (action._id.actionName == 'Initiate Advance') return <DtactionsPayment setSubmitCallback={setSubmitCallback} />
          //     else return <DtactionsBalance setSubmitCallback={setSubmitCallback} />
          // }
          // )()
        }

        <hr></hr>
        <form className="p-2">
          <p className="is-size-5 has-text-weight-bold">Credit Details</p>
          <Columns>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold">Duration*</label>
                {/* <input className="py-1" placeholder="In days"  value={Form?.duration}></input> */}
                <input
                  type="text"
                  className="input is-small "
                  value={Form?.duration}
                  // ref={refEntityName}
                  disabled

                // onChange={(e) => handleChange(e, "entityName")}
                />
              </div>
            </Col4>
          </Columns>
          <Columns>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold">
                  Amount*
                </label>
                <input
                  type="text"
                  className="input is-small "
                  value={Form?.creditlimit}
                  // ref={refEntityName}
                  disabled

                // onChange={(e) => handleChange(e, "entityName")}
                // natureofbusiness
                />
              </div>
            </Col4>
          </Columns>
          <Columns>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold">
                  Nature of Business
                </label>

                <select disabled className="py-1 px-2" name="natureofbusiness">
                  <option>{Form?.natureofbusiness}</option>
                </select>
              </div>
            </Col4>
          </Columns>

          <Columns>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold">
                  Nature of Cargo
                </label>
                <select disabled className="py-1 px-2" name="natureofbusiness">
                  <option>{Form?.natureofcargo}</option>
                </select>
              </div>
            </Col4>
          </Columns>
          <Columns>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold">Turnover</label>
                <div>
                  <select
                    disabled
                    className="py-1 wd_20 px-2"
                    name="Currency"
                    value={Form?.turnover?.currency}
                  >
                    {currencyList.map((r, i) => {
                      return (
                        <option key={i} value={r}>
                          {r}
                        </option>
                      )
                    })}
                  </select>
                  <input
                    className="py-1 wd_80 px-2"
                    placeholder="Value"
                    type="text"
                    value={Form?.turnover?.value}
                    // ref={refEntityName}
                    disabled
                  ></input>
                </div>
              </div>
            </Col4>
          </Columns>
          <hr></hr>
          <p className="is-size-5 has-text-weight-bold">Financial Summary*</p>
          <table className="table_caf wd_80">
            <thead>
              <td>Product</td>
              <td>Total Potential</td>
              <td>Monthly Revenue Projected</td>
            </thead>
            <tbody>
              <tr>
                <td className="px-2">International</td>
                <td>
                  <span></span>
                  <input
                    type="text"
                    className="inp_table_caf"
                    value={
                      Form?.financialsummary?.International?.totalpotential
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="inp_table_caf"
                    value={
                      Form?.financialsummary?.International?.monthlyrevenue
                    }
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td className="px-2">Domestic Transport</td>
                <td>
                  <input
                    type="text"
                    className="inp_table_caf"
                    value={Form?.financialsummary?.Domestic?.totalpotential}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="inp_table_caf"
                    value={Form?.financialsummary?.Domestic?.monthlyrevenue}
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p className="is-size-5 has-text-weight-bold mt-4">Finance*</p>
          <div className="is-flex ">
            <table className="table_caf_fin wd_80">
              <thead>
                <td>Customer Contact Person</td>
                <td>Description/Department</td>
                <td>Contact Number</td>
                <td>Email</td>
                {/* <td></td> */}
              </thead>
              <tbody>
                {Form.finance?.map((r, i) => {
                  return (<tr>
                    <td>
                      <input
                        type="text"
                        className="inp_table_caf is-size-6"
                        value={r.customercontact}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="inp_table_caf is-size-6"
                        value={r.department}
                        disabled
                      />
                    </td>
                    <td className="is-flex wd_100">
                      <span className="mt-2 pl-2 ">+91</span>
                      <input
                        type="text"
                        className="inp_table_caf is-size-6"
                        value={r?.contactnum}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="inp_table_caf is-size-6"
                        value={r?.email}
                        disabled
                      />
                    </td>

                  </tr>)
                })}

                {/* })} */}
              </tbody>
            </table>
          </div>

          <p className="is-size-5 has-text-weight-bold mt-4">
            Finance Escalation
          </p>
          <table className="table_caf_fin wd_80">
            <thead>
              <td>Customer Contact Person</td>
              <td>Description/Department</td>
              <td>Contact Number</td>
              <td>Email</td>
            </thead>
            <tbody>
              {Form.financeescalation?.map((r, i) => {
                return (
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="inp_table_caf"
                        value={r?.customercontact}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="inp_table_caf"
                        value={r?.department}
                        disabled
                      />
                    </td>
                    <td className="is-flex wd_100">
                      {r.contactnum &&
                        <span className="contNumcodespn mt-2">+91</span>
                      }
                      <input
                        type="text"
                        className="inp_table_caf"
                        value={r?.contactnum}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="inp_table_caf"
                        value={r?.email}
                        disabled
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <hr></hr>

          {(
            <>
              {(isSalesPending() && isUserSales()) && (
                <p className="is-size-5 has-text-weight-bold">
                  Sales Approvals for this Credit Facility
                </p>

              )
              }
              {(isFinancePending() && isUserFinance()) && (
                <p className="is-size-5 has-text-weight-bold">
                  Finance Approvals for this Credit Facility
                </p>
              )
              }
              {((isSalesPending() && isUserSales()) || (isFinancePending() && isUserFinance())) && (
                <div className="is-flex is-justify-content-space-between">
                  <span className="is-size-6" style={{ marginTop: "1%" }}>
                    <div>
                      <label>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="approve"
                          checked={approvalStatus === "approve"}
                          onChange={handleVerifyRadioChange}
                        />
                        Approve
                      </label>
                      <label style={{ marginLeft: "10px" }}>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="reject"
                          checked={approvalStatus === "reject"}
                          onChange={handleVerifyRadioChange}
                        />
                        Reject
                      </label>
                    </div>
                  </span>
                </div>
              )}

              {approvalStatus === "reject" && (
                <div>
                  <textarea
                    //  id="rejectremarksid"
                    className="px-2"
                    rows="4"
                    cols="50"
                    placeholder="Enter your Remarks"
                    value={Form.remarks}
                    onChange={handleMessageChange}
                    //onChange={approvebtnnfn}
                    style={{ marginTop: "10px", border: "1px solid #d1d1e0" }}
                  />
                </div>
              )}
              {(isSalesPending() && approvalStatus === "approve") && (
                <div>
                  Do you want to approve? <span></span>
                  <input
                    type="checkbox"
                    className="is-size-2"
                    name="approvalStatus"
                    // value="approve"
                    id="approveChkbox"
                  // onChange={approvebtnnfn}
                  />
                </div>
              )}
            </>
          )}

          {(isFinancePending() && approvalStatus === "approve") && (
            <>
              <Col4>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Credit Rating Agency
                </p>
                <div className="is-flex">
                  <input
                    type="text"
                    className="input is-small "
                    placeholder="Name"
                    value={Form.creditagency}
                    //value={Branch?.gst}
                    // ref={ref1}
                    id="gst"
                    pattern="[A-Z0-9]{15}"
                    onChange={(e) => handleChange(e, "creditagency")}
                    style={{ width: "80%" }}
                  />
                </div>
              </Col4>
              <Col4>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Credit Score
                </p>
                <div className="is-flex">
                  <input
                    type="text"
                    className="input is-small "
                    placeholder="Score"
                    value={Form.creditscore}
                    //value={Branch?.gst}
                    // ref={ref1}
                    id="gst"
                    pattern="[A-Z0-9]{15}"
                    onChange={(e) => handleChange(e, "creditscore")}
                    style={{ width: "80%" }}
                  />
                </div>
              </Col4>
              <Col4>
                <p className="label is-small">Overall Credit Score</p>
                <div className="is-flex">
                  <input
                    type="text"
                    className="input is-small "
                    placeholder="Overall Score"
                    value={Form.overallcreditscore}
                    //value={Branch?.gst}
                    // ref={ref1}
                    id="gst"
                    pattern="[A-Z0-9]{15}"
                    onChange={(e) => handleChange(e, "overallcreditscore")}
                    style={{ width: "80%" }}
                  />
                </div>
              </Col4>
              <p
                className="label is-small"
                style={{ fontSize: "15px", marginLeft: "7px" }}
              >
                Invoice discount available
              </p>
              <Col4>
                <>
                  <button
                    className="mt-1 is-clickable"
                    type="button"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                    }}
                    onClick={toggleSoleProp}

                    disabled={true}
                  >
                    {Entity?.vendor?.isSoleProp ? (
                      <img height="20px" width="36px" src={on}></img>
                    ) : (
                      <img height="20px" width="36px" src={off}></img>
                    )}
                  </button>
                </>
              </Col4>
              <Col4>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Virtual Account Number
                </p>
                <div className="is-flex">
                  <input
                    type="text"
                    className="input is-small "
                    //value={Branch?.gst}
                    // ref={ref1}
                    id="gst"
                    pattern="[A-Z0-9]{15}"
                    disabled={true}
                    // onChange={(e) => handleChange(e, "gst")}
                    style={{ width: "80%" }}
                  />
                </div>
              </Col4>
              <Col4>
                <p className="label is-small">Invoice Discount Partner</p>

                <select
                  className="select is-small "
                  style={{ width: "80%", border: "1px solid #d1d1e0" }

                  }
                  disabled={true}
                //value={Branch.relationshipManager}
                //{...(isViewMode() ? { disabled: true } : {})}
                //</Col4> onChange={(e) => handleChange(e, "relationshipManager")}
                >
                  <option selected disabled value="">
                    Select Invoice Discount Partner
                  </option>
                  {/* {renderSalesOptions(
                     Object.values(salesList),

                    // Branch?.relationshipManager
                   )} */}
                  <option value="">Details 1</option>
                  <option value="">Details 2</option>
                </select>
              </Col4>
            </>
          )}

          {(isSignedCopyUploadPen() && isUserSales()) && (
            <>
              <p className="is-flex is-align-items-center">
                Download Application{" "}
                <a
                  // href={
                  //   process.env.REACT_APP_API_SERVER +
                  //   "/api/v1/stakeholder/getCreditDocument/" +
                  //   Form.entityId 
                  // }
                  // target="_blank"
                  // rel="noreferrer"
                  // alt=""
                  //   onClick={async () => {
                  //   await CreditsApis.getCafDocs(Form.entityId ,setDownloadFormData )
                  //      console.log(downloadFormData);
                  //       const buffer = Buffer.from(downloadFormData.data);
                  //       const blob = new Blob([buffer]);
                  //       const fileURL = window.URL.createObjectURL(blob);
                  //       console.log(fileURL)
                  //       let alink = document.createElement('a');
                  //       alink.href = fileURL;
                  //       alink.download = `${Form.entityId}.pdf`;
                  //       alink.click();


                  // }}
                  onClick={async () => {
                    let res = await Api.getCafDocumentById(Form.entityId)
                    const buffer = Buffer.from(res)
                    const blob = new Blob([buffer])
                    const fileURL = window.URL.createObjectURL(blob)
                    let alink = document.createElement('a')
                    alink.href = fileURL
                    alink.download = `${Form.entityId}.pdf`
                    alink.click()
                  }}
                >
                  <span className="icon mt-1 ml-3 is-size-4 ">
                    <RiFolderDownloadFill />
                  </span>
                </a>
              </p>
              <p className="is-flex is-align-items-center">
                Upload Signed Credit application{" "}


                {Form.documents[0].fileLink.length > 0 ? (
                  <>
                    <span className="ml-2 mr-1 is-size-4">
                      <a
                        href={
                          process.env.REACT_APP_API_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Form.documents[0]?.pkey +
                          "/" +
                          Form.documents[0]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill />
                      </a>
                    </span>
                    <span className="icon ml-2 is-size-4 is-clickable">
                      <MdDelete
                        onClick={() => (
                          delDoc(), (hiddenFileInput1.current.value = "")
                        )}
                      />
                    </span></>) : <><a>
                      <span className="icon mt-1 ml-3 is-size-4 ">
                        <LuUpload
                          onClick={() => hiddenFileInput1.current.click()}
                        />
                      </span>
                    </a></>
                }
              </p>

              <input
                type="file"
                onChange={(e1) => {
                  change(
                    e1.target.files,
                    "SignedCredit",
                    Form.entityId
                  )
                }}
                name="image"
                accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                multiple=""
                ref={hiddenFileInput1}
                data-original-title="upload photos"
                style={{ display: "none" }}
              />
            </>
          )}
          {((isSignedCopyUploadPen() && isUserSales()) || (isSalesPending() && isUserSales()) || (isFinancePending() && isUserFinance())) &&
            (
              <div>
                <span>
                  <button
                    className="button_cancel"
                    style={{ marginLeft: "3px", paddingLeft: "10px" }}
                    onClick={() => {
                      EntityV2Store.EntityRec.set(new EntityTypeV2())
                      routerStore.creditApplicationView = "list"
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="button_submit"
                    id="approvebtnid"
                    onClick={(e) => handleSubmit(e)}
                    disabled={subbtnstatus}
                  >
                    SUBMIT
                  </button>
                </span>
              </div>
            )
          }
        </form>

        <hr />
      </div>
    </>
  )

  function clearErrorMessages(r) {
    pendingErrors[r] = ""
    errors[r] = ""
    setPendingErrors(pendingErrors)
  }
}
