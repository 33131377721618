import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Typography } from "@mui/material";

import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { bookingsStore, breadCrumbStore, configStore, contractsStore, enquiryFormState, enquiryState, loginStore, newDashboardState, quoteDataRec,EntityV2Store,entityStore } from "../state/CommonState";
import { AntSwitch } from "../mui-components/AntSwitch";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
import schedule from "../assets/images/schedule.svg";
import prices from "../assets/images/price.svg";
import AgAutoComplete, { AgMuiUn } from "../mui-components/AgAutoComplete";
import { useForm } from "react-hook-form";
import trackicon from "../assets/images/track.svg";
import shipIcon from "../assets/images/shipicon.svg";
import vgmicon from "../assets/images/vgmicon.svg";
import arrow from "../assets/images/arrowright.svg";
import airplane from "../assets/images/airplane.svg";
import debitcard from "../assets/images/debitcard.svg";
import arrowtilted from "../assets/images/arrowtilted.svg"
import { useGlobalState } from "../GlobalState";
import ChartApi from "../charts/chart.api";
import { data } from "../charts/c1";
import ApiAgTable from "../components/ag-table/api_ag-table";
import Newapi from "../state/new-api";
import { subscribe, useSnapshot } from "valtio";
import { Calculate } from "@mui/icons-material";
import ListBookingsMainv2 from "../mybooksv2/booking-list-main-v2";
import { AgMuiSelect } from "../mui-components/AgMuiSelect";
import  helpList  from "../static-data/helpcenter";
import { SHIPMENT_SCOPE } from "../util/quotationUtil";
import { AgDatePicker } from "../mui-components/AgDatePicker";
import LabeledTextField from "../mui-components/LabeledTextField";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import filter from "../assets/images/filter.svg";
import filter1 from "../assets/images/filter1.svg";
import { ViewSchedule } from "./ViewSchedules";
import { ShipmentScopeTag } from "../mui-components/ShipmentScope";
import { CargoDetailsPage } from "./CargoDetails";
import backarrow from "../assets/images/backarrow.svg";
import { PricingDetailsPage } from "./PricingDetailsPage";
import { Cargo, Container, Vehicle, enquiryStore } from "../enquiry/enquiry-data";
import TaskList from "../mytasks/task-list";
import BookingsApi from "../state/bookings-api";
import { taskStore } from "../mytasks/task-store";
import play_icon from "../assets/images/media_play.svg"
import MonApi from "../state/mongo-api";
import CustomerMyactions from "../my-actions/customer-myactions";
import { VerticalDashedLine } from "../mui-components/VerticalDashedLine";
import pickup from "../assets/images/pickup.svg";
import arrival from "../assets/images/arrival.svg";
import pickup2 from "../assets/images/pickup2.svg";
import arrival2 from "../assets/images/arrival2.svg";
import DtBookingMain from "../dt/DtBookingMain";
import DtContractMain from "../dt/DtcontractMain";
import { DtStore } from "../dt/DtStore";
import { DtMap } from "../dt/DtMap";
import { ShippingDetails } from "./ShippingDetails";
import Utils from "../util/Utils";
import { myactionsstore } from "../my-actions/store-myactions";
import { Visibility } from "../my-actions/visibility";
import { VisibilityGraph } from "../my-actions/visibilitygraph";
import { Close } from "reactour";
import closeIcon from "../assets/images/close.svg";
import { WelcomeCard } from "../mui-components/welcomecard";
import { Nodata } from "../mui-components/nodatafound";
import { ChartNewHome } from "../charts/chart.new.home";
import TrackMyBooking from "../trackmybookings/TrackMyBookings";
import { SettingsPage } from "./SettingPage";
import { CiSearch } from "react-icons/ci";
import { HelpCenter } from "./HelpCenter";
import { HorizontalDashedLine } from "../mui-components/HorizontalDashedLine";
import BranchApi from "../state/branch-api";
import EntityApi from "../state/entity-api";
import { ENTITY_STATUS } from "../util/constants";
const shipmentTYpes = ['LCL', 'FCL', 'Air'];




function getColorForDate(status) {
    const LegendColor = {
        delayed: "#F25B5B",
        ontime: "#555555",
        completed: "#71A282",
        complete: "#71A282",
        "in-transit": "#555555"
    }
    return LegendColor[status.toLowerCase()];
}

function getColorForTag(status) {
    const Color = {
        delayed: "#2E379733",
        ontime: "#EBB02D33",
        completed: "#2C8A5733",
        complete: "#2C8A5733",
        "in-transit": "#EBB02D33"
    }
    return Color[status.toLowerCase()];
}

function getColorForTagText(status) {
    const Color = {
        delayed: "#2A6ED4",
        ontime: "#EBB02D",
        completed: "#2C8A57",
        complete: "#2C8A57",
        "in-transit": "#EBB02D"
    }
    return Color[status.toLowerCase()];
}


function getText(status) {
    const Text = {
        delayed: "Yet to commence",
        ontime: "In-Transit",
        completed: "Completed",
        "in-transit": "In-Transit",
        complete: "Completed"
    }
    return Text[status.toLowerCase()];
}






function DashboardComponent({selectedQuestion}) {
    const defaultForm = useForm(({
        defaultValues: {
            mode: "LCL",
            origin: "",
            destination: "",
            orginScope: false,
            destinationScope: false,
            cargoreadinessdate: "",
            scope: ""
        }
    }))
    const isRateSelected = useRef(false);
    enquiryFormState.form = defaultForm;

    const { control, watch, register, setValue, handleSubmit, reset, clearErrors, formState, trigger, getFieldState } = defaultForm;

    const { isValid, errors } = formState

    const isOriginDoor = watch("orginScope");
    const isDestinationDoor = watch("destinationScope");
    const shipmentScope = watch("scope");

    const onSubmit = async (data, e) => {

        if(configStore?.currentSelectedEntity?.status !== ENTITY_STATUS.DEACTIVATED){

        

        const validationErrors = await trigger();
        console.log("the validation errorr is ", validationErrors);
        enquiryState.cargoReadinessDate = data.cargoreadinessdate
        enquiryState.destination = data.destination
        enquiryState.origin = data.origin
        enquiryState.mode = data.mode
        enquiryState.sscope = data.scope

        enquiryStore.current.enqRoute.cargoReadinessDate = data.cargoreadinessdate
        enquiryStore.current.enqRoute.destination = data.destination
        enquiryStore.current.enqRoute.origin = data.origin
        enquiryStore.current.shipmentScope = data.scope
        enquiryStore.current.shipmentType = data.mode

        if (isRateSelected.current) {
            let ocountry = 'origin';
            let dcountry = 'destination';
            let portre = /.*\((.....)\)$/;
            let doorre = /.*\[(..,.*)\]$/;
            let otype = enquiryStore.current.shipmentScope.split("-")[0];
            let dtype = enquiryStore.current.shipmentScope.split("-")[2];
            let origin1 = enquiryStore.current.enqRoute.origin;
            let destination1 = enquiryStore.current.enqRoute.destination;

            if (origin1.includes('(') || origin1.includes('[')) {
                if (otype == 'Port') ocountry = origin1.match(portre)[1].slice(0, 2)
                else ocountry = origin1.match(doorre)[1].slice(0, 2)
            }

            if (destination1.includes('(') || destination1.includes('[')) {
                if (dtype == 'Port') dcountry = destination1.match(portre)[1].slice(0, 2)
                else dcountry = destination1.match(doorre)[1].slice(0, 2)
            }

            // if (ocountry == dcountry) {
            //     configStore.setModalMessage('We do not have Domestic Transport Capabilities at present ' + ocountry + '/' + dcountry)
            //     return
            // }

            // if (ocountry != 'IN' && ocountry != 'origin' && dcountry != 'IN' && dcountry != 'destination') {
            //     configStore.setModalMessage('We do not serve these routes at present and will keep you posted when we launch in these markets')
            //     enquiryStore.current.enqRoute.origin = ''
            //     enquiryStore.current.enqRoute.destination = ''
            //     return
            // }

            //     if(enquiryStore.current.shipmentScope.includes("Door")){
            //     let r = /\[.*,([^,]*)\]/
            //     let route = enquiryStore.current.enqRoute
            //     let url;
            //     let locType;
            //     if (route.origin.includes('[IN')) {
            //         locType = 'Pick-up'
            //         let arr = route.origin.match(r)
            //         let pin = arr[1]
            //         url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getvehicles4pin/pickup/${pin}`
            //     } else if (route.destination.includes('[IN')) {
            //         locType = 'Delivery'
            //         let arr = route.destination.match(r)
            //         let pin = arr[1]
            //         url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getvehicles4pin/delivery/${pin}`
            //     }
            //     console.log("the url is ",url);
            //   if(url){  
            //     let ret = MonApi.aget(url).then(data => {
            //         if (locType && (!data || !data.length)) {
            //             configStore.setModalMessage(`Unfortunately, we do not have transport rates for this ${locType} Address. We are still expanding our Geographical presence and will update you once the facility gets activated. Meantime, please feel free to place an Enquiry with us and we will ensure a quotation is provided at the earliest.`, [], false)
            //             if (locType == 'Pick-up') route.origin = ''
            //             else route.destination = ''
            //             return;
            //         } else {
            //             // setVehicles(data)
            //             console.log("the data are ",data);
            //             breadCrumbStore.setBreadCrumbs(["Dashboard","CargoDetails"])
            //         }
            //     })}else{
            //         breadCrumbStore.setBreadCrumbs(["Dashboard","CargoDetails"])
            //     }
            // }else

            {
                breadCrumbStore.setBreadCrumbs(["Dashboard", "Cargo Details"])
            }
        } else {
            breadCrumbStore.setBreadCrumbs(["Dashboard", "View Schedule"])
        }
        }else {
            configStore.setModalMessage('Your Current Selected Entity is Deactivated')
        }
    }

    useEffect(() => {
        console.log("The is door chnage is ", isOriginDoor)
        setValue("origin", "");
    }, [isOriginDoor])

    useEffect(() => {
        setValue("destination", "");
    }, [isDestinationDoor])

    useEffect(() => {
        console.log("the shipment scope ", shipmentScope)
        if (shipmentScope?.length > 0) {
            setValue("origin", "");
            setValue("destination", "")
            clearErrors();
        }
    }, [shipmentScope])

    const [open, setOpen] = useState(false);
    const [visibility, setVisibilityData] = useState({})
    const [selectedType, setType] = useState(0);
    useEffect(()=> {
        enquiryStore.current.shipmentType = 'LCL'
    },[])
    // useEffect(()=> {
    //     reset();
    //     // setType(0)
    //     // setValue("mode", shipmentTYpes[0]);
    //     // enquiryState.mode = shipmentTYpes[0];
    //     // enquiryStore.current.shipmentType = shipmentTYpes[0];
    //     // console.log("shipmentTYpes[0];",shipmentTYpes[0]);
    // }, [])
    useEffect(() => {
        reset();
        setValue("mode", shipmentTYpes[selectedType]);
        enquiryState.mode = shipmentTYpes[selectedType];
        enquiryStore.current.shipmentType = shipmentTYpes[selectedType] || 'LCL';
    }, [selectedType,breadCrumbStore])
    return <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", backgroundColor: "transparent", justifyContent: "space-evenly" }}>
        <WelcomeCard />
        <Box sx={{ height: "34%", backgroundColor: "transparent", width: "100%" }}>
            <form noValidate style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ height: "100%", backgroundColor: "white", borderRadius: "10px", flexDirection: "column", display: "flex", padding: "10px 25px", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "20%", height: "24%", backgroundColor: "#F5F5F5", borderRadius: "20px", padding: "4px", fontSize: "14px", fontFamily: "Figtree" }}>
                        {shipmentTYpes.map((type, i) => <Box onClick={() => {
                            setType(i);
                        }} sx={{
                            borderRadius: "20px", backgroundColor: selectedType === i ? "#2A6ED4" : "transparent", color: selectedType === i ? "white" : "#555555", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontFamily: "Figtree",
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }} >{type.toUpperCase()}</Box>)}
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "50%", alignItems: "center", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", gap: 1, justifyContent: "space-between" }}>
                            {/* <Grid item xs={12} md={2} > */}
                            <Box sx={{ width: "15%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "start" }}>
                                <Typography sx={{ color: "#2A6ED4", fontWeight: "bold", fontSize: "14px", fontFamily: "Figtree" }}>Scope *</Typography>
                                <AgMuiSelect2 fontSize="14px" name="scope" control={control} rules={{ required: "Scope is required" }} width="100%" options={Object.values(SHIPMENT_SCOPE)} />
                            </Box>
                            {/* </Grid> */}
                            {/* <Grid item xs={12} md={3}> */}
                            <Box sx={{ width: "25%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "start" }}>
                                <Typography sx={{ color: "#2A6ED4", fontWeight: "bold", fontSize: "14px", fontFamily: "Figtree" }}>Origin *</Typography>
                                <AgMuiUn clearErrors={clearErrors} trigger={trigger} validateAddress={true} placeholder="Choose Origin" key={shipmentScope + selectedType} rules={{ required: "Origin is Required" }} isDoor={shipmentScope === SHIPMENT_SCOPE.D2D || shipmentScope === SHIPMENT_SCOPE.D2P} control={control} name="origin" />
                            </Box>
                            {/* </Grid> */}
                            {/* <Grid item md={1} alignSelf="center"> */}
                            <Box sx={{ height: "100%", paddingTop: "20px", display: "flex", width: "60px", flexDirection: "row", color: "black", alignItems: "center", justifyContent: 'center' }}>
                                <Box sx={{ backgroundColor: "black", width: "5px", height: "4px", borderRadius: "50%", marginRight: "1px" }}></Box>
                                <svg height="10" width="100%">
                                    <line x1="0" y1="5" x2="100%" y2="5" stroke="black" stroke-width="0.5" stroke-dasharray="2,1" stroke-linecap="round" />
                                </svg><Box sx={{ backgroundColor: "black", width: "5px", height: "4px", borderRadius: "50%", marginLeft: "1px" }}></Box>

                                {/* <Box sx={{ height: "4px", width: "4px", borderRadius: "50%", backgroundColor: "black" }}></Box>
                                    <span style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>...............</span>
                                    <Box sx={{ height: "4px", width: "4px", borderRadius: "50%", backgroundColor: "black" }}></Box> */}
                            </Box>
                            {/* </Grid> */}
                            {/* <Grid item xs={12} md={3}> */}
                            <Box sx={{ width: "25%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "start" }}>
                                <Typography sx={{ color: "#2A6ED4", fontWeight: "bold", fontSize: "14px", fontFamily: "Figtree" }}>Destination *</Typography>
                                <AgMuiUn clearErrors={clearErrors} trigger={trigger} validateAddress={true} placeholder="Choose Destination" key={shipmentScope + selectedType} rules={{ required: "Destination is Required" }} isDoor={shipmentScope === SHIPMENT_SCOPE.D2D || shipmentScope === SHIPMENT_SCOPE.P2D} control={control} name="destination" />
                            </Box>
                            {/* </Grid>  */}
                            {/* <Grid item xs={12} md={2}> */}
                            <Box sx={{ display: "flex", width: "15%", flexDirection: "column", justifyContent: "space-between", alignItems: "start" }}>
                                <Typography sx={{ color: "#2A6ED4", fontWeight: "bold", fontSize: "14px", fontFamily: "Figtree" }}>Cargo Readiness Date *</Typography>
                                {/* <AgDatePicker placeholder="Date"/> */}
                                <LabeledTextField fontSize="14px" inputProps={{ min: new Date().toISOString().split('T')[0] }} rules={{ required: "Cargo Readiness date is Required" }} control={control} name="cargoreadinessdate" type="date" size="small" fullWidth />
                            </Box>
                            {/* </Grid> */}
                        </Box>
                        {/* <Box sx={{display:"flex",flexDirection: "column", width: "20%", backgroundColor:"red",height: "80%", justifyContent: "space-around"}}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "start" }}>
                        <Typography sx={{ color: "#2A6ED4", fontWeight: "bold", fontSize:"14px", fontFamily:"Figtree"  }}>Scope</Typography>
                        <AgMuiSelect width="80%" options={Object.values(SHIPMENT_SCOPE)} /> 
                    </Box>
                    
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", width: "40%", height: "80%", justifyContent: "space-around" }}>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ color: "#2A6ED4", fontWeight: "bold", fontSize:"14px", fontFamily:"Figtree" }}>Origin</Typography>
                       
                    </Box>
                    <AgMuiUn  isDoor={isOriginDoor} control={control} name="origin" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", color: "black", alignItems: "center" }}>
                    <Box sx={{ height: "4px", width: "4px", borderRadius: "50%", backgroundColor: "black" }}></Box>
                    <span style={{ textAlign: "center", display:"flex", justifyContent:"center", alignItems:"center" }}>...............</span>
                    <Box sx={{ height: "4px", width: "4px", borderRadius: "50%", backgroundColor: "black" }}></Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", width: "40%", height: "80%", justifyContent: "space-around" }}>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ color: "#2A6ED4", fontWeight: "bold", fontSize:"14px", fontFamily:"Figtree"  }}>Destination</Typography>
                    </Box>
                    <AgMuiUn isDoor={isDestinationDoor} control={control} name="destination" />
                </Box> */}
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "20%", justifyContent: "end", gap: 4 }}>
                        {/* <Button onClick={()=>{
                            breadCrumbStore.setBreadCrumbs(["Settings"],"Settings")
                        }}>Settings</Button> */}
                        {/* { shipmentScope === SHIPMENT_SCOPE.P2P && shipmentTYpes[selectedType] !== "Air"   && <Button
                       onClick={()=>{
                        isRateSelected.current = false;
                       }}
                       
                       type="submit" size="small" startIcon={<img style={{ width: "90%", height: "90%" }} src={schedule} />} variant="contained" sx={{ textTransform: "capitalize" }}>View Schedules</Button>} */}
                        <Button onClick={() => {
                            // if(isValid){
                            isRateSelected.current = true;
                            // }
                        }}
                            name="get"
                            type="submit" size="small"
                            // startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} 
                            variant="contained" sx={{ textTransform: "capitalize" }}>Enquiry</Button>
                    </Box>
                </Box>
            </form>
        </Box>
        <Box sx={{ height: "56%",gap:1 ,backgroundColor: "transparent", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
            <MyBookings dialogHandler={(visibility) => {
                setOpen(true);
                setVisibilityData(visibility);
            }} />
            <MyActions />
            <Finance/>
            {/* <HelpCard selectedQuestion={selectedQuestion} /> */}
            <Dialog maxWidth="lg" open={open} fullWidth sx={{
            "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#2A6ED4 !important"
                }
            }} >

                <DialogContent sx={{ width: "100%" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box onClick={() => {
                            setOpen(false);
                        }} sx={{
                            display: "flex", alignSelf: "end", justifyContent: "center", alignItems: "center", backgroundColor: "#2B6ED4", borderRadius: "5px", width: "max-content", padding: "8px", "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "18px", height: "18px" }} src={closeIcon} />
                        </Box>
                        <VisibilityGraph graphNodes={visibility.graphNode} dsrRec={visibility.booking} />
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    </Box>
}


export function Dashboard() {

    const { breadCrumbs, currentComponent } = useSnapshot(breadCrumbStore)
    const { selectedTransportMode, isProfileSelected } = useSnapshot(newDashboardState);
    const selectedQuestion = useRef(-1);
       useSnapshot(enquiryStore)

    const screensList = [
        { title: "Bookings", component: <CustomerMyactions /> },
        { title: "View Schedule", component: <ViewSchedule /> },
        { title: "Cargo Details", component: <CargoDetailsPage /> },
        { title: "Choose Schedule", component: <ViewSchedule chooseSchedule={true} /> },
        { title: "Pricing Details", component: <PricingDetailsPage /> },
        { title: "My Actions", component: <TaskList /> },
        { title: "Booking Details", component: <ShippingDetails /> },
        { title: "Finance", component: <ChartNewHome /> },
        { title : "Settings", component : <SettingsPage/> },
        {title : "Help Center", component:<HelpCenter selectedQuestion={selectedQuestion.current}/>}
    ]

    useEffect(() => {
        newDashboardState.isProfileSelected = false;
    }, [])

  

    const domesticSCreensList = [
        { title: "Bookings", component: <DtBookingMain /> },
        { title: "Contracts", component: <DtContractMain /> },
    ]

    const getCurrentScreen = (screenname) => {
        let currentScreen;
        if (selectedTransportMode === 0) {
            currentScreen = screensList.find((screen) => screen.title === screenname);
        } else if (selectedTransportMode === 1) {
            currentScreen = domesticSCreensList.find((screen) => screen.title === screenname);
        }
        return currentScreen;
    }

    if (breadCrumbs.length === 0) {
        return selectedTransportMode === 0 ? <DashboardComponent selectedQuestion={selectedQuestion} /> : selectedTransportMode === 1 ? <DomesticTransportDashBoard /> : <Box sx={{ height: "100%", width: "100%", overflowY: "unset" }}><TrackMyBooking /></Box>
    } else {
        return <Box sx={{ height: "100%", width: "100%", paddingRight: "8px", overflowY: "unset", display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ height: "6%", width: "100%", display: "flex", flexDirection: "row" }}>
                <IconButton disableRipple onClick={() => {
                    breadCrumbStore.back();
                }}>
                    <img src={backarrow} />
                </IconButton>
                {breadCrumbs.map((breadCrumb, i) => <Button onClick={() => {
                    breadCrumbStore.setCurrentCrumb(i);
                }} disableRipple sx={{ fontFamily: "Figtree", textTransform: "capitalize", color: i === breadCrumbs.length - 1 ? "#2A6ED4" : "black", paddingLeft: 0, "&:hover": { backgroundColor: "transparent" }, fontWeight: "bold" }}>{breadCrumb}  {i === breadCrumbs.length - 1 ? "" : "/"}</Button>)}</Box>
            <Box sx={{ height: "92%" }}>
                {getCurrentScreen(currentComponent).component}
            </Box>
        </Box>
    }
}


function CenteredCircularProgress() {
    return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}><CircularProgress /></Box>
}

function HelpCard({selectedQuestion}) {
 
    

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredHelpList = helpList.filter((item) =>
        item.question.toLowerCase().includes(searchQuery.toLowerCase())
    );



    return <Box sx={{ display: "flex", flexDirection: "column", width: "22%", height: "100%", backgroundColor: "white", borderRadius: "10px", padding: "8px", justifyContent: "space-between" }}>
        <Box sx={{ height: "10%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold", color: "black" }}>Help Center</Typography>
            <Button onClick={() => {
                breadCrumbStore.setBreadCrumbs(["Help Center"],"Help Center")
                selectedQuestion.current = -1;
            }} disableRipple variant="text" sx={{
                textTransform: "capitalize", textDecoration: "underline", color: "black", "&:hover": {
                    textDecoration: "underline", backgroundColor: "transparent"
                }
            }}>View All</Button>
        </Box>
        <Box sx={{
            height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", backgroundColor: "white", overflowY: "auto", borderRadius: "4px", gap: 0.5, paddingRight: "5px", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2A6ED4 !important"
            }
        }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CiSearch style={{ fontSize: 20 }} />
                            </InputAdornment>
                        ),
                        style: { borderRadius: '20px', height: '35px',margin:"10px 0px" }, // Inline style for border-radius
                    }}
                    placeholder="Search"
                />

<Box sx={{overflowY:"auto",height:"100%", gap:1, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" , paddingRight:"6px", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2A6ED4 !important"
            }}}>
                {filteredHelpList.length > 0 ? (
                    filteredHelpList.map((help, i) => <GetHelp onClick={()=>{
                        selectedQuestion.current = help.id;
                        breadCrumbStore.setBreadCrumbs(["Help Center"],"Help Center")
                    }} key={help.id} help={help} />)
                ) : (
                    <Nodata />
                )}
            </Box>
        </Box>
    </Box>
}

function Finance() {
    const [apiState, setApiState] = useState({ data: null, isLoading: true })
    const configurationStore = useSnapshot(configStore)
    useEffect(() => {
        try {
            setApiState(() => ({ data: null, isLoading: true }));
            ChartApi.getFinanceData((data) => {
                setApiState(() => ({ data: data, isLoading: false }))
            })
        } catch (error) {

        }
    }, [configurationStore.currentSelectedEntity , configurationStore.currentSelectedBranch])



    return <Box sx={{ display: "flex", flexDirection: "column", width: "22%", height: "100%", backgroundColor: "white", borderRadius: "10px", padding: "8px", justifyContent: "space-between" }}>
        <Box sx={{ height: "10%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold", color: "black" }}>Finance</Typography>
            {/* <Button onClick={()=>{
                   
                }} disableRipple variant="text" sx={{
                    textTransform: "capitalize", textDecoration: "underline", color: "black", "&:hover": {
                        textDecoration: "underline", backgroundColor: "transparent"
                    }
                }}>View All</Button> */}
        </Box>
        <Box sx={{ height: "90%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", backgroundColor: "white", overflowY: "auto", borderRadius: "10px", gap: 1 }}>
            {
                apiState.isLoading ? <CenteredCircularProgress />
                    :
                    <>
                        <Box sx={{ height: "46%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography sx={{ fontFamily: "Figtree", color: "black", fontWeight: "bold", fontSize: "14px" }}>Total Overdue</Typography>
                            <Typography sx={{ fontFamily: "Figtree", fontSize: "30px", color: "#555555", fontWeight: "bold" }}>
                                ₹ {apiState?.data["overdueAmount"]
                                    ? parseFloat(apiState?.data["overdueAmount"]).toLocaleString('en-IN', {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })
                                    : "0.0"}
                            </Typography>
                            {/* <Button variant="contained" size="small" startIcon={<img src={debitcard} />}>Pay now</Button> */}
                        </Box>
                        <Pending pendings={apiState.data?.pendinglist} />
                    </>
            }
        </Box>
    </Box>
}

function convertDateFormat(inputDateStr) {
    // Convert input date to Date object
    var inputDate = new Date(inputDateStr);

    // Format the date as "DD/MM/YYYY"
    var day = inputDate.getDate();
    var month = inputDate.getMonth() + 1; // Months are zero-based in JavaScript
    var year = inputDate.getFullYear();

    // Add leading zeros if needed
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    // Create the output date string
    var outputDateStr = day + "/" + month + "/" + year;

    return outputDateStr;
}

function Pending({ pendings }) {
    return <Box sx={{ height: "54%", width: "100%", display: "flex", flexDirection: "column", borderRadius: "10px", border: "1px solid #E8E8E8", alignItems: "start", padding: "4px 6px" }}>
        <Typography sx={{ textAlign: "start", color: "black", fontWeight: "bold", fontFamily: "Figtree", fontSize: "14px" }}>Pending Reconciliation</Typography>
        <Box sx={{
            height: "100%", width: "100%", display: "flex", flexDirection: "column", overflowY: "auto", paddingRight: "4px", justifyContent: "center", alignItems: "center", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2A6ED4 !important"
            }
        }}>
            {pendings && pendings.length > 0 ?
                pendings.map((pending) => <PendingTile amount={Math.abs(parseFloat(pending["Amount (INR)"])?.toFixed(2) || 0.0)} date={convertDateFormat(pending["Date"])} />)
                : <Typography sx={{ color: "grey" }}>Yay...All payments are reconciled!</Typography>}
        </Box>
    </Box>
}

function PendingTile({ amount, date }) {
    return <Box sx={{ width: "100%", height: "40px", backgroundColor: "#F5F5F5", borderRadius: "8px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingY: "8px", paddingX: "8px", marginBottom: "5px" }}>
        <Box sx={{ height: "100%", width: "30%", display: "flex", justifyContent: "start", flexDirection: "column", alignItems: "start" }}>
            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", color: "black", fontSize: "10px" }}>Received on</Typography>
            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", color: "black", fontSize: "10px" }}>{date}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", color: "#555555", fontSize: "90%" }}>₹ {amount}</Typography>
        </Box>
        {/* <IconButton>
            <img style={{ width: "90%", height: "90%" }} src={arrowtilted} />
        </IconButton> */}
    </Box>
}

function MyBookings({ dialogHandler }) {
    const config = useSnapshot(configStore);
    const [apiState, setApiState] = useState({ data: null, isLoading: true });
    const elist = useRef([]);
    const [graphList, setGraphList] = useState([]);

    function setlist() {
        //setData1({results : myactionsstore.list, count:myactionsstore.count})
        for (let i = 0; i < apiState?.data?.results1?.length; i++) {
            if (apiState?.data?.results1[i]['booking']['containershow'] == undefined) {
                apiState.data.results1[i]['booking']['containershow'] = -1
            }
        }
        let eli = []
        let graphList1 = []
        for (let i = 0; i < apiState?.data?.results1?.length; i++) {
            let rec = apiState?.data?.results1[i]
            let oneGraph = []
            console.log("the graph data is ..... ", rec);
            oneGraph = Visibility.GraphLine(rec)
            // if (rec.shipmentType !== 'Air') oneGraph = Visibility.oceanGraphLine(rec)
            // else oneGraph = Visibility.airGraphLine(rec)
            let activeOn = false
            let lastactive = -1
            for (let i = oneGraph.length - 1; i >= 0; i--) {
                let node = oneGraph[i]
                if (rec.status_closed == 'Y' || rec.status_closed == 'Yes' || rec.booking.status == 'ARCHIVED') {
                    node.isActive = true
                }
                if (node.isActive && !activeOn) {
                    node.activelines = 1
                    lastactive = i
                }
                if (node.isActive) activeOn = true
                if (activeOn && !node.isActive) node.isActive = true
                if (node.isActive && i < lastactive) node.activelines = 2
            }
            graphList1.push(oneGraph)
            eli.push(rec)

        }
        setGraphList(graphList1);
        elist.current = eli;
    }



    useEffect(() => {
        setlist();
    }, [apiState.data])

    // useEffect(()=>{
    //     setApiState({ isLoading: false, data: {results1 : graphList} })
    // },[graphList])

    useEffect(() => {
        try {
            setApiState(() => ({ data: null, isLoading: true }));
            ApiAgTable.getAllDsr4Display(0, 10, "", "", { filter: "ALL", filter1: "ALL" }).then(async (data) => {
                let lres = await data.json();
                setApiState({ isLoading: false, data: lres })
            })
        } catch (error) {
            setApiState(() => ({ data: null, isLoading: true }));
        }

    }, [config.currentSelectedEntity , config.currentSelectedBranch])

    return <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", width: "42%", height: "100%", backgroundColor: "white", borderRadius: "10px", padding: "10px" }}>
        <Box sx={{ height: "10%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold", color: "black" }}>Track Bookings ({apiState.data?.results1?.length || 0})</Typography>
            <Button disableRipple variant="text" onClick={() => {
                breadCrumbStore.setBreadCrumbs(["Dashboard", "Bookings"]);
                bookingsStore.currentBookingsListComponent = "list"
            }} sx={{
                textTransform: "capitalize", textDecoration: "underline", color: "black", "&:hover": {
                    textDecoration: "underline", backgroundColor: "transparent"
                }
            }}>View All</Button>
        </Box>
        <Box sx={{
            height: "80%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", backgroundColor: "white", overflowY: "auto", border: "1px solid #E8E8E8", borderRadius: "10px", gap: 0.5, padding: "8px", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2A6ED4 !important"
            }
        }}>
            {apiState.isLoading ? <CenteredCircularProgress /> : apiState.data?.results1 && apiState.data?.results1.length > 0 ? apiState.data?.results1.map((booking, i) => <Booking dialogHandler={dialogHandler} graphNode={graphList[i]} booking={booking} />) : <Nodata />}
        </Box>
        <Legends />
    </Box>
}

function ActionFilters({ filters, isOpen, handleClose, anchorEl }) {
    return <Menu
        sx={{ height: "60%" }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
        {filters.map((filter, i) => <MenuItem sx={{
            textAlign: "start",
            wordBreak: "break-word",
            whiteSpace: "normal",
            maxWidth: "300px", margin: "8px", borderRadius: "4px",
            color: "#555555",
            "&:hover": {
                backgroundColor: "#2A6ED4",
                color: "white"
            }
        }} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (filter.onClickHandler) {
                filter.onClickHandler(i);
            }
            handleClose(e);
        }} >{filter.title}</MenuItem>)}
    </Menu>
}





function formatDate2(input) {
    let date;
    let formattedDate;

    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    if (typeof input === 'string') {
        if (!input.includes("-")) {
            const epoch = parseInt(input, 10);
            date = new Date(epoch);
            const day = date.getDate();
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();
            formattedDate = `${day}, ${month} ${year}`;
        } else {
            const val = input.split("-");
            const day = val[2];
            const month = monthNames[parseInt(val[1]) - 1];
            const year = val[0];
            console.log("the val e ", val);
            formattedDate = `${day}, ${month} ${year}`;
        }
    } else {
        // If input is not a string, return an error message
        return 'Invalid input';
    }

    // Get day, month, and year


    return formattedDate;
}



function ContractDtLegend(legend) {
    const color = {
        active: {
            backgroundColor: "#2C8A5733",
            color: "#2C8A57"
        },
        expired: {
            backgroundColor: "#55555533",
            color: "#555555"
        }
    }
    return color[legend.toLowerCase()];
}

//  function checkBranchGst(data){
//     let isGstAvail = false;
//     console.log(data,'oooo');
//     data.map((r,i)=>{
//     if( r.status === "VERIFIED" && r.gst.length > 0 && r.branchAddresses[0].address.length > 0) {
//       isGstAvail= true
//       return isGstAvail
//         }
//     })
//     return isGstAvail  
//    }


   function checkEntityStatus(status){
    let isStatusAvail = false; 
    if(status === "VERIFIED") {
      isStatusAvail= true
      return isStatusAvail
    }  
    return isStatusAvail  
   }

function AddressComponent({ isLocation = false, address, showButton = true, isBooking = true, isLast = false, handler,entityId }) {
    // const [branches, setbranches] = useState([])
    return <Box sx={{ display: "flex", flexDirection: 'row', gap: 1, height: "50px", }}>
        <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
            {/* <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"#D4D8D8",width:"10px",height:"20px",borderRadius:"50%"}}> */}
            <img style={{ width: "60%", height: "60%" }} src={isLocation ? arrival2 : pickup2} />
            {/* </Box> */}
            <Box sx={{ display: "flex" }}>
                <VerticalDashedLine dashArray="2,3" height={isLast ? "0px" : "40px"} />
            </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
            {address?.map((location) => <DomesticBoxCompoment heading={location.heading} value={location.value} />)}
        </Box>
        {isLast && <Box sx={{ alignSelf: "center" }}>
            {isBooking ? showButton ? <Button onClick={() => {
                if (handler) {
                    handler();
                }
            }} sx={{ textTransform: 'capitalize' }} size="small" variant="contained">Track</Button> : <></> : showButton ? <Button onClick={() => {
            if(entityId){
                if (handler) {
                    EntityApi.getEntity(entityId,entityData=>{
                     let entitystatusResult =  checkEntityStatus(entityData.status)
                    if(entitystatusResult){
                        handler();
                    }else{
                        configStore.setModalMessage(`Please submit entity details for verification to proceed with the booking
                        Kindly contact the Reporting manager or Customer Success to update and proceed Booking .
                            Contact ID :
           
                            Reporting Manager - ${entityData?.customer?.domesticTransport?.relationshipManager}
                            Customer Success - ${entityData?.customer?.domesticTransport?.customerService}
                            Customer ID ${entityId}.`)
                    }
                })        
                }
            }else{
                if(handler){
                    handler();   
                }
            }
            }} sx={{ textTransform: 'capitalize' }} size="small" variant="contained">Book</Button> : <></>}
        </Box>}
    </Box>
}



function DomesticBooking({ booking }) {
    let bookingColor = TrackBookingsLegendColor(booking?.status);
    if(!bookingColor){
        bookingColor = {
            backgroundColor: "#55555533",
            color: "#555555"
        }
    }
    return <Box sx={{ displa: 'flex', flexDirection: "column", backgroundColor: "#F5F5F5", borderRadius: "6px", padding: "8px 12px", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center" }}>
            {/* <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"13px",color:"black"}}>E-way bill expires on {booking.ebill}</Typography> */}
            <span style={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", fontSize: "12px", color: "black", paddingRight: "4px" }}>Status :</Typography>
                <Box sx={{ width: "max-content", padding: "2px 8px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: "10px", fontFamily: "Figtree", fontSize: "60%", backgroundColor: bookingColor.backgroundColor , color: bookingColor.color, height: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {booking?.status}
                </Box>
            </span>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "10px" }}>
            {booking?.address?.map((location, i) => <AddressComponent handler={() => {
                console.log("the link is ", booking.publicLink);
                DtStore.MapUrl = booking.publicLink
                DtStore.modalVisible2 = true
            }} showButton={booking?.publicLink?.length > 0} address={location.locations} isLast={i === booking?.address.length - 1} isLocation={location.isArrival} />)}
        </Box>
    </Box>
}


function DomesticBoxCompoment({ heading, value }) {
    return <Box sx={{ display: "flex", flexDirection: "column", gap: 0.3, width: "100px" }}>
        <Typography sx={{ fontSize: "11px", fontFamily: 'Figtree', textTransform: "uppercase", color: "#555555" }}>{heading}</Typography>
        <Typography sx={{
            fontSize: "11px", fontFamily: 'Figtree', fontWeight: "bold", color: "black", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            lineClamp: 2
        }}>{value}</Typography>
    </Box>
}


function Contract({ contract, handler,entityId }) {
    const contractLegendColor = ContractDtLegend(contract?.status)
    return <Box sx={{ displa: 'flex', flexDirection: "column", backgroundColor: "#F5F5F5", borderRadius: "6px", padding: "8px 12px", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center" }}>
            <span style={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", fontSize: "12px", color: "black" }}>Status :</Typography>
                <Box sx={{ width: "max-content", padding: "2px 8px", display: "flex", textTransform: "capitalize", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: "10px", fontFamily: "Figtree", fontSize: "60%", backgroundColor: contractLegendColor.backgroundColor, color: contractLegendColor.color, height: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {contract?.status}
                </Box>
            </span>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "10px" }}>
            {contract?.address?.map((location, i) => <AddressComponent handler={() => {
                if (handler) {
                    handler();
                }
            }}
                showButton={contract?.status.toLowerCase() === "active"}
                isBooking={false} address={location.locations} isLast={i === contract?.address.length - 1} isLocation={location.isArrival} entityId = {entityId}/>)}
        </Box>
    </Box>
}

function ContractsDt() {
    const config = useSnapshot(configStore);
    const [apiState, setApiState] = useState({ data: null, isLoading: true })
    useEffect(() => {
        try {
            setApiState(() => ({ data: null, isLoading: true }));
            ApiAgTable.dtContractData(0, 10, "", "", { status: "ALL", date: Utils.getCurrentDateString() }).then(res => {
                res.clone().json().then((res) => {
                    setApiState({ data: res.results, isLoading: false })
                }, err => {
                    console.log(err)
                })
            })
        } catch (error) {
            setApiState(() => ({ data: null, isLoading: false }));
        }

    }, [config.currentSelectedEntity, config.currentSelectedBranch])

    return <Box sx={{
        display: "flex", flexDirection: "column", height: '90%', overflowY: "auto", gap: 1, paddingRight: "6px", "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2A6ED4 !important"
        }
    }}>
        {apiState.isLoading ? <CenteredCircularProgress /> : apiState?.data?.length === 0 ? <Nodata /> : apiState.data?.map((contracts) => {
            let contract = contracts?.DtContracts
            let entityId = contract?.entityId
            let currentContractData = {};
            currentContractData.status = contract.status;


            currentContractData.address = [];

            currentContractData.address[0] = {
                isArrival: false, locations: [
                    { heading: "Origin", value: Utils.getCountry(contract?.pickup) }, { heading: "Created Date", value: formatDate2(contract?.tsCreatedOn) }, { heading: "Vehicle Type", value: contract?.vehicle[0].vehicle_type }
                ]
            };

            contract?.delivery.forEach((delAddress, i) => {

                let location = {
                    isArrival: true, locations: [
                        { heading: contract?.delivery.length > 1 ? `Destination ${i + 1}` : "Destination", value: Utils.getCountry(delAddress?.address) }
                    ]
                };
                if (i == 0) {
                    location.locations.push({ heading: "Effective date", value: formatDate2(contract?.effective_date) })
                    location.locations.push({ heading: "Valid upto", value: formatDate2(contract?.valid_upto) })
                }
                currentContractData.address[i + 1] = location;
            });

            return <Contract handler={() => {
                DtStore.DTBooking = DtStore.createbooking(contracts.DtContracts)
                DtStore.bview = "create"
                DtStore.valid = false
                DtStore.isViewParticular = true
                configStore.dtCurrentView = 'Booking'
                breadCrumbStore.setBreadCrumbs(["Dashboard", "Contracts"]);
            }} contract={currentContractData} entityId={entityId} />
        })}
    </Box>
}


function TrackBookingsLegendColor(legend) {
    const color = {
        "yet to commence": {
            backgroundColor: "#715BFD33",
            color: "#715BFD"
        },
        "completed": {
            backgroundColor: "#5CB78433",
            color: "#5CB784"
        },
        "in-transit": {
            backgroundColor: "#FF764C33",
            color: "#FF764C"
        },
        "report at pickup" : {
            backgroundColor: "#B8717133",
            color: "#B87171"
        },
        "commenced": {
            backgroundColor: "#AB936433",
            color: "#AB9364"
        }
    }
    return color[legend.toLowerCase()]
}

function TrackBookingsDT() {
    const config = useSnapshot(configStore);
    const [apiState, setApiState] = useState({ data: null, isLoading: true })
    useEffect(() => {
        try {
            setApiState(() => ({ data: null, isLoading: true }));
            ApiAgTable.dtBookingData(0, 10, "", "", { status: "INPROGRESS", date: Date() }).then(res => {
                res.clone().json().then((res) => {
                    console.log("the response is ", res)
                    setApiState({ data: res.results, isLoading: false })
                }, err => {
                    console.log(err)
                })
            })
        } catch (error) {
            setApiState(() => ({ data: null, isLoading: false }));
        }

    }, [config.currentSelectedEntity , config.currentSelectedBranch])

    return <Box sx={{
        display: "flex", flexDirection: "column", height: '90%', overflowY: "auto", gap: 1, paddingRight: "6px", "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2A6ED4 !important"
        }
    }}>
        {apiState.isLoading ? <CenteredCircularProgress /> : apiState?.data?.length === 0 ? <Nodata /> : apiState.data?.map((bookings) => {
            let booking = bookings?.DtBookings
            let currentBookingData = {};
            currentBookingData.status = booking.status;
            currentBookingData.ebill = ""
            currentBookingData.publicLink = booking?.publiclink
           
            currentBookingData.address = [];
            console.log("the boooking ")
            let detail = booking?.details[0];
            currentBookingData.address[0] = {
                isArrival: false, locations: [
                    { heading: "Pick up", value: Utils.getCountry(detail?.pickup) }, { heading: "Pick up date", value: formatDate2(booking?.pickupdate) }, { heading: "Vehicle no.", value: booking?.vehicle_no }
                ]
            };

            detail?.delivery.forEach((delAddress, i) => {

                let location = {
                    isArrival: true, locations: [
                        { heading: "Arrival", value: Utils.getCountry(delAddress?.address) }
                        ,
                        // {heading : "ETA",value : "" }
                    ]
                };
                if (i == 0) {
                    location.locations.push({ heading: "Vehicle Type", value: booking?.vehicle })
                }
                currentBookingData.address[i + 1] = location;
            });
            console.log("the currenet bookimg data ", currentBookingData);
            return <>
                <DomesticBooking booking={currentBookingData} /></>
        })}
    </Box>
}

function DomesticTransportDashBoard() {
    return <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", backgroundColor: "transparent", justifyContent: "space-evenly" }}>
        <WelcomeCard />
        <Box sx={{ height: "92%", backgroundColor: "transparent", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "12px", flex: 1, padding: "0px 10px" }}>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ fontWeight: "bold", color: "black" }}>Contracts</Typography>

                    <Button disableRipple variant="text" onClick={() => {
                        breadCrumbStore.setBreadCrumbs(["Dashboard", "Contracts"]);
                    }} sx={{
                        textTransform: "capitalize", textDecoration: "underline", color: "black", "&:hover": {
                            textDecoration: "underline", backgroundColor: "transparent"
                        }
                    }}>View All</Button>
                </Box>
                <ContractsDt />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "12px", flex: 1, padding: "0px 10px", gap: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ fontWeight: "bold", color: "black" }}>Track Bookings</Typography>

                    <Button disableRipple variant="text" onClick={() => {
                        breadCrumbStore.setBreadCrumbs(["Dashboard", "Bookings"]);
                    }} sx={{
                        textTransform: "capitalize", textDecoration: "underline", color: "black", "&:hover": {
                            textDecoration: "underline", backgroundColor: "transparent"
                        }
                    }}>View All</Button>
                </Box>
                <DtMap />
                <TrackBookingsDT />
            </Box>

        </Box>
    </Box>
}

function MyActions() {
    const config = useSnapshot(configStore);
    const [apiState, setApiState] = useState({ data: null, isLoading: true })
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const isOpen2 = Boolean(anchorEl2);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (e) => {
        e?.stopPropagation();
        setAnchorEl(null);
    };
    useEffect(() => {
        try {
            setApiState(() => ({ data: null, isLoading: true }));
            ApiAgTable.myactionsData(0, 10, "", "", {
                actionType
                    :
                    "CUSTOMER",
                displayStatus
                    :
                    "PENDING"
            }).then(async (data) => {
                setApiState({ isLoading: false, data: await data.json() })
            })
        } catch (error) {
            setApiState(() => ({ data: null, isLoading: true }));
        }
    }, [config.currentSelectedEntity, config.currentSelectedBranch])
    return <Box sx={{ display: "flex", flexDirection: "column", width: "34%", height: "100%", backgroundColor: "white", borderRadius: "10px", padding: "10px" }}>
        <Box sx={{ height: "10%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold", color: "black" }}>My Actions</Typography>
            {/* <Box sx={{display:"flex",flexDirection:"row",gap:2}}>
                <Box sx={{"&:hover":{
                    cursor : "pointer"
                }}} onClick={
                      handleClick
                }><>
                    <img src={filter}/>
                    <ActionFilters isOpen={isOpen} handleClose={handleClose} anchorEl={anchorEl} filters={[{title:"Due Date"},{title:"Bookings"}]} />
                    </>
                    </Box>
                <Box sx={{"&:hover":{
                    cursor : "pointer"
                }}} onClick={
                      (e)=>{
                        setAnchorEl2(e.currentTarget)
                      }
                }><>
                    <img src={filter1}/>
                    <ActionFilters isOpen={isOpen2} handleClose={(e)=>{
                        e?.stopPropagation();
                        setAnchorEl2(null);
                    }} anchorEl={anchorEl2} filters={[{title:"All",onClickHandler:()=>{

                    }},{title:"Documents"},{title:"Scheduling"},{title:"Money"},{title:"Approved"}]} />
                    </></Box>
             </Box> */}
            <Button disableRipple variant="text" onClick={() => {
                breadCrumbStore.setBreadCrumbs(["Dashboard", "My Actions"]);
                taskStore.isViewAll = true;
            }} sx={{
                textTransform: "capitalize", textDecoration: "underline", color: "black", "&:hover": {
                    textDecoration: "underline", backgroundColor: "transparent"
                }
            }}>View All</Button>
        </Box>
        <Box sx={{
            height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", backgroundColor: "white", overflowY: "auto", borderRadius: "4px", gap: 0.5, paddingRight: "5px", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2A6ED4 !important"
            }
        }}>
            {apiState.isLoading ? <CenteredCircularProgress /> : apiState.data?.results && apiState.data?.results.length > 0 ? apiState.data.results.map((action) => <Action action={action} />) : <Nodata />}
        </Box>
    </Box>
}

function Legends() {
    return <Box sx={{ width: "100%", height: "10%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
        <Legend color="#F25B5B" text="Delayed" />
        <Legend color="#555555" text="On Time" />
        <Legend color="#71A282" text="Advanced" />
    </Box>
}



function Legend({ color, text }) {
    return <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: 1, justifyContent: "center" }}>
        <Box sx={{ width: "13px", height: "13px", borderRadius: "50%", backgroundColor: color }}></Box>
        <Typography sx={{ paddingX: "5px" }}>-</Typography>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "14px" }}>{text}</Typography>
    </Box>
}



function Booking({ booking, graphNode, dialogHandler }) {
    const date = formatDate(booking.booking.bookingDate);
    let { contract } = booking.booking;
    let status = booking.status_closed == 'Y' || booking.status_closed == 'Yes' || booking.booking.status == 'ARCHIVED' ? "ARCHIVED" : "in-transit"
    return <>
        <Box sx={{ width: "100%", paddingBottom: "5px" }}>
            <Box sx={{ paddingX: "4px", borderTopLeftRadius: "4px", borderTopRightRadius: "4px", backgroundColor: "#F5F5F5", width: "max-content", fontSize: "12px", fontFamily: "Figtree", color: "black", fontWeight: "bold" }}>#{booking._id}</Box>
            <Box sx={{ height: "50px", borderBottomLeftRadius: "4px", borderTopRightRadius: "4px", borderBottomRightRadius: "4px", width: "100%", backgroundColor: "#F5F5F5" }}>
                <Box sx={{ height: "100%", width: "100%", display: 'flex', flexDirection: "row", padding: "8px", justifyContent: "space-between" }}>
                    <Box sx={{ width: "20%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", color: getColorForDate(getStatusOfBooking(status)), alignItems: "center" }}>
                        <p style={{ fontFamily: "Figtree", fontSize: "14px", padding: 0, fontWeight: "bold", textTransform: "capitalize" }}>{date[0]}, {date[1]}</p>
                        <p style={{ fontFamily: "Figtree", fontSize: "14px", padding: 0, fontWeight: "bold", textTransform: "capitalize" }}>{date[2]}</p>
                    </Box>
                    <Box sx={{ width: "2px", height: "100%", backgroundColor: "#EAEAEA" }}></Box>
                    <Box sx={{ minWidth: "64%", maxWidth: "64%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", paddingX:"20px",gap:0.4 }}>
                        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "50%", justifyContent: "space-between" }} >
                            <Box sx={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center", gap: 1 }}>
                                <ShipmentScopeTag scope={contract?.shipmentType?.toUpperCase()} />
                            </Box>
                            <Box sx={{ width: "max-content", padding: "8px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: "10px", fontFamily: "Figtree", fontSize: "80%", backgroundColor: getColorForTag(getStatusOfBooking(status)), color: getColorForTagText(getStatusOfBooking(status)), height: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {getText(getStatusOfBooking(status))}
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", width: "100%" }} >
                            <TransportRange from={contract.origin} to={contract.destination} />
                        </Box>
                    </Box>
                    <Button onClick={() => {
                        dialogHandler({ graphNode, booking })
                    }} sx={{ fontSize: "16px", textTransform: "capitalize" }}
                        startIcon={<img style={{ width: "100%", height: "100%" }} src={trackicon} />}
                        variant="contained" size="small">Track</Button>
                </Box>
            </Box>

        </Box>

    </>
}


function calculateTimeDifference(dateStr, timeStr) {
    // Convert input strings to a Date object
    timeStr = timeStr.padStart(5, '0');
    const targetDateTime = new Date(`${dateStr}T${timeStr}`);
    const currentDateTime = new Date();

    console.log("the current date time ", currentDateTime);
    // Calculate the time difference in milliseconds
    const timeDiffMillis = targetDateTime - currentDateTime;

    console.log("the targert time is ", targetDateTime);

    console.log("the tiem difference is ", timeDiffMillis);

    // Calculate days, hours, and minutes
    const days = Math.round(timeDiffMillis / (24 * 60 * 60 * 1000));
    const hours = Math.round((timeDiffMillis % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.round((timeDiffMillis % (60 * 60 * 1000)) / (60 * 1000));

     console.log("the date ", dateStr, " the time ", timeStr, " the days ", " : ", days, " the hours ", " : ", hours, " the minutes ", " : ", minutes);

    if (days != 0) {
        const trend = decideDueTrend(days, 3);
        return { unit: "days", ...trend };
    } else if (hours != 0) {
        const trend = decideDueTrend(hours, 3);
        return { unit: "hours", ...trend };
    } else {
        const trend = decideDueTrend(minutes, 30);
        return { unit: "minutes", ...trend };
    }
}


function decideDueTrend(value, limit) {
    if (value < 0) {
        return { value: Math.abs(value), color: "#F25B5B", tag: "Overdue for" }
    } else {
        if (value < limit) {
            return { value: Math.abs(value), color: "#EBB02D", tag: "Due in" }
        }
        return { value: Math.abs(value), color: "#555555", tag: "Due in" }
    }
}



function formatDate(dateString) {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const dayIndex = date.getDay();
    const day = date.getDate();

    return [months[monthIndex], day, days[dayIndex]];
}

function TransportRange({ from, to }) {
    return <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between",alignItems:"center" }}>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", fontWeight: "bold", width: "40%", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "2px", color: "black", whiteSpace: "nowrap" }}>{from}</Typography>
        <HorizontalDashedLine dashArray="3,2" width="50px"/>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", fontWeight: "bold", width: "40%", overflow: "hidden", textOverflow: "ellipsis", paddingLeft: '2px', textAlign: "end", color: "black", whiteSpace: "nowrap" }}>{to}</Typography>
    </Box>
}

function Action({ action }) {
    let dueDate;
    if (action?.dueDate?.length > 0) {
        if (action?.dueTime?.length > 0) {
            dueDate = calculateTimeDifference(action.dueDate, action.dueTime);
        } else {
            dueDate = calculateTimeDifference(action.dueDate, "00:00");
        }
    }

    return <Box sx={{ width: "100%", paddingBottom: "8px" }}>
        <Box onClick={() => {
            BookingsApi.getBooking(action.bookingNum, (data) => {
                contractsStore.current = data.contract
                // enquiryStore.initializeWithConract(data.contract)

                taskStore.current = action
                taskStore.currentContainer = ''
                taskStore.current.lastview = "CUSTOMER"
                if (taskStore.current.isValid == undefined) taskStore.current.isValid = true
                if (taskStore.current.isValidfield == undefined) taskStore.current.isValidfield = []
                taskStore.myActionVisible = true
                breadCrumbStore.setBreadCrumbs(["Dashboard", "My Actions"])
            })
        }} sx={{
            borderRadius: "4px", width: "100%", backgroundColor: "#F5F5F5", padding: "4px 6px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", "&:hover": {
                cursor: "pointer"
            }
        }}>
            <Box sx={{ width: "65%", display: "flex", flexDirection: "column", height: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "80%", height: "50%", justifyContent: "space-between" }} >
                    <TransportRange from={action.origin} to={action.destination} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "50%", alignItems: "center", justifyContent: "space-between", gap: 0.5, paddingTop: "2px" }} >
                    <Box sx={{ height: "70%" }}>  <ShipmentScopeTag scope={action.shipmentType} /></Box>
                    <Box sx={{ width: "0.5%", backgroundColor: "#E8E8E8", borderRadius: "2px", height: "18px" }}></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, justifyContent: "start", alignItems: "center", width: "75%" }}>
                        {/* <img style={{ width: "10%", height: "60%" }} src={vgmicon} /> */}
                        <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", color: "black", overflow: "hidden", whiteSpace: "nowrap" }} >{action.actionName}</Typography>
                    </Box>
                </Box>
            </Box>
            <div style={{ width: "0.5%", backgroundColor: "#EAEAEA", height: "40px" }}></div>
            <Box sx={{ width: "29%", display: "flex", flexDirection: "row", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ height: "100%", width: "70%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                    <p style={{ fontFamily: "Figtree", fontSize: "12px", padding: 0, display: "block", color: dueDate?.color }}>{dueDate ? dueDate.tag : "-"}</p>
                    <p style={{ fontFamily: "Figtree", fontSize: "12px", padding: 0, fontWeight: "bold", color: dueDate?.color }}>{dueDate ? `${dueDate.value} ${dueDate.unit}` : <></>}</p>
                </Box>
                <IconButton sx={{ padding: 0 }}>
                    <img style={{
                        width: "70%", height: "70%", "&:hover": {
                            cursor: "pointer"
                        }
                    }} src={arrow} />
                </IconButton>
            </Box>
        </Box>
    </Box>
}

function GetHelp(props) {

    return <Box sx={{ width: "100%" }}>
        <Box onClick={props.onClick} sx={{
            borderRadius: "4px", width: "100%", backgroundColor: "#F5F5F5", padding: "4px 6px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", "&:hover": {
                cursor: "pointer"
            },gap:1
        }}>
            <img style={{
                width: "20%", height: "20%", "&:hover": {
                    cursor: "pointer"
                }
            }} src={play_icon} />
            <Box sx={{ display: "flex", textTransform:"capitalize",fontFamily:"Figtree",textAlign:"start",flexDirection: "row", width: "100%",fontSize:"14px", height: "50%", alignItems: "center", justifyContent: "space-between", gap: 0.5, paddingTop: "2px" }} >
                {props.help.question}
            </Box>
            
            <Box sx={{ width: "29%", display: "flex", flexDirection: "row", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <IconButton sx={{ padding: 0 }}>
                    <img style={{
                        width: "70%", height: "70%", "&:hover": {
                            cursor: "pointer"
                        }
                    }} src={arrow} />
                </IconButton>
            </Box>
        </Box>
    </Box>
}

function getStatusOfBooking(status) {
console.log("the status is ", status);
    if (status === 'Y' || status === "yes" || status === "ARCHIVED") {
        return "completed";
    } else {
        return "ontime";
    }
}




