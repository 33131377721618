import { useSnapshot } from "valtio"
import { entityStore, loginStore } from "../state/CommonState"
import { $c, $cg, $cr } from '../components/AgComponents'
export default function ViewEntity() {
    const domain = loginStore.email.split('@')[1]
    useSnapshot(entityStore)
    const entity = entityStore.entityRec
    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_API_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }

    return (
        <>
            <h1 className="title">View Entity</h1>
            <div className="columns">
                <$cr n='2'>Entity Name</$cr>
                <$cg n='8'>{entity.entityName}</$cg>
            </div>
            <div className="columns">
                <$cr n='2'>Client ID</$cr>
                <$cg n='3'>{entity.clientId}</$cg>
                <$cr n='2'>GST</$cr>
                <$cg n='3'>{entity.gst}</$cg>
            </div>
            {
                (domain === 'agraga.com') ?
                    <div className="columns">
                        <$cr n='2'>Sales Person</$cr>
                        <$cg n='3'>{entity.salesPerson}</$cg>
                        <$cr n='2'>Customer Service</$cr>
                        <$cg n='3'>{entity.customerService}</$cg>
                    </div> : <></>
            }
            <div className="columns">
                <$cr n='2'>State</$cr>
                <$cg n='3'>{entity.state}</$cg>
                <$cr n='2'>Country</$cr>
                <$cg n='3'>{entity.country}</$cg>
            </div>
            <div className="columns">
                <$cr n='2'>Address</$cr>
                <$cg n='8'>
                    <pre>{entity.address}</pre>
                </$cg>
            </div>
            <div className="columns">
                <$cr n='2'>Pin Code</$cr>
                <$cg n='3'>{entity.pinCode}</$cg>
                <$cr n='2'>PAN</$cr>
                <$cg n='3'>{entity.pan}</$cg>
            </div>
            <div className="columns">
                <$cr n='2'>IEC Code</$cr>
                <$cg n='3'>{entity.IECCode}</$cg>
                <$cr n='2'>Credit Customer ?</$cr>
                <$cg n='3'>{entity.isCreditCustomer}</$cg>
            </div>
            {
                entity.isCreditCustomer === 'Yes' && (
                    <div className="columns">
                        <$cr n='2'>Number of Days</$cr>
                        <$cg n='3'>{entity.creditCustomerdaysCount}</$cg>
                        <$cr n='2'> Credit Limit</$cr>
                        <$cg n='3'>{(() => parseFloat(entity.creditLimit).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: "currency",
                                        currency: entity.creditLimitCurrencyType,
                        }))()}</$cg>
                    </div>
                )
            }
            <div className="columns">
                <$cr n='2'>Status</$cr>
                <$cg n='3'>{entity.status}</$cg>
            </div>
            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>List of Required Documents</th>
                                    <th>Document Uploaded</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    entity.documents.map((e, i) =>
                                        <tr>
                                            <td>{e.name}</td>
                                            <td>{renderLink(e.fileLink)}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </$c>
            </div>
        </>
    )
}