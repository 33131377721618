import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query";


import { AgragaBooking, ClientBooking } from "../components/ag-table/columns";

import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";

import { Sorting } from "../components/ag-table/ag-sorting";

import { reducer, PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAGE_FILTER_CHANGED, TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED } from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import { bookingsStore, configStore, contractsStore, entityStore, loginStore } from "../state/CommonState";
import { useSnapshot } from "valtio";


import { useGlobalState } from "../GlobalState";
import { AgWarnPopup1 } from "../components/AgCustomComponents";
import AgBookingRequestCancellationv2 from "./AgBookingRequestCancellation-v2";
import BookingsApi from "../state/bookings-api";
import QuoteCalcV2 from "../util/quote-calc-v2";
import Utils from "../util/Utils";
import { enquiryStorev2 } from "../enquiryv2/enquiry-data-v2";
import { Box, Typography } from "@mui/material";
import { Nodata } from "../mui-components/nodatafound";

const queryClient = new QueryClient()


const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};


const DataTable = (props) => {
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(contractsStore)
    useSnapshot(enquiryStorev2)
    
    const filterStatus = {
        'SPOT': 'SPOT'
    }
    const [selectedFilter, SetSelectedFilter] = useState('ALL');
    let title = 'All Bookings'

    const { changeCurrentMenu } = useGlobalState()
    if (loginStore.isClient()) title = 'My Bookings'
    const tablist = ['Submitted Bookings', 'Shipments In-Progress', 'Archives', 'Cancelled']

    let status = 'SUBMITTED'
    switch (bookingsStore.listActiveTab) {
        case 'Shipments In-Progress': status = 'INPROGRESS'; break;
        case 'Archives': status = 'ARCHIVED'; break;
        case 'Cancelled': status = 'CANCELLED'; break;
    }

    let column = {}

    if (!loginStore.isClient()) {
        column = AgragaBooking
    } else {
        column = ClientBooking
    }

    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }

    let columns = column
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState);
    useEffect(() => {
        ApiAgTable.bookingsData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res => {
            res.clone().json().then((res) => {
                let a = []
                for (let i = 0; i < res.results.length; i++) {
                    let d = res.results[i]
                    let stype = d.Bookings.contract.shipmentType.substr(0, 3).toLowerCase()
                    if(stype=='air'){
                        if(d.Bookings.contract.airline != undefined && d.Bookings.contract.airline.trim().length==0 && d.Bookings.airline != undefined && d.Bookings.airline.trim().length!=0){
                            d.Bookings.contract.airline = d.Bookings.airline
                        }
                    }
                    a.push(d)
                }
                res.results = a
                if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
                    if (JSON.stringify(data1) != JSON.stringify(res)) {
                        setData1(res)
                    }
                }
            }, err => {
                console.log(err)
            })
        })
    }, [queryPayload, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, configStore.currentSelectedEntity , configStore.currentSelectedBranch])



    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );

    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }, [keyword, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { status : status, quotationref:selectedFilter } });
        gotoPage(0);
    }, [columns, bookingsStore.listActiveTab, bookingsStore.requestCancellationReason, selectedFilter]);

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);





    const myRole = loginStore.userRec.aguserRec.role
    const [focusedBookingId, setFocusedBookingId] = useState(null);
    return (
        <>
            <AgWarnPopup1 callback={async () => {
                if (bookingsStore.requestCancellationReason === null || bookingsStore.requestCancellationReason == '') {
                    configStore.setModalMessage(`Invalid Reason.`);
                }
                // configStore.setText = false
                await BookingsApi.cancelBooking(focusedBookingId, bookingsStore.requestCancellationReason);
                bookingsStore.requestCancellationReason = null;
            }}>
                <p className="is-size-7 notification is-warning">{`Are you sure you wish to cancel the booking ${focusedBookingId}`}</p>
                <br></br>
                <p>Reason:{bookingsStore.requestCancellationReason}</p>
            </AgWarnPopup1>
            <AgBookingRequestCancellationv2 />
            <nav className="level">
                <div class="level-left">
                    <div class="level-item">
                        <p class="title is-4" style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>
                            {title}
                        </p>
                    </div>
                    <div class="level-item">
                        <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                    </div>
                </div>
                {
                    (!loginStore.isClient())?<div class="level-item level-left ml-6">
                    <div class="field">
                        <p class="control has-icons-left has-icons-right">
                            <span class="select">
                                <select name="status" id="status"
                                    value={selectedFilter} onChange={(e) => SetSelectedFilter(e.target.value)}
                                >
                                    <option value="ALL">ALL</option>
                                    {
                                        Object.entries(filterStatus).map(([key, value]) => (
                                            <option value={value}>{key}</option>
                                        ))
                                    }
                                </select>
                                <span class="icon">
                                    <i class="fa-solid fa-filter"></i>
                                </span>
                            </span>
                        </p>
                    </div>
        </div>:""
                }
                
            </nav>
          { loginStore.isClient() ? 
             <Box sx={{width:"100%",display: "flex", flexDirection: 'row', gap: 1,backgroundColor:"white",borderRadius:"5px", marginY : "5px",padding:"5px"}}>
               {tablist.map((e)=>  <Box onClick={()=>{
                     bookingsStore.listActiveTab = e
                }} sx={{
      height: "100%", borderRadius: "5px", display: "flex", flexDirection: 'row', gap: 1, justifyContent: "center", alignItems: "center", padding: "5px 14px", backgroundColor: e === bookingsStore.listActiveTab ? "#2A6ED4" : "transparent", color: e === bookingsStore.listActiveTab ? "white" : "#555555", '&:hover': {
          cursor: "pointer"
      }
  }}>
      {/* <img style={{ filter: selected ? "brightness(0) invert(1)" : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(22%) hue-rotate(49deg) brightness(98%) contrast(85%)" }} height="13%" width="13%" src={icon} /> */}
      <Typography fontSize="14px" fontFamily="Figtree" fontWeight={600}>{e==="Archives"?"Completed":e}</Typography>
  </Box>)}
             </Box>

          :  <div id="quotelistclient" className="tabs is-boxed" style={{ marginBottom: '0px', backgroundColor: '#2c358a' }}>
                <ul>
                    {
                        tablist.map(e => {
                            let className = ''
                            let styles = { color: 'lightgrey' }
                            if (e === bookingsStore.listActiveTab) {
                                className = 'is-active'
                                styles = { backgroundColor: '#eea724', color: 'black' }
                            }
                            styles.paddingTop = '5px'
                            styles.paddingBottom = '5px'
                            styles.paddingRight = '15px'
                            return <li className={className} onClick={() => {
                                bookingsStore.listActiveTab = e
                            }}>
                                <a style={styles}><span>{(e=='Archives'?'Completed':e)}</span></a>
                            </li>
                        })
                    }
                </ul>
            </div>}
            <div className="box" style={{flex:1}}>
                <div className="table-container" >
                    <table className="table is-fullwidth is-small ">
                        <thead className="is-scrollable-header has-background-white-ter is-unselectable">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <>{
                                            (column.Header != 'Actions' || (column.Header == 'Actions' && status == 'SUBMITTED')) ? <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <Sorting column={column} />
                                            </th> : <></>
                                        }
                                        </>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="contractlist" style={{ fontSize: '0.9rem' }} {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}
                                        onClick={() => {
                                            console.log(row.original.Bookings._id)
                                            if (!loginStore.isClient()) {
                                                window.open(`${window.location.href}bookingsv2/view/${row.original.Bookings._id}`, '_blank').focus();
                                                return;
                                            }
                                            
                                                            bookingsStore.current = row.original.Bookings
                                                            entityStore.entityRec.set(row.original.Entities)
                                                            contractsStore.current = row.original.Bookings.contract
                                                            contractsStore.current = new QuoteCalcV2(contractsStore.current).recalc()
                                                            enquiryStorev2.initializeWithConract(row.original.Bookings.contract)
                                                            bookingsStore.currentBookingsListComponent = 'view'
                                                        }}
                                                        >
                                                            {
                                                                row.cells.map(cell => {
                                                                    {
                                                                        let e = cell['row']['original']
                                                                        switch (cell['column']['Header']) {
                                                                            case "Booking Date":
                                                                                return <td>{Utils.ddmmyyyystring(e.Bookings.bookingDate)}</td>
                                                                                case "BranchName":
                                                                                    return <td>{`${e.Branches?.state}-${(e.Branches?.gst.length>0 ?e.Branches?.gst : e.Entities?.tinNum )}`}</td>
                                                                            case "Quotation Ref #":
                                                                                if(loginStore.isClient()){
                                                                                    return <td>{e.contract._id}</td>
                                                                                }else{
                                                                                    return <>
                                                                                    {(e.Bookings?.contract?.quotationNum=="SPOT")?<td>SPOT</td>:<td><a href={`${window.location.href}quotation/pureView/${e.Bookings?.contract?.quotationNum}`} target="_blank" onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                    }}>{e.Bookings?.contract?.quotationNum}</a></td>}
                                                                                    </>
                                                                                    
                                                                                    
                                                                                }
                                                                            case "Actions": 
                                                                                if(e.Bookings.status === `SUBMITTED`){
                                                                                    return <td>
                                                                                    <div
                                                                                        class='dropdown post-options is-right is-hoverable '
                                                                                        onClick={null}
                                                                                    >
                                                                                        <div class='dropdown-trigger'>
                                                                                            <button
                                                                                                class='button'
                                                                                                aria-haspopup='true'
                                                                                                aria-controls='dropdown-menu-post'
                                                                                            >
                                                                                                <span class='icon is-small'>
                                                                                                    <i
                                                                                                        class='fas fa-ellipsis-h'
                                                                                                        aria-hidden='true'
                                                                                                    ></i>
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                         <div
                                                                                            class='dropdown-menu'
                                                                                            id='dropdown-menu-post'
                                                                                            role='menu'
                                                                                        >
                                                                                           <div class='dropdown-content'>
                                                                                                
                                                                                                   
                                                                                                        <a class='dropdown-item'
                                                                                                            onClick={(event) => {
                                                                                                                Object.assign(bookingsStore.current,e)
                                                                                                                event.preventDefault();
                                                                                                                event.stopPropagation();
                                                                                                                setFocusedBookingId(e._id);
                                                                                                                bookingsStore.hasToShowRequestCancellation = true;
                                                                                                            }}
                                                                                                        >
                                                                                                            Request Cancellation
                                                                                                        </a>
                                                                                                 
                                                                                            </div>
                                                                                             
                                                                                        </div>
                                                                                         
                                                                                    </div>
                                                                                            </td>
                                                                                }else{
                                                                                    return <></>
                                                                                }
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }
                                                                    
                                                                })
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {(rows.length > 0) ? (
                        <>
                            <div className="columns" style={{ width: "100%", padding: "1%" }}>
                                <div className="column is-6">
                                    <ReactTablePagination
                                        page={page}
                                        gotoPage={gotoPage}
                                        previousPage={previousPage}
                                        nextPage={nextPage}
                                        canPreviousPage={canPreviousPage}
                                        canNextPage={canNextPage}
                                        pageOptions={pageOptions}
                                        pageSize={pageSize}
                                        pageIndex={pageIndex}
                                        pageCount={pageCount}
                                        setPageSize={setPageSize}
                                        manualPageSize={manualPageSize}
                                        dataLength={totalCount}
                                    />
                                </div>
                                <div className="column is-6" style={{ textAlign: "right" }}>
                                    <div className="select">
                                        <select
                                            value={pageSize}
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                        >
                                            {[5, 10,20, 50, 100].map((pageSize) => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </>
                    ) :  loginStore.isClient() ? <Nodata width="200px" height="200px" /> : <></>}
                </div>
            </div>
        </>
    )

}


const BookingListv2 = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default BookingListv2;