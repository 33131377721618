import { SendBookingMailer } from "../pricing/pricing.screen"
import Api from "./Api"
import { bookingsStore, contractsStore, configStore } from "./CommonState"
const BOOKINGSURI = {
    GET4CONTRACT: (contractNum) => `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/contract/${contractNum}`,
    GET: (bookingNum) => `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/${bookingNum}`,
    GETALL: `${process.env.REACT_APP_API_SERVER}/api/v1/bookings`,
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/bookings`,
    CANCEL_BOOKING: (id) =>  `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/cancelBooking/${id}`,
    SETCUSTREF_BOOKING: (id) =>  `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/setCustref/${id}`
}
export default class BookingsApi {
    static getAllBookings(callback = f => f) {
        Api.fetch(BOOKINGSURI.GETALL, (data) => {
            bookingsStore.list = data
            callback(data)
        })
    }
    static getBooking(bookingNum, callback = f => f) {
        Api.fetch(BOOKINGSURI.GET(bookingNum), (data) => {
            let stype = data.contract.shipmentType.substr(0, 3).toLowerCase()
            if(stype=='air'){
                if(data.contract.airline != undefined && data.contract.airline.trim().length==0 && data.airline != undefined && data.airline.trim().length!=0){
                    data.contract.airline = data.airline
                }
            }
            bookingsStore.current = data
            callback(data)
        })
    }
    static getForContract(contractNum, callback = f => f) {
        Api.fetch(BOOKINGSURI.GET4CONTRACT(contractNum), (data) => {
            bookingsStore.list = data
            callback(data)
        })
    }
    
    static saveBooking(fromPricing) {
        Api.post(BOOKINGSURI.POST, bookingsStore.current, (data) => {
            //if (fromPricing) SendBookingMailer(data)
            configStore.setBookingModalMessage(data._id)
        })
    }

    static cancelBooking(bookingNum,reason) {
        Api.put(BOOKINGSURI.CANCEL_BOOKING(bookingNum),{ reason },() => {
            // configStore.setModalMessage(`Booking ${bookingNum} Cancelled`)
            configStore.setModalMessage(`Booking Cancellation request placed successfully`)
        } )
    }

    static cancelBookingfromqoute(bookingNum,reason) {
        Api.put(BOOKINGSURI.CANCEL_BOOKING(bookingNum),{ reason },() => {
            // configStore.setModalMessage(`Booking ${bookingNum} Cancelled`)
        } )
    }

    static setcustrefBooking(bookingNum,contract) {
        Api.put(BOOKINGSURI.SETCUSTREF_BOOKING(bookingNum),{ contract },() => {
            configStore.setModalMessage(`Booking ${bookingNum} - Cust Ref Changed.`)
        } )
    }
}