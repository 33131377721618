const helpList = [{
    id:1,
    question : "Understand my Dashboard",
    src: "https://agraga-pub-assets.s3.ap-south-1.amazonaws.com/video/Understand+my+Dashboard.mp4",
    content : ["Your Dashboard allows you to,","share new enquiries with us","view the status of your existing bookings and,",`have visibility & control on all day-to-day activities related to these bookings
    Enquiries for LCL, FCL and Air can be submitted in just a few clicks. Just select the scope of the shipment, provide us the Origin and Destination basis this scope.`,`If you want to pick-up from or deliver upto Door, please enter the PIN or ZIP code and then the rest of the address before shortlisting and selecting from the drop-down options.`,
    `Status of existing bookings that are in-transit, can be accessed from this section. You can view the visibility graph and access the Document Centre by clicking on Track.`,
    `The My Actions section shows you pending activities that need to be completed from your end, along-with when they are due, in the decreasing order of priority.`,
`You can access all these as well as other sections from the Top Navbar as well.`]
},
{
    id : 2,
    question : "How to access My Profile or update my Settings?",
    src : "https://agraga-pub-assets.s3.ap-south-1.amazonaws.com/video/How+to+access+My+Profile+or+update+my+Settings.mp4",
    content : [
        `Click on your Initials on the Top Right Hand Side and select Profile.`,
        `From here, you can change or correct your First & Last Names, Phone Numbers as well as your Password.`,
        `You can also Invite new users to access your Company profile by just clicking on Invite User and providing the Name and Email ID. They will receive an Email along-with their Login credentials.`
    ]
},
{
    id:3,
    src : "https://agraga-pub-assets.s3.ap-south-1.amazonaws.com/video/How+to+invite+new+Users+to+access+my+Entity+login.mp4",
    question : "How to invite new Users to access my Entity login?",
    content : [
        `Please note that you can invite new users only if you are the Admin for your Company.`,
        `Click on your Initials on the Top Right Hand Side and select Profile.`,
        `Click on Invite User against the respective Entity Name and provide the Name and Email ID. The Invitee will receive an Email along-with their Login credentials.`
    ]
},
{
    id:4,
    question : "How to update my KYC Information?",
    content : [
        `Click on your Initials on the Top Right Hand Side and select Profile.`,
        `Click on Show KYC Documents under the respective Entity for which you wish to upload the KYC Documents.`,
        `Now just Browse and Upload the respective Documents.`
    ]
},
{
    id : 5,
    question : "How to access immediate LCL solutions?",
    content : [
        `Click on Quick Quote from the top NavBar.`,
        `If you want to us arrange the Pick-up from Door, change the Toggle to Door under Origin. And if you want us to deliver upto Door, change the Toggle to Door under Destination.`,
        `Please enter the PIN or ZIP code and then the rest of the address wherever you have selected Door, before shortlisting and selecting the correct address from the drop-down options.`,
        `If you know by when your Cargo will be ready to ship out, please select the date.`,
        `Now, provide us the Package Type, Number of Packages, Weight per Package and then the Dimensions in Length, Width & Height of each Package. More than one Package Type? No problem, click on the Plus icon and repeat the process.`,
        `In case your enquiry is only Port-to-Port, we only require the Total Weight (in Kgs) and Total Volume (in Cubic Meters).`,
        `If you have opted for pick-up or delivery services in India, you would need to provide us the vehicle type you want us to book for you.`,
        `Now, click Get Rates.`,
        `Allow us a few seconds to provide you the most optimized options.`,
        `The results across different schedules and routes, along-with the how much each solution costs, will be clearly calculated and shown here. Click on View Detailed Pricing against each schedule, to view all the services included.`,
        `In case, you do not wish to avail Customs Clearance or Transportation at Origin or Destination, just unselect the same from the Inclusions to get your reduced charges.`,
        `In some instances, your overseas Buyer or Seller would also be liable for some charges. The quantum of the same is also available in this section.`
    ]
},
{
    id : 6,
    question : "How to place an FCL Enquiry?",
    content : [
        `Click FCL in the Dashboard on Enquiry section and then select the scope from the Drop-down.
        Proceed to enter the Origin & Destination along-with the tentative date by when the caro would be ready to Ship and click Enquiry`,
        ` Please enter the PIN or ZIP code and then the rest of the address wherever you have selected Door, before shortlisting and selecting the correct address from the drop-down options.`,
        `If Hazardous, provide us the Class, Packing Group and the UNNo.`,
        `If Temperature controlled, please input the Temperature in degree Centigrade along-with the value of the consignment in US dollars.`,
        `Now select the Stuffing & De-stuffing types basis your needs.`,
        `Select the Container Type, followed by the Number of such containers and the weight of the cargo per container.   More than one Container Type required? No problem. Click on the Plus icon and repeat the process.`,
        `Click Submit and your enquiry is generated. Allow us a few hours to share our most optimized solution for the same.`
    ]
},
{
    id : 7,
    question : "How to place an Air Freight Enquiry?",
    content : [
        `Click Air in the Dashboard on Enquiry section and then select the scope from the Drop-down.`,
        `Proceed to enter the Origin & Destination along-with the tentative date by when the cargo would be ready to Ship and click Enquiry.`,
        ` Please enter the PIN or ZIP code and then the rest of the address wherever you have selected Door, before shortlisting and selecting the correct address from the drop-down options.`,
        `If Hazardous, provide us the Class, Packing Group and the UNNo.`,
        `In case your packages are fragile and you do not want any other packages to be stacked on top, please enable the Non-Stackable toggle.`,
        `Please note that this will be a more expensive solution.`,
        `If Temperature controlled, please input the Minimum & Maximum Temperature range acceptance, in degree Centigrade.`,
        `Now, provide us the Package Type, Number of Packages, Weight per Package and then the Dimensions in Length, Width & Height of each Package.`,
        `More than one Package Type? No problem, click on the Plus icon and repeat the process.`,
        `Click Submit and your enquiry is generated. Allow us a few hours to share our most optimized solution for the same.`
    ]
},
{
    id: 8,
    question : "How to track my Shipments?",
    content : [
        `Dashboard`,
        `Check the Track Bookings section which lists out ten of the most recent bookings.`,
        `When you click on Track, a new pop-up with the visibility graph along-with the document centre will open, with the most recent updated status
        You can also click on View all to access the Visibility section.`,
        `Visisbility`,
        `Alternatively, click on Visibility on top NavBar to access the same as well with the added flexibility to search your bookings`
    ]
},{
    id : 9,
    question : "How to share my Commercial Invoice & Packing List?",
    content : [
        `My Actions`,
        `You can access the My Actions section from the Top NavBar.`,
        `All pending actions will be listed out in the order of priority.`,
        `Search for the Agraga Booking Reference # against which you wish to provide the Commercial Invoice & Packing List, open it and proceed to Upload the documents before Submitting`,
        `Dashboard announcement (if it is due)`,
        `Alternatively, The My Actions section on the Dashboard will list out the most urgent ten actions that are pending for completion in case you only wish to focus on critical items.`
    ]
},
{
    id : 10,
    question : "How to provide my Shipping Instructions?",
    content :  [
        `My Actions`,
        `You can access the My Actions section from the Top NavBar.`,
        `All pending actions will be listed out in the order of priority. Search for the Agraga Booking Reference # against which you wish to provide the Shipping Instructions.`,
        `You have 2 options - one, Upload the Shipping Instructions as per your template.`,
        `You can also choose to update all the relevant information as per our Template before submitting.`,
        `Dashboard announcement (if it is due)`,
        `Alternatively, The My Actions section on the Dashboard will list out the most urgent ten actions that are pending for completion in case you only wish to focus on critical items.`
    ]
},
{
    id : 11,
    question : "How to approve my Shipping Bill Checklist?",
    content : [
        `My Actions`,
        `You can access the My Actions section from the Top NavBar. All pending actions will be listed out in the order of priority.`,
        `Search for the Agraga Booking Reference # against which you wish to verify the Shipping Bill checklist.`,
        `Once you open the same, you can access the checklist, verify all details and proceed to Approve the same.`,
        `In case there are any discrepancies, please Reject the same and input all the corrections required before Submitting.`,
        `We will shortly send the revised checklist through the My Actions after making the necessary corrections.`,
        `Dashboard announcement (if it is due)`,
        `Alternatively, The My Actions section on the Dashboard will list out the most urgent ten actions that are pending for completion in case you only wish to focus on critical items.`
    ]
},
{
    id : 12,
    question : "How to approve my Bill of Entry Checklist?",
    content : [
        `My Actions`,
        `You can access the My Actions section from the Top NavBar. All pending actions will be listed out in the order of priority.`,
        `Search for the Agraga Booking Reference # against which you wish to verify the Bill of Entry checklist. Once you open the same, you can access the checklist, verify all details and proceed to Approve the same.`,
        `In case there are any discrepancies, please Reject the same and input all the corrections required before Submitting.`,
        `We will shortly send the revised checklist through the My Actions after making the necessary corrections.`,
        `Dashboard announcement (if it is due)`,
        `Alternatively, The My Actions section on the Dashboard will list out the most urgent ten actions that are pending for completion in case you only wish to focus on critical items.`
    ]
},
{
    id : 13,
    question : "How to access my IGM details?",
    content : [
        `My Actions`,
        `You can access the My Actions section from the Top NavBar.`,
        `All pending actions will be listed out in the order of priority.`,
        `Search for the Agraga Booking Reference # against which you wish to access the IGM Details. Once you open the same, you can access the GIGM & LIGM Numbers.`
    ]
},
{
    id : 14 ,
    question : "How to approve my Draft Bill of Lading?",
    content : [ 
        `My Actions`,
        `You can access the My Actions section from the Top NavBar.`,
        `All pending actions will be listed out in the order of priority.`,
        `Search for the Agraga Booking Reference # against which you wish to verify the Draft Bill of Lading.`,
        `Once you open the same, you can access the Draft, verify all details and proceed to Approve and Submit the same.`,
        `In case there are any discrepancies, please Reject the same and input all the corrections required before Submitting.`,
        `We will shortly send the revised Draft Bill of Lading through the My Actions after making the necessary corrections.`,
        `Please note that the Draft Bill of Lading is prepared on the basis of the Shipping Instructions provided by you and deviations between the two could result in issues.`,
        `Dashboard announcement (if it is due)`,
        `Alternatively, The My Actions section on the Dashboard will list out the most urgent ten actions that are pending for completion in case you only wish to focus on critical items.`
    ]
},
{
    id : 15,
    question : "How to approve my Draft AWB?",
    content : [
        `My Actions`,
        `You can access the My Actions section from the Top NavBar.`,
        `All pending actions will be listed out in the order of priority.`,
        `Search for the Agraga Booking Reference # against which you wish to verify the Draft AWB.`,
        `Once you open the same, you can access the Draft, verify all details and proceed to Approve and Submit the same.`,
        `In case there are any discrepancies, please Reject the same and input all the corrections required before Submitting.`,
        `We will shortly send the revised Draft AWB through the My Actions after making the necessary corrections.`,
        `Please note that the Draft AWB is prepared on the basis of the Shipping Instructions provided by you and deviations between the two could result in issues.`,
   `Dashboard announcement (if it is due)`,
   `Alternatively, The My Actions section on the Dashboard will list out the most urgent ten actions that are pending for completion in case you only wish to focus on critical items.`
    ]
},
{
    id : 16,
    question : "How to access my Booking related Documents?",
    content : [
        `Dashboard`,
        `Check the Track Bookings section which lists out ten of the most recent bookings.`,
        `When you click on Track, a new pop-up with the visibility graph along-with the document centre will open, with the most recent updated status
        You can also click on View all to access the Visibility section`,
        `Visibility`,
        `Alternatively, click on Visibility on top NavBar to access the same as well with the added flexibility to search your bookings. All bookings have a Document Centre which includes all the approved documents against that Booking.`
    ]
},
{
    id: 17,
    question : "How do I check the status of my Enquiries?",
    content : [
        "Click on Enquiries on the Top NavBar.",
        `All your enquiries will be listed out in a Table and the status of the same can be viewed here.`,
        `Once we have provided the charges against an enquiry, the status will reflect as Quoted.`,
        `Click on that Record and it will navigate to the quote along-with the schedules available.`,
        `Click on View Detailed Pricing to view the break-up of the charges.`
    ]
},
{
    id : 18,
    question : "How to view my Quotations?",
    content : [
        `Click on Create Bookings on the top NavBar and select Quotations followed by the Shipment Type.`,
        `Once selected, it will list out your Valid quotations and clicking on the Record you want to check, it will navigate to the quote along-with the schedules available.`,
        `Click on View Detailed Pricing to view the break-up of the charges.`
    ]
},
{
    id : 19 ,
    question : "Can I use a quotation to place a Booking later?",
    content : [
        `When you click to open a Valid Quotation, you have the option of Accept or Decline against the same.`,
        `When you Accept a Quotation, it converts into Contract.`,
        `You can place Bookings against this Contract anytime upto the Contract Valid till date.`
    ]
},
{
    id :  20,
    question : "How can I re-negotiate the rates quoted?",
    content : [
        `When you click to open a Valid Quotation, you have the option of Accept or Decline against the same.`,
        `In case you do not find the charges competitive, please click on Decline and share your feedback.`,
        `We will shortly connect with you, understand your exact requirements and share a revised quotation basis the same to match your requirements.`
    ]
},
{
    id : 21,
    question : "How do I place a Booking against my Contract?",
    content : [
        `Click on Create Bookings on the top NavBar and select Contacts followed by the Shipment Type.`,
        `Once selected, it will list out your Valid Contracts along-with the Valid Till date. Clicking on the Record you want to check, it will navigate to the Contract along-with the schedules available.`,
        `Click on Proceed to Booking.`,
        `You can update any reference number you need to help you track this booking. Example: Your Purchase Order number.`,
        `Fill out the Shipper's contact details, Shipper information, Incoterms and Commodity details. Any additional instructions can be updated in the Special Instructions section.`,
        `We also provide the flexibility to upload the Commercial Invoices and Packing Lists while placing the booking.
        Click on Confirm and Save Booking.`
    ]
},
{
    id : 22,
    question : "Can I track other shipments on Agraga, which are not booked through Agraga?",
    content : [
        `We do provide the ability to track any LCL or FCL shipment on our platform, even if Agraga is not executing the same for you.`,
        `Click on Track My Booking on the Top NavBar and proceed to click on New.`,
        `Select the Carrier and the Reference Number that you have available to input. You can choose either the Carrier Booking Number OR the Master Bill of Lading Number OR Container Number. You can also enter a Reference Number to assign an Internal Reference to help you track your active or completed Bookings.`,
        `All your existing tracking will be reflecting below the New tab for your ready reference.`,
        `Please note that this functionality would need to be activated by us. In case you do not have this feature, please reach out to us to enable the same.`
    ]
},
{
    id : 23,
    question : "I am unable to proceed beyond Sign-in. What should I do?",
    content : [
        `After you sign in if you see a message, stating that your registration is pending for activation, request you to kindly get in touch with us so that we can proceed with your account activation and give you full access to Agraga.`
    ]
}
]

export default helpList