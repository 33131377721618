import { Box, Checkbox, Collapse, Typography } from "@mui/material";
import { AgMuiSelect } from "./AgMuiSelect";
import indiaFlag from "../assets/images/india.svg";
import arrowdown from "../assets/images/arrowdown.svg";

function PriceDetail(){
    return  <>
    <Box sx={{display:"flex",flexDirection:"column",gap:0.3}} >
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"12px",fontWeight:"bold"}} >Charge Head</Typography>
     <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"12px",fontWeight:"bold"}} >Convert Price</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Final Discounted Offer</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 1,32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >(Less) Volume Discount</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
 </Box>
 <Box sx={{display:"flex",flexDirection:"column",gap:0.5}} >
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"12px",fontWeight:"bold"}} >Inclusions</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
     <span style={{display:"flex",flexDirection:"row",gap:0.6,alignItems:"center"}}><Checkbox sx={{padding:0}} size="small"/><Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Destination Custom Clearances</Typography></span>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
    <span style={{display:"flex",flexDirection:"row",gap:0.6,alignItems:"center"}}><Checkbox sx={{padding:0}} size="small"/> <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Delivery Charges</Typography></span>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ Nil</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Origin Charges</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Ocean Freight</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Destination CFS Charges</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Destination Charges</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Security Filling - (ENS)</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Security Filling - (ACD)</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Security Filling - (AMS)</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
     <Typography sx={{fontFamily:"Figtree",color:"#555555",fontSize:"10px"}} >Security Filling - (ISF)</Typography>
     <Typography sx={{fontWeight:"bold", fontFamily:"Figtree",color:"black",fontSize:"10px"}} >₹ 32,345.82</Typography>
    </Box>
 </Box>
 <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
 <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",color:"black",fontSize:"12px"}}>Your Account Total</Typography>
 <span style={{display:"flex",flexDirection:"row",gap:1,alignItems:"center"}}><AgMuiSelect fontSize="10px" startIcon={indiaFlag} size="small" value="INR" options={["INR"]} /><Typography sx={{fontWeight:"bold",fontFamily:"Figtree",color:"black",fontSize:"12px"}}>₹ 1,32,345.82</Typography></span>
 </Box>
 </>
}


export function PricingDetailsCard({title = "Price Details",isCollapsable=false,isOpen=false,onClick}){
    return <Box sx={{display:"flex",flexDirection:"column",width:"100%",gap:0.3}}>
            <Box onClick={onClick} sx={{display:"flex",flexDirection:"row",justifyContent:"space-between","&:hover":{
        cursor : isCollapsable ? "pointer" : "none"
       }}}>
       <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",color:"black",fontSize:"14px"}}>{title}</Typography>
    {  isCollapsable && <img src={arrowdown} />}
       </Box>
           {isCollapsable ?   <Collapse sx={{paddingX:"12px"}} in={isOpen} timeout="auto" unmountOnExit={false}>
            <PriceDetail />
       </Collapse> : <PriceDetail/> }
       </Box>
   }