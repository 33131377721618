import { useSnapshot } from "valtio"
import { aguserStore, cususerStore, routerStore } from "../state/CommonState"
import { $c, $cg, $cr } from '../components/AgComponents'
import UserEntityView from "./user-entity-view"
import { ImCancelCircle } from "react-icons/im"
export default function ViewAguser() {
    useSnapshot(aguserStore)
    const aguser = cususerStore.cususerRec
    return (
        <>
            <h1 className="title is-flex is-justify-content-space-between pl-4 pr-4"> <span>View User</span>
            <span> <ImCancelCircle
                // onClick={() => closeaddUser()}
                onClick={async (e) => {
                  e.preventDefault()
                  // props.fn.fn(false)
                  routerStore.agusersCurrentView = "list"
                }}
                className="is-clickable"
              /></span>
            </h1>
            <div className="columns">
                {aguser.firstName &&
                    <>
                <$cr n='2'>First Name</$cr>
                <$cg n='3'>{aguser.firstName}</$cg>
                    </>
                }
                {aguser.lastName &&
                    <>
                <$cr n='2'>Last Name</$cr>
                <$cg n='3'>{aguser.lastName}</$cg>
                    </>
                }
            </div>
            <div className="columns">
                {aguser.email &&
                    <>
                <$cr n='2'>Email</$cr>
                <$cg n='3'>{aguser.email}</$cg>
                    </>
                }
                {aguser.phone &&
                    <>
                <$cr n='2'>Phone</$cr>
                <$cg n='3'>{aguser.phone}</$cg>
                    </>
                }
            </div>
            <div className="columns">
                {aguser.companyName &&
                    <>
                <$cr n='2'>Entity Name</$cr>
                <$cg n='3'>{aguser.companyName}</$cg>
                    </>
                }
                {aguser.country &&
                    <>
                <$cr n='2'>Country</$cr>
                <$cg n='3'>{aguser.country}</$cg>
                    </>
                }
            </div>
            <div className="columns">
                {aguser.role &&
                    <>
                <$cr n='2'>Role</$cr>
                <$cg n='3'>{aguser.role}</$cg>
                    </>
                }
                {aguser.status &&
                    <>
                <$cr n='2'>Status</$cr>
                <$cg n='3'>{aguser.status}</$cg>
                    </>
                }
            </div>
            <div className="columns">
                {aguser.isAgragaUser &&
                    <>
                        <$cr n='2'>Is Agraga User?</$cr>
                        <$cg n='3'>{aguser.isAgragaUser}</$cg>
                    </>
                }
                {aguser.vertical &&
                    <>
                        <$cr n='2'>Vertical</$cr>
                        <$cg n='3'>{aguser.vertical}</$cg>
                    </>
                }
            </div>
            <div className="columns">
                {aguser.division &&
                    <>
                        <$cr n='2'>Division</$cr>
                        <$cg n='3'>{aguser.division}</$cg>
                    </>
                }
                {aguser.national &&
                    <>
                        <$cr n='2'>National</$cr>
                        <$cg n='3'>{aguser.national}</$cg>
                    </>
                }
            </div>
            <div className="columns">
                {aguser.zonal &&
                    <>
                        <$cr n='2'>Zonal</$cr>
                        <$cg n='3'>{aguser.zonal}</$cg>
                    </>
                }
                {aguser.branch &&
                    <>
                        <$cr n='2'>Branch</$cr>
                        <$cg n='3'>{aguser.branch}</$cg>
                    </>
                }
            </div>
            <div className="columns">
                {aguser.regional &&
                    <>
                        <$cr n='2'>Regional</$cr>
                        <$cg n='3'>{aguser.regional}</$cg>
                    </>
                }
                {aguser.reportingManager &&
                    <>
                        <$cr n='2'>Reporting Manager</$cr>
                        <$cg n='3'>{aguser.reportingManager}</$cg>
                    </>
                }
            </div>
            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <UserEntityView />
                </$c>
            </div>
        </>
    )
}