import { useSnapshot } from "valtio"

import {
  ApprovalTabStore,
  configStore,
  loginStore,
  routerStore,
  vehicleStore,
} from "../state/CommonState"
import { useEffect, useRef, useState } from "react"
import { BsFillEyeFill } from "react-icons/bs"
import { CiFileOff } from "react-icons/ci";
//import { Col12, Col1,Col2, Col3, Col4, Col5, Col6, Columns } from "../Controls";
import {
  Col12,
  Col1,
  Col2,
  Col3,
  Col4,
  Col8,
  Col7,
  Col5,
  Cols,
  Col6,
  Columns,
  Col10,
  Col11,
} from "../Controls"

import { loader } from "../util/loader"
import RoleUtils from "../util/RoleUtils"
import Approval_Apis from "../state/approval-api"

import MainVehicle from "../vehicle/main-vehicle"
import VehicleApi from "../state/vehicle_api"


export default function EditVehicleApproval(props) {
  const Vehicle = vehicleStore.vehicleRec
  const APP = ApprovalTabStore.ApprovalRec
  const [FormUserStatus, setFormUserStatus] = useState("")
  const [Remarks, setRemarks] = useState("")
  const [errors, setErrors] = useState({})
  const [pendingErrors, setPendingErrors] = useState({})
  const [approveCheckbox, setApproveCheckbox] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState(null)
  const [message, setMessage] = useState("")
  const [downloadFormData, setDownloadFormData] = useState({})
  const [cursor, setCursor] = useState(0)
  const [subbtnstatus, setsubbtnstatus] = useState(false)
  const [RDIR, setRDIR] = useState(false)
  const [ROLE, setROLE] = useState("")
  const ref = useRef(null)
  const hiddenFileInput1 = useRef()
  let title = ""
  switch (props.mode) {
    case "edit":
      title = `Modify (${Vehicle?._id}) ${Vehicle?.Status}`
      break
    case "view":
      title = `View (${Vehicle?._id}) ${Vehicle?.Status}`
      break
    case "new":
      title = "Create Vehicle"
      break
    default:
      title = "Vehicle"
      break
  }
  useSnapshot(ApprovalTabStore)
  useSnapshot(loader)
  useEffect(() => {
    const input = ref.current
  }, [ref, cursor])
  useEffect(() => {
    setROLE(ApprovalTabStore.ApprovalRec.userRole)
    setFormUserStatus(ApprovalTabStore.ApprovalRec.action)
  }, [])
  const handleVerifyRadioChange = (event) => {
    // Vehicle.remarks = ""
    setRemarks("")
    setApprovalStatus(event.target.value)
    setApproveCheckbox(true)
    setMessage("")
  }

  const handleMessageChange = (e) => {
    // Vehicle.remarks = e.target.value
    setRemarks(e.target.value)
  }


  const timelinepushfn = (r) => {
    let timeline = {
      date: Date.now(),
      event: r,
      user: loginStore.email,
      role: RoleUtils.getUserRole()
    }
    Vehicle.timeline.push(timeline)
  }
  useEffect(() => {
    setRemarks(Vehicle?.Remarks)
    if(!Vehicle.timeline || Vehicle.timeline === null){
      Vehicle.timeline = []
    }
  }, [])
  
  const handleSubmit = async (e) => {
    setsubbtnstatus(true)
    console.log(ROLE);
      if (approvalStatus === "approve" ) {
        const chkBox = document.getElementById("approveChkbox")?.checked
        if (chkBox) {
          await timelinepushfn("Verified")
          let dataUpdate = {
            formdata: Vehicle,
            userTypeObj:{
            role: ROLE,
            status:"approve",
            updatedBy: loginStore.email,
            },
          }
          await Approval_Apis.vehUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
            setsubbtnstatus(false)
          })
        } else {
          setsubbtnstatus(false)
        }

      } else if (approvalStatus === "reject") {
        if (Remarks.length < 1) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Please enter remarks , it should contain atleast 3 characters")
        }
        if (Remarks.trim().length < 3) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Remarks should contain atleast 3 characters")
        }
        await timelinepushfn("Rejected")
        let dataUpdate = {
          formdata: Vehicle,
          userTypeObj:{
            role: ROLE,
            status:"reject",
            rejectedrole: Vehicle.role,
            updatedBy: loginStore.email,
          },
          Remarks
        }
        await Approval_Apis.vehUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
          setsubbtnstatus(false)
        })
      } else{
        setsubbtnstatus(false)
      }

  } 
  const onRedirect =async (event)=>{
    setsubbtnstatus(true)
    await timelinepushfn("Rejection-Acknowledged")
    let dataUpdate = {
      formdata: Vehicle,
      userTypeObj:{
      role: ROLE,
      status:"approve",
      updatedBy: loginStore.email,
      },
    }
    await Approval_Apis.vehUpdateStatus(dataUpdate, setsubbtnstatus,async(d)=>{
      if (d.Status === "Rejected") {
        await VehicleApi.viewVehicle(Vehicle.Regnumber,async(d)=>{
          if (d.Status === "Rejected") {
            setRDIR(true)
            setsubbtnstatus(false)
          }
        })    
        }
    })

  }
  const dateFormat = (date)=>{
    return date?.split("-").reverse().join("/")
  // return   dd[1]+"/"+dd[2]+"/"+dd[0]
  }
  return (
    <>
    
    { RDIR ? <MainVehicle fn={RDIR}/>: <>
      <div class="main-content" style={{ zoom: "0.9" }}>
        <div className="header-wrapper">
          <div className="left-header">
            <div className="page-heading" >

              <span className="heading">
                {Vehicle.Regnumber}/{FormUserStatus}{' '} 
              </span>

            </div>
          </div>
          <div style={{ marginRight: "3%" }}>
            <span>
              <button
                onClick={async (e) => {
                  e.preventDefault()
                //   EntityV2Store.EntityRec.set(new EntityTypeV2())
                  routerStore.creditApplicationView = "list"
                }}
                className="delete is-large is-info "
                style={{ backgroundColor: "#485fc7", marginRight: "15%" }}
              ></button>
            </span>
          </div>
        </div>
        <section
          class="section section-padding"
          style={{ paddingTop: "0px" }}
        >
          <Cols>
            <Col7 style={{ zoom: "0.9",height:"105vh",overflowX:"scroll",display:"flex",flexDirection:"column",flexWrap:"wrap"}}>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Registration Number</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Vehicle.Regnumber}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Vehicle Type</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Vehicle.Vechtype}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>State</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Vehicle.State?.split("/")[0]}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>City</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Vehicle.City}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Vehicle Owner Name</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Vehicle.Vechownname}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Insurance Expiry Date</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {dateFormat(Vehicle.InsExpdate)}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                {/* <span className="ml-2 mr-1 is-size-4"> */}
                     {Vehicle.Documents[2]?.pkey.length > 0 ? <a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_API_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Vehicle.Documents[2]?.pkey +
                          "/" +
                          Vehicle.Documents[2]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a> :<CiFileOff style={{fontSize:"35px"}} />}
                    {/* </span> */}
                </Col2>
                    </Cols>
                    </Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>PUC Expiry Date</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {dateFormat(Vehicle.PucExpdate)}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                {/* <span className="ml-2 mr-1 is-size-4"> */}
                     {Vehicle.Documents[3]?.pkey.length > 0 ? <a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_API_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Vehicle.Documents[3]?.pkey +
                          "/" +
                          Vehicle.Documents[3]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    {/* </span> */}
                </Col2>
                    </Cols>
                    </Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>FC Expiry Date</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {dateFormat(Vehicle.FcExpdate)}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                {/* <span className="ml-2 mr-1 is-size-4"> */}
                      {Vehicle.Documents[4]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_API_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Vehicle.Documents[4]?.pkey +
                          "/" +
                          Vehicle.Documents[4]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    {/* </span> */}
                </Col2>
                    </Cols>
                    </Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>RC Issue Date</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{dateFormat(Vehicle.RcIssuedate)}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>RC Expiry Date</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{dateFormat(Vehicle.RcExpdate)}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <Col12 style={{margin:"0 0.75rem",display:"flex",gap:"1rem",alignItems:"center"}}>
                <span style={{color: "#000"}}>Upload RC Document</span>
                     {Vehicle.Documents[0]?.pkey.length > 0 ? <a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_API_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Vehicle.Documents[0]?.pkey +
                          "/" +
                          Vehicle.Documents[0]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    </Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <Col12 style={{margin:"0 0.75rem",display:"flex",gap:"1rem",alignItems:"center"}}>
                <span style={{color: "#000"}}>Upload Agreement of sales</span>
                      {Vehicle.Documents[1]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_API_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Vehicle.Documents[1]?.pkey +
                          "/" +
                          Vehicle.Documents[1]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    {/* </span> */}
                    </Col12>
                </div>


              
            </Col7>
            <Col5 style={{position:"relative",height:"95vh"}}>
              <div
                style={{
                  fontWeight: "bold",
                  color: "purple",
                  fontSize: "1.1rem",
                }}
                className=""
              >
                TimeLine
              </div>
              <table
                className="table is-fullwidth is-narrow timeline_table"
                style={{ zoom: "0.8" }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Event</th>
                    <th>User</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {Vehicle?.timeline?.map((r, i) => {
                    return <>
                      <tr key={i}>
                        <td>{new Date(Vehicle?.timeline[i].date).toLocaleString()}</td>
                        <td>{Vehicle.timeline[i].event}</td>
                        <td>{Vehicle?.timeline[i]?.user}</td>
                        <td>{Vehicle.timeline[i].role}</td>
                      </tr>
                    </>
                  })}
                </tbody>
              </table>
              <div className="approve-container">
              {(
            <>
              {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") && (
                <p className="is-size-5 has-text-weight-bold">
                  Document Verification
                </p>

              )
              }
              {/* {true && (
                <p className="is-size-5 has-text-weight-bold">
                  Finance Approvals for this Credit Facility
                </p>
              )
              } */}
              {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") && (
                <div className="is-flex is-justify-content-space-between">
                  <span className="is-size-6" style={{ marginTop: "1%" }}>
                    <div>
                      <label>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="approve"
                          checked={approvalStatus === "approve"}
                          onChange={handleVerifyRadioChange}
                        />
                        Approve
                      </label>
                      <label style={{ marginLeft: "10px" }}>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="reject"
                          checked={approvalStatus === "reject"}
                          onChange={handleVerifyRadioChange}
                        />
                        Reject
                      </label>
                    </div>
                  </span>
                </div>
              )}

              {approvalStatus === "reject" && (
                <div>
                  <textarea
                    //  id="rejectremarksid"
                    className="px-2"
                    rows="4"
                    cols="50"
                    placeholder="Enter your Remarks"
                    value={Remarks}
                    onChange={handleMessageChange}
                    //onChange={approvebtnnfn}
                    style={{ marginTop: "10px", border: "1px solid #d1d1e0" }}
                  />
                </div>
              )}
              {(approvalStatus === "approve") && (
                <div>
                  Do you want to approve? <span></span>
                  <input
                    type="checkbox"
                    className="is-size-2"
                    name="approvalStatus"
                    // value="approve"
                    id="approveChkbox"
                  // onChange={approvebtnnfn}
                  />
                </div>
              )}
            </>
          )}
            {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") &&
            (
              <div>
                <span>
                  <button
                    className="button_cancel btn_height"
                    style={{ marginLeft: "3px", paddingLeft: "10px" }}
                    onClick={() => {
                    //   EntityV2Store.EntityRec.set(new EntityTypeV2())
                      routerStore.creditApplicationView = "list"
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="button_submit btn_height"
                    id="approvebtnid"
                    onClick={(e) => handleSubmit(e)}
                    disabled={subbtnstatus}
                  >
                    SUBMIT
                  </button>
                </span>
              </div>
            )
          }
 
              </div>
              {(ROLE === "FINANCE" || ROLE === "Operations"|| ROLE === "SALES") && (ROLE === "SALES" || Vehicle?.timeline?.find((d)=>d.event === "Rejection-Acknowledged")) && (
              <>
            <div style={{border:"1px solid #f5f5f5"}}>
              <p style={{padding:"1rem",backgroundColor:"#f2f2f2"}}>Rejection Remarks</p>
             <p style={{padding:"1rem"}}>{Vehicle?.Remarks}</p> 
            </div>  
            {(APP.status ==="Pending") && ROLE === "SALES" && <button className="button_submit"   disabled={subbtnstatus} style={{padding:".7rem",}} onClick={(e)=>onRedirect(e)}>Acknowlegement</button>}

            </>
          )

          }

            </Col5>
          </Cols>
        </section>




        <hr />
      </div>
      </>
} </>
  )

  function clearErrorMessages(r) {
    pendingErrors[r] = ""
    errors[r] = ""
    setPendingErrors(pendingErrors)
  }

}
