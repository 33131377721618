import { useSnapshot } from "valtio"
import { configStore, contractsStore, entityStore, loginStore, bookingsStore } from "../state/CommonState"
import { $c, $cg, $cr, AgInput, AgSelect } from '../components/AgComponents'
import EntityApi from "../state/entity-api"
import AguserApi from "../state/AguserApi"
import { useEffect, useState } from "react"
import Api from "../state/Api"
import { Roles, ServerLists } from "../StaticData"
import stateList from "../static-data/states"
import LCLScheduleComp from "./LCLScheduleComp"
import BookingsApi from "../state/bookings-api"
import { incoTermsList, commodityList, hazardousList } from "../StaticData"
import BookingConfirmation from "../schedules/booking-confirmation"
import Agswitch from "../components/ag-switch"
import { Agyesno } from "../components/Agyesno"
import FCLScheduleComp from "./FCLScheduleComp"
import AirScheduleComp from "./AirScheduleComp"
import { DummySchedule } from "./dummy-schedule"
import LCLPricingSchedule from "../pricing/lcl.schedule"
import AirPricingSchedule from "../pricing/air.schedule"

export default function CarrierWebView(props) {
    console.log('INSIDE CARRIER WEBVIEW', props.showbooking)
    const [space, setSpace] = useState('')
    const [companyName, setCompanyName] = useState('')
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    let brec = bookingsStore.current
    const aguser = loginStore.userRec.aguserRec
    useEffect(() => {
        EntityApi.getList()
        const ent = entityStore.list.filter(e1 => e1.gst === brec.company)
        if (ent.length > 0) {
            let entity = ent[0]
            setCompanyName(entity.entityName)
            setSpace( space === '' ? ' ' : '')
        }else{
            setCompanyName(brec.company)
        }
    }, [ bookingsStore.current])
    if (!brec) return <></>
    if (!brec.contract) return <></>
    console.log('GOT HERE ############################', brec.contract._id)
    contractsStore.current = brec.contract
    const domain = loginStore.email.split('@')[1]

    let title = 'Carrier Web Form'

    const entity1 = entityStore.entityRec
    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_API_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }
    const filecat = '' + Date.now()
    const enableMBL = () => (contractsStore.current.shipmentType.indexOf('FCL') >= 0
        && contractsStore.current.shipmentScope.endsWith('ort')) ? {} : { disabled: 1 }
    let commList = commodityList
    if (brec.contract.hazardous === 'Yes') commList = hazardousList
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)
    console.log('==============FROM INSIDE CARRIERWEBVIEW', brec.contract.shipmentType.toUpperCase().substr(0, 3))
    let showbooking = {}
    if (props.showbooking === '1') showbooking = {showbooking: '1'}
    let Lclschedulecomponent = LCLScheduleComp
    let Airschedulecomponent = AirScheduleComp
    if (bookingsStore.current.createdFromPricing){
        if(stype()=="LCL"){
            Lclschedulecomponent = LCLPricingSchedule
        }
        if(stype()=="AIR"){
            Airschedulecomponent = AirPricingSchedule
        }
    } 
    console.log('\n\n\nfrompricing=', bookingsStore.current.createdFromPricing, '***\n\n')
    return (
        <div style={{ fontSize: '0.8rem' }}>
            {
                bookingsStore.current?.hasToShowDummySchedule ? <DummySchedule stype={stype()} viewonly={true} />
                : stype() === 'LCL' ?
                    <Lclschedulecomponent data={bookingsStore.current.schedule} viewonly={true} {...showbooking} nobutton={true}/> :
                    stype() === 'FCL' ?
                        <FCLScheduleComp data={bookingsStore.current.schedule} viewonly={true} {...showbooking}/> :
                        <Airschedulecomponent viewonly={true} {...showbooking}/>
            }

            <div className="columns">
                <$cr n='2'>First Name{space}</$cr>
                <$c n='3'>
                    {brec.firstName}
                </$c>
                <$cr n='2'>Last Name</$cr>
                <$c n='3'>
                    {brec.lastName}
                </$c>
            </div>
            <div className="columns">
                <$cr n='2'>Email ID</$cr>
                <$c n='3'>
                    {brec.emailid}
                </$c>
                <$cr n='2'>Contact Number</$cr>
                <$c n='3'>
                    {brec.phone}
                </$c>
            </div>
            <div className="columns">
                <$cr n='2'>Shipper Name</$cr>
                <$c n='3'>
                    {companyName}
                </$c>
                <$cr n='2'>Address</$cr>
                <$c n='3'>
                    {brec.address}
                </$c>
            </div>
            <div className="columns is-narrow">
                <$cr n='2'>PIN/ZIP Code</$cr>
                <$c n='3'>
                    {brec.pin}
                </$c>
                <$cr n='2'>Country</$cr>
                <$c n='3'>
                    {brec.country}
                </$c>
            </div>

            <div className="columns">
                <$cr n='2'>Incoterms</$cr>
                <$c n='3'>
                    {brec.incoTerms}
                </$c>
                <$cr n='2'>Commodity Type</$cr>
                <$c n='3'>
                    {brec.commodityType}
                </$c>
            </div>
            <div className="columns is-narrow">
                <$cr n='2'>Special Instructions</$cr>
                <$c n='6'>
                    {brec.specialInstructions}
                </$c>
                <$c n='2'>
                    {
                        // (enableMBL().disabled)
                         (contractsStore.current.shipmentType.toLowerCase().includes('fcl') 
                         && contractsStore.current.shipmentScope.endsWith('ort')) ?
                            <Agyesno disabled={true} label='Do you require a Direct Carrier MBL?'
                                record={brec} name='carrierMBL' /> : null
                    }
                </$c>
            </div>

            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>Required Documents</th>
                                    <th>Document Uploaded</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bookingsStore.current?.commercialInvoice_filename_list?.length > 0 &&  (<tr>
                                        <td>Commercial Invoice (Non-Mandatory)</td>
                                        <td>{ brec.commercialInvoice_filename_list.map(renderLink).map(link => <>{link}<br/></>)}</td>
                                    </tr>)
                                }   
                                   {
                                        bookingsStore.current?.packingList_filename_list?.length > 0  &&
                                            <tr>
                                                <td>Packing List (Non-Mandatory)</td>
                                                <td>{brec.packingList_filename_list.map(renderLink).map(link => <>{link}<br/></>)}</td>
                                                </tr>  
                                    }
                                {
                                    (
                                        (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
                                        || (brec.contract.hazardous === 'Yes')
                                    ) ?
                                        <tr>
                                            <td>MSDS</td>
                                            <td>{renderLink(brec.msds_filename)}</td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) ?
                                        <tr>
                                            <td>Non-DG Declaration</td>
                                            <td>{renderLink(brec.nondgdecl_filename)}</td>
                                        </tr> : <></>
                                }
                                {
                                    (brec.contract.hazardous === 'Yes') ?
                                        <>
                                            <tr>
                                                <td>DG Declaration</td>
                                                <td>{renderLink(brec.dgdecl_filename)}</td>
                                            </tr>
                                            <tr>
                                                <td>IIP Certificate</td>
                                                <td>{renderLink(brec.iipcert_filename)}</td>
                                            </tr>
                                        </> : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </$c>
            </div>

        </div>
    )
}