import { routerStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import ListEntities from "./list-entities"
import EditEntity from "./edit-entity"
import ViewEntity from "./view-entity"

export default function MainEntity() {
    const store = useSnapshot(routerStore)
    switch(store.entityCurrentView) {
        case 'list': return <ListEntities/>
        case 'view': return <ViewEntity/>
        case 'edit': return <EditEntity mode='edit'/>
        case 'new': return <EditEntity mode='new'/>
    }
}