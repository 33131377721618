import { useEffect, useState } from "react"
import { subscribe, useSnapshot } from "valtio"
import { AgAddress, AgUnlocde } from "../components/ag-address/ag-address"
import { AgInput, AgUnloc } from "../components/AgComponents"
import AgLabel from "../components/AgLabel"
import { useGlobalState } from "../GlobalState"
import Taskapi from "../mytasks/tasks-api"
import BookingsApi from "../state/bookings-api"
import { configStore, contractsStore, bookingsStore, enquiriesValidationStore, loginStore } from "../state/CommonState"
import Utils from "../util/Utils"
import { enquiryStore } from "./enquiry-data"
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";

function PureView(props) {
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(bookingsStore)
    const rec = enquiryStore.current.enqRoute
    const [sidisable, setSidisable] = useState(true)

    useEffect(() => {
        let f = async () => {
            let ret = await Taskapi.getTasklist4Booking(bookingsStore.current._id)
            let l = false
            console.log(ret)
            for (let i = 0; i < ret.length; i++) {
                let e = ret[i]
                if (e.actionName?.indexOf('Shipping Instructions') != -1) {
                    l = true
                    i = ret.length + 1
                }
            }
            setSidisable(l)
            console.log(sidisable)
        }

        f()
        let unsubscribe = subscribe(enquiryStore.current.enqRoute, () => {
            if (contractsStore.current) {
                contractsStore.current.customerReferenceNum = rec.customerReferenceNum
            }
        })
        return unsubscribe
    }, [enquiryStore.current.enqRoute])



    if (!contractsStore.current) return <></>

    return (
        <div style={{ boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
            <header className="card-header">
                <p className="card-header-title">
                    <span className="icon is-small mr-2">
                        <i className="fas fa-road"></i>
                    </span>
                    Route
                </p>
            </header>
            <div className="card-content">
                <div className="content">
                    <div className="columns">
                        <div className="column is-6">
                            <AgLabel label='Origin' value={rec.origin} />
                        </div>
                        <div className="column is-6">
                            <AgLabel label='Destination' value={rec.destination} />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-6">
                            <AgLabel label='Cargo Readiness Date' value={Utils.convertDateformat(rec.cargoReadinessDate)} />
                        </div>
                        <div className="column is-6" style={!RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CUSTOMER_REF_NUMBER) ? { pointerEvents: 'none', opacity: 0.6 } : {}}>

                            {
                                (sidisable) ? <AgLabel label='Cust Ref #' value={rec.customerReferenceNum} /> :
                                    <div className="field">

                                        <label className="label is-small" style={{ marginBottom: '0px' }}>Cust Ref #</label>
                                        <div className="control is-fullwidth">
                                            <input value={rec['customerReferenceNum']}
                                                onChange={(e) => {
                                                    rec['customerReferenceNum'] = e.target.value
                                                }}
                                                onBlur={(e) => {
                                                    BookingsApi.setcustrefBooking(bookingsStore.current._id, contractsStore.current)
                                                }}

                                                placeholder="Cust Ref #" />
                                        </div>
                                    </div>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function HomescreenRoute(props) {
    let enquiriesValidationStoreSnap = useSnapshot(enquiriesValidationStore)
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    const { currentMenu } = useGlobalState();
    const rec = enquiryStore.current.enqRoute

    useEffect(() => {
        let unsubscribe = subscribe(enquiryStore.current.enqRoute, () => {
            if (contractsStore.current) {
                contractsStore.current.customerReferenceNum = rec.customerReferenceNum
            }
            if (rec.origin.length != 0 || rec.destination.length != 0 || rec.cargoReadinessDate.length != 0 || rec.customerReferenceNum.length != 0) {
                configStore.showEnquiries = false
            }
            //  if(rec.cargoDetails.totalVolume === 0){
        
    // }
            //console.log('Routes changed=', rec.origin, rec.destination)
            if (Utils.isUnloc(rec.origin) && Utils.isUnloc(rec.destination))
                configStore.homescreenCurrentShipmentScope = 'Port-to-Port'
            else if (Utils.isUnloc(rec.origin) && !Utils.isUnloc(rec.destination))
                configStore.homescreenCurrentShipmentScope = 'Port-to-Door'
            else if (!Utils.isUnloc(rec.origin) && Utils.isUnloc(rec.destination))
                configStore.homescreenCurrentShipmentScope = 'Door-to-Port'
            else
                configStore.homescreenCurrentShipmentScope = 'Door-to-Door'

            enquiryStore.current.shipmentScope = configStore.homescreenCurrentShipmentScope
            if (configStore.scheduleOrCarrierform == 'carrierform') {
                bookingsStore.current.contract = contractsStore.current
            }
        })
        return unsubscribe
    }, [enquiryStore.current.enqRoute])


    if (!contractsStore.current) return <></>
    //console.log('FROM ENQUIRY STORE=', enquiryStore.current.enqRoute)


    let disabled = {}


    if (props.viewonly) disabled = { disabled: 1 }
    if (props.pureview) return <PureView />
    return (
        <div style={ loginStore.isClient() ? {backgroundColor:"white",borderRadius:"5px"} :  { boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
            <header className="card-header">
                <p className="card-header-title">
                    <span className="icon is-small mr-2">
                        <i className="fas fa-road"></i>
                    </span>
                    Route
                </p>
            </header>
            <div className="card-content">
                <div className="content">
                    <div className="columns">
                        <div className="column is-6">
                            {
                                (currentMenu == "Enquiries" || currentMenu == 'Pricing Calculator') ? <AgAddress {...disabled} label='Origin' isInvalid={enquiriesValidationStoreSnap.origin} handleChange={enquiriesValidationStore} record={rec} name='origin' ShipmentType={configStore.homescreenCurrentShipmentType} /> :
                                    <AgUnlocde {...disabled} label='Origin' record={rec} name='origin' />
                            }
                            {enquiriesValidationStoreSnap.origin && <p className="is-size-7" style={{ color: 'red' }}>Please enter the Origin.</p>}



                        </div>
                        <div className="column is-6">
                            {
                                (currentMenu == "Enquiries" || currentMenu == 'Pricing Calculator') ? <AgAddress {...disabled} label='Destination' isInvalid={enquiriesValidationStoreSnap.origin} handleChange={enquiriesValidationStore} record={rec} name='destination' ShipmentType={configStore.homescreenCurrentShipmentType} /> :
                                    <AgUnlocde {...disabled} label='Destination' record={rec} name='destination' />
                            }
                            {enquiriesValidationStoreSnap.destination && <p className="is-size-7" style={{ color: 'red' }}>Please enter the Destination.</p>}

                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-6">
                            <AgInput {...disabled} label='Cargo Readiness Date' isInvalid={enquiriesValidationStoreSnap.origin} handleChange={enquiriesValidationStore} record={rec}
                                name='cargoReadinessDate' type='date' />
                            {enquiriesValidationStoreSnap.cargoReadinessDate && <p className="is-size-7" style={{ color: 'red' }}>Please select the Cargo Readiness Date.</p>}
                        </div>
                        <div className="column is-6">
                            <AgInput label='Cust Ref #' record={rec} 
                                name='customerReferenceNum' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}