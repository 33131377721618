import { Box, Typography } from "@mui/material";
import { ScheduleCard } from "../mui-components/ScheduleCard";
import { OptionBox } from "../mui-components/OptionBox";
import { ShipmentScopeTag } from "../mui-components/ShipmentScope";
import { useSnapshot } from "valtio";
import { breadCrumbStore, cargoStore, enquiryState } from "../state/CommonState";
import styled from "@emotion/styled";

import { DetailsPage } from "./DetailsPage";
import { CenteredBox } from "../mui-components/CentereedBox";
import { useForm } from "react-hook-form";
import { AntSwitch } from "../mui-components/AntSwitch";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import LabeledTextField from "../mui-components/LabeledTextField";
import { DetailsText } from "../mui-components/DetailsText";
import { EnquiryDetailsView } from "../mui-components/EnquiryDetailsView";
import { enquiryStore } from "../enquiry/enquiry-data";
import { useEffect } from "react";
import { CargoTypes } from "./CargoTypes";
import { CargoDetails, ContainerDetails } from "../state/Types";


const Divider = styled(Box)(({theme})=>({
    width : "1px",
    borderRadius : "4px",
    height : "80%",
    backgroundColor : "#AAAAAA"
}))



function getCargoState(){
    return cargoStore.cargoList.map((cargo)=>{

    })
}

export function CargoDetailsPage({isSchedule}) {
 
    useEffect(()=>{
    

      return()=>{
         enquiryStore.initialize();
         console.log("the cargo is umnounted . . . . . . . . ")
      }
    },[])

    const defaultForm = useForm({
        defaultValues: {
            shipmentType: enquiryStore.current.shipmentType,
            originType: false,
            destinationType: false,
            hazardous: enquiryStore.current.hazardous === "No" ? false : true,
            quoteClass: enquiryStore.current.quoteClass,
            packingGroup: enquiryStore.current.packingGroup,
            temperature: enquiryStore.current.temperature,
            cargoValue: enquiryStore.current.cargoValue,
            unnumber: enquiryStore.current.unnumber,
            nonStackable:  enquiryStore.current.nonStackable === "No" ? false : true,
            temperatureControlled:  enquiryStore.current.temperatureControlled === "No" ? false : true,
            minTemperature: enquiryStore.current.minTemperature,
            maxTemperature: enquiryStore.current.maxTemperature,
            stuffingType: enquiryStore.current.stuffingType,
            destuffingType: enquiryStore.current.destuffingType,
            status: "ENQUIRY",
            cargoDimensionUnit: "Cms",
            enqRoute: enquiryStore.current.enqRoute,
            containerdetails:enquiryStore.current.containerDetails,
            cargodetails: enquiryStore.current.cargoDetails,
            vehicledetails : enquiryStore.current.vehicles
        }
    })



  
 

    return <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1 }}>
        <EnquiryDetailsView/>
        <Box sx={{ height : "88%", width: "100%", display: "flex", flexDirection: "row", gap: 1 }}>
        <CargoTypes form={defaultForm}/>        
            <Box sx={{ height: "100%", width: "80%", display: "flex", flexDirection: "column", gap: 1 }}>
               { breadCrumbStore.breadCrumbs.length===3 && <ScheduleCard isSchedule={false} />}
                <Box sx={{overflowY:"auto",flex:1,width:"100%",display:"flex",flexDirection:"column",backgroundColor:"white",borderRadius: "10px"}}>
                    <DetailsPage mode={enquiryStore.current.shipmentType} form={defaultForm}/>
                </Box>
            </Box>
        </Box>
    </Box>
}