import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Agyesno } from "../components/Agyesno";
import { configStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
let server = process.env.REACT_APP_API_SERVER
export default function Taskfileapproval(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [taskcopy, settaskcopy] = useState({})
    useEffect(() => {
        let f = async () => {
            let t = await Taskapi.getTaskCopy(taskStore.current)
            console.log('GOT THE TASK COPY=', t)
            settaskcopy(t[0])
        }
        f()
    }, [taskStore.current])
    let task = taskStore.current
    console.log('CHECKING FOR TASKCOPY=', taskcopy)
    if (task._id.bookingNum !== taskcopy?._id?.bookingNum || task._id.num !== taskcopy?._id?.num) return <></>
    let stage = task.stage
    let docs = task.files
    if (!docs) docs = []
    console.log('*************', task)
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    <th>Document</th>
                    <th>Link</th>
                    <th>Status</th>
                </tr>
                {
                    docs.map((e, i) => <tr style={{ padding: '0.5rem' }}>
                        <td>{e.label}</td>
                        <td >
                            {
                                (e.filename) ?
                                    <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                        {e.filename}
                                    </a> : <></>
                            }
                        </td>
                        <td>
                            {
                                (taskcopy.files[i].approved !== 'Approve') ?
                                    <select value={e.approved} onChange={async (e1) => {
                                        e.approved = e1.target.value
                                        let path1 = 'clearapproval'
                                        if (e1.target.value === 'Approve') path1 = 'approvefile'
                                        let url = server + '/api/v1/filestore/' + path1 + '/' + task.bookingNum + '/' + e.filename
                                        await MonApi.get(url)
                                    }}>
                                        <option value=''></option>
                                        <option value='Approve'>Approve</option>
                                        <option value='Reject'>Reject</option>
                                    </select>
                                    : e.approved
                            }
                        </td>
                        <td >
                            {
                                (e.approved === 'Reject') ?
                                    <input style={{ width: '15rem' }} placeholder='Provide rejection reason'
                                        value={e.rejectReason} onChange={(e1) => {
                                            e.rejectReason = e1.target.value
                                        }} /> : <></>
                            }
                        </td>
                    </tr>)
                }
            </table>
            <hr />
        </div>
    )
}
export function validateFileApproval(task) {
    let ret = {
        rejected: false,
        errors: []
    }
    let files = task.files
    if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
            let f = files[i]
            if (f.filename && f.filename.length > 0 &&
                f.approved !== 'Approve' && f.approved !== 'Reject') {
                ret.errors.push('Uploaded file needs to be either Approved or Rejected')
                break
            }
            if (f.approved === 'Reject' && !f.rejectReason) {
                ret.errors.push('Rejection reason is mandatory')
                break
            }
            if (f.approved === 'Reject') ret.rejected = true
        }
    }
    return ret
}