import { subscribe, useSnapshot } from "valtio";
import { useEffect,useState } from "react";
import { configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Delaypickupdestination(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage >= 1) {
        return (
            <div className="box">
                <p>
                    Please note that your buyer has not taken delivery at destination
                </p>
                <hr />
                <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
                    <tr>
                        <td>Reason</td>
                        <td>{task.reason}</td>
                    </tr>
                    <tr>
                        <td>Last Free Date</td>
                        <td>{task.lastFreeDate}</td>
                    </tr>
                </table>
                <p>
                    <span>
                        {
                            (stage === 1) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 2
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </div>
        )
    }
}
export function DelaypickupdestinationTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [disableButton,setDisableButton] = useState(true)
    let task = taskStore.current
    let stage = task.stage
    console.log(task.reason)
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            if (task.reason.trim().length > 0) {
                setDisableButton(false)
            }else{
                setDisableButton(true)
            }
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr />
            <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
                <tr>
                    <td>Reason why not yet picked-up (if available)</td>
                    <td>
                        <textarea cols={50} rows={3} value={task.reason} onChange={(e) => task.reason = e.target.value} />
                    </td>
                </tr>
                <tr>
                    <td>Last Free Date</td>
                    <td><input type='date' value={task.lastFreeDate} onChange={(e) => task.lastFreeDate = e.target.value} /></td>
                </tr>
            </table>
            <p>
                <button className="button islink"
                disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}