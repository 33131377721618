import { useSnapshot } from "valtio"
import { aguserStore, bookingsStore, configStore, contractsStore, entityStore, loginStore, newDashboardState, setCommonState } from "./state/CommonState";
import Login from "./Login";
import Main from "./Main";
import { useLocation } from "react-router";
import Api from "./state/Api";
import AcceptQuote from "./AcceptQuote";
import AgModal from "./components/AgModal";
import { useEffect, useState } from "react";
import EntityApi from "./state/entity-api";
import { useGlobalState } from './GlobalState'
import AguserApi from "./state/AguserApi";
import BookingsApi from "./state/bookings-api";
import { enquiryStore } from "./enquiry/enquiry-data";
import Tasktriggermodal from "./mytasks/task-trigger-modal";
import { taskStore } from "./mytasks/task-store";
import Taskapi from "./mytasks/tasks-api";
import ErrorBoundary from "./components/error/ErrorBoundary";
import { AgLoader } from "./components/ag-loader/ag-loader";
import Tour from 'reactour'
import { tourStore } from "./tourStore";
import "./style.css"
import { enquiryStorev2 } from "./enquiryv2/enquiry-data-v2";
import EntityV2Api from "./state/entities-api";

export default function Agapp() {
    // useSnapshot(contractsStore)
    useSnapshot(bookingsStore)
    // let loderbody = document.getElementsByClassName('loaderbody')
    const getExchangerates = async( ) =>{
        await Api.getExchangerates()
      }
    let urllist = []
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {

        let [resource, config] = args;

        let url = args[0]?.replaceAll('/', '')
        url = url + '/'
        // if(loderbody.length>0){
        //     if(url.indexOf('commongetmapunloc')==-1 && url.indexOf('commongetcount')==-1){
        //         loderbody[0].style.display="flex"
        //     }
        // }
        const response = await originalFetch(resource, config);
        // if(loderbody.length>0){
        //     loderbody[0].style.display="none"
        // }

        let l = await response.clone().json()


        if (l["error"] && l["error"] == 'Invalid Token') {
            loginStore.reinitialize()
            localStorage.removeItem('ag_email')
            localStorage.removeItem('tour')
            localStorage.removeItem('ag_current_entity')
            localStorage.removeItem('devicetoken')
            let isRememberedUser = localStorage.getItem("ag_remember_user");
            if(isRememberedUser){
                localStorage.removeItem('ag_userrec')
            }else{
                sessionStorage.removeItem('ag_userrec')
            }
        }
        urllist.push(url)
        if (urllist.length == 1 || (urllist.length > 1 && urllist[urllist.length - 1] != url)) {
            if (url.indexOf('commongetcount/') == -1) {
                if (loginStore.userRec.userFound && !localStorage.getItem("checkcount")) {
                    await Api.getmenucount();
                } else {
                    localStorage.removeItem("checkcount")
                }
            }
        }

        return response;
    };

    const { changeCurrentMenu } = useGlobalState()
    useSnapshot(configStore)
    useSnapshot(loginStore)
    useSnapshot(tourStore)
    const location = useLocation().pathname
    const cloasetour = (event) => {
        tourStore.enable = false
        tourStore.show = false
        let d = JSON.parse(localStorage.getItem('ag_userrec'))
        d.aguserRec.tour = 'no'
        d = JSON.stringify(d)
        localStorage.setItem('ag_userrec', d)
        loginStore.userRec.aguserRec.tour = 'no'
        let data = { email: loginStore.userRec.aguserRec.email, tour: "no" }
        AguserApi.updatetour(data)
    }

    useEffect(() => {
        Api.loadCountryList()
        configStore.currentURL = location
        const email = localStorage.getItem('ag_email')
        const currentEntity = localStorage.getItem('ag_current_entity')

        if(currentEntity && currentEntity !== "undefined" ){
            configStore.currentSelectedEntity = JSON.parse(currentEntity);
        }
        console.log("the email is ",email)
        if (email) loginStore.email = email
        let userRecString;
        let isRememberedUser = localStorage.getItem("ag_remember_user");
        console.log("the remeber me type is ",typeof isRememberedUser);
        if(isRememberedUser === "true"){
            userRecString = localStorage.getItem('ag_userrec')
        }else{
            userRecString = sessionStorage.getItem('ag_userrec')
        }
        
        // || sessionStorage.getItem('ag_userrec')
        if (userRecString) {
            let user
            try {
                user = JSON.parse(userRecString)
                loginStore.email = user.email;
                loginStore.userRec = user;
                entityStore.list = loginStore.userRec.companyList;
                aguserStore.aguserRec.set(loginStore.userRec.aguserRec);
            } catch (e) {
                console.log('ERROR IN PARSING LOCALSTORAGE USERREC =', userRecString)
            }
        }
    }, [])
    
    useEffect(() => {
        if (loginStore?.userRec?.aguserRec?.tour == 'yes' && loginStore?.userRec?.aguserRec?.email.indexOf('agraga.com') == -1) {
            tourStore.enable = true
            tourStore.show = true
        } else {
            tourStore.enable = false
            tourStore.show = false
            tourStore.setstep(0)
        }


    }, [loginStore, tourStore]);

    if (!loginStore.userRec.userFound){
        return (
            <>
                <AgLoader />
                <ErrorBoundary>
                    <AgModal /><Login />
                </ErrorBoundary>

            </>
        )
         }
       
    if (configStore.currentURL.startsWith("/quote/accept")) {
        getExchangerates()
        const quoteId = location.split('/')[3]
        console.log(quoteId)
        Api.getQuote(quoteId)
        return <AcceptQuote id={quoteId} />
    }
    if (configStore.currentURL.startsWith("/quotation/view")) {
        getExchangerates()
        const quoteId = location.split('/').at(3);
        if (quoteId) {
            configStore.quoteCurrentView = 'view';
            EntityApi.getList();
            Api.getQuote(quoteId);
        }
    }
    if (configStore.currentURL.startsWith("/quotation/pureView")) {
        getExchangerates()
        const quoteId = location.split('/').at(3);
        if (quoteId) {
            configStore.quoteCurrentView = 'pureView';
            EntityApi.getList();
            Api.getQuote(quoteId);
        }
    }
    if (configStore.currentURL.startsWith("/quotation/edit")) {
        const quoteId = location.split('/').at(3);
        if (quoteId) {
            const callback = (data) =>{
                setCommonState(data)
                if(data.status != 'DRAFT' && data.status != 'DRAFT(Enquiry)'){
                    configStore.quoteCurrentView = 'pureView';
                }else{
                    configStore.quoteCurrentView = 'edit';
                }
            }
            EntityApi.getList();
            Api.getretrunQuote(quoteId,callback);
            
            
            
        }
    }
    if (configStore.currentURL.startsWith("/bookings/view")) {
        getExchangerates()
        const bookingId = location.split('/').at(3);
        if (bookingId) {
            bookingsStore.currentBookingsListComponent = 'view'
            BookingsApi.getBooking(bookingId, (data) => {
                EntityV2Api.viewEntity(data.contract.entityId)
                contractsStore.current = data.contract
                //////////////////
                let stype = contractsStore.current.shipmentType.substr(0, 3).toLowerCase()
                if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                else configStore.homescreenCurrentShipmentType = 'Air'
                configStore.homescreenCurrentShipmentScope = contractsStore.current.shipmentScope
                ////////////////////////
                enquiryStore.initializeWithConract(data.contract)
            })
        }
    }
    if (configStore.currentURL.startsWith("/bookingsv2/view")) {
        getExchangerates()
        const bookingId = location.split('/').at(3);
        if (bookingId) {
            bookingsStore.currentBookingsListComponent = 'view'
            BookingsApi.getBooking(bookingId, (data) => {
                EntityV2Api.viewEntity(data.contract.entityId)
                contractsStore.current = data.contract
                bookingsStore.current = data
                //////////////////
                let stype = contractsStore.current.shipmentType.substr(0, 3).toLowerCase()
                if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                else configStore.homescreenCurrentShipmentType = 'Air'
                configStore.homescreenCurrentShipmentScope = contractsStore.current.shipmentScope
                ////////////////////////
                enquiryStorev2.initializeWithConract(data.contract)
            })
        }
    }
    if (configStore.currentURL.startsWith("/contracts/view")) {
        getExchangerates()
        const contractId = location.split('/').at(3);
        if (contractId) {
            Api.getContract(contractId, (data) => {
                if (loginStore.isClient()) {
                    let l = 1
                    loginStore.userRec.companyList.filter((e) => {
                        if (e._id == data.entityId) {
                            configStore.currentSelectedEntity = e
                            let branch = e.branches.filter((x, a) => (
                                x.branchId === data.branchId))
                            configStore.currentSelectedBranch = branch[0]
                            l = 0
                        }
                    })
                    if (l == 0) {
                        enquiryStore.initializeWithConract(data)
                        //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                        configStore.homescreenCurrentView = 'view'
                        configStore.scheduleOrCarrierform = 'schedule'
                        let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
                        if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                        else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                        else configStore.homescreenCurrentShipmentType = 'Air'
                        configStore.homescreenCurrentShipmentScope = enquiryStore.current.shipmentScope
                    } else {
                        window.location.href = window.location.href
                    }
                } else {
                    enquiryStore.initializeWithConract(data)
                    //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                    configStore.homescreenCurrentView = 'view'
                    configStore.scheduleOrCarrierform = 'schedule'
                    let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
                    if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                    else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                    else configStore.homescreenCurrentShipmentType = 'Air'
                    configStore.homescreenCurrentShipmentScope = enquiryStore.current.shipmentScope
                }

            })
        }
    }

   if(!loginStore.isClient()){
        if (configStore.currentURL.startsWith("/contractsv2/view")) {
            getExchangerates()
            const contractId = location.split('/').at(3);
            if (contractId) {
                Api.getContractv2(contractId, (data) => {
                    if (loginStore.isClient()) {
                        let l = 1
                        loginStore.userRec.companyList.filter((e) => {
                            if (e._id == data.entityId) {
                                configStore.currentSelectedEntity = e
                                let branch = e.branches.filter((x, a) => (
                                    x.branchId === data.branchId))
                                configStore.currentSelectedBranch = branch[0]
                                l = 0
                            }
                        })
                        if (l == 0) {
                            enquiryStorev2.initializeWithConract(data)
                            //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                            configStore.homescreenCurrentView = 'view'
                            configStore.scheduleOrCarrierform = 'schedule'
                            let stype = enquiryStorev2.current.shipmentType.substr(0, 3).toLowerCase()
                            if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                            else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                            else configStore.homescreenCurrentShipmentType = 'Air'
                            configStore.homescreenCurrentShipmentScope = enquiryStorev2.current.shipmentScope
                            newDashboardState.selectedTab = 3
                        } else {
                            window.location.href = window.location.origin+"/"
                        }
                    } else {
                        enquiryStorev2.initializeWithConract(data)
                        //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                        configStore.homescreenCurrentView = 'view'
                        configStore.scheduleOrCarrierform = 'schedule'
                        let stype = enquiryStorev2.current.shipmentType.substr(0, 3).toLowerCase()
                        if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                        else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                        else configStore.homescreenCurrentShipmentType = 'Air'
                        configStore.homescreenCurrentShipmentScope = enquiryStorev2.current.shipmentScope
                    }
    
                })
            }
        }

        if (configStore.currentURL.startsWith("/myactions")) {
            getExchangerates()
            if (loginStore.userRec.userFound) {
                if (loginStore.isClient()) {
                    let nlink = decodeURI(configStore.currentURL)
                    nlink = nlink.split('ACTLINGACT')
                    if (nlink.length == 2) {
                        let action = nlink[1]
                        nlink = nlink[0].split('/')
                        if (nlink.length == 3) {
                            let bookingNum = nlink[2]
                            BookingsApi.getBooking(bookingNum, async (data) => {
                                let l = 1
                                loginStore.userRec.companyList.filter((e) => {
                                    if (e._id == data.contract.entityId) {
                                        configStore.currentSelectedEntity = e
                                        let branch = e.branches.filter((x,a) => (
                                            x.branchId === data.branchId))
                                        configStore.currentSelectedBranch = branch[0]
                                        l = 0
                                    }
                                })
                                if (l == 0) {
                                    contractsStore.current = data.contract
                                    let ret = await Taskapi.getTaskForAction(bookingNum, action)
                                    if (ret.length > 0) {
                                        taskStore.current = ret[0]
                                        taskStore.currentContainer = ''
                                        taskStore.myActionVisible = true
                                    }
                                } else {
                                    window.location.href = window.location.origin+"/"
                                }
    
    
                            })
                        }
                    }
                } else {
                    window.location.href = window.location.origin+"/"
                }
    
            }
    
        }
    }
    
   

    let loginparams = {}
    if (configStore.currentURL.startsWith("/complete/signup/")) {
        const email = location.split('/')[3]
        console.log('INSIDE SIGNUP=', email)
        return <>
            <AgLoader />
            <ErrorBoundary>
                <AgModal /><Login email={email} />
            </ErrorBoundary>

        </>
    } else {
        //console.log('Inside Agap. email=', loginStore.userRec)
        console.log("testing...",loginStore.userRec.userFound);
        // default route
        if (!loginStore.userRec.userFound){
            return (
                <>
                    <AgLoader />
                    <ErrorBoundary>
                        <AgModal /><Login />
                    </ErrorBoundary>

                </>
            )
             } else{

        //    if( loginStore.userRec?.aguserRec["role"]?.toLowerCase().includes("client") && loginStore.userRec.companyList.length===0){
        //         return (
        //             <>
        //             <AgLoader />
        //                 <ErrorBoundary>
        //                     <AgModal /><Login key="entity" mode="entity"/>
        //                 </ErrorBoundary>
        //             </>
        //         )
             
        //         }else{
             return <>
                <AgLoader />
                <ErrorBoundary>
                    <AgModal />
                    <Tasktriggermodal />
                    <Main />
                    {/* { !loginStore.isClient() && <Tour
                        steps={tourStore.tourSteps}
                        isOpen={tourStore.show}
                        goToStep={tourStore.step}
                        nextStep={tourStore.nextstep}
                        prevStep={tourStore.prevstep}
                        disableDotsNavigation={true}
                        closeWithMask={false}
                        onRequestClose={(event) => cloasetour(event)}
                    />} */}
                </ErrorBoundary>

            </>
                // }
             }
    }

}