import React, { useState, useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

import {
  EntityColumn,
  VehicleColumn,
  EntityColumnnonagraga,
  UserColumn,
} from "../components/ag-table/columns";
import VehicleApi from "../state/vehicle_api";
import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS, ROLES, VERTICALS } from "../util/constants";

import { Sorting } from "../components/ag-table/ag-sorting";
import {
  reducer,
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  PAGE_FILTER_CHANGED,
  TOTAL_COUNT_CHANGED,
  PAYLOAD_CHANGED,
} from "../components/ag-table/ag-reducer";
import ApiAgTable from "../components/ag-table/api_ag-table";
import {
  aguserStore,
  configStore,
  entityStore,
  loginStore,
  routerStore,
  vehicleStore,
} from "../state/CommonState";
import { useSnapshot } from "valtio";
import { AguserType, EntityType, VehicleType } from "../state/Types";
import InviteUser from "../agusers/invite-user";

const queryClient = new QueryClient();

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: 0,
  queryPageFilter: "",
  queryPageSortBy: [],
  queryPayload: {},
};

const DataTable = (props) => {
  useSnapshot(configStore);
  useSnapshot(vehicleStore);

  const domain = loginStore.email.split("@")[1];
  const filterStatus = {
    Pending: "PENDING",
    Verified: "VERIFIED",
    Rejected: "REJECTED",
    InsExpired: "INS-EXPIRED",
    PUCExpired: "PUC-EXPIRED",
    FcExpired: "FC-EXPIRED",
    RcExpired: "RC-EXPIRED",
  };

  const [selectedFilter, SetSelectedFilter] = useState("all");

  const [keyword, setKeyword] = useState("");
  const [inviteGst, setInviteGst] = useState("");
  const [data1, setData1] = useState({ results: [] });
  const onClickFilterCallback = (filter) => {
    setKeyword(filter);
  }; //EntityV2Column
  let column = {};
  //VehicleColumn
  if (domain === "agraga.com") {
    column = VehicleColumn;
  } else {
    column = EntityColumnnonagraga;
  }
  // let columns = useMemo( () => COLUMNS, [])
  let columns = column;
  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryPayload,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState);
  useEffect(() => {
    ApiAgTable.vehiclesData(
      queryPageIndex,
      queryPageSize,
      queryPageFilter,
      queryPageSortBy,
      queryPayload
    ).then((res) => {
      res
        .clone()
        .json()
        .then(
          (res) => {
            if (
              JSON.stringify(data1.results) !==
              JSON.stringify(res.results.vechicles)
            ) {
              if (JSON.stringify(data1) !== JSON.stringify(res.results)) {
                setData1(res);
                console.log("resklfl", res);
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }, [
    queryPayload,
    queryPageIndex,
    queryPageSize,
    queryPageFilter,
    queryPageSortBy,
  ]);

  const totalPageCount = Math.ceil(totalCount / queryPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data1?.results.vechicles || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data1 ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  const manualPageSize = [];

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
    gotoPage(0);
  }, [keyword, gotoPage]);

  useEffect(() => {
    console.log(selectedFilter);
    dispatch({
      type: PAYLOAD_CHANGED,
      payload: { filter: selectedFilter || "all" },
    });
    gotoPage(0);
  }, [selectedFilter]);

  React.useEffect(() => {
    if (data1?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data1.count,
      });
    }
  }, [data1?.count]);

  return (
    <>
      <h1 className="title">Vehicle List</h1>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">{data1?.count} vehicles</p>
          </div>
          {RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_VEHICLES) &&
          <p className="level-item">
            <a
              className="button is-link"
              onClick={() => {
                vehicleStore.vehicleRec.set(new VehicleType());
                routerStore.vehicleCurrentView = "new";
              }}>
              Add New vehicle
            </a>
          </p>
          }
          <div className="level-item">
            <TableFilter
              onClickFilterCallback={onClickFilterCallback}
              defaultKeyword={keyword}
            />
          </div>
          <div className="level-item level-left ml-6">
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <span className="select">
                  <select
                    name="status"
                    id="status"
                    value={selectedFilter}
                    onChange={(e) => SetSelectedFilter(e.target.value)}>
                    <option key="all" value="all">ALL</option>
                    {Object.entries(filterStatus).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    ))}
                  </select>
                  <span className="icon">
                    <i className="fa-solid fa-filter"></i>
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </nav>

      <div className="box">
        <div className="table-container" style={{ overflow: "initial" }}>
          <table className="table is-fullwidth is-small ">
            <thead className="is-scrollable-header has-background-white-ter is-unselectable">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th>S.No</th>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <Sorting column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="contractlist"
              style={{ fontSize: "0.9rem" }}
              {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <>
                    <tr {...row.getRowProps()}>
                      <td>{pageIndex * pageSize + i + 1}</td>
                      {row.cells.map((cell) => {
                        {
                          let e = cell["row"]["original"];

                          switch (cell["column"]["Header"]) {
                            case "Vehicle Registration No#":
                              return (
                                <td>
                                  <a
                                    target="_blank"
                                    onClick={async (event) => {
                                      // vehicleStore.vehicleRec.set(e);

                                      await VehicleApi.viewVehicle(e.Regnumber);
                                      routerStore.vehicleCurrentView = "view";
                                      event.stopPropagation();
                                    }}>
                                    {e.Regnumber}
                                  </a>
                                </td>
                              );
                            case "Actions":
                              return (
                                <td>
                                  <div
                                    className="dropdown post-options is-right is-hoverable "
                                    onClick={null}>
                                    <div className="dropdown-trigger">
                                      <button
                                        className="button"
                                        aria-haspopup="true"
                                        aria-controls="dropdown-menu-post"
                                        //   onClick={() => {
                                        //     vehicleStore.vehicleRec.set(e);
                                        //     routerStore.vehicleCurrentView =
                                        //       "view";
                                        //   }}
                                      >
                                        <span className="icon is-small">
                                          <i
                                            className="fas fa-ellipsis-h"
                                            aria-hidden="true"></i>
                                        </span>
                                      </button>
                                    </div>
                                    {RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.REJECTED_VEHICLES) && e.Status !== "Pending" &&
                                    <div
                                      className="dropdown-menu"
                                      id="dropdown-menu-post"
                                      role="menu">
                                      <div className="dropdown-content">
                                        <a
                                          href="/#"
                                          className="dropdown-item"
                                          onClick={async (event) => {
                                            console.log(e);

                                            await VehicleApi.viewVehicle(
                                              e.Regnumber
                                            );
                                            routerStore.vehicleCurrentView =
                                              "edit";
                                            event.stopPropagation();
                                          }}>
                                          Modify
                                        </a>
                                        {/* <a
                                              href="/#"
                                              className="dropdown-item"
                                              onClick={() => {
                                                console.log(e);
                                                // BranchApi.viewBranch(e._id);
                                                VehicleApi.getList(e._id);
                                                routerStore.branchCurrentView =
                                                  "view";
                                              }}>
                                              View
                                            </a> */}
                                      </div>
                                    </div>}
                                  </div>
                                </td>
                              );

                            default:
                              return (
                                <td {...cell.getCellProps()}>
                                  <span>{cell.render("Cell")}</span>
                                </td>
                              );
                          }
                        }
                      })}
                    </tr>
                    {inviteGst === row.original.gst ? (
                      <tr>
                        <td style={{ backgroundColor: "grey" }} colSpan={9}>
                          <InviteUser
                            gst={row.original.gst}
                            resetState={setInviteGst}
                            admin="Yes"
                            style={{ float: "right" }}
                          />
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
          {rows.length > 0 && (
            <>
              <div className="columns" style={{ width: "100%" }}>
                <div className="column is-6">
                  <ReactTablePagination
                    page={page}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    setPageSize={setPageSize}
                    manualPageSize={manualPageSize}
                    dataLength={totalCount}
                  />
                </div>
                <div className="column is-6" style={{ textAlign: "right" }}>
                  <div className="select">
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}>
                      {[20, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ListVehicle = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable />
    </QueryClientProvider>
  );
};

export default ListVehicle;
