import Api from "./Api"
import {
  ApprovalTabStore,
  DriverStore,
  EntityV2Store,
  branchStore,
  configStore,
  creditAppStore,
  creditformStore,
  routerStore,
  vehicleStore,
} from "./CommonState"
import { CreditFormType } from "./Types"
import EntityV2Api from "./entities-api";


const APPROVAL_API = {
  GET: `${process.env.REACT_APP_API_SERVER}/api/v1/approval/get`,
  GETCAF: (entityID) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/checkcaf/${entityID}`,
  GETENT: (entityID) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/get_ent/${entityID}`,
  GETBRANCH: (entityID) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/get_branch/${entityID}`,
  GETDRIVER: (entityID) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/get_driver/${entityID}`,
  GETVEHICLE: (entityID) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/get_vehicle/${entityID}`,
  UPDATESTATUS: () =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/updateStatus`,
  ENTUPDATESTATUS: () =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/entUpdateStatus`,
  DRIUPDATESTATUS: () =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/driUpdateStatus`,
  VEHUPDATESTATUS: () =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/vehUpdateStatus`,
  BRANCHUPDATESTATUS: () =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/branchUpdateStatus`,
  CHECKDEACTIVATE: (ID) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/approval/check_entity_deactivate/${ID}`,
  
}


export default class Approval_Apis {
    // static async GEt_Approval_List() {
    //   console.log("about to call get gstList api")
    //   await Api.approval_API(APPROVAL_API.GET(), (data) => {
    //     return data
    //   })
    // }
    static async fetchDataEntityApprovals(
      url,
      page,
      pageSize,
      pageFilter,
      pageSortBy,
      payload
    ) {
      payload["page"] = page + 1;
      payload["limit"] = pageSize;
      payload["keyword"] = pageFilter;
      payload["status"] = payload.status || "Pending";
      payload["userRole"] = payload.userRole ;
      if (pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        const sortyByDir = sortParams.desc ? -1 : 1;
        payload["sort"] = {};
        payload["sort"][sortParams.id] = sortyByDir;
      }
      try {
        const requestOptions = {
          method: "GET",
          headers: Api.H(),
        };
        let URL = `${url}?status=${payload.status}&limit=${payload.limit}&page=${payload.page}&keyword=${payload.keyword}&userRole=${payload.userRole}`;
  
        const response = await fetch(URL, requestOptions);
        return response;
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
    }
    static async approvalsGet(page, pageSize, pageFilter, pageSortBy, payload) {
      return Approval_Apis.fetchDataEntityApprovals(
        APPROVAL_API.GET,
        page,
        pageSize,
        pageFilter,
        pageSortBy,
        payload
      );
    }
    static async viewCaf(entityID, userRole, action) {
      console.log("about to call api.get")
      await Api.fetch1(APPROVAL_API.GETCAF(entityID), (data) => {
        console.log("data", data)
        if (data.status) {
          data.data.action = action
          data.data.role = userRole
          creditformStore.CreditformRec.set(data.data)
          // creditAppStore.role = userRole
          // creditAppStore.action = action
          routerStore.creditApplicationView = "new"
  
        } else if (!data.data) {
          configStore.setModalMessage("No data found")
        }
  
      })
    }
    static async viewEnt(entityID, userRole, action,row) {
      console.log("about to call api.get",row)
      await Api.fetch1(APPROVAL_API.GETENT(entityID), (data) => {
        console.log("data", data)
        if (data?.status) {
            EntityV2Store.EntityRec.set(data)
            ApprovalTabStore.ApprovalRec.set({userRole,status:row.status,action:row.action})
            routerStore.creditApplicationView = "entityAPP"
  
        } else if (!data.data) {
          configStore.setModalMessage("No data found")
        }
  
      })
    }
    static async viewBranch(entityID, userRole, action,row) {
      console.log("about to call api.get")
      await Api.fetch1(APPROVAL_API.GETBRANCH(entityID), (data) => {
        console.log("data", data)
        if (data?.status) {
            branchStore.BranchRec.set(data)
            ApprovalTabStore.ApprovalRec.set({userRole,status:row.status,action:row.action})
            routerStore.creditApplicationView = "branchAPP"
  
        } else if (!data.data) {
          configStore.setModalMessage("No data found")
        }
  
      })
    }
    static async viewDriver(entityID, userRole, action,row) {
      console.log("about to call api.get")
      await Api.fetch1(APPROVAL_API.GETDRIVER(entityID), (data) => {
        console.log("data", data.Status)
        if (data?.Status) {
            DriverStore.DriversRec.set(data)
            ApprovalTabStore.ApprovalRec.set({userRole,status:row.status,action:row.action})
            routerStore.creditApplicationView = "driverAPP"
  
        } else if (!data.data) {
          configStore.setModalMessage("No data found")
        }
  
      })
    }
    static async viewVehicle(entityID, userRole, action,row) {
      console.log("about to call api.get")
      await Api.fetch1(APPROVAL_API.GETVEHICLE(entityID), (data) => {
        console.log("data", data)
        if (data?.Status) {
            vehicleStore.vehicleRec.set(data)
            ApprovalTabStore.ApprovalRec.set({userRole,status:row.status,action:row.action})
            routerStore.creditApplicationView = "vehicleAPP"
  
        } else if (!data.data) {
          configStore.setModalMessage("No data found")
        }
  
      })
    }
    
    static async updateStatus(dataUpdate, setsubbtnstatus) {
      console.log("about to call api.get")
      await Api.post(APPROVAL_API.UPDATESTATUS(), dataUpdate, (data) => {
        console.log("data", data)
        if (data) {
          routerStore.creditApplicationView = "list"
          setsubbtnstatus(false)
        }
  
      })
    }
    static async entUpdateStatus(dataUpdate, setsubbtnstatus,callback) {
      console.log("about to call api.get",dataUpdate)
      await Api.post(APPROVAL_API.ENTUPDATESTATUS(), dataUpdate, (data) => {
        console.log("data", data)
        if (data) {
          if(dataUpdate.userTypeObj.role !== "SALES"){
            routerStore.creditApplicationView = "list"
          }
          // EntityV2Api.viewEntity(dataUpdate.formdata.entityID)
          // routerStore.entityUserManageView = "edit"
          callback(data)
          setsubbtnstatus(true)
        }
  
      })
    }
    static async driUpdateStatus(dataUpdate, setsubbtnstatus,callback) {
      console.log("about to call api.get",dataUpdate)
      await Api.post(APPROVAL_API.DRIUPDATESTATUS(), dataUpdate, (data) => {
        console.log("data", data)
        if (data) {
          if(dataUpdate.userTypeObj.role !== "SALES"){
            routerStore.creditApplicationView = "list"
          }
          // EntityV2Api.viewEntity(dataUpdate.formdata.entityID)
          // routerStore.entityUserManageView = "edit"
          callback(data)
          setsubbtnstatus(true)
        }
  
      })
    }
    static async vehUpdateStatus(dataUpdate, setsubbtnstatus,callback) {
      console.log("about to call api.get",dataUpdate)
      await Api.post(APPROVAL_API.VEHUPDATESTATUS(), dataUpdate, (data) => {
        console.log("data", data)
        if (data) {
          if(dataUpdate.userTypeObj.role !== "SALES"){
            routerStore.creditApplicationView = "list"
          }
          // EntityV2Api.viewEntity(dataUpdate.formdata.entityID)
          // routerStore.entityUserManageView = "edit"
          callback(data)
          setsubbtnstatus(true)
        }
  
      })
    }
    static async BRANCHUpdateStatus(dataUpdate, setsubbtnstatus,callback) {
      console.log("about to call api.get",dataUpdate)
      await Api.post(APPROVAL_API.BRANCHUPDATESTATUS(), dataUpdate, (data) => {
        console.log("data", data)
        if (data) {
          if(dataUpdate.userTypeObj.role !== "SALES"){
            routerStore.creditApplicationView = "list"
          }
          // EntityV2Api.viewEntity(dataUpdate.formdata.entityID)
          // routerStore.entityUserManageView = "edit"
          callback(data)
          setsubbtnstatus(true)
        }
  
      })
    }
      static async apiCHECKDEACTIVATE(ID, callback) {
        console.log("about to call api.get")
        await Api.fetch1(APPROVAL_API.CHECKDEACTIVATE(ID), (data) => {
          console.log("data", data)
          if (data) {
            callback(data)
          }
        })
      }

}