import { useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import AgFile, { AgscheduleFile } from "../components/AgFile"
import { configStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import { getStaticConsolidatorList } from "../StaticData"
import LclSchedulesTable from "../components/ag-table/lclschedules-ag-table"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"

export default function Schedules() {
    const store = useSnapshot(myactionsstore)
    const [data, setData] = useState({})
    const [lclVisible, setLclVisible] = useState(false)
    const [fclVisible, setFclVisible] = useState(false)

    let errors = data.validationErrors
    return (
        <div>
            {
                (!lclVisible && !fclVisible) ? <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <tbody>
                        <tr id="ts2">
                            <td style={{
                                width: 0,
                                minWidth: "fit-content"
                            }}>
                                <button className="button"
                                    onClick={(e) => {
                                        setLclVisible(true)
                                    }}>
                                    <span className="icon ">
                                        <i className="fas fa-eye"></i>
                                    </span>
                                </button>
                            </td>
                            <td>
                                <div className="title is-6">LCL</div>
                            </td>
                            {RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                            <td >

                                <AgscheduleFile uploadShipmentType="LCL" consolidatorlist={JSON.stringify(getStaticConsolidatorList())} parent="ts2"
                                    callback={(filename) => {
                                        configStore.setModalMessage('LCL Schedules upload successful.')
                                    }}
                                />
                            </td>
                            }
                            <td><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="./LCL_Template.csv" download="LCL_Template.csv">Download LCL Template</a></td>

                        </tr>
                        <tr id="ts1">
                            <td style={{
                                width: 0,
                                minWidth: "fit-content"
                            }}>
                                <button className="button"
                                    onClick={(e) => {
                                        setFclVisible(true)
                                    }}>
                                    <span className="icon ">
                                        <i className="fas fa-eye"></i>
                                    </span>
                                </button>
                            </td>
                            <td>
                                <div className="title is-6">FCL</div>
                            </td>
                            {RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                            <td>
                                <AgscheduleFile source='entity-upload' uploadShipmentType="FCL" parent="ts1"

                                    callback={(filename) => {
                                        configStore.setModalMessage('FCL Schedules upload successful.')
                                    }} />

                            </td>
                                }
                            <td>
                                <a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="./FCL_Template.csv" download="FCL_Template.csv">Download FCL Template</a>
                            </td>
                        </tr>
                    </tbody>
                </table> : <></>
            }
            {
                (lclVisible) ?

                    <div className="box">
                        <div className="columns">
                            <div className="column is-10"><div className="title is-5">LCL Schedule</div></div>
                            <div className="column is-2" style={{ textAlign: "right" }}>
                                <button className="button islink"
                                    onClick={(e) => {
                                        setLclVisible(false)
                                    }}>
                                    <span className="icon ">
                                        <i className="fas fa-xmark"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <LclSchedulesTable />
                    </div> : <></>
            }

            {
                (fclVisible) ?

                    <div className="box">
                        <div className="columns">
                            <div className="column is-10"><div className="title is-5">FCL Schedule</div></div>
                            <div className="column is-2" style={{ textAlign: "right" }}>
                                <button className="button islink"
                                    onClick={(e) => {
                                        setFclVisible(false)
                                    }}>
                                    <span className="icon ">
                                        <i className="fas fa-xmark"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div> : <></>
            }


        </div>
    )
}