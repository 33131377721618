import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { Col1, Col11, Col12, Col2, Col3, Col4, Col6, Cols } from "../Controls";
import { bookingsStore, configStore, entityStore } from "../state/CommonState";
import { ServerLists } from "../StaticData";
import { OneInput, OneSelect, OneSelect2, OneSelect5 } from "./act-common";
import { taskStore } from "./task-store";

export default function SITopForm(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    let p = {
        borderBottom: '1px solid #eeeeee'
    }
    let bold = {
    }

    return (
        <div>
            {
                ([11,12].indexOf(stage)==-1)?<hr />:<></>
            }
            <Cols>
                <Col6>
                    <table className="table is-fullwidth is-bordered is-narrow" style={{ fontSize: '0.85rem' }}>
                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Shipper / Exporter Details</th>
                        </tr>
                        <tr>
                            <OneInput label='Shipper/Exporter' name='expName' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='Address' name='expAddress' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='City' name='expCity' />
                            <OneInput label='State' name='expState' />
                        </tr>
                        <tr>
                            <OneInput label='PIN/ZIP Code' name='expZip' colSpan='3' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Country' name='expCountry' /> */}
                            <OneSelect5 label='Country' name='expCountry'  dataList={ServerLists.countryNameList}/>
                            <OneInput  label='Tax ID #' name='expTaxid' />
                        </tr>

                        <tr>
                            <OneInput label='Contact Person' name='expContactPerson' />
                            <OneInput label='Contact Number' name='expContactNumber' message={true}/>
                        </tr>
                        <tr>
                            <OneInput label='Email ID' name='expEmail' colSpan='3' />
                        </tr>


                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Notify Party 1 Details</th>
                        </tr>
                        <tr>
                            <OneInput label='Notify Party Name' name='party1Name' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='Notify Party Address' name='party1Address' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='City' name='party1City' />
                            <OneInput label='State' name='party1State' />
                        </tr>
                        <tr>
                            <OneInput label='Zip Code' name='party1Zip' colSpan='3' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Country' name='party1Country' colSpan='3' /> */}
                            <OneSelect label='Country' name='party1Country' dataList={ServerLists.countryNameList} colSpan={3}/>                            
                        </tr>
                        <tr>
                            <OneInput label='Contact Person' name='party1ContactPerson' />
                            <OneInput label='Contact Number' name='party1ContactNumber' message={true}/>
                        </tr>
                        <tr>
                            <OneInput label='Email ID' name='party1Email' colSpan='3' />
                        </tr>
                    </table>
                </Col6>
                <Col6>
                    <table className="table is-fullwidth is-bordered is-narrow" style={{ fontSize: '0.85rem' }}>
                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Consignee / Importer Details</th>
                        </tr>
                        <tr>
                            <OneInput label='Consignee/Importer' name='impName' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='Address' name='impAddress' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='City' name='impCity' />
                            <OneInput label='State' name='impState' />
                        </tr>
                        <tr>
                            <OneInput label='PIN/ZIP Code' name='impZip' colSpan='3' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Country' name='impCountry' /> */}
                            {/* <select className="select is-fullwidth" value={entity.country}
                                onChange={(e) => entity.country = e.target.value} >
                                {ServerLists.countryNameList.map(e => <option value={e}>{e}</option>)}
                            </select> */}
                            <OneSelect5 label='Country' name='impCountry' dataList={ServerLists.countryNameList}/>

                            <OneInput label='Tax ID #' name='impTaxid' />
                        </tr>
                        <tr>
                            <OneInput label='Contact Person' name='impContactPerson' />
                            <OneInput label='Contact Number' name='impContactNumber' message={true}/>
                        </tr>
                        <tr>
                            <OneInput label='Email ID' name='impEmail' colSpan='3' />
                        </tr>
                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Notify Party 2 Details</th>
                        </tr>
                        <tr>
                            <OneInput label='Notify Party Name' name='party2Name' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='Notify Party Address' name='party2Address' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='City' name='party2City' />
                            <OneInput label='State' name='party2State' />
                        </tr>
                        <tr>
                            <OneInput label='Zip Code' name='party2Zip' colSpan='3' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Country' name='party2Country' colSpan='3' /> */}
                            <OneSelect label='Country' name='party2Country' dataList={ServerLists.countryNameList} colSpan={3}/>

                        </tr>
                        <tr>
                            <OneInput label='Contact Person' name='party2ContactPerson' />
                            <OneInput label='Contact Number' name='party2ContactNumber' message={true} />
                        </tr>
                        <tr>
                            <OneInput label='Email ID' name='party2Email' colSpan='3' />
                        </tr>

                    </table>
                </Col6>
            </Cols>
        </div>
    )
}