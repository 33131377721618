import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import LabeledTextField from "../mui-components/LabeledTextField";
import { PageLayout } from "../mui-components/Pagelayout";
import AguserApi from "../state/AguserApi";
import { configStore } from "../state/CommonState";

function ForgotPassword({callback}){
    const defaultForm = useForm({
        defaultValues : {
            email : ""
        }
    })

    const {handleSubmit,control} = defaultForm;

const submit = (data)=>{
    AguserApi.forgotPasswordAguser(data.email,() => {
        configStore.setModalMessage("Your password has been sent to your e-mail")
        callback('login');
    });
}

   return <Box sx={{display:"flex",flexDirection:"column",justifyContent:"space-evenly",alignItems:"start",minHeight:"100%",backgroundColor:'white',padding:"10px 20% 10px 15%"}}>
           <form noValidate style={{width:"100%",display:"flex", justifyContent:"start",alignItems:"start",flexDirection:"column"}} onSubmit={handleSubmit(submit)} >
          <Typography sx={{fontWeight:"bolder",fontSize:"30px",textAlign:"start",marginBottom:'4%',fontFamily:"Figtree",color:"black"}}>Forgot Password</Typography>
<LabeledTextField control={control} width="100%" InputProps={{
            style:{
              fontSize:"80%",
              borderRadius:0
            }
           }}  size="small" label="Enter Email id" fullWidth  name="email" rules={{required:"Email is Required",pattern : {
            value : /^[a-zA-Z0-9_.+]+(?<!^[0-9]*)@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            message : "Invalid Email ID"
           }}}/>
           <Button size="medium" fullWidth sx={{
            marginTop:"7%",
            textTransform:"none",
            borderRadius:0,
            backgroundColor:"#000000",color:"white","&:hover":{
            backgroundColor:"#000000"
          }}} type="submit">Send mail</Button> 
           <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",margin:"12px 0px 40px 0px",flexDirection:"row",gap:1}} >
             <Typography sx={{textTransform:"none",color:"gray",fontSize:"12px"}}>Already have an account?</Typography>
             <Typography onClick={()=>{
            callback('login')
          }} sx={{textTransform:"none",color:"black",fontSize:"12px",fontWeight:"bolder",textDecoration:"underline","&:hover":{cursor:'pointer'}}}>Sign-in</Typography>
           </Box>
          </form>
   </Box>
}


export function ForgotPasswordPage({callback}){
    return <PageLayout Component={<ForgotPassword callback={callback} />} />
}