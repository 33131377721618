import Api from "./Api"
import { configStore, contractsStore } from "./CommonState"
const SCHURI = {
    GET: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/schedules/${id}`,
    SAVE: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/schedules/${id}`,
}
export default class SchApi {
    static async getSchedules(id, callback = f => f) {
        Api.fetch(SCHURI.GET(id), (data) => {
            console.log('fetched schedules for', id, data)
            callback(data)
        })
    }
    static saveSchedules(id, schedules) {
        console.log('about to call save schedules')
        Api.post(SCHURI.SAVE(id), ( contractsStore.current.hasToShowDummySchedule  ? ({ hasToShowDummySchedule : contractsStore.current.hasToShowDummySchedule }) : schedules ), (data) => {
            configStore.setModalMessage('Schedules Saved')
        })
    }
}