import { useEffect, useRef, useState } from "react"
import { EntityV2Store, configStore,entityStore, modalStore } from "../state/CommonState"
import EnquiriesApi from "../state/enq-api"
import Utils from "../util/Utils"
import { enquiryStore } from "./enquiry-data"
import { loginStore } from "../state/CommonState"
import {subscribe, useSnapshot } from "valtio"
import { Nodata } from "../mui-components/nodatafound"
import Api from "../state/Api"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"

export default function ListEnquiry(props) {
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(enquiryStore)
    useSnapshot(modalStore)
    const [currentEntity, setCurrentEntity] = useState(configStore.currentSelectedEntity.gst)
    const [list, setList] = useState([])
    const txtSearch = useRef()

    const filterStatus = {
        'ENQUIRY': 'ENQUIRY',
        'DRAFT': 'DRAFT',
        'FINAL': 'FINAL',
        // 'ACCEPTED': 'ACCEPTED'
    }

    function getstatus(type) {
        console.log("the type came here is ",type)
        return {
            'ENQUIRY': 'In-process',
            'DRAFT': 'In-process',
            'FINAL': 'Quoted',
            //   'ACCEPTED': 'Quoted'
        }[type]
    }
    const [selectedFilter, SetSelectedFilter] = useState(`ALL`);

    function contractsopen(id){
        if(loginStore.isClient()){
            Api.getContract(id+"C", (data) => {
                if (loginStore.isClient()) {
                    let l = 1
                    loginStore.userRec.companyList.filter((e) => {
                        if (e._id == data.entityId) {
                            configStore.currentSelectedEntity = e
                            l = 0
                        }
                    })
                    if (l == 0) {
                        console.log("the entity found hghgedcjhgedcjhgdc dh gwdjhdc dchg qdjhqdc ")
                        enquiryStore.initializeWithConract(data)
                        //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                        configStore.homescreenCurrentView = 'view'
                        configStore.scheduleOrCarrierform = 'schedule'
                        let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
                        if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                        else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                        else configStore.homescreenCurrentShipmentType = 'Air'
                        configStore.homescreenCurrentShipmentScope = enquiryStore.current.shipmentScope
                        configStore.showQoute = true;
                    } else {
                        console.log("the entioty not found  . . . .")
                        window.location.href = window.location.href
                    }
                } else {
                    enquiryStore.initializeWithConract(data)
                    //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                    configStore.homescreenCurrentView = 'view'
                    configStore.scheduleOrCarrierform = 'schedule'
                    let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
                    if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                    else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                    else configStore.homescreenCurrentShipmentType = 'Air'
                    configStore.homescreenCurrentShipmentScope = enquiryStore.current.shipmentScope
                }
            })
        }else{
            window.open(`${window.location.href}quotation/view/${id}`, '_blank').focus();
        }
       
    }

    const getEntityName = (entityId) => {
        if(entityId === "TBA")
        return "YET TO BE ASSIGNED"
        let entity = EntityV2Store.list.filter(e => e._id === entityId)
        
        if (entity.length === 0) return entityId
        return entity[0].entityName
    }

    const createNewEnquiry=()=>{
        enquiryStore.initialize();
        configStore.homescreenCurrentShipmentType = "LCL"
        configStore.homescreenCurrentShipmentScope = "Port-to-Port"
        enquiryStore.currentCargoTableKey = "" + new Date().getMilliseconds();
        enquiryStore.current.createdBy = loginStore.email;
        enquiryStore.current.gst = configStore.currentSelectedEntity.gst;
        enquiryStore.current.shipmentType = configStore.homescreenCurrentShipmentType;
        enquiryStore.current.countryFromEnquiry =  "India"
        configStore.homescreenCurrentView = "new";
        configStore.scheduleOrCarrierform = "schedule";
        enquiryStore.currentEnquiryListComponent="new";
        modalStore.checkingOrigin = false
        modalStore.checkingDestination = false
        configStore.buttondisable = false
        configStore.acceptMail = false
        configStore.checkedEmptyDropdown = false
    }

    function ShipmentType (){

        // let list = enquiryStore.list.filter(e => {
        //     let str = Object.values(e).join('')
        //     str = str.toLowerCase()
        //     if (str.indexOf(configStore.homescreenCurrentShipmentType.toLowerCase()) >= 0) return e
        // })
        setList(enquiryStore.list)
        // txtSearch.current?.value = ''    
    }
    console.log(list)
    useEffect(() => {
        EnquiriesApi.getAllEnquiries()
        let unsubscribe = subscribe(configStore, () => {
            if(currentEntity != configStore.currentSelectedEntity.gst){
                setCurrentEntity(configStore.currentSelectedEntity.gst)
                EnquiriesApi.getAllEnquiries()
            }else{
                ShipmentType()
            }
        })
        return unsubscribe
    }, [configStore,currentEntity , configStore.currentSelectedEntity , configStore.currentSelectedBranch])

    useEffect(()=>
    {
        let list = enquiryStore.list.filter(e => {
            let str = Object.values(e).join('')
            str = str.toLowerCase()
            console.log("the filter is ",selectedFilter," ",str.indexOf(selectedFilter.toLowerCase()), " ", str)
            if(selectedFilter==="ALL"){
                if (str.indexOf("final") == -1) return e
            }else if(str.indexOf(selectedFilter.toLowerCase())!==-1){
                 return e
            }
            
        })
        setList(list)
    },[selectedFilter])

    useEffect(()=>{
        let unsubscribe = subscribe(enquiryStore, () => {
            if(loginStore.isClient()){
                ShipmentType()
            }else{
                let list = enquiryStore.list.filter(e => {
                    let str = Object.values(e).join('')
                    str = str.toLowerCase()
                    
                        if (str.indexOf("final") == -1) return e
                    
                })
                setList(list)
            }
        })
        return unsubscribe

    },[enquiryStore,selectedFilter])

    useEffect(()=>{
        console.log("the entity list isa ", entityStore.list)
        entityStore.list = loginStore.userRec.companyList;
    },[])

    const search = () => {
        const searchStr = txtSearch.current.value.trim().toLowerCase();
        let list;
        if (searchStr.length > 0) {
            const regex = new RegExp(searchStr, 'i');
            console.log(regex, "regex");
            list = enquiryStore.list.filter(e => regex.test(e._id.toLowerCase()));
            setList(list);
        } else {
            setList(enquiryStore.list);
        }
    };

    // const search = (value='ALL') => {
    //     SetSelectedFilter(value)
    //     const searchStr = txtSearch.current.value.toLowerCase()
    //     // console.log(enquiryStore.list,"searchStrsearchStr");
    //     let list = enquiryStore.list.filter(e => {
    //         let str = Object.values(e).join('')
    //         str = str.toLowerCase()
    //         if(loginStore.isClient()){
    //             console.log("inside 1 if",configStore.homescreenCurrentShipmentType.toLowerCase());
    //             // 44A0062
    //             if (str.indexOf(configStore.homescreenCurrentShipmentType.toLowerCase()) >= 0) return e
    //         }else{
    //             console.log("inside 1 else");
    //             return e
    //         }
    //     })

    //     if(!loginStore.isClient() && value!='ALL'){
    //         console.log("inside 2 if")
    //         list = list.filter(e => {
    //             let str = Object.values(e).join('')
    //             str = str.toLowerCase()
    //             if (str.indexOf(value.toLowerCase()) >= 0) return e
    //         })
    //     }
    //     if(searchStr.length>0){
    //         console.log(list,"list");
    //         list = list.filter(e => {
    //             let str = Object.values(e).join('')+Object.values(e.enqRoute).join('')+Utils.convertDateformat(e.enquiryDate)
    //             str = str.toLowerCase()
    //             if (str.indexOf(searchStr) >= 0) return e
    //         })
    //     }
    //     setList(list)
    //   }
    // if (entityStore.list.length === 0) return <></>
    return (
        <>
            {
                (!props.viewonly && loginStore.isClient())?
                    <>
                        <div class="columns">
                            <div class="column">
                                <h5 className="title is-4"
                                    style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>Recent Enquiries</h5>
                            </div>
                            <div class="column ">
                                <div className="field has-addons" style={{float:"right"}}>
                                    <p className="control" >
                                        <input ref={txtSearch} className="input is-small" type="text" placeholder="Search"
                                            style={{ width: '20rem' }} onKeyUp={(e) => {
                                                search()
                                            }} />
                                    </p>
                                    <p className="control">
                                        <button style={{backgroundColor:loginStore.isClient()? "#2A6ED4 !important" : ""}} className="button is-small islink" onClick={search}>
                                            Search
                                        </button>
                                    </p>
                                </div>
                            </div></div>

                    </>
                    :
                    <>
                        <h5 className="title is-4"
                            style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>Enquiries</h5>
                        <div className="field has-addons">
                        {
                    (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.AGRAGA_ENQUIRY_BUTTON)) ? 
                    <p className="control">
                    <button className="button is-small islink" style={{marginRight:"3em",width:"7em",borderRadius:"5px"}} onClick={
                        // enquiryStore.currentEnquiryListComponent = "new"
                        createNewEnquiry

                    }>
                        New
                    </button>
                </p> : <></>
                }
                            <p className="control">
                                <input ref={txtSearch} className="input is-small" type="text" placeholder="Search"
                                    style={{ width: '20rem' }} onKeyUp={(e) => {
                                        search()
                                    }} />
                            </p>
                        
                            <p className="control">
                                <button className="button is-small islink" onClick={search}>
                                    Search
                                </button>
                            </p>
                            {

                                (!loginStore.isClient()) ?
                                    <div class="level-item level-left ml-6">
                                        <div class="field">
                                            <p class="control has-icons-left has-icons-right">
                                                <span class="select">
                                                    <select name="status" id="status" value={selectedFilter} onChange={(e) =>{ 
                                                        console.log("the slected filter is ",e.target.value)
                                                        SetSelectedFilter(e.target.value)}}>
                                                        <option value="ALL">ALL</option>
                                                        {
                                                            Object.entries(filterStatus).map(([key, value]) => (
                                                                <option value={value}>{value}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <span class="icon">
                                                        <i class="fa-solid fa-filter"></i>
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    : <></>
                            }
                        </div>
                    </>

            }

            <div className="box" style={{flex:1,paddingBottom:"10px"}}>
                <div className="table-container">
                    <table className="table is-narrow is-small is-fullwidth">
                        <thead>
                            <tr>
                                <th>Enquiry #</th>
                                {
                                    (!props.viewonly && loginStore.isClient())?
                                        <>
                                            <th>
                                                Customer Ref #
                                            </th>
                                            <th>
                                                Enquiry Date & Time
                                            </th>

                                        </>:
                                        <th>Entity</th>
                                }
                                <th>Created By</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                {
                                    (!props.viewonly && loginStore.isClient())?
                                        <></>:
                                        <th>Shipment Type</th>
                                }

                                <th>Status</th>
                                {
                                    (!props.viewonly && loginStore.isClient())?
                                        <></>:
                                        <th style={{width:'10rem'}}>Created On</th>
                                }

                            </tr>
                        </thead>
                        <tbody className="contractlist" style={{ fontSize: '0.9rem' }}>
                            {
                                list.map(e => {
                                    console.log(e)
                                    return (
                                        <tr onClick={() => {
                                            switch (e.status) {
                                                case 'FINAL':
                                                    contractsopen(e._id)
                                                    break;
                                                case 'ACCEPTED':
                                                    contractsopen(e._id)
                                                    break;
                                                case 'DRAFT':
                                                    if(!loginStore.isClient()){
                                                        window.open(`${window.location.href}quotation/view/${e._id}`, '_blank').focus();
                                                    }
                                                    break;
                                                default:
                                                    if(!loginStore.isClient()){
                                                        EnquiriesApi.getEnquiry(e._id, (data) => {
                                                            let stype = data.shipmentType.substr(0, 3).toLowerCase()
                                                            if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                                                            else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                                                            else configStore.homescreenCurrentShipmentType = 'Air'
                                                            configStore.homescreenCurrentShipmentScope = data.shipmentScope

                                                        })
                                                        enquiryStore.currentEnquiryListComponent = 'view'
                                                    }
                                                    break;
                                            }



                                        }}>
                                            <td>{e._id}</td>
                                            {
                                                (!props.viewonly && loginStore.isClient()) ?
                                                    <>
                                                        <td>{e.enqRoute.customerReferenceNum}</td>
                                                        <td>{Utils.convertDateformat(e.enquiryDate)}</td>
                                                    </> :
                                                    <td>{getEntityName(e.entityId)}</td>
                                            }
                                            <td>{e.createdBy}</td>
                                            <td>{e.enqRoute?.origin}</td>
                                            <td>{e.enqRoute?.destination}</td>
                                            {
                                                (!props.viewonly && loginStore.isClient()) ?
                                                    <></> :
                                                    <td>{e.shipmentType === 'LCL' ? e.shipmentType:(e.shipmentType=== 'Air' ? e.shipmentType: (e.shipmentType === 'FCL (Dock/Dock)' ? 'FCL (CFS/CFS)':(e.shipmentType === 'FCL (Dock/Factory)'? 'FCL (CFS/Factory)' : (e.shipmentType === 'FCL (Factory/Dock)' ? 'FCL (Factory/Cfs)' : (e.shipmentType === 'FCL (Factory/Factory)' ? 'FCL (Factory/Factory)':'')))))}</td>
                                            }
                                            <td>{

                                                (!loginStore.isClient())?
                                                    e.status:
                                                    <>{
                                                        getstatus(e.status)
                                                    }</>
                                            }
                                            </td>
                                            {
                                                (!props.viewonly && loginStore.isClient()) ?
                                                    <></> :
                                                    <td>{Utils.convertDateformat(e.enquiryDate)}</td>
                                            }

                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </table>
                    { list.length === 0 && loginStore.isClient() && <Nodata width="250px" height="250px" /> }
                </div>
            </div>
        </>
    )
}