import { useSnapshot } from "valtio"
import { configStore, entityStore, loginStore } from "../state/CommonState"
import { $c, $cg, $cr, AgInput, AgSelect } from '../components/AgComponents'
import EntityApi from "../state/entity-api"
import AguserApi from "../state/AguserApi"
import { useEffect, useState } from "react"
import Api from "../state/Api"
import { Roles, ServerLists } from "../StaticData"
import stateList from "../static-data/states"
import { Agyesno } from "../components/Agyesno"
import { useRef } from "react"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from '../util/constants'

export default function EditEntity(props) {
    const attachmentNameMapping = {
        'IEC Code': 'iec_code',
        'PAN Card': 'pan_card',
        'GST Certificate': 'gst_cert',
        'Credit Application Form (CAF)': 'caf'
    }

    let parent = ['iec_code', 'pan_card', 'gst_cert', 'caf']
    let parent1 = null
    var parent_container = null
    var newHandle = function (e) {
        console.log(e)
        let parent_container1 = null

        parent_container = e.target;
        do {
            parent_container = parent_container.parentNode;
            if (parent_container && parent.indexOf(parent_container.id) == -1) {
                parent_container1 = null
                parent1 = null
            } else {
                parent_container1 = parent_container
                parent1 = parent_container.id
            }
        }
        while (parent_container1 == null && parent_container !== document.body);
        parent_container = parent_container1


    };
    var newHandle1 = function (e) {
        if (parent && parent_container && parent1) {
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if (e.clipboardData.files.length > 0) {
                let a = parent_container.className.split('###')
                if (a.length > 1) {
                    change(i[0].files, parent1, +a[1])
                    e.stopPropagation()
                }
            }
        }
    };





    function change(files, filetype, i) {
        const formData = new FormData()
        formData.append('myFile', files[0])

        formData.append('source', 'entity-upload')
        formData.append('category', entity.gst)
        formData.append('filetype', filetype)

        fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/common/receivefile`, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('got data', data)
                if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                else {
                    //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                    entity.documents[i].fileLink = `${data.filename}`
                    document.getElementById(filetype).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    const bin2hex = (d) =>
    {
        const randomBytes = (count) => {
            const result = Array(count)
            for (let i = 0;i < count;++i) {
              result[i] = Math.floor(256 * Math.random())
            }
            return result
          }
    let bin = randomBytes(d)
      var i = 0, l = bin.length, chr, hex = ''
    
      for (i; i < l; ++i)
      {
    
        chr = bin[i].toString(16)
    
        hex += chr.length < 2 ? '0' + chr : chr
    
      }
    
      return hex
    
    }
    


    const token= ()=> {
    let str1 = bin2hex(32)
    let str2 = ""
    if(entity.gst && entity.gst.length>0){
        str2 = btoa(entity.gst+Date.now())
    }else{
        str2 = btoa(bin2hex(15)+Date.now())
    }

    
      let result = '';
      const maxLength = Math.max(str1.length, str2.length);
    
      for (let i = 0; i < maxLength; i++) {
        if (i < str1.length) {
          result += str1.charAt(i);
        }
    
        if (i < str2.length) {
          result += str2.charAt(i);
        }
      }
      entity.api = {
        "key" : result,
        "active":"Yes"
      }
    }


    const domain = loginStore.email.split('@')[1]
    const [salesList, setSalesList] = useState([])
    const [csList, setCsList] = useState([])
    const [cadminList, setCadminList] = useState([])
    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)
    let title = 'Create New Entity'
    if (props.mode !== 'new') title = 'Modify Entity Details'
    useSnapshot(entityStore)
    const setLists = (users) => {
        console.log('USERS=', users)
        let agusers = users.filter(e => {if(e.email?.endsWith('agraga.com') && e.status=="ACTIVE") return e})
        agusers.sort((a, b) => a.firstName?.localeCompare(b.firstName))
        setSalesList(agusers)
        setCsList(agusers)

        // document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        // document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}

        // document.getElementsByTagName('body')[0].addEventListener('click', newHandle, false);
        // document.getElementsByTagName('body')[0].addEventListener('paste', newHandle1, false);
    }
    useEffect(() => {
        const input = ref.current
        console.log(input, cursor);
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor])

    function trimStr(str) {
        if(!str) return str;
        return str.replace(/^\s+/g, '');
    }

    const handleChange = (e, r) => {
        setCursor(e.target.selectionStart)
        if (r == 'address' || r == 'entityName') {
            entity[r] = trimStr(e.target.value)
        } else {
            entity[r] = e.target.value.trim()
        }

    }
    useEffect(() => {
        AguserApi.getList(setLists)
        if (domain === 'agraga.com')
            AguserApi.getUsers4role(Roles.CLADMIN, setCadminList)
        else {
            let list = [
                {
                    email: loginStore.email,
                    firstName: loginStore.userRec.aguserRec.firstName,
                    lastName: loginStore.userRec.aguserRec.lastName
                }
            ]
            setCadminList(list)
        }

    }, [])

    const entity = entityStore.entityRec
    console.log(entity.isTrackMyBooking, entity.msme, "msmee");
    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_API_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }
    return (
      <>
        <h1 className="title">{title}</h1>
        <div className="columns">
          <$cr n="2">Entity Name</$cr>
          <$cg n="8">
            <input
              className="input"
              value={entity.entityName}
              ref={ref}
              onChange={(e) => handleChange(e, "entityName")}
            />
          </$cg>
        </div>
        <div className="columns">
          <$cr n="2">Client ID</$cr>
          <$cg n="3">
            <input
              className="input"
              value={entity.clientId}
              ref={ref}
              onChange={(e) => handleChange(e, "clientId")}
            />
          </$cg>
          <$cr n="2">GST</$cr>
          <$cg n="3">
            <input
              className="input"
              value={entity.gst}
              ref={ref}
              id="gsttt"
              onChange={(e) => handleChange(e, "gst")}
            />
          </$cg>
        </div>
        {domain === "agraga.com" ? (
          <>
            <div className="columns">
              <$cr n="2">Sales Person</$cr>
              <$cg n="3">
                <select
                  className="select is-fullwidth"
                  value={entity.salesPerson}
                  onChange={(e) => (entity.salesPerson = e.target.value)}
                >
                  <option value=""></option>
                  {salesList.map((e) => (
                    <option value={e.email}>
                      {e.firstName + " " + e.lastName}
                    </option>
                  ))}
                </select>
              </$cg>
              <$cr n="2">Customer Service</$cr>
              <$cg n="3">
                <select
                  className="select is-fullwidth"
                  value={entity.customerService}
                  onChange={(e) => (entity.customerService = e.target.value)}
                >
                  <option value=""></option>
                  {csList.map((e) => (
                    <option value={e.email}>
                      {e.firstName + " " + e.lastName}
                    </option>
                  ))}
                </select>
              </$cg>
            </div>
            <div className="columns">
              <$cr n="2">Country</$cr>
              <$cg n="3">
                <select
                  className="select is-fullwidth"
                  value={entity.country}
                  onChange={(e) => (entity.country = e.target.value)}
                >
                  {ServerLists.countryNameList.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </select>
              </$cg>
              <$cr n="2">State</$cr>
              <$cg n="3">
                {entity.country !== "India" ? (
                  <input
                    className="input"
                    value={entity.state}
                    onChange={(e) => (entity.state = e.target.value)}
                  />
                ) : (
                  <select
                    className="select is-fullwidth"
                    value={entity.state}
                    onChange={(e) => (entity.state = e.target.value)}
                  >
                    <option value=""></option>
                    {stateList.map((e) => (
                      <option value={e}>{e}</option>
                    ))}
                  </select>
                )}
              </$cg>
            </div>
            <div className="columns">
              <$cr n="2">Address</$cr>
              <$cg n="8">
                <textarea
                  className="textarea"
                  value={entity.address}
                  ref={ref}
                  onChange={(e) => handleChange(e, "address")}
                />
              </$cg>
            </div>
            <div className="columns">
              <$cr n="2">Pin Code</$cr>
              <$cg n="3">
                <input
                  style={{ width: "15rem" }}
                  className="input"
                  value={entity.pinCode}
                  ref={ref}
                  onChange={(e) => handleChange(e, "pinCode")}
                />
              </$cg>
              <$cr n="2">PAN</$cr>
              <$cg n="3">
                <input
                  className="input"
                  value={entity.pan}
                  ref={ref}
                  onChange={(e) => handleChange(e, "pan")}
                />
              </$cg>
            </div>
            <div className="columns">
              <$cr n="2">IEC Codee</$cr>
              <$cg n="3">
                <input
                  style={{ width: "15rem" }}
                  className="input"
                  id="gbbb"
                  value={entity.IECCode}
                  ref={ref}
                  onChange={(e) => handleChange(e, "IECCode")}
                />
              </$cg>
              <$cg n="3">
                <Agyesno
                  label="Credit Customer ?"
                  record={entity}
                  name="isCreditCustomer"
                  callback={(val) => (entity.isCreditCustomer = val)}
                >
                  {" "}
                </Agyesno>
              </$cg>
            </div>
            {entityStore.entityRec.isCreditCustomer === "Yes" && (
              <>
                <div className="columns">
                  <$cr n="2">Number of Days</$cr>
                  <$cg n="3">
                    <input
                      style={{ width: "15rem" }}
                      type="number"
                      className="input"
                      value={entity.creditCustomerdaysCount}
                      onChange={(e) =>
                        (entity.creditCustomerdaysCount = e.target.value)
                      }
                    />
                  </$cg>
                  <$cr n="2"> Credit Limit</$cr>
                  <div className="columns is-gapless">
                    <div class="column">
                      <div class="control">
                        <div class="select">
                          <select
                            className="select"
                            value={entity.creditLimitCurrencyType}
                            onChange={(e) =>
                              (entity.creditLimitCurrencyType = e.target.value)
                            }
                          >
                            {configStore.getCurrencyList().map((e) => (
                              <option value={e}>{e}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="control">
                        <input
                          style={{ width: "15rem" }}
                          type="number"
                          className="input"
                          value={entity.creditLimit}
                          onChange={(e) =>
                            (entity.creditLimit = e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="columns">
              <$cr n="2">Entity Admin</$cr>
              <$cg n="3">
                <select
                  className="select is-fullwidth"
                  value={entity.admin}
                  onChange={(e) => (entity.admin = e.target.value)}
                >
                  {cadminList.map((e) => (
                    <option value={e.email}>
                      {e.firstName + " " + e.lastName}
                    </option>
                  ))}
                </select>
              </$cg>
              <$cr n="2">Status</$cr>
              {domain === "agraga.com" ? (
                <$cg n="3">
                  {
                    <select
                      className="select is-fullwidth"
                      value={entity.status}
                      style={
                        !(
                          RoleUtils.isUserAuthorized(
                            PERMISSIONS.EDIT.ENTITIES_STATUS
                          ) || props.mode === "new"
                        )
                          ? { pointerEvents: "none", opacity: 0.6 }
                          : {}
                      }
                      onChange={(e) => (entity.status = e.target.value)}
                    >
                      <option value="TEMPORARY">TEMPORARY</option>
                      {(RoleUtils.isUserAuthorized(
                        PERMISSIONS.EDIT.ENTITIES_VERIFIED
                      ) ||
                        props.mode === "new" ||
                        entity.status == "VERIFIED") && (
                        <option value="VERIFIED">VERIFIED</option>
                      )}
                      {(RoleUtils.isUserAuthorized(
                        PERMISSIONS.EDIT.ENTITIES_KYCVERIFIED
                      ) ||
                        props.mode === "new" ||
                        entity.status == "KYC-VERIFIED") && (
                        <option value="KYC-VERIFIED">KYC-VERIFIED</option>
                      )}
                    </select>
                    // (entity.status !== 'KYC-VERIFIED') ?
                    //     <select className="select is-fullwidth" value={entity.status}
                    //         onChange={(e) => entity.status = e.target.value} >
                    //         <option value='TEMPORARY'>TEMPORARY</option>
                    //         <option value='VERIFIED'>VERIFIED</option>
                    //         <option value='KYC-VERIFIED'>KYC-VERIFIED</option>
                    //     </select>
                    //     : entity.status
                  }
                </$cg>
              ) : (
                <$cg n="3">{entity.status}</$cg>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <hr />
        <div className="columns">
          <$cr n="2">Track My Booking</$cr>
          <$cg n="4">
            <Agyesno
              record={entity}
              name="isTrackMyBooking"
              callback={(val) => {
                entity.isTrackMyBooking = val;
                if (val === "No") {
                  entity.isAirChecked = false;
                  entity.isOceanChecked = false;
                  entity.isDTChecked = false;
                }
              }}
            >
              {" "}
            </Agyesno>
          </$cg>
          {entityStore.entityRec.isTrackMyBooking === "Yes" && (
            <>
              <div
                class="column"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                {/* <$cg n='2'> */}
                <div>
                                <input type="checkbox" checked={entity.isAirChecked} onChange={(e) => {
                                    entity.isAirChecked = e.target.checked;
                                }} />
                                <label > Air</label>
                            </div>
                {/* </$cg> */}
                {/* <$cg n='2'> */}
                <div>
                  <input
                    type="checkbox"
                    checked={entity.isOceanChecked}
                    onChange={(e) => {
                      entity.isOceanChecked = e.target.checked;
                    }}
                  />
                  <label> Ocean</label>
                </div>
                {/* </$cg> */}
                {/* <$cg n='2'> */}
                {/* <div>
                            <input type="checkbox" checked={entity.isDTChecked} onChange={(e) => {
                                entity.isDTChecked = e.target.checked;
                            }} />
                            <label> Domestic Transport</label>
                            </div> */}
                {/* </$cg> */}
              </div>
            </>
          )}
        </div>

        <div className="columns">
          <$cr n="2">Is MSME ?</$cr>
          <$cg n="4">
            <Agyesno
              record={entity}
              name="msme"
              callback={(val) => {
                entity.msme = val;
              }}
            >
              {" "}
            </Agyesno>
          </$cg>
        </div>

        <div className="columns">
          <$c n="1"></$c>
          <$c n="9">
            <div className="table-container ">
              <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                <thead>
                  <tr>
                    <th style={{ width: "16rem" }}>
                      List of Required Documents
                    </th>
                    <th>Document Uploaded</th>
                    {entity.status !== "KYC-VERIFIED" ? (
                      <th style={{ width: "12rem" }}></th>
                    ) : (
                      <></>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {entity.documents.map((e, i) => (
                    <tr
                      id={attachmentNameMapping[e.name]}
                      className={attachmentNameMapping[e.name] + "###" + i}
                    >
                      <td>{e.name}</td>
                      <td>{renderLink(e.fileLink)}</td>
                      {entity.status !== "KYC-VERIFIED" ? (
                        <td>
                          <input
                            type="file"
                            onChange={(e1) => {
                              const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                              if (pattern.test(e1.target.files[0]["name"])) {
                                change(
                                  e1.target.files,
                                  attachmentNameMapping[e.name],
                                  i
                                );
                              } else {
                                configStore.setModalMessage(
                                  "Please Check File Name and Format"
                                );
                                return (e1.target.value = "");
                              }
                            }}
                          />
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </$c>
        </div>


{
    (entity && entity.gst !=undefined && entity.gst.length==15)?<>
    {
            (entity.api == undefined )?<div className="columns">
            <$c n="1"></$c>
            <$c n="9">
              <div className="table-container " style={{ textAlign: "center" }}>
                <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>
                        <strong>Api Key</strong>
                      </th>
                      <th style={{ textAlign: "center" }}>
                      <button class="button is-primary" onClick={()=>{
                        token()
                      }}>Generate</button>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </$c>
          </div>:
          <div className="columns">
          <$c n="1"></$c>
          <$c n="9">
            <div className="table-container " style={{ textAlign: "center" }}>
              <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>
                      <strong>Api Key</strong>
                    </th>
                    <th colspan="2" rowspan="1" style={{ textAlign: "center" }}>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{overflowWrap:"anywhere"}}>{entity.api.key}</td>
                    <td>{
                        entity.api.active=='Yes'?<button class="button is-info" onClick={()=>{
                            entity.api.active = "No"
                        }}>Disable</button>:<button class="button is-info" onClick={()=>{
                            entity.api.active = "Yes"
                        }}>Enable</button>
                        }
                    </td>
                    <td>
                      <button class="button is-danger" onClick={()=>{
                        delete entity.api
                      }}>Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </$c>
        </div>
        }
    </>:""
}
        
        <div className="columns">
          <$cr n="1"></$cr>
          <$c n="3">
            <button
              className="button is-primary"
              onClick={(e) => {
                console.log("Save clicked");
                if (entityStore.entityRec.clientId.length == 0) {
                  configStore.setModalMessage(`Entity Client ID is Mandatory.`);
                  return;
                }
                // if (!entityStore.entityRec.clientId.match(/^[A-Za-z0-9]{4}[0-9]{4}/)) {
                //     configStore.setModalMessage(`Client ID must contain 4 characters followed by 4 digits`);
                //     return;
                // }
                if (entityStore.entityRec.country.length <= 0) {
                  configStore.setModalMessage(`Entity Country is Mandatory.`);
                  return;
                }
                if (entityStore.entityRec.address === "") {
                  configStore.setModalMessage(`Please fill customer address.`);
                  return;
                }
                if (entityStore.entityRec.isCreditCustomer === "Yes") {
                  if (entityStore.entityRec.creditCustomerdaysCount < 1) {
                    configStore.setModalMessage(
                      `The days count shouldn't be less than 1 day.`
                    );
                    return;
                  }
                  if (entityStore.entityRec.creditCustomerdaysCount > 120) {
                    configStore.setModalMessage(
                      `The days count shouldn't be more than 120 days.`
                    );
                    return;
                  }
                  if (entityStore.entityRec.creditLimit < 1) {
                    configStore.setModalMessage(
                      `The credit limit should be more than 1.`
                    );
                    return;
                  }
                }
                if (entityStore.entityRec.isTrackMyBooking === "Yes") {
                  if (
                    !entityStore.entityRec.isAirChecked &&
                    !entityStore.entityRec.isOceanChecked &&
                    !entityStore.entityRec.isDTChecked
                  ) {
                    configStore.setModalMessage(
                      `Please select at least one Solution.`
                    );
                    return;
                  }
                }

                if (entityStore.entityRec.pan.length > 10) {
                  configStore.setModalMessage(`Invalid Pan Number.`);
                  return;
                }
                if (props.mode === "new") {
                  if (domain !== "agraga.com") entity.admin = loginStore.email;
                  EntityApi.createEntity();
                } else EntityApi.saveEntity();
              }}
            >
              Save
            </button>
          </$c>
        </div>
      </>
    );
}