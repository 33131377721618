import { configStore, aguserStore, routerStore } from "../state/CommonState"
import { useEffect, useRef, useState } from "react"
import AgModal from "../components/AgModal";
import AguserApi from "../state/AguserApi";
import { useSnapshot } from "valtio";
import { AguserType } from "../state/Types";
import Utils from "../util/Utils";

export default function AuditAgusers () {
    useSnapshot(aguserStore)
    useSnapshot(configStore)

    useEffect(() => {
        AguserApi.getAuditList()
    }, [])

    return (
        <>
 
            <h1 className="title">User Audit Trail</h1>

            <div className="table-container">
                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Action</th>
                            <th>Performed By</th>
                            <th>Performed On</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                       {
                           aguserStore.auditList.map((e,i) => {
                               return (
                                   <tr>
                                       <td  style={{whiteSpace: 'nowrap'}}>{Utils.formatToDateTime(e.createdOn, true)}</td>
                                       <td style={{whiteSpace: 'nowrap'}}>{e.action}</td>
                                       <td>{e.agent}</td>
                                       <td>{e.user}</td>
                                       <td>{JSON.stringify(e.data)}</td>
                                   </tr>
                               )
                           })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}