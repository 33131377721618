import { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import { bookingsStore, configStore, loginStore } from "../state/CommonState"
import Utils from "../util/Utils"
import TaskDatetime from "./task-datetime"
import Tasklistsmall from "./task-list-small"
import TaskStatusList from "./task-status-list"
import { taskStore } from "./task-store"
import Taskapi, { Task } from "./tasks-api"
import { getAction, getActionList, getActionListNames, getComponent } from "./tasks-static-data"
import { IconButton } from "@mui/material"
import backarrow  from "../assets/images/backarrow.svg";

export default function TaskContainer(props) {
    useSnapshot(taskStore)
    const [otherActions, setOtherActions] = useState([])
    const [openPending, setOpenPending] = useState(true)
    const [openStatus, setOpenStatus] = useState(false)
    let activeStyle = { backgroundColor: '#2c358f', color: 'white', border: '1px solid #2c358f' }
    let inactiveStyle = { backgroundColor: '#bbbbbb', color: 'white', border: '1px solid #bbbbbb' }
    let pendingStyle = inactiveStyle
    let completedStyle = inactiveStyle
    if (openPending === true) pendingStyle = activeStyle
    else completedStyle = activeStyle
    let active = 'none'
    if (taskStore.myActionVisible) active = 'block'
    let task = taskStore.current
    let action = getAction(task.actionName)
    let Comp = action?.component
    console.log('GOT COMPONENT=', Comp)
    useEffect(() => {
        let f = async () => {
            let ret = await Taskapi.getTasklist4Booking(task.bookingNum)
            console.log('RESULT OF BOOKINGS ACTIONS=', ret)
            if (loginStore?.isClient())
                ret = ret.filter(e => task._id.num !== e._id.num && e.customerStatus === 'PENDING')
            else
                ret = ret.filter(e => task._id.num !== e._id.num && e.status === 'PENDING')
            setOtherActions(ret)
        }
        f()
    }, [taskStore.current])

    useEffect(()=>{
     return ()=>{
        taskStore.myActionVisible = false;
        taskStore.isViewAll = false
     }
    },[])
    return (
        <div className="box" style={{ display: active, width: '100%' }}>
            <div >
                <div style={{ height: '70vh' }}>
                    <div className="columns">
                        <div className="column is-7">
                            <div  style={{display:"flex",flexDirection:"row",gap:1,alignItems:"center"}}>
                                {loginStore.isClient() && taskStore.isViewAll  && 
                                
                                <IconButton disableRipple onClick={()=>{
                                    if (taskStore.previous) {
                                        taskStore.current = taskStore.previous
                                        taskStore.previous = null
                                    }
                                    taskStore.myActionVisible = false
                                    taskStore.isViewAll = false
                               }}>
                                   <img src={backarrow}/>
                               </IconButton>
                                }
                                  <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                                {task.actionName}
                            </h5>
                            </div>
                            <div className="box">
                                <tr>
                                    <td><strong>Booking ID</strong></td>
                                    <td style={{ width: '20px' }}></td>
                                    <td>{task.bookingNum}</td>
                                </tr>
                                <tr>
                                    <td>{task.origin}</td>
                                    <td style={{ fontSize: '1.2rem', fontWeight: 'bold' }}> &#8594; </td>
                                    <td>{task.destination}</td>
                                </tr>
                                <tr>
                                    <td>{task.shipmentType}</td>
                                    <td style={{ width: '20px' }}></td>
                                    <td>{task.shipmentScope}</td>
                                </tr>
                                {
                                    (task.dueDate) ?
                                        <tr>
                                            <td>Due Date & Time</td>
                                            <td style={{ width: '20px' }}></td>
                                            <td>{Utils.formatToDateTime1(task.dueDate, task.dueTime)}</td>
                                        </tr>
                                        : <></>
                                }
                            </div>
                            {
                                (!loginStore.isClient() && task.pendingWith === 'CUSTOMER') ? <>
                                    <button style={{ backgroundColor: "#2c358a", }} className="button is-link" onClick={() => {
                                        Taskapi.sendActionTriggerEmail()
                                        taskStore.setModalMessage('Email send successfully')
                                    }}>
                                        Retrigger Mail
                                    </button>&nbsp;&nbsp;
                                </> : <>

                                </>
                            }
                            {/* {
                                (!loginStore.isClient() && task.updatelist!=undefined && task.updatelist.length>0)?
                                <div className="box">
                                <div>
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                   <tr>
                                        <th>{(task.updatelist[0]['status'].toUpperCase()=='CREATED' || task.updatelist[0]['status'].toUpperCase()=='AGRAGA')?<>Agraga</>:<>Customer</>} Completion</th>
                                        <td>{task.updatelist[0]['updatedBy']}</td>
                                        <td>{Utils.formatToDateTime(Number(task.updatelist[0]['updatedOn']))}</td>
                                        
                                    </tr>
                                
                                
                                </table>
                                </div>
                                </div>:<></>
                            } */}

                        </div>
                        <div className="column is-5">
                           { !loginStore.isClient()  && <button style={{ float: 'right' }} className="button is-small islink"
                                onClick={async (e) => {
                                    if (taskStore.previous) {
                                        taskStore.current = taskStore.previous
                                        taskStore.previous = null
                                    }
                                    taskStore.myActionVisible = false
                                    taskStore.isViewAll = false
                                }}>
                                <span className="icon ">
                                    <i className="fas fa-xmark"></i>
                                </span>
                            </button>
}
                            <hr />

                            <div>
                                {/* {
                                (!loginStore.isClient())?<>
                            <button className="button is-link" onClick={()=>{
 Taskapi.sendActionTriggerEmail()
 taskStore.setModalMessage('Email send successfully')
                            }}>
                                Retrigger Mail
                            </button>&nbsp;&nbsp;
                            </>:<>

</>
} */}
                                {/* <button className="button is-link" onClick={()=> {
                            if (openStatus) setOpenStatus(!openStatus)
                            setOpenPending(!openPending)
                        }} >
                                Pendings
                            </button>&nbsp;&nbsp; */}
                                {/* <button className="button is-link" onClick={()=> {
                                if (openPending) setOpenPending(!openPending)
                                setOpenStatus(!openStatus)
                            }} >
                                Status
                            </button> */}
                            {
                                (loginStore.isClient())?<div>
                                <button className="button is-link"  style={{backgroundColor:"#2c358a"}} onClick={()=> {
        setOpenPending(!openPending)
    }} >
                                    Pendings
                                </button>
                                </div>:<div className="column is-6" style={{ width: "100%" }} >
                                    <span >
                                        <button className="button is-small"
                                            onClick={() => {
                                                if (openStatus)
                                                    setOpenStatus(!openStatus)
                                                setOpenPending(true)
                                            }}
                                            style={{
                                                borderRadius: '25px 0px 0px 25px', width: "50%",
                                                ...pendingStyle
                                            }}>Pendings</button>
                                    </span>
                                    <span>
                                        <button className="button is-small"
                                            onClick={() => {
                                                if (openPending) setOpenPending(!openPending)
                                                setOpenStatus(true)
                                            }}
                                            style={{
                                                borderRadius: '0px 25px 25px 0px', width: "50%",
                                                ...completedStyle
                                            }}>Status</button>
                                    </span>
                                </div>
                            }
                                
                            </div>


                            <hr />
                            {
                                (openPending ? <Tasklistsmall actionList={otherActions} /> : <></>)
                            }
                            {
                                (openStatus ? <TaskStatusList /> : <></>)
                            }

                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-11">
                            {
                                (Comp) ? <Comp action={action} /> : <></>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}