import { useSnapshot } from "valtio"
import { useState, useEffect } from "react"
import on from './on2.png'
import off from './off.png'
import { modalStore, containerStore } from "../state/CommonState"
import { enquiryStore } from "../enquiry/enquiry-data"

export function Agyesno(props) {
    const { label, record, name, callback = f => f , isDisabled = false } = props
   // const imRecord = useSnapshot(record?record:{})
    const [checked1, setChecked] = useState(record?record[name]:props.value)
    useSnapshot(enquiryStore)
    if (record) {
        if (record[name] !== checked1) setChecked(record[name])
    }

    return (
        <div className="field" style={{textAlign: 'center'}}>
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control" style={{cursor : isDisabled ? "not-allowed" : "default"}}>
                    <div onClick={ isDisabled ? 
                       null :
                        (() => {
                            enquiryStore.current.containerDetails = [{containerType:"",numContainers:0,unit:"Kgs",wtPerContainer:0}]
                            console.log('inside yesno')
                            if (props.disabled) return
                            const newval = checked1 === 'Yes'? 'No' : 'Yes'
                            if (props.record) record[name] = newval
                            console.log('inside again', newval)
                            callback(newval)
                            setChecked(newval)
                            // console.log("newval",newval,label)
                            if(label === 'Hazardous' && newval === 'Yes'){
                                modalStore.disableTemp = true;
                                // modalStore.disablehaz = false
                            }
                            if(label === 'Hazardous' && newval === 'No'){
                                modalStore.disableTemp = false;
                                // modalStore.disablehaz = true
                            }
                            if(label === 'Temperature Controlled?' && newval === 'Yes'){
                                containerStore.resetNewRec()
                                modalStore.disablehaz = true;
                                // modalStore.disableTemp = false
                            }
                            if(label === 'Temperature Controlled?' && newval === 'No'){
                                containerStore.resetNewRec()
                                modalStore.disablehaz = false;
                                // modalStore.disableTemp = true
                            }
                            if(name === 'reworking_at_pol' && newval === 'Yes'){
                                modalStore.enablerwp = true
                            }
                            if(name === 'reworking_at_pol' && newval === 'No'){
                                modalStore.enablerwp = false
                            }
                    })}>
                        {
                            (checked1 === 'Yes') ?
                                <img height='36px' width='36px' src={on}></img> :
                                <img height='36px' width='36px' src={off}></img>
                        }
                    </div>
            </div>
        </div>
    )
}