import { useState } from "react";
import { useSnapshot } from "valtio";
import { dtactionStore } from "./dtaction-store";
import MonApi from "../state/mongo-api";
import EntityApi from "../state/entities-api";
import { configStore } from "../state/CommonState";
import Utils from "../util/Utils";
import { Col1, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls";
import { loader } from "../util/loader";

export default function DtactionPayout() {
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState(0);
  const [loading, setloading] = useState(false);

  useSnapshot(dtactionStore);
  let booking = dtactionStore.current.booking;
  let modalClass = "modal";
  if (dtactionStore.modalVisible1) modalClass += " is-active";
  let balancePaymentDue = Utils.toNum(booking.vendor_agreed_cost) +
  Utils.toNum(booking.vendor_additional_cost) -
  Utils.toNum(booking.advancePayment)
  const save = async () => {
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/dtactions`;
    console.log("\n\nVALUE OF SUBMIT CALLBACK=", dtactionStore.submitCallback);
    if (dtactionStore.submitCallback()) {
      let ret = await MonApi.apost(url, dtactionStore.current, "PUT");
      dtactionStore.view = "list";
      dtactionStore.listLoadCounter++;
    }
  };

  function checkValue(event) {
    setAmount(handleDecimalsOnValue(event.target.value));
}

function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
}

  const makePayment = async (entity) => {
    if (amount < 1) {
      configStore.setModalMessage(
        "Minimum Transaction Amount Should Be Greater Than 1 Rupee.."
      );
      setAmount(0);
      return 0;
    }
    if (amount % 1 != 0) {
      if (amount?.split(".")[1].length > 2) {
        configStore.setModalMessage(
          "Transaction amount should be two  numbers after decimal point."
        );
        setAmount(0);
        return 0;
      }
    }

    if (dtactionStore.current._id.actionName == "Initiate Advance") {
      if (
        amount >
        (dtactionStore.current.booking.vendor_agreed_cost * 110) / 100
      ) {
        configStore.setModalMessage(
          `Transaction Amount Should Not Be Greater Than ${
            (dtactionStore.current.booking.vendor_agreed_cost * 110) / 100
          }`
        );
        setAmount(0);
        return 0;
      }
    }
    if (dtactionStore.current._id.actionName == "Balance Payment") {
      if (
        amount > balancePaymentDue
      ) {
        configStore.setModalMessage(
          ` Transaction Amount Should Not Be Greater Than ${
           balancePaymentDue
          }`
        );
        setAmount(0);
        return 0;
      }
    }

    dtactionStore.modalVisible1 = false;
    loader.show()
    console.log("about to Payment api call.", entity);

    let entityData = await EntityApi.viewEntity(entity.id);
    let paymentData = {
      reference_id: entity.id,
      account_number: entityData.vendor.accountNo,
      fund_account_id: entityData.funded_Id,
      amount: amount,
      booking_id: booking._id,
      action_name: dtactionStore.current._id.actionName,
      remarks: dtactionStore.current.remarks,
    };
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/razorpay/payout`;
    let payoutData = await MonApi.apost(url, paymentData);
    if (payoutData.status == "success") {
      loader.hide()
      configStore.setModalMessage("Payment has been done successfully..");
      if(payoutData.results.utr==null){
        payoutData.results.utr="0"
      }
      dtactionStore.current.paymentAmt = payoutData.results.amount / 100;
      dtactionStore.current.utrNo = payoutData.results.utr;
      dtactionStore.current.paymentDate = new Date()
        .toISOString()
        .split("T")[0];
      save();
      setAmount(0);
    
    } else if (payoutData.status == "failed") {
      loader.hide()

      configStore.setModalMessage(payoutData?.error?.description);
      //dtactionStore.current.paymentAmt = 0;
      setAmount(0);

      
    }
    // await Api.post(url,paymentData, (data) => {
    //   console.log("data",data)
    //   dtactionStore.setModalMessage("Payment has been done successfully..");
    //   // if (!data.branch.length > 0) {
    //   //   branchStore.BranchRec.set(new BranchType());
    //   //   let branchh = branchStore.BranchRec;
    //   //   branchh.country = data.entityCountry;
    //   //   data.branch.push(branchh);
    //   // }
    //   // branchStore.BranchRec.set(BranchType)

    //   //EntityV2Store.EntityRec.set(data);

    // });
  };

  const handleChange = (event) => {
    const { value } = event.target;
  };

  return (
    <div
      className={modalClass}
      // onClick={() => {
      //   dtactionStore.modalVisible1 = false;
      // } }
    >
      <div className="modal-background"></div>
      <div
        className="modal-card"
        onClick={(e) => {
          e.stopPropagation();
        }}

        style={{ borderRadius: "10px" }}
      >
        <section className="modal-card-body" >
          <Cols>
            <Col3 style={{ color: "darkblue" }}>Payment Amount :</Col3>
            <Col5>
              <div class="field">
                <div class="input-d-wrapper">
                  <input
                    className="input"
                    type="number"
                    value={amount}
                    onChange={(event) => checkValue(event, 'change')}
                    onKeyDown = {
                      (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                  />
                  <div class="input-dropdown">
                    INR
                    {/* <img
                      src="./assets/chevron-white.png"
                      alt="chevron"
                      class="chevron-3"
                    /> */}
                    {/* <div class="currency-div d-none">
                      <ul>
                        <li>INR</li>
                        <li>USD</li>
                        <li>DNR</li>
                        <li>EUR</li>
                        <li>DIR</li>
                        <li>QSD</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </Col5>
          </Cols>
          <Cols>
            <Col3 style={{ color: "darkblue" }}>Remarks :</Col3>
            <Col5>
              <textarea
                className="textarea"
                rows="5"
                value={dtactionStore.current.remarks}
                onChange={(ev) => {
                  dtactionStore.current.remarks = ev.target.value;
                }}
              ></textarea>
            </Col5>
          </Cols>
          <br></br>
          <button
            class="button is-danger"
            style={{ float: "right" }}
            onClick={async () => {
              makePayment({
                id: dtactionStore.current.vendor.split(" (")[0],
              });
            }}
            // type="submit" value={dtactionStore.currentMainComponent = <HomeMain/>}
          >
            Submit
          </button>
          <button
            class="button is-warning"
            onClick={() => (                                    
              dtactionStore.modalVisible1 = false,dtactionStore.current.remarks='',setAmount(0))}
          >
            Cancel
          </button>
        </section>
      </div>
    </div>
  );
}


