import { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import { rtpStore } from "./pricing-store"
import { bookingsStore, configStore } from "../state/CommonState"
import { TransportTable1 } from "../enquiry/LCLChargeClientView"

let enabledCharges = [
    'Origin Customs Clearance',
    'Destination Customs Clearance',
    'Pick-up Charges',
    'Delivery Charges'
]
export default function ChargeTag({ charge, subscript, callback = f => f }) {
    useSnapshot(rtpStore)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    let e = charge
    let rec
    if (subscript >= 0) rec = rtpStore.records[subscript].dummyContract
    //console.log('\n\n\n CHARGES LIST=', rec)
    let [included, setincluded] = useState(e.isIncluded)
    let buttonClass = 'button is-small '
    let buttonColor = 'is-success'
    if (included != 'Yes') {
        buttonColor = 'is-dark'
    }
    useEffect(() => {
        //e.isIncluded = included
        if (rec) {
            let elem = rec.chargesList.find(e1 => e.chargeDescription == e1.chargeDescription)
            if (elem && elem.isIncluded != included) {
                elem.isIncluded = included
                let erate = configStore.getExchangeRate(elem.currency)
                if (!erate || erate === 0) erate = 1.0
                let amt = elem.total * erate
                if (included != 'Yes') amt *= -1
                rec.quoteValue += amt
                let usdRate = configStore.getExchangeRate('USD')
                rec.quoteValueUSD = rec.quoteValue / usdRate
                bookingsStore.current.contract.quoteValue = rec.quoteValue
                bookingsStore.current.contract.quoteValueUSD = rec.quoteValueUSD
            }
        }
        console.log('e = ', e)
    }, [included])
    return (
        <span className="tag is-link is-medium" style={{ zoom: '0.9' }}>
            {
                (e.transportRec) ?
                    <div className="transporttooltip">{e.chargeDescription}
                        <span className="transporttooltiptext"><TransportTable1 data={e.transportRec} /> </span>
                    </div>
                    :
                    e.chargeDescription
            }
            {
                enabledCharges.includes(e.chargeDescription) && rec ?
                    <button className={buttonClass + buttonColor}
                        style={{ borderRadius: '50%', zoom: '0.8', marginLeft: '3px' }}
                        onClick={() => {
                            setincluded(included == 'Yes' ? 'No' : 'Yes')

                        }}
                    >
                        <span className="icon is-small" >
                            {
                                included == 'Yes' ?
                                    <i className="fas fa-check is-small"></i>
                                    :
                                    <i className="fas fa-xmark is-small"></i>
                            }
                        </span>
                    </button> :
                    <></>
            }
        </span>
    )
}