import { useSnapshot } from "valtio";
import { Agyesno } from "../components/Agyesno";
import { configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
let server = process.env.REACT_APP_API_SERVER

export function Taskfiledisplay1(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    let docs = task.files
    if (!docs) docs = []
    console.log('*************', task)
    const displayApproved = (status) => {
        if (status.toLowerCase() === 'Approve') return 'Approved'
        if (status.toLowerCase() === 'Reject') return 'Rejected'
        return ''
    }
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    <th>Document</th>
                    <th>Link</th>
                    <th></th>
                </tr>
                {

                    (stage==12)?<tr style={{ padding: '0.5rem' }}>
                    <td>{docs[0].label}</td>
                    <td >
                        {
                            (docs[0].filename) ?
                                <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + docs[0].filename}>
                                    {docs[0].filename}
                                </a> : <></>
                        }
                    </td>
                    <td>
                        {displayApproved(docs[0].approved)}
                    </td>
                    <td >
                        {
                            (docs[0].approved === 'Reject') ?
                            docs[0].rejectReason : ''
                        }
                    </td>
                </tr>:<></>
}{


                (stage==13)?<>
                {/* <tr style={{ padding: '0.5rem' }}>
                <td>{docs[1].label}</td>
                <td >
                    {
                        (docs[1].filename) ?
                            <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + docs[1].filename}>
                                {docs[1].filename}
                            </a> : <></>
                    }
                </td>
                <td>
                    {displayApproved(docs[1].approved)}
                </td>
                <td >
                    {
                        (docs[1].approved === 'Reject') ?
                        docs[1].rejectReason : ''
                    }
                </td>
            </tr> */}
            <tr style={{ padding: '0.5rem' }}>
                    <td>{docs[0].label}</td>
                    <td >
                        {
                            (docs[0].filename) ?
                                <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + docs[0].filename}>
                                    {docs[0].filename}
                                </a> : <></>
                        }
                    </td>
                    <td>
                        {displayApproved(docs[0].approved)}
                    </td>
                    <td >
                        {
                            (docs[0].approved === 'Reject') ?
                            docs[0].rejectReason : ''
                        }
                    </td>
                </tr></>:<></>
                }
            </table>
            <hr />
        </div>
    )
}

export default function Taskfiledisplay(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    let docs = task.files
    if (!docs) docs = []
    console.log('*************', task)
    const displayApproved = (status) => {
        if (status.toLowerCase() === 'Approve') return 'Approved'
        if (status.toLowerCase() === 'Reject') return 'Rejected'
        return ''
    }
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    <th>Document</th>
                    <th>Link</th>
                    <th></th>
                </tr>
                {
                    docs.map(e => <tr style={{ padding: '0.5rem' }}>
                        <td>{e.label}</td>
                        <td >
                            {
                                (e.filename) ?
                                    <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                        {e.filename}
                                    </a> : <></>
                            }
                        </td>
                        <td>
                            {displayApproved(e.approved)}
                        </td>
                        <td >
                            {
                                (e.approved === 'Reject') ?
                                    e.rejectReason : ''
                            }
                        </td>
                    </tr>)
                }
            </table>
            <hr />
        </div>
    )
}
