import { Box, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { EnquiryDetailsView } from "../mui-components/EnquiryDetailsView";
import { PricingDetailsCard } from "../mui-components/PricingDetailsCard";
import { AntSwitch } from "../mui-components/AntSwitch";
import { breadCrumbStore, enquiryState } from "../state/CommonState";
import { useState } from "react";
import arrowdown from "../assets/images/arrowdown.svg";
import closeIcon from "../assets/images/close.svg";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
import Utils from "../util/Utils";
import { useSnapshot } from "valtio";
import { enquiryStore } from "../enquiry/enquiry-data";

function CargoAttributeBox({title,value}){
    return <Box sx={{display:"flex",flexDirection:"column",gap:0.5,width:"150px"}}>
        <Typography sx={{fontFamily:"Figtree",fontSize:"12px",fontWeight:"bold",color:"#2A6ED4"}}>{title}</Typography>
        <Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"#555555"}}>{value}</Typography>
    </Box>
}

function ServiceSwitch({ title }) {
    return <span style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}><Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{title}</Typography><AntSwitch style={{ scale: "0.8" }} /></span>
}

const PriceDetailsFCL = ["Your Account","Shipper's Account"];

function FclPricingDetails(){
    const [selected,setSelect] = useState(0);
    return <Box sx={{display:"flex",flexDirection:"column",width:"40%",height:"100%",gap:1,overflowY:"auto"}}>
       {PriceDetailsFCL.map((priceDetail,i)=><Box  sx={{display:"flex",flexDirection:"column",borderRadius:"5px",backgroundColor:'white'}}>
            <PricingDetailsCard title={priceDetail} isCollapsable={true} isOpen={selected===i} onClick={()=>{
           if(selected===PriceDetailsFCL.length-1){
            setSelect(0)
           }else{
            setSelect(1)
           }
       }}/>
       </Box>
       )}
    </Box>
}

function BookingConfirmationDialog({open,handleClose}){
    return <Dialog open={open}>
        <DialogContent sx={{backgroundColor:"#F5F5F5"}}>
            <Box sx={{display:"flex",flexDirection:'column'}}>
                <Box onClick={()=>{
                    handleClose();
                }} sx={{display:"flex",alignSelf:"end",justifyContent:"center",alignItems:"center",backgroundColor:"#2B6ED4",borderRadius:"5px",width:"max-content",padding:"8px","&:hover":{
                    cursor : "pointer"
                }}}>
                    <img style={{width:"18px",height:"18px"}} src={closeIcon} />
                </Box>
                <Box sx={{display:"flex",flexDirection:'column',gap:2,padding:"10px 20px"}}>
                <Box sx={{display:"flex",flexDirection:'column',alignItems:"center",gap:1}}>     
                <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",color:"black"}}>Your Booking has been placed successfully</Typography>
                <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",color:"black"}}>Booking Id</Typography>
                </Box>
                <Box sx={{display:"flex",flexDirection:'column',alignItems:"start",gap:1}}>    
                <Typography sx={{fontFamily:"Figtree",color:"#555555"}}>Enter Customer Reference</Typography>
                <CustomStyledTextField size="small" fullWidth/>
                </Box>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions sx={{backgroundColor:"#F5F5F5"}}>
            <Button onClick={()=>{
                handleClose();
            }} variant="contained" sx={{color:"#2B6ED4", textTransform : "capitalize",
                fontFamily:"Figtree",backgroundColor:"white","&:hover":{
                backgroundColor : "white"
            }}}>Add Later</Button>
            <Button variant="contained" sx={{  textTransform : "capitalize",
                fontFamily:"Figtree"}}>Save</Button>
        </DialogActions>
    </Dialog>
}

function CalculateContainer(containerdetails){
    const containerSummary = { containers : [], wtPerContainer : 0 }

    containerdetails.forEach((container)=>{
        containerSummary.containers.push(`${container.numContainers} x ${container.containerType}`)
        containerSummary.wtPerContainer += parseInt(container.wtPerContainer)
    })

    containerSummary.wtPerContainer = Utils.R(
        containerSummary.wtPerContainer
      );
      return containerSummary;
}






export function PricingDetailsPage(){
    const [open,setOpen] = useState(false);
    const enquiry = useSnapshot(enquiryStore);
    let mode = enquiry.current.shipmentType.toLowerCase();
    let calculatedCargo; 
    if(mode==="air"){
        calculatedCargo = Utils.AirCargoCalculation(enquiry.current.cargoDetails);
    }else{
        calculatedCargo =  Utils.CalculateCargo(enquiry.current.cargoDetails); 
    }
    const vehicles = Utils.VehicleAggregation(enquiry.current.vehicles);
    const calculatedContainers = CalculateContainer(enquiry.current.containerDetails);
    return <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", gap: 1 }}>
    <EnquiryDetailsView/>
    <Box sx={{display:'flex',flexDirection:"row",width:"100%",gap:1,height:"80%"}}>
        <Box sx={{width:"60%",height:"100%",display:"flex",flexDirection:"column",gap:1}}>
            <Box sx={{backgroundColor:"white",borderRadius:"10px",height:"75%",width:"100%",display:"flex",flexDirection:"column",justifyContent:"space-around",paddingX:"14px"}}>
            { enquiryState.mode.toLowerCase() === "fcl" && <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
            <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>Container Details</Typography>
            <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"start",flexWrap:"wrap",rowGap:1}}>
            <CargoAttributeBox title="Container Type" value={calculatedContainers.containers.join(", ")} />
                        <CargoAttributeBox title="Total Volume" value={`${calculatedContainers.wtPerContainer} ${enquiry.current.containerDetails[0]?.unit}`} />
            </Box>
            </Box> }
                <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
                    <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>Cargo Det</Typography>
                    <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",flexWrap:"wrap",rowGap:0.8}}>
                        { mode === "air" && <CargoAttributeBox title="Package Type" value={calculatedCargo?.productType?.join(", ")} />}
                        <CargoAttributeBox title="Total Quantity" value={calculatedCargo.quantity} />
                        <CargoAttributeBox title="Total Weight" value={`${calculatedCargo.totalWeight} ${ mode === "air" ? enquiry.current.cargoDetails[0].weight.unit : enquiry.current.cargoDetails[0]?.totalWeight?.unit}`} />
                       { mode === "air" ? <CargoAttributeBox title="Dimensions" value={`${calculatedCargo?.dimensions?.totalLength} x ${calculatedCargo?.dimensions?.totalWidth} x ${calculatedCargo?.dimensions?.totalHeight} ${enquiry.current.cargoDetails[0]?.dimensions?.unit}`} /> :  <CargoAttributeBox title="Total Volume" value={`${calculatedCargo.totalVolume} ${enquiry.current.cargoDetails[0]?.totalVolume?.unit}`} />}
                        <CargoAttributeBox title="Hazardous" value={enquiry.current.hazardous} />
                        <CargoAttributeBox title="Non-Stackable" value={enquiry.current.nonStackable} />
                        { (mode === "fcl" || mode === "air" ) && <>
                        <CargoAttributeBox title="Temp. Controlled" value={enquiry.current.temperatureControlled} />
                        </>
                        }
                       {  mode === "fcl" && <><CargoAttributeBox title="Stuffing Type" value={enquiry.current.stuffingType} />
                        <CargoAttributeBox title="De-Stuffing Type" value={enquiry.current.destuffingType} />
                        </>}
                    </Box>
                </Box>
                <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
                    <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>Vehicle Details</Typography>
                    <Box sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",justifyContent:"start"}}>
                        {vehicles.map((vehicle)=> <Typography sx={{fontFamily:"Figtree",color:"#555555",flexBasis:"150px",fontSize:"12px"}}>{vehicle}</Typography>)}
                        </Box> 
                </Box>
            </Box>
            <Box sx={{backgroundColor:"white",borderRadius:"10px",height:"25%",width:"100%",flexDirection:"column",display:"flex",justifyContent:"space-evenly",paddingX:"12px"}}>
              <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>Added Services</Typography>
              <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-around",alignItems:"center"}}>
                <ServiceSwitch title="Marine Finance" />
                <ServiceSwitch title="Trade Finance" />
                <ServiceSwitch title="Ship-Now-Pay-Later" />
              </Box>
            </Box>
        </Box>
        { enquiryState.mode.toLowerCase() === "fcl" ?  <FclPricingDetails/> : <Box sx={{width:"40%",height:"100%",borderRadius:"10px",padding:"8px",backgroundColor:"white"}}>
      <PricingDetailsCard/>
    </Box>}
    </Box>
    <Box sx={{display:"flex",flexDirection:'row',width:"100%",justifyContent:"start",flex:1,alignItems:"center"}}>
      <Box sx={{display:"flex",flexDirection:"row",gap:1,alignItems:"center"}}><Checkbox sx={{padding:0}} /><span sx={{fontSize:"14px"}}>I Accept Terms & Conditions</span></Box>
      <Box sx={{display:"flex",flexDirection:"row",gap:1}}>
        <Button variant="contained" size="small" sx={{backgroundColor:"white",color:"#2B6ED4",textTransform:"capitalize",fontSize:"12px"}} >Save Contract</Button>
        <Button onClick={()=>{
            // setOpen(true);
            breadCrumbStore.addCrumb("Booking Details")
        }} variant="contained" size="small" sx={{textTransform:"capitalize",fontSize:"12px"}}>Proceed to booking</Button>
      </Box>
    </Box>
    <BookingConfirmationDialog open={open} handleClose={()=>{
        setOpen(false);
    }} />
    </Box>
}