import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query";


import { AgragaDTBooking, AgragaDtlist, ClinetDTBooking } from "../components/ag-table/columns";

import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";

import { Sorting } from "../components/ag-table/ag-sorting";

import { reducer, PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAGE_FILTER_CHANGED, TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED } from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import { configStore, customerStore, entityStore, loginStore } from "../state/CommonState";
import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import { loader } from "../util/loader";
import DtApi from "../state/dt-api";
import { DtMap } from "./DtMap";
import Utils from "../util/Utils";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import { data } from "../charts/c1";
import EntityApi from "../state/entity-api";

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

const DataTable = (props) => {
    useSnapshot(loginStore)
    useSnapshot(DtStore)
    useSnapshot(customerStore)
    useSnapshot(configStore)
    useSnapshot(loader)
    useSnapshot(entityStore)
    let title = 'All Bookings'
    const [reason, setReason] = useState("");
    if (loginStore.isClient()) title = 'My Bookings'
    const tablist = ['Shipments In-Progress', 'Archives', 'Cancelled']
    let modalClass = 'modal'
    if (DtStore.modalVisible) modalClass += ' is-active'
    let status = 'Shipments In-Progress'
    switch (DtStore.btabview) {
        case 'Shipments In-Progress': status = 'INPROGRESS'; break;
        case 'Archives': status = 'ARCHIVED'; break;
        case 'Cancelled': status = 'CANCELLED'; break;
    }

    let column = {}

    if (!loginStore.isClient()) {
        column = AgragaDTBooking
    } else {
        column = ClinetDTBooking
    }


    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }
    let columns = column
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState);

    useEffect(() => {
        ApiAgTable.dtBookingData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res => {
            res.clone().json().then((res) => {
                if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
                    if (JSON.stringify(data1) != JSON.stringify(res)) {
                        setData1(res)
                    }
                }
            }, err => {
                console.log(err)
            })
        })


    }, [queryPayload, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy , configStore.currentSelectedEntity , configStore.currentSelectedBranch])

    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );


    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }, [keyword, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { status: status } });
        gotoPage(0);
    }, [columns, DtStore.btabview]);

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);

    const addre = (d) => {
        let a = d.split('[')
        if (a.length > 1) {
            a = a[1].split(']')
            if (a.length > 1) {
                return a[0]
            } else {
                return d
            }
        } else {
            return d
        }
    }

    const declinesubmit = () => {
        loader.show()
        DtApi.postCancelDtBooking({id:DtStore.cancelid,reason:reason},cancelmsg)
        DtStore.modalVisible = false
    }
    
    const cancelmsg = ()=>{
        loader.hide()
        setReason("")
        configStore.setModalMessage("Booking is Cancelled.")
        DtStore.btabview = "Cancelled"
    }
    
    function checkEntityStatus(status){
        let isStatusAvail = false; 
        if(status === "VERIFIED") {
          isStatusAvail= true
          return isStatusAvail
        }  
        return isStatusAvail  
       }



    return (
        <>
        <DtMap />

            <nav className="level">
                <div class="level-left">
                    <div class="level-item">
                        <p class="title is-4" style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>
                            {title}
                        </p>
                    </div>
                    <div class="level-item">
                        <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                    </div>
                </div>
            </nav>
            <div id="quotelistclient" className="tabs is-boxed" style={{ marginBottom: '0   px', backgroundColor: '#2c358a' }}>
                <ul>
                    {
                        tablist.map(e => {
                            let className = ''
                            let styles = { color: 'lightgrey' }
                            if (e === DtStore.btabview) {
                                className = 'is-active'
                                styles = { backgroundColor: '#eea724', color: 'black' }
                            }
                            styles.paddingTop = '5px'
                            styles.paddingBottom = '5px'
                            styles.paddingRight = '15px'
                            return <li className={className} onClick={() => {
                                DtStore.btabview = e
                            }}>
                                <a style={styles}><span>{(e=='Archives'?'Completed':e)}</span></a>
                            </li>
                        })
                    }
                </ul>
            </div>

            <div className="box">
                <div className="table-container" style={{ overflow: 'initial' }}>
                    <table className="table is-fullwidth is-small ">
                        <thead className="is-scrollable-header has-background-white-ter is-unselectable">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (

                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <Sorting column={column} />
                                        </th>

                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="contractlist" style={{ fontSize: '0.9rem' }} {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                    >
                                        {
                                            row.cells.map(cell => {
                                                {
                                                    let e = cell['row']['original']
                                                    switch (cell['column']['Header']) {
                                                        case "Booking Date":
                                                            return <td>{Utils.ddmmyyyystring(e.DtBookings.bookingdate)}</td>
                                                        case "Pickup Date":
                                                            return <td>{Utils.ddmmyyyystring(e.DtBookings.pickupdate)}</td>
                                                        case "Vehicle Number":
                                                            return <>{
                                                                <td>
                                                                    
                                                                    <span title={e.DtBookings.vehicle}>{e.DtBookings.vehicle_no}</span>
                                                                </td>
                                                            }</>
                                                        case "Customer Name":
                                                            return <>{
                                                                <td>
                                                                     <span title={e?.SHEntities[0]?.entityName}>{e?.SHEntities[0]?.entityName}</span>
                                                                </td>
                                                            }</>
                                                        case "Branch Name":
                                                            return <>{
                                                                <td>
                                                                     <span title={e?.SHBranches?.state?.split("/")[0]}>{e?.SHBranches?.state?.split("/")[0]} - {e?.SHBranches?.gst}</span>
                                                                </td>
                                                            }</>
                                                        case "Booking ID":
                                                            return <td>
                                                                <a
                                                                    target="_blank" onClick={(event) => {
                                                                        DtStore.DTBooking = row.original.DtBookings
                                                                        DtStore.bview = "edit"
                                                                        DtStore.valid = false
                                                                        configStore.dtCurrentView = 'Booking'
                                                                    }}
                                                                >{e.DtBookings._id}</a>
                                                            </td>
                                                        case "Status":
                                                            return <>
                                                                
                                                                    {
                                                                        (e.DtBookings.publiclink && e.DtBookings.publiclink.length>0)?
                                                                        <td>
                                                                            <a
                                                                    // target="_blank" href={e.DtBookings.publiclink}
                                                                    onClick={()=>{
                                                                        DtStore.MapUrl = e.DtBookings.publiclink
                                                                        DtStore.modalVisible2 = true
                                                                    }}
                                                                >{e.DtBookings.status}</a>
                                                                        </td>
                                                                        :
                                                                        <td>{e.DtBookings.status}</td>
                                                                    }
                                                                

                                                            
                                                            </>
                                                        case "Pick-up Address":
                                                            return <>
                                                                {
                                                                    <td>
                                                                        {
                                                                            (e.DtBookings.details[0]['pickup_2'] != undefined && e.DtBookings.details[0]['pickup_2'].trim().length>0)?<>
                                                                            {addre(e.DtBookings.details[0]['pickup'])}
                                                                            <hr className="m-0" />
                                                                            {addre(e.DtBookings.details[0]['pickup_2'])}
                                                                            </>:<>
                                                                            {addre(e.DtBookings.details[0]['pickup'])}
                                                                            </>
                                                                        }
                                                                        
                                                                    </td>
                                                                }
                                                            </>
                                                        case "Delivery Address":
                                                            return <>{
                                                                <td>
                                                                    {
                                                                        e.DtBookings.details[0]['delivery'].map((v, i) => {
                                                                            if (i < e.DtBookings.details[0]['delivery'].length - 1) {
                                                                                return <>
                                                                                    {addre(v.address)}
                                                                                    <hr className="m-0" />
                                                                                </>
                                                                            } else {
                                                                                return <>
                                                                                    {addre(v.address)}
                                                                                </>
                                                                            }
                                                                        })
                                                                    }
                                                                </td>

                                                            }
                                                            </>
                                                        case "Actions":
                                                            return <td>
                                                                <div
                                                                    class='dropdown post-options is-right is-hoverable '
                                                                    onClick={null}
                                                                >
                                                                    <div class='dropdown-trigger'>
                                                                        <button
                                                                            class='button'
                                                                            aria-haspopup='true'
                                                                            aria-controls='dropdown-menu-post'
                                                                        >
                                                                            <span class='icon is-small'>
                                                                                <i
                                                                                    class='fas fa-ellipsis-h'
                                                                                    aria-hidden='true'
                                                                                ></i>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        class='dropdown-menu'
                                                                        id='dropdown-menu-post'
                                                                        role='menu'
                                                                    >
                                                                        {RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.DTBOOKING) ?
                                                                        <div class='dropdown-content'>
                                                                                    <a
                                                                                        class='dropdown-item'
                                                                                        onClick={() => {
                                                                                            EntityApi.getEntity(e?.SHEntities[0]?.entityId,entityData=>{
                                                                                            let entitystatusResult =  checkEntityStatus(entityData.status)
                                                                                            if(entitystatusResult){
                                                                                            DtStore.DTBooking = DtStore.clonebooking(e.DtBookings)
                                                                                            DtStore.entityId= e.SHEntities[0].entityId
                                                                                            DtStore.bview = "create"
                                                                                            DtStore.valid = false
                                                                                            configStore.dtCurrentView = 'Booking'
                                                                                        } else{
                                                                                            configStore.setModalMessage(`Please submit entity details for verification to proceed with the booking`)
                                                                                        }
                                                                                        }) 
                                                                                        }}
                                                                                    >
                                                                                        Clone
                                                                                    </a>
                                                                            {(e.DtBookings.request_cancel == 'Yes') ?
                                                                        
                                                                        <a
                                                                                    class='dropdown-item'
                                                                                    onClick={() => {
                                                                                        DtStore.cancelid = e.DtBookings._id
                                                                                        setReason("")
                                                                                        DtStore.modalVisible = true
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </a>:""}
                                                                                
                                                                    </div>
                                                                    :
                                                                    (e.DtBookings.request_cancel == 'Yes') &&
                                                                            <div class='dropdown-content'>
                                                                                <a
                                                                                    class='dropdown-item'
                                                                                    onClick={() => {
                                                                                        DtStore.cancelid = e.DtBookings._id
                                                                                        setReason("")
                                                                                        DtStore.modalVisible = true
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </a>
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                            </td>
                                                        default:
                            return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                    }
                                                }

                                            })
                                        }
                        </tr>
                        )
                            })}
                    </tbody>
                </table>
                {(rows.length > 0) && (
                    <>
                        <div className="columns" style={{ width: "100%" }}>
                            <div className="column is-6">
                                <ReactTablePagination
                                    page={page}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageSize={pageSize}
                                    pageIndex={pageIndex}
                                    pageCount={pageCount}
                                    setPageSize={setPageSize}
                                    manualPageSize={manualPageSize}
                                    dataLength={totalCount}
                                />
                            </div>
                            <div className="column is-6" style={{ textAlign: "right" }}>
                                <div className="select">
                                    <select
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[5, 10,20, 50, 100].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                        </div>


                    </>
                )}
            </div>
        </div >
        <div className={modalClass} onClick={() => { DtStore.modalVisible = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>CANCELLATION REASON</h2>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)} value={reason}></textarea>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                                onClick={()=>{
                                                    declinesubmit()
                                                    
                                                }}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => DtStore.modalVisible = false} >cancel</button>
                </section>
            </div>
        </div>
        </>
    )

}

const DtBookingList = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default DtBookingList;