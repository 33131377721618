import { useLayoutEffect, useState } from "react";
import { Col6, Columns } from "../Controls";
import { EmailListComp } from "../components/AgComponents";
import { ccEmailStore, configStore, toEmailStore,entityStore,EntityV2Store } from "../state/CommonState";
import { useSnapshot } from "valtio";
import DtQuoteMailView from "./DtQuoteMailView";
import AguserApi from "../state/AguserApi";
import { useEffect } from "react";
import DtApi from "../state/dt-api";
export default function DtMail() {
    const [customerlist, setCustomerlist] = useState([])
    const [salesList, setSalesList] = useState({})
    const [space, setSpace] = useState(' ')
    useSnapshot(configStore)
    useSnapshot(entityStore)
    const setLists = (users) => {
        let agusers = users.filter(e => e.email?.endsWith('agraga.com'))
        agusers.sort((a, b) => a.firstName?.localeCompare(b.firstName))
        let l = {}
        for (let i = 0; i < agusers.length; i++) {
            l[agusers[i]['email']] = `${agusers[i]['firstName']} ${agusers[i]['lastName']}`
        }
        setSalesList(l)
    }
    const getlist = async () => {
        if (Object.keys(customerlist).length == 0) {
            await AguserApi.getList(setLists)
        }
        if (Object.keys(customerlist).length == 0) {
            let l = {}
            for (let i = 0; i < EntityV2Store.list.length; i++) {
                l[EntityV2Store.list[i]['entityId']] = EntityV2Store.list[i]['entityName']
            }
            setCustomerlist(l)
        }
    }
    useEffect(() => {
        getlist()
    }, [])
    return (
        <>
        {
            (Object.keys(customerlist).length > 0 && Object.keys(salesList).length > 0) ?<>
            {space}
            <Columns>
                <Col6>
                    <EmailListComp label='TO - email ids' emailStore={toEmailStore} />
                </Col6>
                <Col6>
                    <EmailListComp label='CC - email ids' emailStore={ccEmailStore} />
                </Col6>
            </Columns>
            <DtQuoteMailView customerlist={customerlist} salesList={salesList}/>
            <button className="button is-danger" 
            onClick={(e) => {
                 DtApi.sendTransportMail(customerlist,salesList)
                }}>
                    Send mail
                </button>
                <button  class="button is-warning" style={{position:"relative",left:"55em",width:"7em"}} onClick={()=> configStore.dtCurrentView = "list"}>Cancel</button>
            </>:""
        }
        
        </>
    )
}