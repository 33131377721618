import Api from "./Api"
import {
  configStore,
  creditAppStore,
  creditformStore,
  routerStore,
} from "./CommonState"
import { CreditFormType } from "./Types"


const CREDITSURI = {
  POST: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/caf`,
  CHECKCAF: (entityID) => `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/checkcaf/${entityID}`,
  GET: (id) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/${id}`,
  GETCAF: (entityID) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/checkcaf/${entityID}`,
  GETGSTLIST: (entityID, branchGST) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/gstList/${entityID}`,
  PUT: (entityID) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/${entityID}`,
  UPDATE: (cafid) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/credits/updatecaf`,
  UPDATESTATUS: () =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/credits/updateStatus`,
  GETCAFDOC: (cafid) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getCreditDocument/${cafid}`,
}
export default class CreditsApis {
  static async getGstList(entityID) {
    console.log("about to call get gstList api")
    await Api.fetch1(CREDITSURI.GETGSTLIST(entityID), (data) => {
      creditformStore.CreditformRec.gstList = data
    })
  }
  static async createCaf() {
    console.log("about to call api.post")
    Api.post(CREDITSURI.POST, creditformStore.CreditformRec, (data) => {
      configStore.setModalMessage("Credit Application Initiated Successfully")
      creditformStore.CreditformRec.set(new CreditFormType())
      routerStore.entityCurrentViewNew = "list"
    })
  }
  static async updateCaf() {
    console.log("about to call api.post")
    Api.post(CREDITSURI.UPDATE(), creditformStore.CreditformRec, (data) => {
      configStore.setModalMessage("Credit Application Saved Successfully")
      creditformStore.CreditformRec.set(new CreditFormType())
      routerStore.entityCurrentViewNew = "list"
    })
  }
  static async checkCaf(entityID, callback = (f) => f) {
    console.log("about to call check caf api")
    await Api.fetch2(CREDITSURI.CHECKCAF(entityID), (data) => {
      callback(data)
    })
  }

  static async viewCaf(entityID, userRole, action) {
    console.log("about to call api.get")
    await Api.fetch1(CREDITSURI.GETCAF(entityID), (data) => {
      console.log("data", data)
      if (data.status) {
        data.data.action = action
        data.data.role = userRole
        creditformStore.CreditformRec.set(data.data)
        // creditAppStore.role = userRole
        // creditAppStore.action = action
        routerStore.creditApplicationView = "new"

      } else if (!data.data) {
        configStore.setModalMessage("No data found")
      }

    })
  }

  static async updateStatus(dataUpdate, setsubbtnstatus) {
    console.log("about to call api.get")
    await Api.post(CREDITSURI.UPDATESTATUS(), dataUpdate, (data) => {
      console.log("data", data)
      if (data) {
        routerStore.creditApplicationView = "list"
        setsubbtnstatus(false)
      }

    })
  }

  static async getCafDocs(cafid, setDownloadFormData) {
    console.log("about to call api.get")
    await Api.fetch1(CREDITSURI.GETCAFDOC(cafid), (data) => {
      console.log("data", data)
      setDownloadFormData(data)
      // return data
    })
    // return datas
  }
}
