import { useEffect, useState } from "react";
import { subscribe ,useSnapshot } from "valtio";
import { Agyesno } from "../components/Agyesno";
import { bookingsStore, configStore } from "../state/CommonState";
import Igmmanifesttable from "./igm-manifest-table";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Blupload(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    
        return (
            <>
                <tr>
                    <td style={{ width: '20rem' }}>Type of BL:</td>
                    <td>
                        <strong>{task.blType}</strong>
                    </td>
                </tr>
                <Taskfiledisplay />
                <hr />
                <div style={{ width: '30%', textAlign: 'left' }}>
                    {
                        (task.isBank === 'Yes') ?
                            <strong>Consignee is a Bank</strong>
                            : <></>
                    }
                    <hr/>
                    {
                        (task.isOriginal === 'Yes') ?
                            <strong>BL type is Original</strong>
                            : <></>
                    }
    
                </div>
            </>
        )

   
}
export function BluploadTrigger(props) {
    const [isOriginalDisabled, setIsOriginalDisabled] = useState(false)
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    const [disableButton,setDisableButton] = useState(true)
    const [depAction, setDepAciton] = useState({})

    
    let task = taskStore.current
    if(depAction){
        task.blType = depAction.blType
    }
    useEffect(() => {
        let f = async () => {
            let ret = await Taskapi.getTaskForAction(bookingsStore.current._id,'BL Release')
            console.log('INSIDE INSIDE', ret)
            if (ret) {
                setDepAciton(ret[0])
            }
        }
        f()
        if (task.isBank === 'Yes') {
            task.isOriginal = 'Yes'
            setIsOriginalDisabled(true)
        } else setIsOriginalDisabled(false)
    }, [task.isBank])
    function check(){
        let disable = false
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    if (!depAction?._id || depAction._id?.bookingNum !== bookingsStore.current._id) {
        return (
            <>
                <br />
                <h1 className="title is-4">Bl Release not found for booking</h1>
            </>
        )
    }
    return (
        <>
        <tr>
                    <td style={{ width: '20rem' }}>Type of BL:</td>
                    <td>
                        <strong>{task.blType}</strong>
                    </td>
                </tr>
            <Taskfiletable />
            <div style={{ width: '40%', textAlign: 'left' }}>
                <Agyesno label='Is the Consignee a Bank?' record={task} name='isBank' />
            </div>
            <div style={{ width: '40%', textAlign: 'left' }}>
                <Agyesno isDisabled={isOriginalDisabled} label='Is the BL type Original?' record={task} name='isOriginal' />
            </div>

            <p>
                <button className="button islink"
                disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        taskStore.setComplete()
                        taskStore.setCustomerComplete()
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}