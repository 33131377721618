import { useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import { configStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import { useGlobalState } from "../GlobalState"
import Agproducttab from "../components/Agproducttab"
import Visibility from "./visibility"
import Others from "./others"
import Pricing from "./pricing"
import Accounts from "./accounts"
import Schedules from "./schedules"
import PricingUploads from "./pricing-uploads"
import Transporter from "./transporter"
import AirPricingUploads from "./air-pricing-uploads"
import FCLPricingUploads from "./fcl-pricing-uploads"

let menuList = ['LCL Pricing', 'FCL Pricing', 'Air Pricing', 'Schedules', 'Visibility', 'Accounts', 'Others','Transporter Tariff']
 //let menuList = ['Schedules', 'Visibility', 'Accounts', 'Others']
export default function Uploads() {
    const { leftMenuHidden, setLeftMenuHidden, changeCurrentMenu } = useGlobalState()
    const store = useSnapshot(myactionsstore)
    const [currentMenu, setCurrentMenu] = useState('LCL Pricing')
    const [data, setData] = useState({})
    let Comp = PricingUploads
    if (currentMenu == 'Others') Comp = Others
    else if (currentMenu == 'Visibility') Comp = Visibility
    else if (currentMenu == 'Accounts') Comp = Accounts
    else if (currentMenu == 'Schedules') Comp = Schedules
    else if (currentMenu == 'Transporter Tariff') Comp = Transporter
    else if (currentMenu == 'Air Pricing') Comp = AirPricingUploads
    else if (currentMenu == 'FCL Pricing') Comp = FCLPricingUploads
    let errors = data.validationErrors
    return (
        <div>
            <h4 className="title is-3">Uploads</h4>
            <div className="columns">
                <div className="column is-12" style={{zoom: '1.1'}}>
                <Agproducttab productList={menuList}
                        activeProduct={currentMenu} setActiveProduct={setCurrentMenu} />
                </div>
            </div>
            <Comp />
        </div>
    )
}