class Quotation {
    constructor() {
        this.shipmentType = ''
        this.shipmentScope = ''
        this.origin = ''
        this.destination = ''
        this.cargoReadinessDate = ''
        this.portOfLoading = ''
        this.portOfDischarge = ''
        this.finalPlaceOfDelivery = ''
        this.customerReferenceNum = ''
        this.quotationNum = ''
        this.validUpto = ''
        this.hazardous = 'No'
        this.quoteClass = ''
        this.packingGroup = ''
        this.unnumber = ''
        this.nonStackable = ''
        this.carrier = ''
        this.transitTime = 0
        this.factoryOrDockStuffing = ''
        this.temperatureControlled = ''
        this.airline = ''
        this.exchangeUSD2INR = 0
        this.exchangeSpecif2INR = 0
    }
}
let quoteData = new Quotation()

class CargoDetails {
    constructor() {
        this.id = 0
        this.packageType = ''
        this.numPackages = 0
        this.wtPerPackage = 0
        this.length = 0
        this.width = 0
        this.height = 0
        this.volPerPackage = 0
        this.totalWeight = 0
        this.totalVolume = 0
    }
}
let newCargoData = new CargoDetails()
let editCargoData = new CargoDetails()
let cargoListData = []
const initializeNewCargoData = () => newCargoData = new CargoDetails()
export { quoteData, CargoDetails, newCargoData, editCargoData, cargoListData}