import { configStore, contractsStore, loginStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { useEffect } from "react"
import Newapi from "../state/new-api"
import HomeListv2 from "./home-list-v2"
import HomeScreenv2 from "./home-screen-v2"

export default function HomeMainv2() {
    const a = useSnapshot(configStore)
    useSnapshot(contractsStore)
    useEffect(() => {
        if (!contractsStore.navigateFromCharts) Newapi.getContracts()
    },[configStore.currentSelectedEntity])

    switch(configStore.homescreenCurrentView) {
        case 'list':
            configStore.displaySchedules = false 
            return <HomeListv2/>
        case 'view': 
            //configStore.displaySchedules = true
            return <HomeScreenv2 viewonly='1'/>
        case 'new': return <HomeScreenv2/>
    }
}