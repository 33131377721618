import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
async function callback(e) {
    taskStore.current.stage = 2
    taskStore.current.pendingWith = 'AGRAGA'
    taskStore.setCustomerComplete()
    await Taskapi.updateTask(taskStore.current)
    taskStore.triggerActionVisible = false
    taskStore.myActionVisible = false
}
export default function Ciplupload(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    const [disableButton, setDisableButton] = useState(true)
    const [rejected, setrejected] = useState(false)
    
    function check(){
        let disable = false
        let reject = false
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(e.approved =="Reject"){
                reject = true
            }
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        setrejected(reject)
        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])

    if (stage === 1) {
        return (
            <div className="box">
                <p>
                    Please Upload your Commercial Invoice(s) & Packing List(s)
                </p>
                {
                    (rejected)?<Taskfiletable showRejectReason={true} />:<Taskfiletable />
                }
                
                <hr />
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                            onClick={callback}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 2) {
        return (
            <>
                <Taskfileapproval />
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink" 
                            onClick={async (e) => {
                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected) {
                                    task.stage = 1
                                    task.pendingWith = 'CUSTOMER'
                                    task.customerStatus = 'PENDING'
                                }
                                else {
                                    task.stage = 3
                                    taskStore.setComplete()
                                }
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Save</button>
                    </span>
                </p>
            </>
        )
    }
    if (task.stage >= 3) {
        return (
            <Taskfiledisplay />
        )
    }
}
export function CipluploadTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [disableButton, setDisableButton] = useState(true)
    let task = taskStore.current
    let stage = task.stage
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            if (taskStore.current.dueDate.length > 0) {
                setDisableButton(false)
            }else{
                setDisableButton(true)
            }
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <br /><br /><br />
            <p>
                <button className="button islink"
                    disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        //await Taskapi.getEmailsForEntity(taskStore.current.gst)
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}