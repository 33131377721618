import Api from "./Api"
import {  configStore,vehicleStore,routerStore } from "./CommonState"
import { VehicleType } from "./Types"
const VEHICLEURI = {
    LIST: `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles`,
    GET: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/${id}`,
   // GET:  `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/`,
    PUT: `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/updatevechicle`,
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/addvechicle`,
    VEHICLETYPE: `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/get/getvechicletype`,
}
export default class VehicleApi {
    static getList(callback = f => f) {
        Api.fetch(VEHICLEURI.LIST, (data) => {
            vehicleStore.list = data
             callback(data)
        })
    }
 // getVehicleType
    static getVehicleType(callback = f => f) {
        Api.fetch(VEHICLEURI.VEHICLETYPE, (data) => {
            vehicleStore.list = data
             callback(data)
        })
    }
    static saveVehicle(setloading) {
        console.log('about to call api.put')
        Api.put(VEHICLEURI.PUT, vehicleStore.vehicleRec, (data) => {
            configStore.setModalMessage('Vehicle Details Saved')
            console.log("dataa1",data)
            if(data){
                console.log("dataa2",data)
                routerStore.vehicleCurrentView = "list";
                setloading(false)
            }
        },setloading)
    }

    
    static createVehicle(setloading) {
        console.log('about to call api.post')
        Api.post(VEHICLEURI.POST, vehicleStore.vehicleRec, (data) => {
            configStore.setModalMessage('Vehicle Details Created')
            if (data){
                vehicleStore.vehicleRec.set(new VehicleType());
                routerStore.vehicleCurrentView = "list";
                setloading(false)
            }
        }, setloading)
    }

    static async viewVehicle(vehicleID,callback) {
        console.log('about to call api.get')
       await  Api.fetch1(VEHICLEURI.GET(vehicleID), (data) => {
            console.log("dataviewvehicle", data)
            if(callback){
                callback(data)  
              }        
            vehicleStore.vehicleRec.set(data); 
           // DBbranchStore.BranchRec.set(data.data);  
        })
    }
}