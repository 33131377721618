import EntityUtils from "../../util/entityUtils";

import { branchStore,EntityV2Store } from "../../state/CommonState";
const default_COLUMNS = [];
//  const Entity = EntityV2Store.EntityRec;
const LCLSCHEDULES_COLUMNS = [
  {
    Header: "POR (CFS / ICD / PORT)",
    accessor: "POR (CFS / ICD / PORT)",
  },
  {
    Header: "POL",
    accessor: "POL",
  },
  {
    Header: "POD UNLOC",
    accessor: "POD UNLOC",
  },
  {
    Header: "VESSEL NAME",
    accessor: "VESSEL NAME",
  },
  {
    Header: "VOYAGE",
    accessor: "VOYAGE",
  },
  {
    Header: "STUFFING CUT OFF DATE",
    accessor: "STUFFING CUT OFF DATE",
  },
  {
    Header: "ETD (POL)",
    accessor: "ETD (POL)",
  },
  {
    Header: "ETA (POD)",
    accessor: "ETA (POD)",
  },
  {
    Header: "CO-LOADER",
    accessor: "CO-LOADER",
  },
];

const MyActionClientPending = [
  {
    Header: "Booking #",
    accessor: "bookingNum",
  },
  {
    Header: "BranchName",
    // accessor: "Branches.gst",
  },
  {
    Header: "Shipment Type",
    accessor: "shipmentType",
  },
  {
    Header: "Action",
    accessor: "actionName",
  },
  {
    Header: "Due Date and Time",
    accessor: "dueDate",
  },
];

const MyActionClientCompleted = [
  {
    Header: "Booking #",
    accessor: "bookingNum",
  },
  {
    Header: "BranchName",
    // accessor: "Branches.gst",
  },
  {
    Header: "Shipment Type",
    accessor: "shipmentType",
  },
  {
    Header: "Action",
    accessor: "actionName",
  },
  {
    Header: "Completed Date and Time",
    accessor: "customerCompletedOn",
  },
  {
    Header: "Completed By",
    accessor: "customerCompletedBy",
  },
];

const MyActionAgragaPending = [
  {
    Header: "Booking #",
    accessor: "Myactions.bookingNum",
  },
  {
    Header: "Customer Name",
    accessor: "Entities.entityName",
  },
  {
    Header: "BranchName",
    accessor: "Branches.gst",
  },
  {
    Header: "Shipment Type",
    accessor: "Myactions.shipmentType",
  },
  {
    Header: "Action",
    accessor: "Myactions.actionName",
  },
  {
    Header: "Due Date and Time",
    accessor: "Myactions.dueDate",
  },
  {
    Header: "Timer",
    accessor: "updatedOn",
  },
];

const MyActionAgragaCustomerPending = [
  {
    Header: "Booking #",
    accessor: "Myactions.bookingNum",
  },
  {
    Header: "Customer Name",
    accessor: "Entities.entityName",
  },
  {
    Header: "BranchName",
    accessor: "Branches.gst",
  },
  {
    Header: "Shipment Type",
    accessor: "Myactions.shipmentType",
  },
  {
    Header: "Action",
    accessor: "Myactions.actionName",
  },
  {
    Header: "Due Date and Time",
    accessor: "Myactions.dueDate",
  },
  {
    Header: "Timer",
    accessor: "updatedOn",
  },
];

const MyActionAgragaComplete = [
  {
    Header: "Booking #",
    accessor: "Myactions.bookingNum",
  },
  {
    Header: "Customer Name",
    accessor: "Entities.entityName",
  },
  {
    Header: "BranchName",
    accessor: "SHBranches.branchName",
  },
  {
    Header: "Shipment Type",
    accessor: "Myactions.shipmentType",
  },
  {
    Header: "Action",
    accessor: "Myactions.actionName",
  },
  {
    Header: "Completed Date and Time",
    accessor: "Myactions.completedOn",
  },
  {
    Header: "Completed By",
    accessor: "Myactions.completedBy",
  },
  {
    Header: "Customer Completion",
    accessor: "Myactions.customerCompletedOn",
  },
];

const EntityColumn = [
  {
    Header: "Entity Name",
    accessor: "entityName",
  },
  {
    Header: "State",
    accessor: "state",
  },
  {
    Header: "GST",
    accessor: "gst",
  },
  {
    Header: "PAN",
    accessor: "pan",
  },
  {
    Header: "MSME",
    accessor: "msme",
  },
  {
    Header: "Salesperson",
    accessor: "salesPerson",
  },
  {
    Header: "Customer Service",
    accessor: "customerService",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "fas fa-eye,fas fa-pen-to-square,fas fa-user-plus",
  },
];

const EntityColumnnonagraga = [
  {
    Header: "Entity Name",
    accessor: "entityName",
  },
  {
    Header: "State",
    accessor: "state",
  },
  {
    Header: "GST",
    accessor: "gst",
  },
  {
    Header: "PAN",
    accessor: "pan",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "fas fa-eye,fas fa-pen-to-square,fas fa-user-plus",
  },
];

const VehicleColumn = [
  {
    Header: "Vehicle Registration No#",
    accessor: "Regnumber",
  },
  
  {
    Header: "Vehicle Type",
    accessor: "Vechtype",
  },
  {
    Header: "Status",
    accessor: "Status",
  },
  {
    Header: "Actions",
    accessor: "fas fa-eye,fas fa-pen-to-square,fas fa-user-plus",
  },
];



const BranchColumn1 = [
  {
    Header:'State',
    accessor:'state',
  },
  {
    Header:'Country',
    accessor:'country',
  },

  {
    Header:'GST Number',
    accessor:'gst',
  },
    
  {
    Header:'Branch Verification',
    accessor:'status',
  },
  
  {
    Header:'Actions',
    accessor:'fas fa-eye,fas fa-pen-to-square,fas fa-user-plus',
  },
]

const BranchColumn2 = [
  {
    Header:'State',
    accessor:'state',
  },
  {
    Header:'Country',
    accessor:'country',
  }, 
  {
    Header:'UID Number',
    accessor:'uidRefNo',
  },
 
  {
    Header:'Branch Verification',
    accessor:'status',
  },
  
  {
    Header:'Actions',
    accessor:'fas fa-eye,fas fa-pen-to-square,fas fa-user-plus',
  },
]

const DriverColumn = [
  {
    Header: "Driver Name",
    accessor: "Name",
  },
  {
    Header: "Driver License No",
    accessor: "DlNumber",
  },
  {
    Header: "Mobile Number",
    accessor: "MobileNumber",
  },

  {
    Header: "Status",
    accessor: "Status",
  },
  {
    Header:'Actions',
    accessor:'fas fa-eye,fas fa-pen-to-square,fas fa-user-plus',
  },
];

const EntityApprovalColumn = [
  {
    Header: "Reference ID",
    accessor: "id",
  },
  {
    Header: "Entity",
    accessor: "name",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Timer(TAT)",
    accessor: "timer",
  },
  // {
  //   Header: "Completed Date",
  //   accessor: "updatedAt",
  // },
  // {
  //   Header: "Completed By",
  //   accessor: "updatedBy",
  // },

];

const EntityApprovalColumnCom = [
  {
    Header: "Reference ID",
    accessor: "id",
  },
  {
    Header: "Entity",
    accessor: "name",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Completed Date",
    accessor: "updatedAt",
  },
  {
    Header: "Completed By",
    accessor: "updatedBy",
  },

];
const EntityUsersColumn = [
  {
    Header: "Name",
    accessor: "firstName lastName",
  },
  {
    Header: "User Type",
    // accessor: "role",
  },
  {
    Header: "Branch",
    // accessor: "mainEntityList",
  },
 
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header:'Actions',
    accessor:'fas fa-eye,fas fa-pen-to-square,fas fa-user-plus',
  },

];
const UserColumn = [
  {
    Header: "Name",
    accessor: "firstName,lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header:"Vertical",
    accessor:"vertical"
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Phone",
    accessor: "phonePrefix,phone",
  },
  {
    Header: "Actions",
    accessor: "fas fa-eye,fas fa-pen-to-square",
  },
];
const AgragaBooking = [
  {
    Header: "Booking #",
    accessor: "Bookings._id",
  },
  {
    Header: "CustomerName",
    accessor: "Entities.entityName",
  },
  {
    Header: "BranchName",
    accessor: "Branches.state",
  },
  {
    Header: "ERP Shipment #",
    accessor: "Bookings.erpJobId",
  },
  {
    Header: "Quotation Ref #",
    accessor: "Bookings.contract.quotationNum",
  },
  {
    Header: "Customer Ref #",
    accessor: "Bookings.contract.customerReferenceNum",
  },
  {
    Header: "Booking Date",
    accessor: "Bookings.bookingDate",
  },
  {
    Header: "Shipment Type",
    accessor: "Bookings.contract.shipmentType",
  },
  {
    Header: "Origin",
    accessor: "Bookings.contract.origin",
  },
  {
    Header: "Destination",
    accessor: "Bookings.contract.destination",
  },
  {
    Header: "Shipper",
    accessor: "Bookings.shipper",
  },
  {
    Header: "Consignee",
    accessor: "Bookings.consignee",
  },
  {
    Header: "Status",
    accessor: "Bookings.status",
  },
  {
    Header: "Actions",
    accessor: "fas fa-ellipsis-h",
  },
];
const ClientBooking = [
  {
    Header: "Booking #",
    accessor: "Bookings._id",
  },
  {
    Header: "BranchName",
    accessor: "Entities.branchName",
  },
  {
    Header: "Contract Ref #",
    accessor: "Bookings.contractNum",
  },
  {
    Header: "Customer Ref #",
    accessor: "Bookings.contract.customerReferenceNum",
  },
  {
    Header: "Booking Date",
    accessor: "Bookings.bookingDate",
  },
  {
    Header: "Shipment Type",
    accessor: "Bookings.contract.shipmentType",
  },
  {
    Header: "Origin",
    accessor: "Bookings.contract.origin",
  },
  {
    Header: "Destination",
    accessor: "Bookings.contract.destination",
  },
  {
    Header: "Shipper",
    accessor: "Bookings.shipper",
  },
  {
    Header: "Consignee",
    accessor: "Bookings.consignee",
  },
  {
    Header: "Status",
    accessor: "Bookings.status",
  },
  {
    Header: "Actions",
    accessor: "fas fa-ellipsis-h",
  },
];

const QuotationColumn = [
  {
    Header: "Quotation Ref. #",
    accessor: "Quotes._id",
  },
  {
    Header: "Date",
    accessor: "Quotes.mailedOn",
  },
  {
    Header: "Scope",
    accessor: "Quotes.quoteData.shipmentScope",
  },
  {
    Header: "Customer",
    accessor: "Entities.entityName",
  },
  {
    Header: "Origin",
    accessor: "Quotes.quoteData.origin",
  },
  {
    Header: "Destination",
    accessor: "Quotes.quoteData.destination",
  },
  {
    Header: "Status",
    accessor: "Quotes.status",
  },
  {
    Header: "Actions",
    accessor: "fas fa-ellipsis-h",
  },
];

const ClientUploadQuote = [
  {
    Header: "Quotation Date",
    accessor: "mailedOn",
  },
  {
    Header: "Customer Reference #",
    accessor: "customerReferenceNum",
  },
  {
    Header: "Quotation Reference #",
    accessor: "quotationNum",
  },
  {
    Header: "Origin",
    accessor: "origin",
  },
  {
    Header: "Destination",
    accessor: "destination",
  },
  {
    Header: "Valid Till",
    accessor: "validUpto",
  },
];
const ClientUploadContract = [
  {
    Header: "Contract Effective Date",
    accessor: "contractCreatedOn",
  },
  {
    Header: "Customer Reference #",
    accessor: "customerReferenceNum",
  },
  {
    Header: "Contract Reference #",
    accessor: "_id",
  },
  {
    Header: "Origin",
    accessor: "origin",
  },
  {
    Header: "Destination",
    accessor: "destination",
  },
  {
    Header: "Valid Till",
    accessor: "validUpto",
  },
];
const ClientUploadArchives = [
  {
    Header: "Date",
    accessor: "contractCreatedOn",
  },
  {
    Header: "Customer Reference #",
    accessor: "customerReferenceNum",
  },
  {
    Header: "Reference #",
    accessor: "_id",
  },
  {
    Header: "Origin",
    accessor: "origin",
  },
  {
    Header: "Destination",
    accessor: "destination",
  },
  {
    Header: "Valid Till",
    accessor: "validUpto",
  },
];
const AgragaUploadQuote = [
  {
    Header: "Quotation Date",
    accessor: "mailedOn",
  },
  {
    Header: "Customer Reference #",
    accessor: "customerReferenceNum",
  },
  {
    Header: "Quotation Reference #",
    accessor: "quotationNum",
  },
  {
    Header: "Origin",
    accessor: "origin",
  },
  {
    Header: "Destination",
    accessor: "destination",
  },
  {
    Header: "Valid Till",
    accessor: "validUpto",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];
const AgragaUploadContract = [
  {
    Header: "Contract Effective Date",
    accessor: "contractCreatedOn",
  },
  {
    Header: "Customer Reference #",
    accessor: "customerReferenceNum",
  },
  {
    Header: "Contract Reference #",
    accessor: "_id",
  },
  {
    Header: "Origin",
    accessor: "origin",
  },
  {
    Header: "Destination",
    accessor: "destination",
  },
  {
    Header: "Valid Till",
    accessor: "validUpto",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];
const AgragaUploadArchives = [
  {
    Header: "Date",
    accessor: "contractCreatedOn",
  },
  {
    Header: "Customer Reference #",
    accessor: "customerReferenceNum",
  },
  {
    Header: "Reference #",
    accessor: "_id",
  },
  {
    Header: "Origin",
    accessor: "origin",
  },
  {
    Header: "Destination",
    accessor: "destination",
  },
  {
    Header: "Valid Till",
    accessor: "validUpto",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

const AgragaDtlist = [
  {
    Header: "Contract Reference #",
    accessor: "DtQuotes._id",
  },
  {
    Header: "Customer Name",
    accessor: "Entities.entityName",
  },
  {
    Header: "Pick-up Address",
    accessor: "pickup",
  },
  {
    Header: "Delivery Address",
    accessor: "delivery",
  },
  {
    Header: "Valid Upto",
    accessor: "DtQuotes.valid_upto",
  },
  {
    Header:"Created Date",
    accessor:"DtQuotes.CreatedDate"
  },
  {
    Header: "Status",
    accessor: "DtQuotes.status",
  },
  {
    Header: "Actions",
    accessor: "action",
  },
];

const DRS_COLUMNS = [
  {
    Header: "Sn",
    accessor: "sn",
  },
];

const AgragaDTBooking = [
  {
    Header: "Booking ID",
    accessor: "DtBookings._id",
  },
  {
    Header: "Vehicle Number",
    accessor: "DtBookings.vehicle_no",
  },
  {
    Header: "Customer Name",
    accessor: "Entities.entityName",
  },
  {
    Header: "Branch Name",
    accessor: "SHBranches.branchName",
  },
  {
    Header: "ERP Shipment Number",
    accessor: "DtBookings.erp",
  },
  {
    Header: "Customer Ref Number",
    accessor: "DtBookings.customer_ref_no",
  },
  {
    Header: "Booking Date",
    accessor: "DtBookings.bookingdate",
  },

  {
    Header: "Pickup Date",
    accessor: "DtBookings.pickupdate",
  },
  {
    Header: "Pick-up Address",
    accessor: "pickup",
  },
  {
    Header: "Delivery Address",
    accessor: "delivery",
  },
  {
    Header: "Status",
    accessor: "DtBookings.status",
  },
  {
    Header: "Actions",
    accessor: "action",
  },
];

const ClinetDTBooking = [
  {
    Header: "Booking ID",
    accessor: "DtBookings._id",
  },
  {
    Header: "Customer Ref Number",
    accessor: "DtBookings.customer_ref_no",
  },
  {
    Header: "Vehicle Type",
    accessor: "DtBookings.vehicle",
  },
  {
    Header: "Booking Date",
    accessor: "DtBookings.bookingdate",
  },
  {
    Header: "Pickup Date",
    accessor: "DtBookings.pickupdate",
  },
  {
    Header: "Pick-up Address",
    accessor: "pickup",
  },
  {
    Header: "Delivery Address",
    accessor: "delivery",
  },
  {
    Header: "Status",
    accessor: "DtBookings.status",
  },
  {
    Header: "Actions",
    accessor: "action",
  },
];

const ClinetDTContract = [
  {
    Header: "Contract Reference #",
    accessor: "DtContracts._id",
  },
  {
    Header: "Pick-up Address",
    accessor: "pickup",
  },
  {
    Header: "Delivery Address",
    accessor: "delivery",
  },
  {
    Header: "Effective Date",
    accessor: "DtContracts.effective_date",
  },
  {
    Header: "Valid Upto",
    accessor: "DtContracts.valid_upto",
  },
  {
    Header: "Status",
    accessor: "DtContracts.status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

const Hitlog = [
  {
    Header: "Email",
    accessor: "user",
  },
  {
    Header: "IP",
    accessor: "ip",
  },
  {
    Header: "Device",
    accessor: "device",
  },
  {
    Header: "URL",
    accessor: "url",
  },
  {
    Header: "Method",
    accessor: "method",
  },
  {
    Header: "Country",
    accessor: "country_name",
  },
  {
    Header: "City",
    accessor: "city_name",
  },
  {
    Header: "Createon",
    accessor: "createOn",
  },
];

const Trysignup = [
  {
    Header: "Name",
    accessor: "firstName,lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phonePrefix,phone",
  },
];

const Nouserentity = [
  {
    Header: "Name",
    accessor: "entityName",
  },
  {
    Header: "GST",
    accessor: "gst",
  },
];

const EntityV2Column = [
  
  {
    Header: "Entity Code #",
    accessor: "entityId"
  },
  
  {
    Header: "Entity Name",
    accessor: "entityName",
  },
  {
    Header: "Entity Country",
    accessor: "entityCountry",
  },
  {
    Header: "Type",
    accessor: "entityType",
  },
  {
    Header: "Category",
    accessor: (entity) => EntityUtils.getCategory(entity),
  },
  {
    Header: "Relationship Manager",
    accessor: (entity) => EntityUtils.getType(entity) === "customer" ? EntityUtils.getSalesPerson(entity) : "NA" ,
  },
  {
    Header: "Customer Service",
    accessor: (entity) => EntityUtils.getType(entity) === "customer" ? EntityUtils.getCustServicePerson(entity) : "NA",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "fas fa-eye,fas fa-pen-to-square,fas fa-user-plus",
  },
];
const DtactionsColumn = [
  {
    Header: "Agraga BR",
    accessor: "bookingRef",
  },
  {
    Header: "Customer Name",
    accessor: "Entities.entityName",
  },
  {
    Header: "Branch Name",
    accessor: "SHBranches.branchName",
  },
  {
    Header: "Action Name",
    accessor: "_id.actionName",
  },
  {
    Header: "Completed Date and Time",
    accessor: "tsCompletedOn",
  },
  {
    Header: "Completed By",
    accessor: "completedBy",
  },
  {
    Header: "Actions",
    accessor: "fas fa-eye,fas fa-pen-to-square",
  },
];
const DtactionsColumnPending = [
  {
    Header: "Agraga BR",
    accessor: "bookingRef",
  },
  {
    Header: "Customer Name",
    accessor: "Entities.entityName",
  },
  {
    Header: "Branch Name",
    accessor: "SHBranches.branchName",
  },
  {
    Header: "Action Name",
    accessor: "_id.actionName",
  },
  {
    Header: "TAT",
    accessor: "tat",
  },
  {
    Header: "Actions",
    accessor: "fas fa-eye,fas fa-pen-to-square",
  },
];
export {
  default_COLUMNS,
  LCLSCHEDULES_COLUMNS,
  MyActionClientPending,
  MyActionClientCompleted,
  MyActionAgragaPending,
  MyActionAgragaCustomerPending,
  MyActionAgragaComplete,
  EntityColumn,
  EntityColumnnonagraga,
  UserColumn,
  VehicleColumn,
  BranchColumn1,
  BranchColumn2,
  AgragaBooking,
  ClientBooking,
  QuotationColumn,
  ClientUploadQuote,
  ClientUploadContract,
  ClientUploadArchives,
  AgragaUploadQuote,
  AgragaUploadContract,
  AgragaUploadArchives,
  DRS_COLUMNS,
  AgragaDtlist,
  AgragaDTBooking,
  ClinetDTBooking,
  DriverColumn,
  ClinetDTContract,
  Hitlog,
  Trysignup,
  Nouserentity,
  EntityV2Column,
  DtactionsColumn,
  DtactionsColumnPending,
  EntityApprovalColumn,
  EntityApprovalColumnCom,
  EntityUsersColumn
};
