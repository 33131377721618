import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { configStore } from "../state/CommonState";
import { OneDatetable, OneInput5table } from "../mytasks/act-common";
import { useEffect, useState } from "react";

export function Vmodal() {
    useSnapshot(myactionsstore)
    useSnapshot(configStore)
    const [ch, setCh] = useState(0)
    const [orgin, setOrgin] = useState([])
    const [des, setDes] = useState([])
    // let orgin = []
    // let des = []
    let ret = configStore.modalvisibledata
    let st = ret.shipmentType
    let sc = ret.shipmentScope

    const seorde = () => {
        if (sc == "Port-to-Door" || sc == "Door-to-Door") {
            if (ret.vdes != undefined && ret.vdes.length > 0) {
                setDes(ret.vdes)
            }
            
        }
        if (sc == "Door-to-Port" || sc == "Door-to-Door") {
            if (ret.vorgin != undefined && ret.vorgin.length > 0) {
                setOrgin(ret.vorgin)
            }
        }
        changech()
    }
    

    const changech = ()=>{
        if(ch==0){
            setCh(1)
        }else{
            setCh(0)
        }
    }

    const add1 = () => {
        let o = orgin
        if (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) {
            o.push({
                vehicle: "",
                arrival_factory: "",
                cargo_pickup: "",
                cargo_arrival_cfs: ""
            })
        } else {
            o.push({
                vehicle: "",
                arrival_factory: "",
                cargo_pickup: "",
                arrival_dispatch_airport: ""
            })
        }
        setOrgin(o)
        changech()

    }

    const del1 = (id) =>{
        let l = []
        for (let i = 0; i < orgin.length; i++) {
            if(i!=id){
                l.push(orgin[i])
            }
        }
        setOrgin(l)
    }

    const del2 = (id) =>{
        let l = []
        for (let i = 0; i < des.length; i++) {
            if(i!=id){
                l.push(des[i])
            }
        }
        setDes(l)
    }

    const add2 = () => {
        let d = des
        if (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) {
            d.push({
                vehicle: "",
                loaded_cfs: "",
                atdfrompod: "",

                estimate_delivery_date_to_consignee: "",
                actual_delivery_date:""
            })
        } else {
            d.push({
                vehicle: "",
                dispatched_from_arrival_airport: "",

                estimate_delivery_date_to_consignee: "",
                actual_delivery_date:""
            })
        }
        setDes(d)
        changech()
    }

    useEffect(()=>{
        if(myactionsstore.vModalVisible==true){
            seorde()
        }
    },[myactionsstore.vModalVisible])

    const onsubmit2 = () =>{
        if(orgin.length>0){
            ret.vorgin = orgin
        }else{
            ret.vorgin = []
        }
        if(des.length>0){
            ret.vdes = des
        }else{
            ret.vdes = []
        }
        configStore.modalvisibledata = ret
        myactionsstore.vModalVisible = false
    }
    let modalClass = 'modal'
    if (myactionsstore.vModalVisible) modalClass += ' is-active'
    return (
        <>
            {
                (myactionsstore.vModalVisible) ? <div className={modalClass} onClick={() => { myactionsstore.vModalVisible = false }}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px', width: "90%" }} >
                        <section className="modal-card-body"  >
                            <div className="table-container" onClick={(e) => e.stopPropagation()}>

                                {
                                    ((sc == "Door-to-Port" || sc == "Door-to-Door") && st!='FCL (Factory/Dock)') ?
                                        <>
                                            <h1>Origin Pick-up</h1>
                                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                                <thead>
                                                    <th>S.No</th>
                                                    <th>Vehicle#</th>
                                                    <th>Arrival @ Factory</th>
                                                    <th>Cargo Pickup</th>
                                                    {
                                                        (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                            <th>Cargo arrival at CFS</th>
                                                        </> : <>
                                                            <th>Arrival @ Dispatch Airport</th>
                                                        </>
                                                    }

                                                    <th><button onClick={add1} className="button is-success is-inverted">
                                                        <span className="icon is-small">
                                                            <i className="fas fa-plus fas fa-lg"></i>
                                                        </span>
                                                    </button></th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        orgin.map((e, i) => {
                                                           return <tr key={i}>
                                                                <td>{i+1}</td> 
                                                                <td><OneInput5table name='vehicle' record={e} label='Vehicle#' /></td>
                                                                <td><OneDatetable name='arrival_factory' record={e} label='Arrival @ Factory'/></td>
                                                                <td><OneDatetable name='cargo_pickup' record={e} label='Cargo Pickup'/></td>
                                                                {
                                                                    (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                                        <td><OneDatetable name='cargo_arrival_cfs' record={e} label='Cargo arrival at CFS'/></td>
                                                                    </> : <>
                                                                        <td><OneDatetable name='arrival_dispatch_airport' record={e} label='Arrival @ Dispatch Airport'/></td>
                                                                    </>
                                                                }
                                                                <td>
                                                                <button className="button is-success is-inverted" onClick={()=>del1(i)}>
                                                                <span class="icon-text has-text-danger">
                                                                    <span class="icon">
                                                                        <i class="fas fa-trash fas fa-lg"></i>
                                                                    </span>
                                                                    </span>
                                                                </button>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>

                                            </table></> : ""
                                }


                                {
                                    ((sc == "Port-to-Door" || sc == "Door-to-Door") && st!='FCL (Dock/Factory)') ? <>
                                        <h1>Destination Delivery</h1>
                                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                            <thead>
                                                <th>S.No</th>
                                                <th>Vehicle#</th>
                                                
                                                {
                                                    (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                        <th>Loaded @ CFS</th>
                                                    {
                                                        (st.toLowerCase().indexOf("lcl") != -1)?<th>Cargo Picked-up for Delivery</th>:<th>Dispatched from CFS</th>
                                                    }
                                                        
                                                    </> : <>
                                                        <th>Dispatched from Arrival Airport</th>
                                                    </>
                                                }
                                                <th>Estimated Delivery Date to Consignee</th>
                                                <th>Actual Delivery Date</th>

                                                <th><button onClick={add2} className="button is-success is-inverted">
                                                    <span className="icon is-small">
                                                        <i className="fas fa-plus fas fa-lg"></i>
                                                    </span>
                                                </button></th>
                                            </thead>
                                            <tbody>
                                                {
                                                    des.map((e, i) => {
                                                        return <tr>
                                                            <td>{i+1}</td>
                                                            <td><OneInput5table name='vehicle' record={e} label='Vehicle#' /></td>
                                                            {
                                                                (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                                
                                                
                                                                    <td><OneDatetable name='loaded_cfs' record={e} label='Loaded @ CFS'/></td>
                                                                    <td><OneDatetable name='atdfrompod' record={e} label='ATD from POD'/></td>
                                                                </> : <>
                                                                    <th><OneDatetable name='dispatched_from_arrival_airport' record={e} label='Dispatched from Arrival Airport'/></th>
                                                                </>


                                                            }
                                                            <td><OneDatetable name='estimate_delivery_date_to_consignee' record={e} label='Estimated Delivery Date to Consignee'/></td>
                                                            <td><OneDatetable name='actual_delivery_date' record={e} label='Actual Delivery Date'/></td>
                                                            <td>
                                                                <button className="button is-success is-inverted" onClick={()=>del2(i)}>
                                                                <span class="icon-text has-text-danger">
                                                                    <span class="icon">
                                                                        <i class="fas fa-trash fas fa-lg"></i>
                                                                    </span>
                                                                    </span>
                                                                </button>
                                                                </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>

                                        </table></> : ""
                                }

                            </div>


                            <button class="button is-warning" onClick={() => myactionsstore.vModalVisible = false} >cancel</button>
                            <button class="button is-danger" style={{ float: "right" }} onClick={onsubmit2}>Submit</button>
                        </section>
                    </div>
                </div> : <></>
            }
        </>
    )
}