import { Box, Typography } from "@mui/material";
import { CenteredBox } from "./CentereedBox";
import { useSnapshot } from "valtio";
import { enquiryState } from "../state/CommonState";
import { OptionBox } from "./OptionBox";
import { ShipmentScopeTag } from "./ShipmentScope";
import calender from "../assets/images/calender.svg";
import styled from "@emotion/styled";
import { enquiryStore } from "../enquiry/enquiry-data";


const Divider = styled(Box)(({theme})=>({
    width : "1px",
    borderRadius : "4px",
    height : "80%",
    backgroundColor : "#AAAAAA"
}))


export function EnquiryDetailsView(){
    const enquiry = useSnapshot(enquiryStore);

    return   <Box sx={{width:"100%",backgroundColor:'white',height:"60px",borderRadius:"8px",display:"flex",flexDirection:"row",padding:"8px",justifyContent:"center",alignItems:"center"}}>
    <CenteredBox sx={{width:"10%"}}><OptionBox title="Mode" content={<span><ShipmentScopeTag fontSize="12px" fontWeight="normal" width="38%" scope={enquiry.current.shipmentType} iconPlacement="start" /></span>} /></CenteredBox>
    <Divider/>
    <CenteredBox sx={{width:"12%"}}><OptionBox title="Scope" content={<Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree" }}>{enquiry.current.shipmentScope}</Typography>} /></CenteredBox>
    <Divider/>
    <CenteredBox sx={{width:"23%"}}><OptionBox title="Origin" content={<Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px" }}>{enquiry.current.enqRoute.origin}</Typography>} /></CenteredBox>
    <Divider/>
    <CenteredBox sx={{width:"23%"}}><OptionBox title="Destination" content={<Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px" }}>{enquiry.current.enqRoute.destination}</Typography>} /></CenteredBox>
    <Divider/>
    <CenteredBox sx={{width:"20%"}}><OptionBox title="Cargo Readiness Date" content={<span style={{ display: "flex", flexDirection: "row", gap: 1, height: "100%", width: "100%", alignItems: "center", justifyContent: "space-between" }}><img src={calender} alt="calender" /><Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enquiry.current.enqRoute.cargoReadinessDate}</Typography></span>} /></CenteredBox>
 </Box>
}