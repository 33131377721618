import { VehicleTypes } from "../enquiry/enquiry-data"
import Utils from "../util/Utils"
/*
{
    "vendorName":"Lets Transport",
    "vehicles":[{"vehicle":"tataAce","numVehicles":2,"costPerVehicle":11178,"amount":22356}],
    "type":"origin","opin":"456006","dpin":"400707"
}
{
    "type":"destination","opin":"21237","dpin":"07021","cost":3737.15,"markup":373.72
}
*/
let USTransport = (props) => {
    let v = props.usTransportRec
    return <td style={{ width: '25rem' }}>
        <div>Transporter <strong>Forward Air</strong>.</div>
        <div style={{ whiteSpace: 'nowrap' }}>From Pin <strong>{v.opin} to {v.dpin}</strong> </div>
        <div style={{ whiteSpace: 'nowrap' }}>Cost <strong>{Utils.N(v.cost, 'USD')}. Mark-up {Utils.N(v.markup, 'USD')}</strong> </div>
    </td>
}
let InstaTransport = (props) => {
    let v = props.instaTransportRec
    return <td style={{ width: '25rem' }}>
        <div>Transporter <strong>INSTA Freight</strong>.</div>
        <div style={{ whiteSpace: 'nowrap' }}>From Pin <strong>{v.opin} to {v.dpin}</strong> </div>
        <div style={{ whiteSpace: 'nowrap' }}>Cost <strong>{Utils.N(v.cost, 'EUR')}. Mark-up {Utils.N(v.markup, 'EUR')}</strong> </div>
    </td>
}
let IndiaTransport = (props) => {
    let v = props.vehicleRec
    let fields = new VehicleTypes().fields
    return <td>
        <div>From Pin <strong>{v.opin} to {v.dpin}</strong> </div>
        <table>
            <thead>
                <tr>
                    <th>Vendor</th>
                    <th>Vehicle Type</th>
                    <th># of Vehicles</th>
                    <th>Cost per vehicle</th>
                </tr>
            </thead>
            <tbody>
                {
                    v.vehicles.map(e => <tr>
                        <td>{e.vendorId}</td>
                        <td>{fields[e.vehicle]}</td>
                        <td>{e.numVehicles}</td>
                        <td>{Utils.N(e.costPerVehicle)}</td>
                    </tr>)
                }
            </tbody>
        </table>
    </td>
}
export {
    IndiaTransport
}
export default function BookingSchedule(props) {
    let e = props.booking.schedule
    let c = props.booking.contract
    /*return (
        <div>{JSON.stringify(e)}</div>
    )*/

    let isOriginIndia = false
    let isOriginUS = false
    let isOriginInsta = false
    let isDestinationIndia = false
    let isDestinationUS = false
    let isDestinationInsta = false
    if (c.vehicleRec) {
        if (c.vehicleRec.type == 'origin') isOriginIndia = true
        else isDestinationIndia = true
    }
    if (c.usTransportRec) {
        if (c.usTransportRec.type == 'origin') isOriginUS = true
        else isDestinationUS = true
    }
    if (c.instaTransportRec) {
        if (c.instaTransportRec.type == 'origin') isOriginInsta = true
        else isDestinationInsta = true
    }
    return (
        <div style={{ padding: '1rem', backgroundColor: '#eee' }}>
            <table className='table is-full-width is-bordered' style={{ zoom: '0.8', }}>
                <thead>
                    <tr>
                        {
                            isOriginIndia || isOriginUS || isOriginInsta ?
                                <th style={{ width: '5rem' }}>Transport</th> : <></>
                        }
                        <th>Place of Receipt</th>
                        <th>Port of Loading</th>
                        <th>Re-working Port</th>
                        <th>Port of Discharge</th>
                        <th>Final POD</th>
                        {
                            c.shipmentType == 'Air' &&
                            <>
                                <th>DO Type</th>
                                <th>Chargeable Weight</th>
                            </>
                        }
                        {
                            c.shipmentType != 'Air' &&
                            <>
                                <th>Carrier</th>
                                <th>Consolidator</th>
                            </>
                        }
                        {
                            isDestinationIndia || isDestinationUS || isDestinationInsta ?
                                <th >Transport</th> : <></>
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            isOriginIndia ?
                                <IndiaTransport vehicleRec={c.vehicleRec} /> : <></>
                        }
                        {
                            isOriginUS ?
                                <USTransport usTransportRec={c.usTransportRec} /> : <></>
                        }
                        {
                            isOriginInsta ?
                                <InstaTransport instaTransportRec={c.instaTransportRec} /> : <></>
                        }
                        <td>{e.por}</td>
                        <td>{e.pol}</td>
                        <td>{e.rep}</td>
                        <td>{e.pod}</td>
                        <td>{e.fpod}</td>
                        {
                            c.shipmentType == 'Air' &&
                            <>
                                <td>{c.doCaseType}</td>
                                <td>{c.chargeableWeight}</td>
                            </>
                        }
                        {
                            c.shipmentType != 'Air' &&
                            <>
                                <td>{e.CARRIER ? e.CARRIER : ''}</td>
                                <td>{e['CO-LOADER'] ? e['CO-LOADER'] : ''}</td>
                            </>
                        }
                        {
                            isDestinationIndia ?
                                <IndiaTransport vehicleRec={c.vehicleRec} /> : <></>
                        }
                        {
                            isDestinationUS ?
                                <USTransport usTransportRec={c.usTransportRec} /> : <></>
                        }
                        {
                            isDestinationInsta ?
                                <InstaTransport instaTransportRec={c.instaTransportRec} /> : <></>
                        }
                    </tr>
                </tbody>
            </table>
        </div>
    )
}