import { proxy } from "valtio"

let rtpStore = proxy({
    markupView: 'list',
    markupMetadata: [],
    selectedMarkupCharge: '',
})
class CountryMarkup {
    constructor() {
        this.initialize()
    }
    initialize() {
        this.origin = ''
        this.destination = ''
        this.reworking = ''
        this.markup = 0
    }
}
class PortsMarkup {
    constructor() {
        this.initialize()
    }
    initialize() {
        this.por = ''
        this.pol = ''
        this.rep = ''
        this.pod = ''
        this.fpod = ''
        this.markup = 0
    }
}
class Markup {
    constructor() {
        this.initialize()
    }
    initialize() {
        this._id = ''
        this.defaultMarkup = 0
        this.countryMarkup = [new CountryMarkup()]
        this.portsMarkup = [new PortsMarkup()]
    }
}
let markupsStore = proxy({
    records: [],
    current: new Markup(),
    initialize: () => {
        markupsStore.current = new Markup()
    },
    newMarkupCountry: {origin: '', destination: '', markup: 0},
    newMarkupPorts: {por: '', pol: '', pod: '', fpod: ''},
    markupCountry: [],
    markupPorts: [],
})
export {
    rtpStore, markupsStore, Markup, CountryMarkup, PortsMarkup
}