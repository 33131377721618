import { useSnapshot } from "valtio"
import { configStore, contractsStore, entityStore, loginStore, bookingsStore, EntityV2Store } from "../state/CommonState"
import { $c, $cr } from '../components/AgComponents'
import EntityApi from "../state/entity-api"
import { useEffect, useState } from "react"
import Api from "../state/Api"
import { MSDS_AND_DG_FILE_MISSING_WARN, Roles, ServerLists } from "../StaticData"
import { AgCustomFile } from "../components/AgFile"
import LCLScheduleCompv2 from "./LCLScheduleComp-v2"
import BookingsApi from "../state/bookings-api"
import { incoTermsList, commodityList, hazardousList } from "../StaticData"
import BookingConfirmation from "../schedules/booking-confirmation"
import { Agyesno } from "../components/Agyesno"
import FCLScheduleCompv2 from "./FCLScheduleComp-v2"
import { AgWarnPopup } from "../components/AgCustomComponents"
import AirScheduleCompv2 from "./AirScheduleComp-v2"
import { DummySchedulev2 } from "./dummy-schedule-v2"
import BranchApi from "../state/branch-api"
import EntityV2Api from "../state/entities-api"


export default function CarrierWebFormv2(props) {
    let url = window.location.href
    const [space, setSpace] = useState('')
    const [entityData, setentityData] = useState({})
    const [companyName, setCompanyName] = useState('')
    const [incoEditable, setIncoEditable] = useState(true)
    const [ctypeEditable, setCtypeEditable] = useState(true)
    const [isWarnAccepted, setIsWarnAccepted] = useState(false);
    const [validIncoterms, setValidIncoterms] = useState(true);
    const [branches, setbranches] = useState([]);
    const [validCommodityType, setValidCommodityType] = useState(true);
    const [validbranch, setvalidbranch] = useState(true)
    const [validAddress, setvalidAddress] = useState(true)
    const [validMobile, setValidMobile] = useState(true)
    const [addressObj, setaddressObj] = useState({})
    const [validIIPCertificate, setValidIIPCertificate] = useState(true)
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [buttonClickedAtleastOnce, setbuttonClickedAtleastOnce] = useState(false);
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    let brec = bookingsStore.current
    const aguser = loginStore.userRec.aguserRec
    const [hasToAutoPopulateEntityDetail, setHasToAutoPopulateEntityDetail] = useState(false);

    useEffect(() => {
       async function setbookingdata () {
            Api.loadCountryList()
            // EntityApi.getList();
            if (contractsStore.current.incoTerms &&
                contractsStore.current.incoTerms.length > 0)
                setIncoEditable(false)
            if (contractsStore.current.commodityType &&
                contractsStore.current.commodityType.length > 0)
                setCtypeEditable(false)
            brec.firstName = aguser.firstName
            brec.lastName = aguser.lastName
            brec.phone = aguser.phone
            brec.emailid = loginStore.email
            brec.incoTerms = contractsStore.current.incoTerms
            brec.commodityType = contractsStore.current.commodityType
            brec.carrierMBL = 'No'
            brec.entityId = brec.contract.entityId
            // if(contractsStore.current.carrierMBL){
            //     brec.carrierMBL = contractsStore.current.carrierMBL
            // }else{
            //     brec.carrierMBL = 'No'
            // }
    
            // const entity = EntityV2Store.list.find(e1 => {
            //     return e1.entityId === brec.contract.entityId
            // })
            let entity =  await EntityV2Api.viewEntity(brec.contract.entityId , setentityData)
            console.log("entity" , entity);
    
            if (!entity) {
                // setCompanyName(brec.contract.gst);
                return;
            }
            let fromLoc = brec.contract.shipmentType.toLowerCase().includes('air') ?
                brec.contract.originAirport
                : brec.contract.portOfReceipt
            // let fpodCountry = brec.contract.finalPlaceOfDelivery.substring(brec.contract.portOfReceipt.indexOf(',')+1,brec.contract.portOfReceipt.indexOf("(")).trim();
    
            if (fromLoc.toLowerCase().includes(entity.entityCountry?.toLowerCase())) {
                setHasToAutoPopulateEntityDetail(true);
                brec.company = entity.entityName
                setCompanyName(entity.entityName)
                // brec.branchId=entity.branch[0].branchId
                // brec.address = entity.branch[0].branchAddresses[0].address
                brec.country = entity.branch[0].country
                // brec.pin = entity.branch[0].branchAddresses[0].pincode
                // setaddressObj(JSON.stringify(entity.branch[0].branchAddresses[0]))
            }
            else {
                // setHasToAutoPopulateEntityDetail(true);
                // brec.company = entity.entityName
                // setCompanyName(entity.entityName)
                // brec.branchId=entity.branch[0].branchId
                // brec.address = entity.branch[0].branchAddresses[0].address
                // brec.country = entity.branch[0].country
                // brec.pin = entity.branch[0].branchAddresses[0].pincode
                // setaddressObj(JSON.stringify(entity.branch[0].branchAddresses[0]))
            }
            // if(hasToAutoPopulateEntityDetail){
            //     brec.company = brec.contract.gst
            //     console.log('GOT COMPANY AS ========', brec.company, entityStore.list.length)
            //     const ent = entityStore.list.filter(e1 => e1.gst === brec.company)
            //     if (ent.length > 0) {
            //         let entity = ent[0]
            //         console.log('entity found =', entity)
            //         setCompanyName(entity.entityName)
            //         brec.address = entity.address
            //         brec.country = entity.country
            //         brec.pin = entity.pinCode
            //     } else {
            //         setCompanyName(brec.contract.gst)
            //     }
            // }
            console.log('******urgentbooking', brec)
        }
        setbookingdata()
      
    }, [bookingsStore.current])

     useEffect( () => {
        let brec = bookingsStore.current
        let entityId = brec.contract?.entityId
      BranchApi.getAllBranches(entityId, (data)=>
      {
        // if(loginStore.isClient()){
            
        // }
        setbranches(data)
      }
      )
     },[])
    useSnapshot(contractsStore)
    
    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_API_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }
    const filecat = '' + Date.now()
    let commList = commodityList
    if (brec.contract.hazardous === 'Yes') commList = hazardousList
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)

    const setBranchfn = (e)=> {
        brec.branchId = e.target.value
        brec.entityId = brec.contract.entityId
        brec.address=""
        brec.pin = ""
        setaddressObj({})
        setvalidbranch(true)
    }

    const setAddressFn = (r,i)=> {
    console.log(r,i);
    if(r.target.value !== ""){
        let data = JSON.parse(r.target.value)
        brec.address = data.address
        brec.pin = data.pincode|| data.zipcode
        setaddressObj(r.target.value)
        setvalidAddress(true)
    }
 
    }

    function checkSelectedBranch(){
       let selectedbranch = entityData.branch.filter((r,i)=>r.branchId === brec.branchId)
       return selectedbranch[0]
    //  entityData.branch
    }
    const getRespectiveMember = (customer) =>{
        if(customer?.crossBorder?.relationshipManager && customer?.crossBorder?.customerService){
            return `${customer?.crossBorder?.relationshipManager} ,${customer?.crossBorder?.customerService}`
        }else if(customer?.domesticTransport?.relationshipManager && customer?.domesticTransport?.customerService){
            console.log(customer?.domesticTransport?.relationshipManager,"***",customer?.domesticTransport?.customerService);
            return `${customer?.domesticTransport?.relationshipManager} ,${customer?.domesticTransport?.customerService}`
        }else{
            return `pat.agraga.com`
        }
    }
    const onSubmit = () => {
        setbuttonClickedAtleastOnce(true)
        setButtonClicked(true);
        let hasError = false
        if (!brec.incoTerms || brec.incoTerms.length < 1) {
            setValidIncoterms(false)
            hasError = true
        } else { setValidIncoterms(true) }
        if (!brec.commodityType || brec.commodityType.length < 1) {
            setValidCommodityType(false)
            hasError = true
        } else { setValidMobile(true) }
        if (!brec.phone || brec.phone.length < 10) {
            setValidMobile(false)
            hasError = true
        } else { setValidMobile(true) }
        if (brec.contract.hazardous === 'Yes') {
            if (!brec.iipcert_filename || brec.iipcert_filename.length < 1) {
                setValidIIPCertificate(false)
                hasError = true
            }else{
                setValidIIPCertificate(true)
            }
        }
        if (!brec.branchId || brec.branchId.length < 1) {
            // configStore.setModalMessage('Please select branch address')
            setvalidbranch(false);
            hasError= true
       
        } else { setvalidbranch(true) }
        if (!brec.address || brec.address.length < 1) {
            // configStore.setModalMessage('Please select branch address')
            setvalidAddress(false);
            hasError= true
            
        } else { setvalidAddress(true) }
        if (hasError) {
            setButtonClicked(false);
            return
        }
        if (!brec.firstName || brec.firstName.length < 1) {
            // configStore.setModalMessage('First Name is Mandatory')
            setButtonClicked(false);
            return
        }
        if (!brec.lastName || brec.lastName.length < 1) {
            // configStore.setModalMessage('Last Name is Mandatory')
            setButtonClicked(false);
            return
        }
        if (!brec.emailid || brec.emailid.length < 1) {
            // configStore.setModalMessage('Email is Mandatory')
            setButtonClicked(false);
            return
        }
      
        if (!brec.company
            // || !brec.address || !brec.pin || !brec.country
        ) {
            // configStore.setModalMessage('Please enter all company details')
            configStore.setModalMessage('Please enter shipper name.')
            setButtonClicked(false)
            return
        }
        if (!brec.country) {
            // configStore.setModalMessage('Please enter all company details')
            configStore.setModalMessage('Please select country.')
            setButtonClicked(false)
            return
        }   if (!brec.pin) {
            // configStore.setModalMessage('Please enter all company details')
            if(entityData.entityCountry.toLowerCase() === 'india'){
                configStore.setModalMessage('Please Enter PinCode')
            }else {
                configStore.setModalMessage('Please Enter ZipCode')
            }

            setButtonClicked(false)
            return
        }
        
        if (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) {
            if ((!brec.msds_filename || brec.msds_filename.length < 1) || (!brec.nondgdecl_filename || brec.nondgdecl_filename.length < 1)) {
                if (!configStore.hasToShowAgWarn && !isWarnAccepted) {
                    configStore.hasToShowAgWarn = true;
                    setButtonClicked(false);
                    return;
                }
            }
            // if (!brec.msds_filename || brec.msds_filename.length < 1) {
            //     configStore.setModalMessage('MSDS is Mandatory')
            //     return
            // }
            // if (!brec.nondgdecl_filename || brec.nondgdecl_filename.length < 1) {
            //     configStore.setModalMessage('Non-Haz Declaration is Mandatory')
            //     return
            // }
        }

        BookingsApi.saveBooking()
        let toUsers = getRespectiveMember(EntityV2Store.EntityRec.customer)
        let rec = contractsStore.current
        // let S_Email = entityStore.getCustomerSEmail(rec.gst)
        // let C_Email = entityStore.getCustomerCEmail(rec.gst)
        // let CustomerName = entityStore.getCustomer4gstOnly(rec.gst)
        let CustomerName = EntityV2Store.EntityRec.entityName
        let shipType = rec.shipmentType
        let shipScope = rec.shipmentScope
        let origin = rec.origin
        let destination = rec.destination
        setTimeout(() => {
            let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = toUsers
            if (url.indexOf('booking.agraga.com') >= 0) {
                to = to + ',sanjeev.gupta@agraga.com'
            }
            // let to = `${S_Email},${C_Email},sanjeev.gupta@agraga.com`;
            let subject = 'Booking - ' + configStore.bookingModalNum
            let message = `Hi Team,<br />
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We have received a booking from ${CustomerName}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Booking Number: ${configStore.bookingModalNum}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Shipment Type: ${shipType}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Scope :${shipScope}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Origin: ${origin}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Destination: ${destination}.</p>
            <br />
            Team Agraga</p>`

            Api.sendClientMailwithoutMessage(from, to, subject, message)
        }, 2000)


    };
    return (
        <div style={{ fontSize: '0.8rem' }}>
            <BookingConfirmation />
            <AgWarnPopup callback={() => {
                setIsWarnAccepted(true);
                onSubmit();
            }}>
                <p className="is-size-7">&emsp;&emsp;{MSDS_AND_DG_FILE_MISSING_WARN}</p>
            </AgWarnPopup>
            {
                bookingsStore.current?.hasToShowDummySchedule ? <DummySchedulev2 stype={stype()} viewonly={true} />
                    : stype() === 'LCL' ?
                        <LCLScheduleCompv2 data={bookingsStore.current.schedule} viewonly={true} /> :
                        stype() === 'FCL' ?
                            <FCLScheduleCompv2 data={bookingsStore.current.schedule} viewonly={true} /> :
                            <AirScheduleCompv2 />
            }

            <div className="columns" style={{ paddingTop:"10px"}}>
                <$cr n='2'>First Name{space}</$cr>
                <$c n='3'>
                    <input  className={`input is-small ${( !buttonClickedAtleastOnce || brec.firstName) ? '' : 'is-danger'}`} value={brec.firstName}
                        onChange={(e) => brec.firstName = e.target.value} />
                </$c>
                <$cr n='2'>Last Name</$cr>
                <$c n='3'>
                    <input  className={`input is-small ${( !buttonClickedAtleastOnce || brec.lastName) ? '' : 'is-danger'}`} value={brec.lastName}
                        onChange={(e) => brec.lastName = e.target.value} />
                </$c>
            </div>
          
            <div className="columns">
                <$cr n='2'>Email ID</$cr>
                <$c n='3'>
                    <input className={`input is-small ${( !buttonClickedAtleastOnce || brec.emailid) ? '' : 'is-danger'}`} value={brec.emailid}
                        onChange={(e) => brec.emailid = e.target.value} />
                </$c>
                <$cr n='2'>Contact Number</$cr>
                <$c n='3'>
                    <input
                        className={`input is-small ${( !buttonClickedAtleastOnce || brec.phone) ? '' : 'is-danger'}`}
                        value={brec.phone}
                        min="10"
                        onChange={(e) => brec.phone = e.target.value}
                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        }}
                    />
                </$c>

            </div>
            <div className="columns">
            <$cr n='2'>Branch{space}</$cr>
                <$c n='3'>
                <div className={`select wd_100 ${validbranch ? '' : 'is-danger'}`}>
                    <select className={`select is-fullwidth is-small ${validbranch ? '' : 'is-danger'}`} value={brec.branchId} onChange={(e)=>setBranchfn(e)}
                         >
                            <option value=""  >Select Branch</option>
                            {
                                branches?.map((r,i) => {
                                    return (<option value={r.branchId}>{`${r.state} - ${(r.gst.length>0 ? r.gst : r.tinNum)}`}</option>)
                                })
                            }
                            {/* <option>hyh</option> */}
                         </select>
                      </div>
                </$c>

            </div>
            <div className="columns">
                <$cr n='2'>Shipper Name</$cr>
                <$c n='3'>
                    {/* <div class="control">
                        <div class="select">
                            <select className="select is-fullwidth is-small" value={brec.company}
                                onChange={(e) => {
                                    brec.company = e.target.value
                                    const ent = entityStore.list.filter(e1 => e1.gst === e.target.value)[0]
                                    brec.address = ent.address
                                    brec.country = ent.country
                                    //setSpace(space===''?' ':'')
                                    console.log('Address', ent.address, brec.address)
                                }} >
                                <option value=''></option>
                                {entityStore.list.map(e => <option value={e.gst}>{e.entityName} - {e.state}</option>)}
                            </select>
                        </div>
                    </div> */}
                    {
                        hasToAutoPopulateEntityDetail
                            ? companyName
                            : (<textarea className={`textarea ${(!buttonClickedAtleastOnce ||  brec.company)? '' : 'is-danger'}`} value={brec.company}
                                onChange={(e) => brec.company = e.target.value }  />)
                    }
                </$c>
                <$cr n='2'>Address</$cr>
                <$c n='3'>
                    {
                        (hasToAutoPopulateEntityDetail && entityData.branch.length > 0) ? 
                        <div className={` select wd_100 ${validAddress ? '' : 'is-danger'}`}>
                        <select className= {`wd_100 p-1 select is-fullwidth is-small ${validAddress ? '' : 'is-danger'}`} onChange={(r,i) => setAddressFn(r,i)} value = {addressObj} 
                        // brec.pin   = e.target.value 
                        // console.log(i)
                          >{<option  value=''>select address</option>}{checkSelectedBranch()?.branchAddresses?.map((r,i)=> <option key={i} value={JSON.stringify(r)} >{r.address}</option>)}</select></div>:  <textarea className={`textarea wd_100 ${validAddress ? '' : 'is-danger'}`} value={brec.address} 
                                onChange={(e) => {brec.address = e.target.value ; setvalidAddress(true)}}/>
                                
                    }
                </$c>
            </div>
            <div className="columns is-narrow">
                <$cr n='2'>PIN/ZIP Code</$cr>
                <$c n='3'>
                    {
                        hasToAutoPopulateEntityDetail ? brec.pin
                            : <input  className={`input is-small ${(!buttonClickedAtleastOnce || brec.pin)? '' : 'is-danger'}`} value={brec.pin}
                                onChange={(e) => brec.pin = e.target.value} />
                    }
                </$c>
                <$cr n='2'>Country</$cr>
                <$c n='3'>
                    {
                        hasToAutoPopulateEntityDetail
                            ? brec.country
                            : <div class="control">
                                <div class={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.country ? '' : 'is-danger'}`}>
                                    <select  className={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.country ? '' : 'is-danger'}`}  value={brec.country}
                                        onChange={(e) => {
                                            brec.country = e.target.value
                                        }} >
                                        {ServerLists.countryNameList.map(e => <option value={e}>{e}</option>)}
                                    </select>
                                </div>
                            </div>
                    }
                </$c>
            </div>

            <div className="columns">
                <$cr n='2'>Incoterms *</$cr>
                <$c n='3'>
                    {
                        (incoEditable) ? (
                            <div className="control">
                                <div className={`select ${validIncoterms ? '' : 'is-danger'}`}>
                                    <select
                                        className={`select is-fullwidth is-small ${validIncoterms ? '' : 'is-danger'}`}
                                        value={brec.incoTerms}
                                        onChange={(e) => {
                                            brec.incoTerms = e.target.value;
                                            setValidIncoterms(true)
                                        }}
                                    >
                                        {incoTermsList.map((e) => (
                                            <option key={e} value={e}>
                                                {e}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <span style={{ color: validIncoterms ? '' : 'red' }}>{brec.incoTerms}</span>
                        )
                    }

                </$c>
                <$cr n='2'>Commodity Type *</$cr>
                <$c n='3'>
                    {
                        (ctypeEditable) ? (
                            <div className="control">
                                <div className={`select ${validCommodityType ? '' : 'is-danger'}`}>
                                    <select
                                        className={`select is-fullwidth is-small ${validCommodityType ? '' : 'is-danger'}`}
                                        value={brec.commodityType}
                                        onChange={(e) => {
                                            brec.commodityType = e.target.value;
                                            setValidCommodityType(true)
                                        }}
                                    >
                                        {commodityList.map((e) => (
                                            <option key={e} value={e}>
                                                {e}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <span style={{ color: validCommodityType ? '' : 'red' }}>{brec.commodityType}</span>
                        )
                    }

                </$c>
            </div>
            <div className="columns is-narrow">
                <$cr n='2'>Special Instructions</$cr>
                <$c n='6'>
                    <textarea rows='3' style={{ fontSize: '0.8rem' }} className="textarea" value={brec.specialInstructions}
                        onChange={(e) => brec.specialInstructions = e.target.value} />
                </$c>
                <$c n='2'>
                    {
                        // (enableMBL().disabled )
                        (contractsStore.current.shipmentType.toLowerCase().includes('fcl')
                            && contractsStore.current.shipmentScope.endsWith('ort')) ?
                            <Agyesno label='Do you require a Direct Carrier MBL?'
                                record={brec} name='carrierMBL' callback={val => brec.carrierMBL = val} /> : null
                    }
                </$c>
            </div>

            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>Required Documents</th>
                                    <th>Document Uploaded</th>
                                    <th style={{ width: '12rem' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="cwf5">
                                    <td>Commercial Invoice (Non-Mandatory)</td>
                                    <td>
                                        {brec.commercialInvoice_filename_list.map(renderLink).map(link => <>{link}<br /></>)}
                                    </td>
                                    <td>
                                        <AgCustomFile source='carrierwebform' cat={filecat} filetype='commercialInvoice' parent="cwf5" multi={true}
                                            callback={(filename) => brec.commercialInvoice_filename_list.push(filename)} withBtn={brec.commercialInvoice_filename_list.length > 0} />
                                    </td>
                                </tr>
                                <tr id="cwf6">
                                    <td>Packing List (Non-Mandatory)</td>
                                    <td>{brec.packingList_filename_list.map(renderLink).map(link => <>{link}<br /></>)}</td>
                                    <td>
                                        <AgCustomFile source='carrierwebform' cat={filecat} filetype='packingList' parent="cwf6" multi={true}
                                            callback={(filename) => brec.packingList_filename_list.push(filename)} withBtn={brec.packingList_filename_list.length > 0} />
                                    </td>
                                </tr>
                                {
                                    (
                                        (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
                                        || (brec.contract.hazardous === 'Yes')
                                    ) ?
                                        <tr id="cwf1">
                                            <td>MSDS</td>
                                            <td>{renderLink(brec.msds_filename)}</td>
                                            <td>
                                                <AgCustomFile source='carrierwebform' cat={filecat} filetype='msds' multi={false}
                                                    callback={(filename) => brec.msds_filename = filename} parent="cwf1" />
                                            </td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) ?
                                        <tr id="cwf2">
                                            <td>Non-DG Declaration <a className="is-link" href="./Non_Hazardous_Declaration.doc" download>(Download Template)</a></td>
                                            <td>{renderLink(brec.nondgdecl_filename)}</td>
                                            <td>
                                                <AgCustomFile cat={filecat} filetype='nondgdecl' multi={false}
                                                    callback={(filename) => brec.nondgdecl_filename = filename} parent="cwf2" />
                                            </td>
                                        </tr> : <></>
                                }
                                {
                                    (brec.contract.hazardous === 'Yes') ?
                                        <>
                                            <tr id="cwf3">
                                                <td>DG Declaration <a className="is-link" href="./DG_Declaration_updated.doc" download>(Download Template)</a></td>
                                                <td>{renderLink(brec.dgdecl_filename)}</td>
                                                <td>
                                                    <AgCustomFile cat={filecat} filetype='dgdecl' multi={false}
                                                        callback={(filename) => brec.dgdecl_filename = filename} parent="cwf3" />
                                                </td>
                                            </tr>
                                            <tr id="cwf4">
                                                <td >IIP Certificate *</td>
                                                <td className={validIIPCertificate ? '' : 'is-danger-border'}>{renderLink(brec.iipcert_filename)}</td>
                                                <td   >
                                                    <AgCustomFile
                                                        cat={filecat}
                                                        filetype='iipcert'
                                                        multi={false}
                                                        callback={(filename) => brec.iipcert_filename = filename}
                                                        parent="cwf4"
                                                    />
                                                </td>
                                            </tr>

                                        </> : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </$c>
            </div>
            <div className="columns">
                <$cr n='1'></$cr>
                <$c n='9'>
                    <button className="button is-warning" onClick={(e) => {
                        configStore.scheduleOrCarrierform = 'schedule'
                    }}>
                        <span className="icon" style={{ marginRight: '5px' }}>
                            <i class="fa-solid fa-circle-left"></i>
                        </span>
                        Schedules
                    </button>
                    <div class="buttons" style={{ float: 'right' }}>
                        {/* <button className="button is-danger is-right" onClick={(e) => {
                            bookingsStore.current = {};
                            configStore.homescreenCurrentView = 'list'
                        }} > Cancel </button> */}
                        <button className="button is-link" onClick={onSubmit}  disabled={isButtonClicked}>
                            Confirm and Save Booking
                        </button>
                    </div>
                </$c>
            </div>
        </div>
    )
}
