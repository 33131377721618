import { useSnapshot } from "valtio"
import { useState, useEffect } from "react"
import {
    aguserStore, configStore, cususerStore, entityStore
} from '../state/CommonState'
import EntityApi from "../state/entity-api"
import BranchApi from "../state/branch-api"
import Api from "../state/Api"

export default function UserEntityView() {
    useSnapshot(aguserStore)
    useSnapshot(entityStore)
    const user = cususerStore.cususerRec
    const [entityList, setEntityList] = useState(entityStore.list)
    const [allEntitiesList , setAllEntitiesList] = useState([])
    const [branchList , setBranchList] = useState([])
    // let prevGst = ''
    // let entityElem = {}
    // const getEntityForGst = (gst) => {
    //     if (gst === prevGst) return entityElem
    //     let data = entityList.filter(e => e.gst === gst)
    //     if (data.length > 0) entityElem = data[0]
    //     else entityElem = {}
    //     prevGst = gst
    //     return entityElem
    // }
   
    // const getGSTList = (e) => {
    //  if (e.role.toLowerCase() === "entity admin" || e.role.toLowerCase() === "entity user" ){
    //     Api.fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/list/branches/${e.entityId}`, (response) => {
    //         if(response){
    //          response.results?.map((r)=> {
    //                 return <p>{r.gst}</p>
    //           }
    //          )}
    //       });
    //   }
    // else if (e.role.toLowerCase() === "branch user"){
    //     e.gstList?.map((r)=> {
    //     return <p>{r}</p>
    //     })
    //  }
    // }
    useEffect(() => {
        let array = [...user.entityList]
        array.push(...user.mainEntityList)
        setAllEntitiesList(array)
    }, [])
    // aguser.entityList.map((e, i) => console.log(e.gst))
    return (
        <>
            <hr />
            <div className="table-container ">
                <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th>Entity</th>
                            <th>PAN/TIN</th>
                            <th>Branches</th>
                            <th>Designation</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allEntitiesList.map((e, i) => {
                                console.log(e);
                                return <tr key={i}>
                                    {/* <td>{getEntityForGst(e.gst).entityName} - {getEntityForGst(e.gst).state}</td> */}
                                    <td>{e.entityName}</td>
                                    <td>{e.entityIdentifier}</td>
                                    {/* {console.log(getGSTList(e))} */}
                                    {/* <td>{getGSTList(e)}</td> */}
                                    {/* <td>{e?.gstList?.map((r)=> {
                                       return <p>{r}</p>
                                    })}
                                    </td> */}
                                    <td>{e?.gstList?.map((r)=> {
                                       return <p>{r}</p>})}</td>
                                         <td>{e.designation}</td>
                                    <td>{e.role}</td>
                                </tr>
                            }
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
