import { useEffect, useState } from "react";
import { subscribe, useSnapshot } from "valtio";
import { AgSelect } from "../components/AgComponents";
import { Agyesno } from "../components/Agyesno";
import { bookingsStore, configStore } from "../state/CommonState";
import Igmmanifesttable from "./igm-manifest-table";
import TaskDatetime from "./task-datetime";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { myactionsConstructUploads } from "./tasks-static-data";

export default function DOreleaseocean(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    return (
        <>
            <strong>Your Delivery Order has been released</strong>
            <br></br>
            {
                (task.isBROOriginal === 'Yes') ?
                    <strong>The Consignee is a bank</strong>
                    : <></>
            }
            
            {
                (task.isOriginalEndorsedBLReceived === 'Yes') ?
                <strong>ONE Original Endorsed BL has been received</strong>
                    : <></>
            }
            <Taskfiledisplay />

            {
                (task.stage === 1) ?
                    <p>
                        <button className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 2
                                taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.setComplete()
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Acknowledge</button>
                    </p>
                    : <></>
            }
        </>
    )
}
export function DOreleaseoceanTrigger(props) {
    const [linkedAction, setLinkedAction] = useState([])
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [disableButton, setDisableButton] = useState(true)
    let task = taskStore.current
    function check(){
        let disable = false
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    useEffect(() => {
        let f = async () => {
            let b = bookingsStore.current
            let ret = await Taskapi.getTasklist4Booking(b._id)
            ret = ret.filter(e => e.actionName === 'BL Upload')
            setLinkedAction(ret)
        }
        f()
    }, [bookingsStore.current._id])
    useEffect(() => {
        if (linkedAction.length > 0) {
            let task1 = linkedAction[0]
            if (task1.isBank === 'Yes' && task1.isOriginal === 'Yes') {
                if (taskStore.current.isBROOriginal === 'No')
                {task.files = myactionsConstructUploads(['Browse & Upload the DO for Ocean'])
                taskStore.original_file = ['Browse & Upload the DO for Ocean']
            }

                else
                    {
                        task.files = myactionsConstructUploads(['Bank Endorsed copy of the BL', 'Browse & Upload the DO for Ocean'])
                        taskStore.original_file = ['Bank Endorsed copy of the BL','Browse & Upload the DO for Ocean']
                    }
             }
            if (task1.isBank === 'No' && task1.isOriginal === 'Yes') {
                if (taskStore.current.isOriginalEndorsedBLReceived === 'No')
                {
                    task.files = myactionsConstructUploads(['Browse & Upload the DO for Ocean'])
                    taskStore.original_file = ['Browse & Upload the DO for Ocean']
                }

                else
                {
                    task.files = myactionsConstructUploads(['Browse & Upload Scan copy of Endorsed BL','Browse & Upload the DO for Ocean'])
                    taskStore.original_file = ['Browse & Upload Scan copy of Endorsed BL','Browse & Upload the DO for Ocean']

                }

            }
            if(task1.isBank === 'No' && task1.isOriginal === "No") {
                    task.files = myactionsConstructUploads(['Browse & Upload the DO for Ocean'])
                    taskStore.original_file = ['Browse & Upload the DO for Ocean']
            }
        }
    }, [taskStore.current.isBROOriginal, taskStore.current.isOriginalEndorsedBLReceived])
    if (linkedAction.length === 0) return <>BL Upload for action not completed yet</>
    let task1 = linkedAction[0]
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                {
                    (task1.isBank === 'Yes' && task1.isOriginal === 'Yes') ?
                        <tr>
                            <td>
                                The Consignee is a Bank. Please confirm if the Bank Release Order has been Received in Originals?
                            </td>
                            <td>
                                <Agyesno label='No/Yes' record={taskStore.current} name='isBROOriginal' />
                            </td>
                            <td>
                                <div style={{ width: '20rem' }}></div>
                            </td>
                        </tr>
                        : <></>
                }
                {
                    (task1.isBank === 'No' && task1.isOriginal === 'Yes') ?
                        <tr>
                            <td>
                                Please confirm that ONE Original Endorsed BL has been received?
                            </td>
                            <td>
                                <Agyesno label='No/Yes' record={taskStore.current} name='isOriginalEndorsedBLReceived' />
                            </td>
                            <td>
                                <div style={{ width: '20rem' }}></div>
                            </td>
                        </tr>
                        : <></>
                }
                {
                    (task1.isBank === 'No' && task1.isOriginal === 'No') ?
                        <></>:<></>
                }
            </table>
            <Taskfiletable />

            <p>
                <button className="button islink"
                    disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}