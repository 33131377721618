import { Box, Button, Collapse, Typography } from "@mui/material"
import { HorizontalLine } from "./HorizontalDashedLine"
import logo from "../assets/images/logo.svg";
import truck from "../assets/images/truck.svg";
import prices from "../assets/images/price.svg";
import ship from "../assets/images/ship.svg";
import { breadCrumbStore, enquiryState } from "../state/CommonState";
import viewIcon from "../assets/images/view.svg";
import arrowDown from "../assets/images/arrowdown.svg";
import { useState } from "react";
import hideIcon from "../assets/images/hide.svg";
import {PricingDetailsCard} from "../mui-components/PricingDetailsCard";
import wolks from "../assets/images/wolkswagon.svg";

function Transit({days}){
    return <Box sx={{ display: "flex", flexDirection: "column", width: "9%", height: "100%", justifyContent: 'space-between', alignItems: "center" }}>
    <img src={logo} style={{ height: "45%", width: "45%" }} />
    <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "black", whiteSpace:"nowrap" }}>{days} Days</Typography>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", color: "#555555",whiteSpace:"nowrap" }}>Transit Time</Typography>
    </span>
</Box>
}

function TrackerProgress({color,routes}){
    return  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: "100%" }}>
        {routes?.map((route,i)=><>
            <Milestone color={color} stage={MileStoneEnum.CUTOFF} place={route?.value} date={route?.subValue} />
    { i < routes.length-1 && <Box sx={{ flex: 1 }}><HorizontalLine width="100%" stroke={color} /></Box>}
        </>)}
    {/* <Milestone color={color} stage={MileStoneEnum.POR} place="Mundra" date="Apr 10" />
    <Box sx={{ flex: 1 }}><HorizontalLine width="100%" stroke={color}/></Box>
    <Milestone color={color} stage={MileStoneEnum.ETD} place="Mundra" date="Apr 10" />
    <Box sx={{ flex: 1 }}><HorizontalLine width="100%" stroke={color} /></Box>
    <Milestone color={color} stage={MileStoneEnum.ETA} place="New York" date="Apr 10" />
    <Box sx={{ flex: 1 }}><HorizontalLine width="100%" stroke={color} /></Box>
    <Milestone color={color} stage={MileStoneEnum.ETA} place="Chicago" date="Apr 10" /> */}
</Box>
}

function Tracker({color,routes}){
    return  <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width:"100%", alignItems: "center", gap: 1 }}>
    <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", color: "black" }}><span style={{ fontWeight: "bold" }}>Cisco</span> America - 3143W / <span style={{ fontWeight: "bold", textTransform: "uppercase", color: "#2A6ED4" }}>INDAMEX</span></Typography>
    <TrackerProgress routes={routes}/>
</Box>
}

export function ScheduleCard({isSchedule=true}) {
    return <Box sx={{ height: "110px", width: "100%", borderRadius: "12px", backgroundColor: "white", display: 'flex', flexDirection: "row", padding: "8px 10px", alignItems: "center", justifyContent: "space-between" }}>
        <Transit days="28" />
        <Box sx={{ display: "flex",height: "100%", width:isSchedule? "75%" : "85%", alignItems: "center", gap: 1, marginRight : isSchedule ? 0 : "10px" }}>
        <Tracker/>
        </Box>
        { isSchedule && <Box>
            <Button size="small" onClick={()=>{
                breadCrumbStore.addCrumb("Cargo Details")
            }} startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} variant="contained" sx={{ textTransform: "capitalize" }}>Get Rates</Button>
        </Box>
       }
    </Box>
}

export function ContractCard({onClick}){
    return <Box sx={{ height: "110px", width: "100%", borderRadius: "12px", gap:3,backgroundColor: "#2B6ED4", display: 'flex', flexDirection: "row", padding: "8px 10px", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{display:"flex",width:"9%",justifyContent:"center",alignItems:"center"}}>
        <img style={{width:"45px",height:"45px"}} src={wolks} />
        </Box>
        <Box sx={{ display: "flex",height: "100%", flex:1, alignItems: "center", gap: 1, paddingX:"8px",flexDirection:"column" }}>
           <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"white"}}>Available Contracts!</Typography>
           <TrackerProgress color="white"/>
        </Box>
        <Box>
            <Button size="small" onClick={()=>{
               onClick();
            }} startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} variant="contained" sx={{ textTransform: "capitalize", color:"#2B6ED4",backgroundColor:"white","&:hover":{
                backgroundColor : "white"
            } }}>Use Contract</Button>
        </Box>
    </Box>
}


function FCLRate({rate,vehicle}){
    return   <span style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
    <Typography sx={{fontFamily:"Figtree",fontSize:"14px",fontWeight:"bold",color:"black",whiteSpace:"nowrap"}}>₹ {rate}</Typography>
    <Typography sx={{fontFamily:"Figtree",fontSize:"10px",color:"black",whiteSpace:"nowrap"}}>Per {vehicle}</Typography>
    </span>
}



export function ChooseScheduleCard({routes,rate}) {
    const [open,setOpen] = useState(false);
    return <Box sx={{ width: "100%", borderRadius: "12px", backgroundColor: "white", display: 'flex', flexDirection: "column"}}>
       <Box sx={{height : "110px", padding: "8px 10px", width:"100%",display:"flex",flexDirection:"row",alignItems: "center", justifyContent: "space-between" }}>
       <Transit days="28" />
        <Box sx={{ display: "flex",height: "100%", alignItems: "center", gap: 1,paddingX: "12px" }}>
            <Tracker routes={routes}/>
        </Box>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"space-evenly",alignItems: enquiryState.mode.toLowerCase() === "fcl" ? "end" : "center",height:"100%"}}>
            { enquiryState.mode.toLowerCase() === "fcl" ?  
              <Box sx={{display:"flex",flexDirection:"row",gap:1}}>
                <FCLRate rate="23,000" vehicle="20’STD"/>
                <FCLRate rate="43,000" vehicle="20’HQ"/>
                <FCLRate rate="23,000" vehicle="40’STD"/>
              </Box>
             : 
             <Typography sx={{fontFamily:"Figtree",fontSize:"14px",fontWeight:"bold",color:"black"}}>₹ {rate}</Typography>}
            <Button size="small" onClick={()=>{
                breadCrumbStore.addCrumb("Pricing Details");
            }} variant="contained" sx={{ textTransform: "capitalize" }}>Select</Button>
        </Box>
       </Box>
       <Collapse in={open} timeout="auto" unmountOnExit={false}>
          <Box sx={{display:"flex",justifyContent:"center",alignItems:  enquiryState.mode.toLowerCase() === 'fcl' ? "space-between" : "center",paddingY:"10px",gap:1}}>
              { enquiryState.mode.toLowerCase() === 'fcl' ? 
              <>
                <Box sx={{border:"1px solid #AAAAAA",borderRadius:"10px",display:"flex",width:"48%",padding:"8px 12px"}}>
                <PricingDetailsCard title="Your Account"/>
             </Box>
              <Box sx={{border:"1px solid #AAAAAA",borderRadius:"10px",display:"flex",width:"48%",padding:"8px 12px"}}>
              <PricingDetailsCard title="Consignee's Account"/>
           </Box></>
              : <Box sx={{border:"1px solid #AAAAAA",borderRadius:"10px",display:"flex",width:"50%",padding:"8px 12px"}}>
                  <PricingDetailsCard/>
               </Box>}
          </Box>
       </Collapse>
       <Box onClick={()=>{
          setOpen((prev)=>!prev);
       }} sx={{width:"100%",backgroundColor:"#2A6ED433",gap:1,justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"row",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",paddingY:"4px","&:hover":{
        cursor : "pointer"
       }}}>
         <img style={{width:"16px",height:"16px"}} src={ open ? hideIcon : viewIcon} />
         <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",fontSize:'14px',color:"black"}}>{ open ? "Hide Pricing Details" : "View Details"}</Typography>
         <img style={{width:"12px",height:"12px",transform:open ? "rotate(180deg)" : "none"}} src={arrowDown} />
       </Box>
    </Box>
}

const MileStoneEnum = {
    CUTOFF: "Cutoff",
    POR: "POR",
    ETD: "ETD",
    ETA: "ETA"
}



export function Milestone({ stage, place, date, color }) {
    return <Box sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end", gap: stage === MileStoneEnum.ETD ? 2 : 1 }}>
        {stage === MileStoneEnum.ETD ? <Box sx={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: color || "#2A6ED4" }}></Box> : <Box sx={{ border: "1px solid #AAAAAA", height: "25px", width: "25px", borderRadius: "50%", backgroundColor : color || "transparent" , display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ height: "13px", width: "13px" }} src={stage === MileStoneEnum.CUTOFF ? truck : ship} />
        </Box>}
        <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography sx={{ fontFamily: "Figtree", fontSize: "10px", color: color || "#555555", fontWeight: "bold" }}>{place}</Typography>
            <Typography sx={{ fontFamily: "Figtree", fontSize: "10px", fontWeight: "bold", color: color || "black" }}>{stage} - {date}</Typography>
        </span>
    </Box>
}