import { Box, Menu, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import logo from "../assets/images/logo.svg";
import crossborder from "../assets/images/crossbordericon.svg";
import domestic from "../assets/images/domestictransporticon.svg";
import { useEffect, useState } from "react";
import Notification from "../assets/images/notifications.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { breadCrumbStore, configStore, contractsStore, loginStore, newDashboardState } from "../state/CommonState";
import ArrowDown from "../assets/images/arrowdown.svg"
import {Dashboard} from "../pages/Dashboard.js";
import { ArrowDownward, ArrowDownwardRounded } from "@mui/icons-material";
import profile from "../assets/images/profile.svg";
import access from  "../assets/images/lock-access.svg";
import location from "../assets/images/location.svg";
import tracking from "../assets/images/Tracking.svg";
import settings from "../assets/images/settings.svg";
import help from "../assets/images/help.svg";
import signout from "../assets/images/sign-out.svg";
import AguserApi from "../state/AguserApi";
import HomeMain from "../enquiry/home-main";
import ListBookingsMainv2 from "../mybooksv2/booking-list-main-v2";
import TaskList from "../mytasks/task-list";
import { taskStore } from "../mytasks/task-store";
import { enquiryStore } from "../enquiry/enquiry-data";
import { useSnapshot } from "valtio";
import DtBookingMain from "../dt/DtBookingMain";
import DtContractMain from "../dt/DtcontractMain";
import ListEnquiry from "../enquiry/list-enquiry";
import PricingScreen2 from "../pricing/pricing.screen.v2";
import MyProfileNew from "../agusers/my-profile-new.js";
import Devices from "../components/devices.js";
import { WelcomeCard } from "./welcomecard.js";
import CustomerMyactions from "../my-actions/customer-myactions.js";
import { CustomTabBar } from "./CustomTabBar.js";
import AcceptRegistration from "../agusers/accept-registration.js";
import { EnquiriesPage } from "../pages/EnquiresPage.js";
import TrackMyBooking from "../trackmybookings/TrackMyBookings.js";
import Spotv3 from "../spotv3/spot.v3";
import Api from "../state/Api.js";
import { enquiryStorev2 } from "../enquiryv2/enquiry-data-v2.js";
import HomeMainv2 from "../enquiryv2/home-main-v2.js";
import BookingsApi from "../state/bookings-api.js";
import Taskapi from "../mytasks/tasks-api.js";

const CrossBorederTabsDetails = [
    { title: "Dashboard",component :<Dashboard/>, onClickHandler : ()=>{
        breadCrumbStore.setCurrentCrumb(0);
    } },
    /*{title : "Quick Quote v2", component : <PricingScreen2 />, onClickHandler:()=>{
        configStore.homescreenCurrentShipmentType = "LCL"
        enquiryStore.initialize();
    }},*/
    { title : "Quick Quote", component : <Spotv3 /> },
    { title: "Enquiries", component : <EnquiriesPage/>, onClickHandler : ()=>{
        configStore.showQoute = false;
        enquiryStore.initialize();
        configStore.homescreenCurrentView = "list";
    }  },
    { title: "Create bookings", component :  <HomeMainv2 /> , onClickHandler:()=>{
        // console.log("t]edhu execute aagudha  . . . . . ")
        // enquiryStore.initialize();
        if(configStore.homescreenCurrentView==="new"){
            configStore.homescreenCurrentView = "list"
        }
        // configStore.homescreenCurrentView = "list";
        // configStore.displaySchedules = false; 
    } },
    { title: "Bookings" , component :  <ListBookingsMainv2 />},
    { title : "Visibility", component : <CustomerMyactions />, },
    { title: "My Actions", component : <TaskList/>, onClickHandler : ()=>{
        taskStore.isViewAll = true;
    } },
    /*{ title : "Spot - WIP", component : <Spotv3 /> },*/
   
];

const DomesticTabDetails = [
    { title: "Dashboard",component :<Dashboard/>, onClickHandler : ()=>{
        breadCrumbStore.setCurrentCrumb(0);
    } },
    {title : "Contracts", component :  <DtContractMain/>, onClickHandler : ()=>{
        configStore.dtCurrentView = "list";
    }},
    {title : "Bookings", component : <DtBookingMain/>, onClickHandler : ()=>{
        configStore.dtCurrentView = "list";
    }}
]

// function TabContainer({ selected, title, onClick }) {
//     return <Box onClick={onClick} sx={selected ? {
//         borderBottom: "2px solid", borderBottomColor: "#2B6ED4", color: "#2B6ED4", height: "34px", "&:hover": {
//             cursor: "pointer"
//         }
//     } : {
//         color: "#555555", height: "34px", "&:hover": {
//             cursor: "pointer"
//         }
//     }}>
//         <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", fontWeight: "400", padding: '0px 10px' }}>{title}</Typography>
//     </Box>
// }



function CustomMenu({title,items,onClickHandler,currentValue}){
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const [value,setTitle] = useState(currentValue || items[0]);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        breadCrumbStore.reset();
        enquiryStore.current.shipmentType='LCL'
    },[value ])
    useEffect(()=>{
        setTitle(currentValue)
    },[currentValue])
   
  
    return <Box sx={{height :"100%", display:"flex",flexDirection:"row",marginX:"8px"}}>
        <Typography sx={{fontFamily:"Figtree",color:"#2B6ED4",fontWeight:"bolder",fontSize:'14px'}}>{title}:</Typography>
        <Box onClick={handleClick}   sx={{display:"flex",flexDirection:"row",marginLeft:"5px",alignItems:"center","&:hover":{
            cursor:"pointer"
        }}}>
            <Typography 
            sx={{color:"#555555",
            maxWidth:"200px",
            overflow:"hidden",
        display: "-webkit-box",
        whiteSpace:"normal",
        WebkitBoxOrient: 'vertical',
   WebkitLineClamp: "1",
   overflow:"hidden",
    // textOverflow:"ellipsis",
    fontSize:"14px"
        }}>{value}</Typography>
            <img style={{marginLeft:"5px"}} src={ArrowDown}/>
        </Box>
        <Menu
              sx={{height:"60%"}}
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              {items.map((item,i)=><MenuItem sx={{
                textAlign:"start",
                wordBreak:"break-word",
                whiteSpace:"normal",
                maxWidth:"300px",margin:"8px", borderRadius:"4px",
               color:"#555555",
              "&:hover":{
                 backgroundColor:"#2A6ED4",
                 color:"white"
              }}} onClick={()=>{
                
                if(onClickHandler){
                    onClickHandler(i);
                }
                 setTitle(item);            
                 setAnchorEl(null);
              }} >{item}</MenuItem>)}
            </Menu>
    </Box>
}


const profileTabs = [{
    action : "Profile",
    icon : profile,
    handler : ()=>{
        newDashboardState.isProfileSelected = true;
        configStore.currentMainMenu = <MyProfileNew/>
    }
},{
    action : "Access Control",
    icon : access,
    handler : ()=>{
        newDashboardState.isProfileSelected = true;
        configStore.currentMainMenu =  <Devices />
    }
},
// {
//     action : "Settings",
//     icon : settings
// },
// {
//     action : "Help Center",
//     icon : help
// },
{
    action : "Sign Out",
    icon : signout,
    handler : ()=>{
        let token = localStorage.getItem("devicetoken")
        let d = JSON.parse(localStorage.getItem("ag_userrec"))
        
        if(!d){
            loginStore.reinitialize()
            localStorage.removeItem('ag_email')
            localStorage.removeItem('tour')
            localStorage.removeItem('ag_current_entity')
            localStorage.removeItem('devicetoken')
            let isRememberedUser = localStorage.getItem("ag_remember_user");
            if(isRememberedUser){
                localStorage.removeItem('ag_userrec')
            }else{
                sessionStorage.removeItem('ag_userrec')
            }
            localStorage.removeItem("ag_remember_user")
        }else{
            AguserApi.logout({token:token,email:d.email , selectgst : configStore.currentSelectedBranch.gst },()=>{
                console.log("logout")
            })
        }
      
        newDashboardState.isProfileSelected = false;
    }
}
]

function ProfileAvatar({ content, commonHandler }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const items = profileTabs;
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    // useEffect(()=>{
    //     if(configStore?.currentSelectedEntity?.isTrackMyBooking?.toLowerCase()==="yes"){
    //         setItems((prev)=>[...profileTabs,{
    //             action : "Track My Bookings",
    //             icon : profile,
    //             handler : ()=>{
    //                 newDashboardState.isProfileSelected = true;
                   
    //             }
    //         }])
    //     }else{
    //         setItems(profileTabs)
    //     }
    // },[configStore.currentSelectedEntity])


    return <Box  onClick={handleClick}  sx={{display:"flex", flexDirection:"row", "&:hover":{
        cursor : "pointer"
    }}}>
    <Box sx={{  height: "30px", width: "30px", borderRadius: "50%", backgroundColor: "#2A6ED4", display: "flex", justifyContent: "center", alignItems: "center", color: "white", textTransform:"uppercase",fontSize:"12px" }}>{content}</Box>
    <img style={{marginLeft:"5px"}} src={ArrowDown}/>
    <Menu
              sx={{height:"60%"}}
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              {items.map((item)=><MenuItem
            
              sx={{
                textAlign:"start",
                wordBreak:"break-word",
                whiteSpace:"normal",
                width:"180px",margin:"8px", borderRadius:"4px",
               color:"#555555",
               "& img" :{
                filter : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(22%) hue-rotate(49deg) brightness(98%) contrast(85%)"
               },
              "&:hover":{
                 backgroundColor:"#2A6ED4",
                 color:"white",
                 '& img': {
                    filter: 'brightness(0) invert(1)',
                  },
              }}} onClick={(e)=>{
                if(item?.handler){
                    item.handler();
                }
                if(commonHandler){
                    commonHandler();
                }
                e.stopPropagation();
                 setAnchorEl(null);
              }} ><img  src={item.icon}/> <span style={{paddingLeft:"8px"}}>{item.action}</span></MenuItem>)}
            </Menu>
    </Box>
}

function ProfileSelection(){
    return <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", backgroundColor: "transparent", justifyContent: "start", gap:1 }}>
                    <WelcomeCard/>
                    {configStore.currentMainMenu}
    </Box>
}

function NotificatioIcon() {
    return <Box sx={{ height: "34px", position: "relative",display:"flex",justifyContent:"center", alignItems:"center" }}>
        <Box sx={{ height: "5px", width: "5px", borderRadius: "50%", position: "absolute", right: "5px", top: "2px", backgroundColor: "#2A6ED4" }}></Box>
        <Box sx={{
            height: "30px", width: "30px", borderRadius: "50%", backgroundColor: "#EFEFEF", display: "flex", justifyContent: "center", alignItems
                : "center"
        }}>
            <img height="14px" width="14px" src={Notification} />
        </Box>

    </Box>
}



export function AgNavBar({selectedTabIndex,setTabIndex}) {

    const [selectedTransport, setTransport] = useState(0);
    const [branchList, setbranchList] = useState([]);
    const [selectedBranch, setselectedBranch] = useState("ALL");
    const isActive  = loginStore.userRec.aguserRec.status === 'ACTIVE';
    const navigate = useNavigate();
    const location = useLocation().pathname;
      useSnapshot(loginStore)
     useSnapshot(configStore)
    //  useSnapshot(configStore.currentSelectedBranch)

   useEffect(()=> {
    setbranchList([])
    const selectedentity = loginStore.userRec.companyList.filter((r,i)=>r.entityId === configStore.currentSelectedEntity?.entityId)
    let branchess = selectedentity[0]?.branches 
    console.log(configStore.currentSelectedEntity , configStore.currentSelectedBranch);
    let branches = JSON.stringify(selectedentity[0]?.branches )
    if(branchess?.length > 1 || branchess?.length === 0){
        branches = JSON.parse(branches)
        branches.unshift({"branchId" : "ALL"})
        setbranchList(branches)
        setselectedBranch(branches[0]?.branchId)
        configStore.currentSelectedBranch= branches[0]
    }else if (branchess?.length === 1) {
        branches = JSON.parse(branches)
        setbranchList(branches)
        setselectedBranch(branches[0]?.branchId)  
        configStore.currentSelectedBranch= branches[0]
    }
   }, [])
    useEffect(() => {
        // configStore.currentSelectedEntity?.entityName
        setbranchList([])
        const selectedentity = loginStore.userRec.companyList.filter((r,i)=>r.entityId === configStore.currentSelectedEntity?.entityId)
        let branchess = selectedentity[0]?.branches 
        console.log(configStore.currentSelectedEntity , configStore.currentSelectedBranch);
        let branches = JSON.stringify(selectedentity[0]?.branches )
        if(branchess?.length > 1 || branchess?.length === 0){
            branches = JSON.parse(branches)
            branches.unshift({"branchId" : "ALL"})
            setbranchList(branches)
            setselectedBranch(branches[0]?.branchId)
        }else if (branchess?.length === 1) {
            branches = JSON.parse(branches)
            setbranchList(branches)
            setselectedBranch(branches[0]?.branchId)  
        }
      
        // configStore.currentSelectedBranch.branchId = "ALL"
        // setselectedBranch('All')
    },[configStore.currentSelectedEntity])

    useEffect(()=>{
        console.log("the location is ", location);
        console.log("the selected tab index is fdcgfcyfv txtgfcf hggdxtrc ",selectedTabIndex)
        // if(selectedTabIndex!==3){
            if (configStore.currentURL.startsWith("/contractsv2/view")) {
                const contractId = location.split('/').at(3);
                if (contractId) {
                    Api.getContractv2(contractId, (data) => {
                        if (loginStore.isClient()) {
                            let l = 1
                            loginStore.userRec.companyList.filter((e) => {
                                if (e._id == data.entityId) {
                                    configStore.currentSelectedEntity = e
                                    let branch = e.branches.filter((x,a) => (
                                        x.branchId === data.branchId))
                                    configStore.currentSelectedBranch = branch[0]
                                    l = 0
                                }
                            })
                            if (l == 0) {
                                setTabIndex(3)
                                enquiryStorev2.initializeWithConract(data)
                                //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                                configStore.homescreenCurrentView = 'view'
                                configStore.scheduleOrCarrierform = 'schedule'
                                let stype = enquiryStorev2.current.shipmentType.substr(0, 3).toLowerCase()
                                if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                                else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                                else configStore.homescreenCurrentShipmentType = 'Air'
                                configStore.homescreenCurrentShipmentScope = enquiryStorev2.current.shipmentScope
                               
                            } else {
                                window.location.href = window.location.origin+"/"
                            }
                        } 
                    })
                }
            }

            if (configStore.currentURL.startsWith("/myactions")) {
                if (loginStore.userRec.userFound) {
                    if (loginStore.isClient()) {
                        let nlink = decodeURI(configStore.currentURL)
                        nlink = nlink.split('ACTLINGACT')
                        if (nlink.length == 2) {
                            let action = nlink[1]
                            nlink = nlink[0].split('/')
                            if (nlink.length == 3) {
                                let bookingNum = nlink[2]
                                BookingsApi.getBooking(bookingNum, async (data) => {
                                    let l = 1
                                    loginStore.userRec.companyList.filter((e) => {
                                        if (e._id == data.contract.entityId) {
                                            configStore.currentSelectedEntity = e
                                            let branch = e.branches.filter((x,a) => (
                                                x.branchId === data.branchId))
                                            configStore.currentSelectedBranch = branch[0]
                                            l = 0
                                        }
                                    })
                                    if (l == 0) {
                                        setTabIndex(6)
                                        contractsStore.current = data.contract
                                        let ret = await Taskapi.getTaskForAction(bookingNum, action)
                                        if (ret.length > 0) {
                                            taskStore.current = ret[0]
                                            taskStore.currentContainer = ''
                                            taskStore.myActionVisible = true
                                        }
                                    } else {
                                        window.location.href = window.location.origin+"/"
                                    }
                                })
                            }
                        }
                    } 
                }
        
            // }
        }
    },[])

  

    useEffect(()=>{
        newDashboardState.selectedTransportMode = selectedTransport;
        breadCrumbStore.setCurrentCrumb(0);
        newDashboardState.isProfileSelected = false;
    },[selectedTransport])

    useEffect(()=>{
        if(selectedTransport===0){
            if(CrossBorederTabsDetails[selectedTabIndex]?.onClickHandler){
                CrossBorederTabsDetails[selectedTabIndex].onClickHandler();
            }
        }else if(selectedTransport===1){
            if(DomesticTabDetails[selectedTabIndex]?.onClickHandler){
                DomesticTabDetails[selectedTabIndex].onClickHandler();
            }
        }
    },[selectedTabIndex])

    // useEffect(() => {
    //     console.log("The location i s ", location.pathname.split("/"));
    //     if (location?.pathname) {
    //         let path = location.pathname.split("/");
    //         if (path[1]) {
    //             let indexAt = CrossBorederTabsDetails.findIndex(({ title }) => title.toLowerCase() === path[1]);
    //             if (indexAt === -1) {
    //                 setTabIndex(0);
    //             } else {
    //                 setTabIndex(indexAt);
    //             }
    //         }else{
    //             setTabIndex(0);
    //         }
    //     }
    // }, [location])

    const handleChange = (event, newValue) => {
        newDashboardState.isProfileSelected = false;
        setTabIndex(newValue);
        // navigate(`/${TabsDetails[newValue].title.toLowerCase()}`);
    };
    let firstName =  loginStore.userRec?.firstName?.length > 0 ? loginStore.userRec?.firstName[0] : ""
    let lastName =  loginStore.userRec?.lastName?.length > 0 ? loginStore.userRec?.lastName[0] : ""

    return <>
        <Box sx={{ height:"50%", display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", padding: "8px 12px 5px 12px"}}>

            <span style={{ display: "inline-flex" , height :"100%"}}>
                <img height="28px" width="28px" src={logo} />
                <Box sx={{ width: "10px" }}></Box>
                { isActive && <><CustomTabBar onClick={() => {
                    setTransport(0);
                }} selected={selectedTransport === 0} title="Cross-Border" icon={crossborder} />
                <Box sx={{ width: "10px" }}></Box>
                <CustomTabBar onClick={() => {
                    setTransport(1);
                }} selected={selectedTransport === 1} title="Domestic Transport" icon={domestic} />
              { configStore?.currentSelectedEntity?.isTrackMyBooking === "Yes" &&  <CustomTabBar onClick={() => {
                    setTransport(2);
                }} selected={selectedTransport === 2} title="Track My Booking" icon={tracking} />}
                </>
                }
            </span>

            <span style={{ display: "flex", gap: 3 , flexDirection:"row", alignItems:'center'}}>
            {console.log('mkmkmk',configStore.currentSelectedBranch)}
            <CustomMenu onClickHandler={async(i)=>{
                console.log("status",loginStore.userRec?.companyList[i]?.status === "DEACTIVATED");
                    if (loginStore.userRec?.companyList[i]?.status === "DEACTIVATED") {
                        let obj = {
                            cb : loginStore.userRec?.companyList[i]?.customer?.crossBorder?.relationshipManager,
                            dt:loginStore.userRec?.companyList[i]?.customer?.domesticTransport?.relationshipManager
                        }
                   await AguserApi.getProfile(obj,(data)=>{

                        return  configStore.setModalMessage(<div>Note: Your Entity is Deactivated and you have limited access rights. Please get in touch with your Agraga Relationship Manager to access all features.
                       
                       <div style={{display:"flex",margin:"1rem 0"}}>
                        {data?.CB_User?<><div  style={{width:"50%"}}>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>CB RM Name</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>Email</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>Phone NO</p>
                        </div>
                        <div  style={{width:"50%"}}>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.CB_User?.firstName}</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.CB_User?.email}</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.CB_User?.phone}</p>
                        </div></>:null}
                       </div>
                       <div  style={{display:"flex"}}>
                       {data?.DT_User?<><div  style={{width:"50%"}}>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>DT RM Name</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>Email</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>Phone NO</p>
                        </div>
                        <div  style={{width:"50%"}}>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.DT_User?.firstName}</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.DT_User?.email}</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.DT_User?.phone}</p>
                        </div></>:null}
                       </div>
                       {data?.PAT_User?<p style={{marginTop:"1rem",textAlign:"center"}}>Or Please Contact <span style={{fontWeight:900}}>{data?.PAT_User?.email}</span> for further details</p>:null}
                        </div>,"",false)
                     })
                  
                    }
                    localStorage.setItem('ag_current_entity', JSON.stringify(loginStore.userRec.companyList[i]))
                    console.log("The company list ",loginStore.userRec.companyList)
                    configStore.currentSelectedEntity = loginStore.userRec?.companyList[i];
                    if(loginStore.userRec?.companyList[i].branches.length > 1 || loginStore.userRec?.companyList[i].branches.length === 0 ){
                        configStore.currentSelectedBranch = {"branchId" : "ALL"}    
                    }else { 
                    configStore.currentSelectedBranch = loginStore.userRec?.companyList[i].branches[0]
                    }
                              
                    // configStore.currentSelectedBranch = loginStore.userRec?.companyList[i].branches[0]
                    newDashboardState.isProfileSelected = false;
                    setTransport(0);
                    if(selectedTabIndex<0){
                        setTabIndex(0);
                    }
                    // setTimeout(() => {
                    //     configStore.currentSelectedBranch = {branchId : "ALL"}
                    //     // setselectedBranch("ALL") 
                    //     // document.getElementById("custommenubranchId").onClickHandler(0)
                    // }, 3000);
            }} currentValue={configStore.currentSelectedEntity?.entityName} title="Entity" items={loginStore.userRec.companyList.map((company)=>company.entityName)} />
                       <CustomMenu 
                       id="custommenubranchId"
                       onClickHandler={(i)=>{
            //         localStorage.setItem('ag_current_entity', JSON.stringify(loginStore.userRec.companyList[i]))
            //         console.log("The company list ",loginStore.userRec.companyList)
            //         configStore.currentSelectedEntity = loginStore.userRec?.companyList[i];
            //         newDashboardState.isProfileSelected = false;
            //         setTransport(0);
            //         if(selectedTabIndex<0){
            //             setTabIndex(0);
            //         }
            // }
                  console.log("lok", i) ;
                  configStore.currentSelectedBranch = branchList[i] ;
                //   if(User.userRec.aguserRec.selectgst){
                //     User.userRec.aguserRec.selectgst = branchList[i].gst ;
                //   }
                       }
            
            }  currentValue={(configStore.currentSelectedBranch?.state?.length >0)? configStore.currentSelectedBranch?.state+ "-" + (configStore.currentSelectedBranch?.gst.length > 0 ? configStore.currentSelectedBranch?.gst  : configStore.currentSelectedBranch?.tinNum ): configStore.currentSelectedBranch?.branchId } title="Branch" items={branchList.map((r) =>r.state? (r.state+ "-" + (r.gst.length > 0 ? r.gst : r.tinNum) ) : r.branchId)} />
                {/* <CustomMenu title="Branch" items={["Chennai","Mumbai", "Bangalore","Cochin"]} /> */}
                {/* <NotificatioIcon /> */}
                <ProfileAvatar commonHandler={()=>{
                    setTabIndex(-1);
                }}  content={firstName+lastName} />
            </span>  
        </Box>
        <Box sx={{ backgroundColor: "#F0F0F0", height: "1px" }}></Box>
        <Box sx={{ display: "flex", flexDirection: 'row', margin: "0px 10px 0px 55px", height: "50%", position: 'relative' }}>
           {  isActive && selectedTransport!==2  && <span style={{ position: "absolute", bottom: "0", display: "inline-flex", gap: 10 }}>
                <Tabs style={{
                    height:"100%",
                }}  value={selectedTabIndex} onChange={handleChange}>
                    {selectedTransport === 0 ? CrossBorederTabsDetails.map((tab, i) => <Tab disableRipple value={i} sx={{ color: "#555555", textTransform: "capitalize", fontFamily: "Figtree", fontWeight: "bold" , fontSize:"12px"}} label={tab.title} />): 
                       DomesticTabDetails.map((tab, i) => <Tab disableRipple value={i} sx={{ color: "#555555", textTransform: "capitalize", fontFamily: "Figtree", fontWeight: "bold" , fontSize:"12px"}} label={tab.title} />)
                    }
                </Tabs>
            </span>}
        </Box>
        </>
}



export function  Fullscreen(){
    useSnapshot(newDashboardState)
    const { selectedTransportMode, isProfileSelected } = useSnapshot(newDashboardState)
    const [selectedTabIndex, setTabIndex] = useState(newDashboardState.selectedTab);
    const isActive  = loginStore.userRec.aguserRec.status === 'ACTIVE';
    const isNewReg  = loginStore.userRec.aguserRec.status === 'NEWREG';
    useEffect(()=>{
       setTabIndex(0);
    },[selectedTransportMode]) 
   return <Box sx={{height:"100%", padding:"8px", backgroundColor:"#EEEFF4",overflowY:"hidden",display:"flex",flexDirection:"column",gap:1}}>
    <Box sx={{ height : "15%", display: "flex",flexDirection: "column", justifyContent: "space-between", boxShadow: '1px 1px 8px 1px rgba(0, 0, 0, 0.2)', borderRadius: "10px",  backgroundColor: "white" }}>
     <AgNavBar setTabIndex={(i)=>{
        newDashboardState.selectedTab = i;
        setTabIndex(i);
     }} selectedTabIndex={selectedTabIndex} />
    </Box>
 <Box sx={{display:"flex",flexDirection:"column",paddingTop:"4px",height : "85%",overflowX:"hidden",overflowY:"auto",paddingRight:"10px","&::-webkit-scrollbar-thumb":{
backgroundColor:"#2A6ED4 !important"
        }}}>
     {  !isActive ?  <AcceptRegistration id={loginStore.email} />  : isProfileSelected ? <ProfileSelection/> : selectedTransportMode === 0 ? CrossBorederTabsDetails[selectedTabIndex]?.component : DomesticTabDetails[selectedTabIndex]?.component}
    </Box>
   </Box>
}