import { MenuItem, TextField, Select, InputAdornment } from "@mui/material";
import { CustomStyledTextField } from "./ColoredTextfield";

export function AgMuiSelect(props) {
    const { options, width, disableUnderline, backgroundColor, iconColor, size,value,onChange,startIcon,fontSize,fontColor, ...restProps } = props;
    const { style, ...rProps} = restProps;
    console.log("The rest props has ",rProps)
    return (
        <CustomStyledTextField value={value} onChange={onChange}
        inputProps={{min: 0, style: { textAlign: 'center' }}} 
        InputProps={{
            startAdornment : startIcon && (
                <InputAdornment>
                    <img style={{width:'40px',height:"40px"}} src={startIcon} />
                </InputAdornment>
            ),
            disableUnderline,
            style: {
                paddingLeft:"3px",
                fontSize : fontSize ||  "16px" ,
                color: fontColor || "black",
                backgroundColor:backgroundColor || "",
                display:"flex",
                alignItems : "center",
                "&:focused" :{
                    backgroundColor : backgroundColor || "transparent"
                },
                justifyContent:"center",
                textAlign:"center",
                ...style
            }
        }} sx={{
            maxWidth: width || "90px",
            '& .MuiSelect-icon': {
                color: iconColor || "inherit",
            },
        }} size={size || "small"} fullWidth  {...rProps} select
        >
            {options?.map((option) => <MenuItem style={{ display: 'flex', alignItems: 'center' }} value={option}>{option}</MenuItem>)}
        </CustomStyledTextField>
    );
}