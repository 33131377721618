import { useSnapshot } from "valtio"
import { configStore } from "../state/CommonState"

export default function BookingConfirmation(props) {
    useSnapshot(configStore)
    let modalClass = 'modal'
    if (configStore.bookingModalVisible) modalClass += ' is-active'
    const ListAjvErrors = () => {
        if (configStore.ajvErrors && configStore.ajvErrors.length > 0) {
            return (
                <section className="modal-card-foot">
                    <ol>
                        {configStore.ajvErrors.map(e => <li>{e}</li>)}
                    </ol>
                </section>
            )
        }
        return <></>
    }
    let href=`mailto:support@agraga.com?subject=Booking#%20${configStore.bookingModalNum}`
    return (
        <div className={modalClass}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <section className="modal-card-head" style={{textAlign: 'left', fontSize: '1.4rem', fontWeight: 'bold'}}>
                    <span style={{width:'90%'}}>Booking Confirmation</span>
                    <button className="delete" style={{ float: 'right' }}
                        onClick={() => {
                            configStore.bookingModalVisible = false
                            configStore.homescreenCurrentView = 'list'
                        }}>
                        </button>
                </section>
                
                <section className="modal-card-body" style={{ textAlign: 'center', fontSize: '1.3rem' }}>
                    <div>Booking Submitted Successfully</div>
                    <div>Thank you!</div>
                    <div>Your booking reference number : <strong>{configStore.bookingModalNum}</strong></div>
                    <div>Thank you for the information provided. Your Booking is Confirmed, and our team will get back to you along-with further information.</div>
                    <div>If you need immediate assistance, please reach out to us on +91 97318 15003 
                        or write us at&nbsp;
                        <a href={href}>support@agraga.com</a></div>
                    <div>We appreciate your patience.</div>
                    <div>Team Agraga</div>
                   
                </section>
            </div>
        </div>
    )
}