import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { configStore } from "../state/CommonState";
import Igmmanifesttable from "./igm-manifest-table";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Igmmanifest(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    return (
        <>
            <Igmmanifesttable viewOnly={true} />

            {
                (task.stage === 1) ?
                    <p>
                        <button className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 2
                                taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.setComplete()
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Acknowledge</button>
                    </p>
                    : <></>
            }
        </>
    )
}
export function IgmmanifestTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    const [disableButton, setDisableButton] = useState(true)
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            let disable = false
            if(task.preNum == 4){
                task.igmDetails.map((e) => {
                    if(e.name=='GIGM Number'){
                        if(e.text.length==0 || e.dt.length==0){
                            disable = true
                        }
                    }
                })
            }

            if(task.preNum == 1){
                task.igmDetails.map((e) => {
                    if(e.name=='Airline IGM Number'){
                        if(e.text.length==0 || e.dt.length==0){
                            disable = true
                        }
                    }
                })
            }
            
            setDisableButton(disable)
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <Igmmanifesttable />

            <p>
                <button className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}