import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Box, Collapse, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import helpList from "../static-data/helpcenter";
import { CiSearch } from "react-icons/ci";
import ReactPlayer from "react-player";



function HelpCenterAccordion({ title, open, content, src, setAccordion }) {
    return <Box sx={{ display: "flex", flexDirection: "column", paddingY: "5px" }}>
        <Box onClick={() => {
            setAccordion();
        }} sx={{
            backgroundColor: "white", "&:hover": {
                cursor: "pointer",
                // backgroundColor : "#AAAAAA"
            }, borderBottom: open ? "" : "0.5px solid #AAAAAA", display: 'flex', flexDirection: "row", justifyContent: "space-between", padding: "4px 8px"
        }}>
            <Typography sx={{ fontFamily: "Figtree", color: open ? "black" : "#555555", fontWeight: open ? "bold" : "", fontSize: "14px" }}>{title}</Typography>
            {open ? <ExpandLessRounded /> : <ExpandMoreRounded />}
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <Box sx={{ padding: "8px", display: "flex", flexDirection: "column", gap: 1 }}>
                {content?.map((point) => <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                    <Box sx={{ backgroundColor: "black", height: "4px", width: "4px", borderRadius: "50%", fontFamily: "Figtree", fontSize: "14px" }}>
                    </Box>
                    {point}
                </Box>)}
                <ReactPlayer width={"100%"} stopOnUnmount={true}  controls={true} url={ src || 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4'} />
            </Box>
        </Collapse>
    </Box>

}

function Accordions({accordions,selectedIndex,setIndex}) {
   
    return <Box sx={{width:"100%",display:"flex",flexDirection:"column",gap:1}}>
        {accordions.map((accordion, i) =>
        <div id={accordion.id+"acc"} >
            <HelpCenterAccordion src={accordion.src}  title={accordion.question}  content={accordion.content} open={i === selectedIndex} setAccordion={() => {
                if (i === selectedIndex) {
                    setIndex(-1)
                } else {
                    setIndex(i);
                }
            }} />
            </div>
        )}
    </Box>
}



export function HelpCenter({selectedQuestion=-1}){
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedIndex,setSelectedAccordion] = useState(-1)

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setSelectedAccordion(-1);
    };

    const filteredHelpList = helpList.filter((item) =>
        item.question.toLowerCase().includes(searchQuery.toLowerCase())
    );


    useEffect(()=>{
        if(selectedQuestion!==-1){
            setSelectedAccordion(selectedQuestion-1);
            setTimeout(()=>{
                const element = document.getElementById(`${selectedQuestion-1}acc`);
                element?.scrollIntoView({ behavior: 'smooth',
                block: 'start'   });
            },500)
         }
    },[])

    return <Box sx={{display:"flex",flexDirection:"column",borderRadius:"12px",backgroundColor:"white",minHeight:"100%",alignItems:"center",padding:"15px 8px"}}>
            <Box sx={{display:"flex",flexDirection:"column",width:"70%",alignItems:"center",gap:5}}>
            <TextField
                    sx={{width:"40%"}}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CiSearch style={{ fontSize: 20 }} />
                            </InputAdornment>
                        ),
                        style: { borderRadius: '57px', height: '50px' }, // Inline style for border-radius
                    }}
                    placeholder="Search Your Queries"
                />
              <Accordions selectedIndex={selectedIndex} setIndex={setSelectedAccordion} accordions={filteredHelpList} />
            </Box>
    </Box>
}