import { data } from "../charts/c1";
import Api from "./Api";
import { configStore, DriverStore, routerStore } from "./CommonState";
import { DriverType } from "./Types";
const DRIVERURI = {
  LIST: `${process.env.REACT_APP_API_SERVER}/api/v1/drivers`,
  GET: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/${id}`,
  PUT: `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/updatedriver`,
  POST: `${process.env.REACT_APP_API_SERVER}/api/v1/drivers/adddriver`,
};
export default class DriverApi {
  static async saveDriver(setloading , dataa,callback) {
    console.log("about to call api.post");
   if (dataa){
    Api.put(DRIVERURI.PUT, dataa, (data) => {
      configStore.setModalMessage("Driver Details Saved");
      if (data) {
        if (callback) {
          callback(data) 
        }
        setloading(false);
        routerStore.driverCurrentView = "list";
      }
    }, setloading);
   }else {
    Api.put(DRIVERURI.PUT, DriverStore.DriversRec, (data) => {
      configStore.setModalMessage("Driver Details Saved");
      if (data) {
        if (callback) {
          callback(data) 
        }
        setloading(false);
        routerStore.driverCurrentView = "list";
      }
    }, setloading);
   }
 
  }

  static createDriver(setloading) {
    console.log("about to call api.post");
    Api.post(DRIVERURI.POST, DriverStore.DriversRec, (data) => {
      configStore.setModalMessage("Driver Created Sucessfully");
      if (data) {
        setloading(false);
        routerStore.driverCurrentView = "list";
        DriverStore.DriversRec.set(new DriverType());
      }
    } ,setloading );
  }


  static  viewDriver(driverID, mode , callback) {
    console.log('about to call api.get')
     Api.fetch(DRIVERURI.GET(driverID), (data) => {
        console.log("dataviewvehicle", data) 
        if(callback){
          callback(data)  
        }
        DriverStore.DriversRec.set(data); 
        routerStore.driverCurrentView = mode;
       
       // DBbranchStore.BranchRec.set(data.data);  
    })
}
}
