import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import {
    quoteDataRec, fclChargesStore, containerStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"

export default function FCLChargesView2(props) {
    const imContainerStore = useSnapshot(containerStore)
    const numContainers = imContainerStore.list.length
    const containerNames = imContainerStore.list.map(e => e.containerType)

    let [space, setSpace] = useState('')

    let imChargesStore = useSnapshot(fclChargesStore)
    console.log('inside fclcharges view')
    console.log(JSON.stringify(fclChargesStore.list))

    const Tn = ({ i, name , list}) => {
        const e = list[i]
        let disabled = false
        if (e.disabled) {
            for (let i = 0; i < e.disabled.length; i++) {
                if (name.endsWith(e.disabled[i])) {
                    disabled = true
                    break
                }
            }
        }
        if (disabled)
            return (
                <div style={{ color: '#dddddd', backgroundColor: '#dddddd', border: 'none', width: '5rem' }} >.</div>
            )
        else {
            console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num) ? 0.0 : Number(num)
            return num > 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
        }
    }
    const displayRec = (e) => {
        for (let i = 0; i < containerNames.length; i++) {
            const c = containerNames[i]
            const wmKey = c + 'perWM'
            const shipmentKey = c + 'perShipment'
            const totalKey = c + 'total'
            const perWM = parseFloat(e[wmKey])
            const perShipment = parseFloat(e[shipmentKey])
            const total = parseFloat(e[totalKey])
            console.log(perWM, perShipment, total)
            if (perWM > 0 || perShipment > 0 || total > 0) return true
        }
        return false
    }
    if (!props.data) {
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < fclChargesStore.list.length; i++) {
            let e = fclChargesStore.list[i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        return <>
        {
            (listo.length>0)?<FCLChargesView2 data={listo} chargeType='On Your Account' />:""
        }
        {
            (listc.length>0)?<FCLChargesView2 data={listc} chargeType='On Consignee Account' />:""
        }
        {
            (lists.length>0)?<FCLChargesView2 data={lists} chargeType='On Shipper Account' />:""
        }
        </>
    }
    let counter = 1

    let grandTotal = {}
    let grantTotaloftotals = 0.0
    containerStore.list.map(c => {
        grandTotal[c.containerType] = 0.0
        console.log(grandTotal)
    })
    props.data.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        containerStore.list.map(c => {
            let totalField = c.containerType + 'total'
            let value = e[totalField] * erate
            grandTotal[c.containerType] += value
            grantTotaloftotals += value
        })
    })
    let checkValue = false;
    props.data.forEach(element => {
        containerNames.map((container) => {
            if((+(element[container+'perWM'])) !== 0 || (+(element[container+'perShipment'])) !== 0 || (+(element[container+'total'])) !== 0){
                checkValue = true;
            }
        })
    });
    return (
        <>
            {
                checkValue ? 
                <>
                <hr />
                <div className="table-container">
                    <table className="table is-bordered is-striped is-narrow is-hoverable ">
                        <thead>
                            <tr>
                                <th colSpan='18'>
                                    <h3 className="is-size-5 has-text-centered">{props.chargeType}</h3>
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                {containerNames.map(e => (
                                    <td className="has-text-centered has-text-weight-bold" colSpan={3}>{e}</td>
                                ))}
                                <th></th>
                            </tr>
                            <tr>
                                <th>ID</th>
                                <th>Charge Description</th>
                                <th>Currency</th>
                                {containerNames.map(e => (
                                    <>
                                        <th style={{ textAlign: 'center' }}>Per Container</th>
                                        <th style={{ textAlign: 'center' }}>Per BL</th>
                                        <th style={{ textAlign: 'right' }}>Total</th>
                                    </>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.data.map((e, i) => (
                                    displayRec(e) ?
                                        <tr key={e.id}>
                                            <td >{counter++}</td>
                                            <td>{e.chargeDescription}</td>
                                            <td>{e.currency}</td>
                                            {containerNames.map(e1 => (
                                                <>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perWM'} list={props.data} /></td>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perShipment'} list={props.data} /></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {Utils.N(e[e1 + 'total'], e.currency)}
                                                    </td>
                                                </>
                                            ))}
                                        </tr> :
                                        <></>
                                )
                                )
                            }
                            <tr>
                                <td colSpan={3}></td>
                                {
                                    containerStore.list.map(c => {
                                        return (
                                            <>
                                                <td></td>
                                                <td></td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <strong>
                                                        {Utils.N(grandTotal[c.containerType])}
                                                    </strong>
                                                </td>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td colSpan={3 + containerStore.list.length * 3}
                                    style={{ textAlign: 'right', fontSize: '1.1rem' }}>
                                    <strong>
                                        {Utils.N(grantTotaloftotals)}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3 + containerStore.list.length * 3} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                </> : <></>
            }
        </>
    )
}
