import { subscribe, useSnapshot } from "valtio";
import { useEffect,useState } from "react";
import { bookingsStore, configStore } from "../state/CommonState";
import Utils from "../util/Utils";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Actd1s1(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let m = props.action?.metaData
    if (!m) m = {}
    let task = taskStore.current
    let stage = task.stage

    if (stage >= 1) {
        return (
            <div className="box">
                <p>{m.stage1Text1}</p>
                <p>{m.stage1Text2} {Utils.formatToDateTime1(task.oneDate, task.oneTime)}</p>
                <hr />
                <p>
                    <span>
                        {
                            (stage === 1) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 2
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </div>
        )
    }
}
export function Actd1s1Trigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    const [disableButton,setDisableButton] = useState(true)
    const [depAction, setDepAciton] = useState({})

    
    let task = taskStore.current
    let stage = task.stage
    if(depAction){
        task.oneDate = depAction.validTill
    }
    let m = props.action?.metaData
    if (!m) m = {}
    useEffect(() => {
        let f = async () => {
            let ret = await Taskapi.getTaskForAction(bookingsStore.current._id,'Carrier Release Order')
            console.log('INSIDE INSIDE', ret)
            if (ret) {
                setDepAciton(ret[0])
            }
        }
        f()
        let unsubscribe = subscribe(taskStore.current, () => {
            console.log(m.stage0Text)

            if(m.stage0Text !='Delay in Container Handover'){
                // alert("hi")
                if(taskStore.current.oneDate.length > 0){
                    setDisableButton(false)
                }else{
                    setDisableButton(true)
                }
            }else if(m.stage0Text != "Empty Container Return to Carrier Pending"){
                // alert("nz")
                if(taskStore.current.oneDate.length > 0 && taskStore.current.oneTime.length > 0 ){
                    setDisableButton(false)
                }else{
                    setDisableButton(true)
                }
            }
            
        })
        return unsubscribe
    }, [taskStore.current])
    if (!depAction?._id || depAction._id?.bookingNum !== bookingsStore.current._id) {
        return (
            <>
                <br />
                <h1 className="title is-4">Carrier Release Order not found for booking</h1>
            </>
        )
    }
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <h5 className="title is-6">{m.stage0Text}</h5>
            <hr />
            <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
                <tr>
                    <td>{m.dateLabel}</td>
                    <td><input type='date' value={task.oneDate} onChange={(e) => task.oneDate = e.target.value} /></td>
                    {
                        (m.timeLabel) ?
                            <>
                                <td>{m.timeLabel}</td>
                                <td>
                                    <select style={{ width: '3rem' }} onChange={(e) => {
                                        task.oneTime = e.target.value
                                    }}>
                                        {
                                            ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                        }
                                    </select> hrs
                                </td>
                            </>
                            : <></>
                    }
                </tr>
            </table>
            <p>
                <button className="button islink"
                     disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}