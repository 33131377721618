import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import { configStore, EntityV2Store } from "../state/CommonState";
import AguserApi from "../state/AguserApi";
import { useEffect, useRef, useState } from "react";
import Dtinput from "./cps/Dtinputs";
import Dtselect from "./cps/Dtselect";
import Dtaddress from "./cps/Dtaddress";
import { commodityList } from "../StaticData";
import DtApi from "../state/dt-api";
import "./dt.css"
import { Dtyesno } from "./cps/Dtyesno";
import { loader } from "../util/loader";
import Select from 'react-select';
import EntityApi from "../state/entity-api";



export default function DtQuote() {
    useSnapshot(DtStore)
    useSnapshot(EntityV2Store)
    useSnapshot(configStore)
    useSnapshot(loader)
    const [customerlist, setCustomerlist] = useState([])
    const [salesList, setSalesList] = useState({})
    const [cmdlist, setCmdlist] = useState({})
    const [vehiclelist, setVehiclelist] = useState({})
    const [vehiclelist1, setVehiclelist1] = useState({})
    const [curlist, setCurlist] = useState({})
    const [error, setErroe] = useState([])
    const [pricinglist, setPricinglist] = useState({ "Vehicle": "vehicle", "Weight": "weight" })
    const [cursor, setCursor] = useState(0)
    const [showtc, setShowTC] = useState(false)
    const ref = useRef(null)
    const [defaultValue, setDefaultValue] = useState(null)
    let dtStore = DtStore.DT
    if (!dtStore && DtStore.view == 'new') {
        DtStore.DT = DtStore.new()
    }
    if(dtStore && dtStore['multipickup']==undefined){
        dtStore['multipickup'] = 'No'
        for (let i = 0; i < dtStore['details'].length; i++) {
            let d = dtStore['details'][i];
            if(d['pickup_2']==undefined){
                d['pickup_2'] = ''
            }
            dtStore['details'][i] = d
        }
    }
    const goList = (data)=>{
        console.log(data)
        loader.hide()
        configStore.dtCurrentView = 'list'
    }

    const checkfield = () => {
        console.log(dtStore,'dtStore');
        let k = Object.keys(dtStore)
        let e = []
        for (let i = 0; i < k.length; i++) {
            if (['pricing_type', 'details', 'tc', 'ctc','gst'].indexOf(k[i]) == -1 && dtStore[k[i]]?.trim().length == 0) {
                e.push(k[i])
            }
        }
        dtStore.details[0]['vehicle'].map((v, i) => {
            if (v['vehicle_type'].trim().length == 0) {
                e.push(`vehicle_type${i}`)
            }
        })
        let erpk = []
        for (let i = 0; i < dtStore.details.length; i++) {
            
            if (dtStore.details[i]['pickup'].trim().length == 0) {
                e.push('pickup' + i)
            }
          
            if (dtStore.details[i]['pickup_2'].trim().length == 0) {
                erpk.push('pickup_2' + i)
            }
            if(dtStore['multipickup'] != 'No'){
                if (erpk.length == dtStore.details.length) {
                    erpk.map((v, i) => {
                        e.push(v)
                    })
                }
            }
            let e1 = []
            for (let j = 0; j < dtStore.details[i]['delivery'].length; j++) {
                if (dtStore.details[i]['delivery'][j]['address'].trim().length == 0) {
                    e1.push(`address${i}${j}`)
                }
            }
            if (e1.length == dtStore.details[i]['delivery'].length) {
                e1.map((v, i) => {
                    e.push(v)
                })
            }
            let e2 = []
            
            let e3 = []
            let lsv = []
            let er = 0
            for (let j = 0; j < dtStore.details[i]['vehicle'].length; j++) {
                if (i != 0) {
                    dtStore.details[i]['vehicle'][j]['vehicle_type'] = dtStore.details[0]['vehicle'][j]['vehicle_type']
                }
                if(lsv.indexOf(dtStore.details[0]['vehicle'][j]['vehicle_type'])==-1){
                    lsv.push(dtStore.details[0]['vehicle'][j]['vehicle_type'])
                }else{
                    e.push(`vehicle_type${j}`)
                }
                if (dtStore.pricing_type == 'vehicle') {
                    if (dtStore.details[i]['vehicle'][j]['vehicle_amount'].trim().length == 0) {
                        e2.push(`vehicle_amount${i}${j}`)
                    }
                }else{
                    if (dtStore.details[i]['vehicle'][j]['amt_per_ton'].trim().length != 0 && dtStore.details[i]['vehicle'][j]['minimum'].trim().length != 0) {
                        er = 1
                    }else{
                        if (dtStore.details[i]['vehicle'][j]['amt_per_ton'].trim().length == 0) {
                            e3.push(`amt_per_ton${i}${j}`)
                        }
                        if (dtStore.details[i]['vehicle'][j]['minimum'].trim().length == 0) {
                            e3.push(`minimum${i}${j}`)
                        }
                    }
                }
            }
            if (e2.length == dtStore.details[i]['vehicle'].length) {
                e2.map((v, i) => {
                    e.push(v)
                })
            }
            if(er==0){
                e3.map((v, i) => {
                    e.push(v)
                })
            }
        }
        setErroe(e)
        if (e.length == 0) {
            return true
        } else {
            return false
        }
    }

 

    const changecur = () =>{
        for (let i = 0; i < dtStore.details.length; i++) {
            let exchn = configStore.getExchangeRate(dtStore.details[i].currency)
            for (let j = 0; j < dtStore.details[i]['vehicle'].length; j++) {
                if (dtStore.pricing_type == 'vehicle') {
                    if (dtStore.details[i]['vehicle'][j]['vehicle_amount'].trim().length > 0) {
                        dtStore.details[i]['vehicle'][j]['vehicle_amount'] = (Number(dtStore.details[i]['vehicle'][j]['vehicle_amount'].trim())*exchn).toFixed(2)
                    }
                } else {
                    if (dtStore.details[i]['vehicle'][j]['amt_per_ton'].trim().length > 0) {
                        dtStore.details[i]['vehicle'][j]['amt_per_ton'] = (Number(dtStore.details[i]['vehicle'][j]['amt_per_ton'].trim())*exchn).toFixed(2)
                    }
                    if (dtStore.details[i]['vehicle'][j]['minimum'].trim().length >0) {
                        dtStore.details[i]['vehicle'][j]['minimum'] = (Number(dtStore.details[i]['vehicle'][j]['minimum'].trim())*exchn).toFixed(2)
                    }
                }
            }
            dtStore.details[i].currency = 'INR'
        }
        return dtStore
    }

    const save = () => {
        DtStore.valid = true
        if (checkfield()) {
            loader.show()
            let d = changecur()
            if(DtStore.view != 'new'){
                DtApi.postEditDt(d,goList)
            }else{
                DtApi.postCreateDt(d,goList)
            }
        }else{
            configStore.setModalMessage('Please check the fields')
        }
    }
    useEffect(() => {
        const input = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor])
    const handleChange = (e) => {
        setCursor(e.target.selectionStart)
        dtStore.ctc = e.target.value
    }

    const setLists = (users) => {
        let agusers = users.filter(e => e.email?.endsWith('agraga.com'))
        agusers.sort((a, b) => a.firstName?.localeCompare(b.firstName))
        let l = {}
        for (let i = 0; i < agusers.length; i++) {
            l[`${agusers[i]['firstName']} ${agusers[i]['lastName']}`] = agusers[i]['email']
        }
        setSalesList(l)
    }
    const setvlist = (data) => {
         console.log("arr",data)
        let d = {}
        let d1 = {}
        if (data['Vehicle Masters']) {
            let l = data['Vehicle Masters']
            for (let i = 0; i < l.length; i++) {
                d[l[i]['Vehicle type']] = l[i]['Vehicle type']
                d1[l[i]['Vehicle type']] = l[i]
            }
            setVehiclelist(d)
            setVehiclelist1(d1)
        }
        
    }
    const checkvalid = (l) => {
        if (DtStore.valid) {
            if (error.indexOf(l) == -1) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    const changeprice = () => {
        console.log(dtStore.pricing_type)
        for (let i = 0; i < dtStore.details.length; i++) {
            for (let j = 0; j < dtStore.details[i]['vehicle'].length; j++) {
                if (dtStore.pricing_type == 'vehicle') {
                    dtStore.details[i]['vehicle'][j] = {
                        vehicle_type: "",
                        vehicle_amount: "",
                    }
                } else {
                    dtStore.details[i]['vehicle'][j] = {
                        vehicle_type: "",
                        amt_per_ton: "",
                        minimum: ""
                    }
                }
            }
        }
    }
    const addpickuppoint = () =>{
        dtStore["multipickup"] = "Yes"
    }
    const adddelivery = () => {
        for (let i = 0; i < dtStore.details.length; i++) {
            dtStore.details[i]['delivery'].push({ address: "" });
        }
    }
    const delpickup2 = ()=>{
        dtStore['multipickup'] = 'No'
        for (let i = 0; i < dtStore['details'].length; i++) {
            dtStore['details'][i]['pickup_2'] = "";
        }
    }
    const deldelivery = (z) => {
        for (let i = 0; i < dtStore.details.length; i++) {
            let d = []
            for (let j = 0; j < dtStore.details[i]['delivery'].length; j++) {
                if(j!=z){
                    d.push(dtStore.details[i]['delivery'][j])
                }
            }
            dtStore.details[i]['delivery'] = d;
        }
    }

    const addlane = () => {

        let l = {}
        l["pickup"] = ""
        l["pickup_2"] = ""
        l["currency"] = "INR"
        l["delivery_ap"] = "No"

        l['delivery'] = []
        for (let i = 0; i < dtStore.details[0]['delivery'].length; i++) {
            l['delivery'].push({ address: "" })
        }
        l['vehicle'] = []
        for (let i = 0; i < dtStore.details[0]['vehicle'].length; i++) {
            if (dtStore.pricing_type == 'vehicle') {
                l['vehicle'].push({
                    vehicle_type: "",
                    vehicle_amount: "",
                })
            } else {
                l['vehicle'].push({
                    vehicle_type: "",
                    amt_per_ton: "",
                    minimum: ""
                })
            }
        }
        dtStore.details.push(l)
    }

    const addvehicle = () => {
        for (let i = 0; i < dtStore.details.length; i++) {
            if (dtStore.pricing_type == 'vehicle') {
                dtStore.details[i]['vehicle'].push(
                    {
                        vehicle_type: "",
                        vehicle_amount: "",
                    }
                );
            } else {
                dtStore.details[i]['vehicle'].push(
                    {
                        vehicle_type: "",
                        amt_per_ton: "",
                        minimum: ""
                    }
                );
            }
        }
    }

    const delvehicle = (z) => {
        for (let i = 0; i < dtStore.details.length; i++) {
            let d = []
            for (let j = 0; j < dtStore.details[i]['vehicle'].length; j++) {
                if(j!=z){
                    d.push(dtStore.details[i]['vehicle'][j])
                }
            }
            dtStore.details[i]['vehicle'] = d;
        }
    }
    const dellane = (z) => {
        let d = []
        for (let i = 0; i < dtStore.details.length; i++) {
            if(i!=z){
                d.push(dtStore.details[i])
            }
        }
        dtStore.details = d
    }
    if(DtStore && DtStore.view != 'new' && defaultValue==null){
        for (let i = 0; i < customerlist.length; i++) {
            if(dtStore && dtStore.entityId && dtStore.entityId.length>0 && dtStore.entityId==customerlist[i]['value']){
                setDefaultValue(customerlist[i])
            }
        }
    }
    const getlist = async () => {
        if (Object.keys(customerlist).length == 0) {
            await AguserApi.getList(setLists)
        }
        EntityApi.getList()
        if (Object.keys(customerlist).length == 0) {
             let l = [];
            for (let i = 0; i < EntityV2Store?.list?.length; i++) {
                if(EntityV2Store.list[i]['entityCountry'] == "India"){
                    l.push({value:EntityV2Store.list[i]['entityId'],label:EntityV2Store.list[i]['entityName']})
                }
                // l[entityStore.list[i]['entityName']] = entityStore.list[i]['gst']
            }
            setCustomerlist(l)
        }
        if (Object.keys(cmdlist).length == 0) {
            let l = {}
            for (let i = 0; i < commodityList.length; i++) {
                l[commodityList[i]] = commodityList[i]
            }
            setCmdlist(l)
        }
        if (Object.keys(vehiclelist).length == 0) {
            
            await DtApi.getVechicleList(setvlist)

        }
        if (Object.keys(curlist).length == 0) {
            let l = {}
            let k = configStore.currencyList
            for (let i = 0; i < k.length; i++) {
                l[k[i]['currency']] = k[i]['currency']
            }
            setCurlist(l)
        }
    }
    const changecurrency = (k)=>{
        for (let i = 0; i < dtStore.details[k].vehicle.length; i++) {
            let l = dtStore.details[k].vehicle[i]
            if (dtStore.pricing_type == 'vehicle') {
                l['vehicle_amount'] = ""
            } else {
                l['amt_per_ton'] = ""
                l['minimum'] = ""
            }
        }
    }
    const changevehicle = (k) =>{
        for (let i = 0; i < dtStore.details.length; i++) {
            let l = dtStore.details[i].vehicle[k]
            l['amt_per_ton'] = ""
            l['minimum'] = ""
        }
        checkfield()
    }

    const checkmin = (i,i1) => {
        if(dtStore.details[i].vehicle[i1]['vehicle_type'].length>0){
            let l = vehiclelist1[dtStore.details[i].vehicle[i1]['vehicle_type']]["Cargo Weight Capacity (Kgs)"]/1000
            dtStore.details[i].vehicle[i1]['minimum'] = (Number(dtStore.details[i].vehicle[i1]['amt_per_ton'])*l).toFixed(2)
        }
        checkfield()
    }
    useEffect(() => {
        getlist()
    }, [])
    return (
        <>{
            (dtStore) ? <>
                <div class="columns">
                    <div class="column is-full">
                        {dtStore._id ? <h1 className="title">Quotations {`(${dtStore._id})`} - Domestic Transport</h1> :
                            <h1 className="title">Quotations New - Domestic Transport</h1>
                        }
                        <div className="columns is-multiline is-vcentered">
                            <div className="column is-one-quarter">
                            <div className="field main-content">
                                <label className="label is-small " style={{ marginBottom: '0px' }}>Customer*</label>
                                <div className= {!checkvalid('entityId')?"control is-select-danger":"control"}>
                                    {
                                        (customerlist.length>0)?<Select
                                        id="dtselect1"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={defaultValue}
                                            value={customerlist.find(customer => customer.value === dtStore.entityId)}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={customerlist}
                                            onChange={(val)=>{
                                                console.log("++++",val)
                                            if(val && val.value){
                                                dtStore.entityId = val.value
                                            }else{
                                                dtStore.entityId = ""
                                            }
                                    }}
                                />:<Select></Select>
                                    }
                                    
                                    
                                </div>
                            </div>
                                {/* <Dtselect callback={checkfield} label="Customer*" record={dtStore} name="gst" dataList={customerlist} isValid={checkvalid('gst')} /> */}
                            </div>
                            <div className="column is-one-quarter">
                                <Dtinput callback={checkfield} label="Valid Upto*" type="date" record={dtStore} name="valid_upto" isValid={checkvalid('valid_upto')} />
                            </div>
                            <div className="column is-one-quarter">
                                <Dtselect callback={checkfield} label="Relationship Manager*" record={dtStore} name="relationship_manager" dataList={salesList} isValid={checkvalid('relationship_manager')} />
                            </div>
                            <div className="column is-one-quarter">
                                <Dtselect callback={checkfield} label="Commodity Type*" record={dtStore} name="commodity_type" dataList={cmdlist} isValid={checkvalid('commodity_type')} />
                            </div>
                            <div className="column is-one-quarter">
                                <Dtselect callback={changeprice} label="Pricing Type*" record={dtStore} name="pricing_type" dataList={pricinglist} isValid={checkvalid('pricing_type')} />
                            </div>
                            {
                                (dtStore['multipickup'] == undefined || dtStore['multipickup'] == 'No')?<>
                                <div className="column is-one-quarter">
                                <div class="columns">
  <div class="column is-half"><button className="button is-link" style={{ width: "100%" }} onClick={addpickuppoint}>Add Pickup Point</button></div>
  <div class="column is-half"><button className="button is-link" style={{ width: "100%" }} onClick={adddelivery}>Add Delivery Point</button></div>

</div>
                                </div>
                                </>:<div className="column is-one-quarter">
                                <button className="button is-link" style={{ width: "100%" }} onClick={adddelivery}>Add Delivery Point</button>
                            </div>
                            }
                            
                            <div className="column is-one-quarter">
                                <button className="button is-link" style={{ width: "100%" }} onClick={addvehicle}>Add Vehicle</button>
                            </div>
                            <div className="column is-one-quarter">
                                <button className="button is-link" style={{ width: "100%" }} onClick={addlane}>Add New Lane</button>
                            </div>
                            <div className="column is-full">
                                <table className="table is-bordered scroll-table">
                                    <thead>
                                        <tr>
                                            {
                                                (dtStore['multipickup'] == undefined || dtStore['multipickup'] == 'No')?<th rowSpan={2}>Pickup Address*</th>:<>
                                                <th rowSpan={2}>Pickup Address 1*</th>
                                                <th rowSpan={2}>Pickup Address 2* <button className="button is-danger is-inverted mt-0 pt-0"
                                                        
                                                        onClick={() => {
                                                            delpickup2()
                                                        }}>
                                                        <span className="icon  mt-0 pt-0">
                                                            <i className="fas fa-trash"></i>
                                                        </span>
                                                    </button></th>
                                                </>
                                            }
                                            
                                            {
                                                dtStore.details[0]['delivery'].map((v, i) => {
                                                    return <th rowSpan={2} style={{ width: "180px" }}>Delivery Address {i + 1}*
                                                    {
                                                        (dtStore.details[0]['delivery'].length>1)?<button className="button is-danger is-inverted mt-0 pt-0"
                                                        
                                                        onClick={() => {
                                                            deldelivery(i)
                                                        }}>
                                                        <span className="icon  mt-0 pt-0">
                                                            <i className="fas fa-trash"></i>
                                                        </span>
                                                    </button>:""
                                                    }
                                                    </th>
                                                })
                                            }
                                            <th rowSpan={2}>Currency</th>
                                            {
                                                dtStore.details[0]['vehicle'].map((v, i) => {
                                                    if (dtStore['pricing_type'] == 'vehicle') {
                                                        return <th ><div className="w-200"><Dtselect callback={checkfield} label="Vehicle Type*" record={dtStore.details[0]['vehicle'][i]} name="vehicle_type" dataList={vehiclelist} isValid={checkvalid(`vehicle_type${i}`)} /> {
                                                            (dtStore.details[0]['vehicle'].length>1)?<button className="button is-danger is-inverted  mt-0 pt-0"
                                                            
                                                            onClick={() => {
                                                                delvehicle(i)
                                                            }}>
                                                            <span className="icon  mt-0 pt-0">
                                                                <i className="fas fa-trash"></i>
                                                            </span>
                                                        </button>:""
                                                        }</div> 
                                                        </th>
                                                    } else {
                                                        return <th colSpan="2"><div className="w-200"><Dtselect callback={()=>changevehicle(i)} label="Vehicle Type*" record={dtStore.details[0]['vehicle'][i]} name="vehicle_type" dataList={vehiclelist} isValid={checkvalid(`vehicle_type${i}`)} /> {
                                                            (dtStore.details[0]['vehicle'].length>1)?<button className="button is-danger is-inverted"
                                                            
                                                            onClick={() => {
                                                                delvehicle(i)
                                                            }}>
                                                            <span className="icon">
                                                                <i className="fas fa-trash"></i>
                                                            </span>
                                                        </button>:""
                                                        }</div> 
                                                        </th>
                                                    }

                                                })
                                            }
                                            <th rowSpan={2} style={{ width: "265px" }}>Delivery Appointment Needed?</th>
                                            {
                                                (dtStore.details.length>1)?<th rowSpan={2}>Action</th>:""
                                            }
                                        </tr>
                                        <tr>
                                            {
                                                dtStore.details[0]['vehicle'].map((v, i) => {
                                                    if (dtStore['pricing_type'] == 'vehicle') {
                                                        return <th >Amount</th>
                                                    } else {
                                                        return <>
                                                            <th>Amount Per Ton</th>
                                                            <th>Minimum Applicable</th>
                                                        </>
                                                    }

                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dtStore.details.map((v, i) => {
                                                return <tr>
                                                    {
                                                (dtStore['multipickup'] == undefined || dtStore['multipickup'] == 'No')?<td>
                                                <Dtaddress callback={checkfield} record={v} name="pickup" isValid={checkvalid(`pickup${i}`)} />
                                            </td>:<>
                                            <td>
                                                        <Dtaddress callback={checkfield} record={v} name="pickup" isValid={checkvalid(`pickup${i}`)} />
                                                    </td>
                                                    <td>
                                                        <Dtaddress callback={checkfield} record={v} name="pickup_2" isValid={checkvalid(`pickup_2${i}`)} />
                                                    </td>
                                                </>
                                            }
                                                    
                                                    {
                                                        v.delivery.map((v1, i1) => {
                                                            return <td>
                                                                <Dtaddress callback={checkfield} record={v1} name="address" isValid={checkvalid(`address${i}${i1}`)} />
                                                            </td>
                                                        })
                                                    }
                                                    <td>
                                                        <Dtselect record={v} callback={()=>{changecurrency(i)}} name="currency" dataList={curlist} />
                                                    </td>
                                                    {
                                                        v.vehicle.map((v1, i1) => {
                                                            if (dtStore['pricing_type'] == 'vehicle') {
                                                                return <td>
                                                                    <Dtinput callback={checkfield} type="number" record={v1} name="vehicle_amount" isValid={checkvalid(`vehicle_amount${i}${i1}`)} />
                                                                </td>
                                                            } else {
                                                                return <>
                                                                    <td>
                                                                        <Dtinput callback={()=>checkmin(i,i1)} type="number" record={v1} name="amt_per_ton" isValid={checkvalid(`amt_per_ton${i}${i1}`)} />
                                                                    </td>
                                                                    <td>
                                                                        <Dtinput callback={checkfield} type="number" record={v1} name="minimum" isValid={checkvalid(`minimum${i}${i1}`)} />
                                                                    </td>
                                                                </>
                                                            }
                                                        })
                                                    }
                                                    <td>
                                                        <Dtyesno record={v} name="delivery_ap" />
                                                    </td>
                                                    {
                                                        (dtStore.details.length>1)?<td><button className="button is-danger is-inverted"
                                                            
                                                        onClick={() => {
                                                            dellane(i)
                                                        }}>
                                                        <span className="icon">
                                                            <i className="fas fa-trash"></i>
                                                        </span>
                                                    </button></td>:""
                                                    }
                                                </tr>
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="column is-full">
                                <h3 className="title is-5">Custom Terms & Conditions</h3>
                                <textarea style={{ width: "100%" }} ref={ref} value={dtStore.ctc} onChange={handleChange} class="textarea" placeholder="" />
                            </div>
                            <div className="column is-full">
                                {showtc ? <button className='button is-warning is-small is-fullwidth'
                                    onClick={() => setShowTC(false)}>
                                    Hide Standard T&C
                                    <span className="icon" style={{ marginLeft: '5px' }}>
                                        <i className="fas fa-sort-up"></i>
                                    </span>
                                </button> :
                                    <button className='button is-info is-small is-fullwidth'
                                        onClick={() => {
                                            setShowTC(true)
                                        }}>
                                        Standard T&C
                                        <span className="icon" style={{ marginLeft: '5px' }}>
                                            <i className="fas fa-sort-down"></i>
                                        </span>
                                    </button>}

                            </div>
                            {
                                showtc ? <div className="column is-full">
                                    <ol type="1" style={{ paddingLeft: '20px' }}>
                                        {
                                            dtStore.tc.map(e => {
                                                return <li>{e}</li>
                                            })
                                        }

                                    </ol>
                                </div> : ""
                            }
                            <div className="column is-10" style={{display:"flex",justifyContent:"flex-start",width:"100%"}}>
                            <div className="column is-1">
                                <button className="button is-link" style={{ width: "100%" }} onClick={save}>Save</button>
                            </div>
                            <div className="column is-1">
                                <button className="button is-link" style={{ width: "100%" }} onClick={() => {
                                    configStore.dtCurrentView = 'list'
                                }}>Cancel</button>
                            </div>
                            
                            </div>
                            
                        </div>

                    </div>
                </div>
            </> : ""
        }


        </>
    )
}
