import ArgNoTable from "./argno";
import ArgYesTable from "./argyes";
import CusNoTable from "./cusno";
import CusYesTable from "./cusyes";
import Map from "./map";
import NouserEntityTable from "./nouserentity";
import SiteHitLogTable from "./sitehitlogtable";
import TrySignupTable from "./trysignuptable";

export default function SiteInformation() {
    return (
        <>
        <Map />
        <div class="box"><SiteHitLogTable /></div>
        <div class="box"><TrySignupTable /></div>
        <div class="box"><NouserEntityTable /></div>
        <div class="box"><CusNoTable /></div>
        <div class="box"><CusYesTable /></div>
        <div class="box"><ArgNoTable /></div>
        <div class="box"><ArgYesTable /></div>
        </>)
}