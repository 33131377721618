import React, { useEffect, useState } from "react";
import { VectorMap } from "react-jvectormap";
import SiteApi from "./siteapi";
import Chart from "react-google-charts";

export default function Map(props) {
    const [mapData, setMapDate] = useState({})
    const [data, setData] = useState({})
    const handleClick = (e, countryCode) => {
        console.log(countryCode);
    };
    const setmap = (data) => {
        if (data.length > 0) {
            setMapDate(data[0]['country'])
            setData(data[0])
        }
    }
    useEffect(() => {
        SiteApi.map(setmap)
    }, [])

    return (
        <>
        {
            (data.country)?<div class="box">
            <h1 class="title">This Month Hit World Map{(data.total) ? " - " + data.total : ""}</h1>
            <div>
                <VectorMap
                    map={"world_mill"}
                    backgroundColor="transparent" //change it to ocean blue: #0077be
                    zoomOnScroll={false}
                    containerStyle={{
                        width: "100%",
                        height: "520px"
                    }}
                    onRegionClick={handleClick} //gets the country code
                    containerClassName="map"
                    regionStyle={{
                        initial: {
                            fill: "#e4e4e4",
                            "fill-opacity": 0.9,
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0
                        },
                        hover: {
                            "fill-opacity": 0.8,
                            cursor: "pointer"
                        },
                        selected: {
                            fill: "#2938bc" //color for the clicked country
                        },
                        selectedHover: {}
                    }}
                    regionsSelectable={true}
                    series={{
                        regions: [
                            {
                                values: mapData, //this is your data
                                scale: ["#146804", "#ff0000"], //your color game's here
                                normalizeFunction: "polynomial"
                            }
                        ]
                    }}
                />
            </div>
        </div>:""
        }
            

            <div class="box">
                <h1 class="title">This Month City List</h1>
                <div class="columns is-multiline is-vcentered">
                    {
                        (data.city) ? Object.keys(data.city).map(e => {
                            return <><div class="column is-3">
                                {e}-{data.city[e]}
                            </div>
                            </>
                        }) : ""

                    }
                </div>
            </div>
            <div class="box">
                <h1 class="title">This Month Hours Chart</h1>
                {
                    (data.hours) ? <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={data.hours}
                    /> : ""

                }
            </div>
        </>)
}
