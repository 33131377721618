import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import logo from '../aglogo.svg'
import Api from '../state/Api'
import { bookingsStore, configStore, contractsStore, loginStore } from '../state/CommonState'
import Utils from '../util/Utils'
import LCLChargeClientViewv2 from './LCLChargeClientView-v2'
import { LCL, Line } from '../components/circle-line'
export default function LCLScheduleCompv2(props) {
    const [showCharges, setShowCharges] = useState(false)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    let e = { ...props.data }
    if (!e['POR (CFS / ICD / PORT)']) e['POR (CFS / ICD / PORT)'] = e['POL']
    let skipValidation = false
    if (props.skipValidation) skipValidation = true
    if (e.fromPricing == 'true') skipValidation = true
    let srec = {
        por: e['POR (CFS / ICD / PORT)'],
        pol: e['POL'],
        pod: e['POD UNLOC'],
        vessel: e['VESSEL NAME'],
        voyage: e['VOYAGE'],
        cutOffDt: e['STUFFING CUT OFF DATE'],
        etdPol: e['ETD (POL)'],
        etaPod: e['ETA (POD)'],
        coLoader: e['CO-LOADER']
    }
    const [pod, setPod] = useState({})
    const [por, setPor] = useState({})
    const [pol, setPol] = useState({})
    useEffect(() => {
        Api.get4Unloc(srec.pod, setPod)
        Api.get4Unloc(srec.por, setPor)
        Api.get4Unloc(srec.pol, setPol)
    }, [props.data])
    let rec = contractsStore.current
    if (props.contract) rec = props.contract
    if (!rec) return <></>
    console.log('REC INSIDE LCLCOMP=', rec)
    let showOrigin = false
    let showDestination = false
    if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
    if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
    let etaDate = Utils.newDate(srec.etaPod)
    let etdDate = Utils.newDate(srec.etdPol)
    let cutOffDate = Utils.newDate(srec.cutOffDt)
    let days = Utils.getDays(etdDate, etaDate)
    let startDate = Utils.newDate(rec.mailedOn)
    startDate = Utils.addDays(startDate, 3)
    // The below overrides the above startDate and cutoff. The below considers only the current date
    // and not the quote start date
    let currentDate = new Date()
    let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
        new Date(rec.cargoReadinessDate) : currentDate
    if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
    if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
    cutOffDate = Utils.newDate(srec.etdPol)
    let startDays = (srec.pol === srec.por) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
    cutOffDate = Utils.addDays(cutOffDate, startDays * -1)
    startDate = Utils.addDays(cargoReadinessDate, startDays)

    if (etdDate < startDate && !props.showbooking && !skipValidation) {
        if (loginStore.isClient()) return <></>
        return <div>
            POR = {srec.por}, POL = {srec.pol}, CRD = {cargoReadinessDate.toISOString().substring(0, 10)},
            ETD = {etdDate.toISOString().substring(0, 10)},
            Start Date as calculated = {startDate.toISOString().substring(0, 10)}<br />
            CONDITION FAILED = ETD less than START<hr />
        </div>
    }

    let endDate = Utils.newDate(rec.validUpto)
    let endDays = por.location !== pol.location ? 8 : 2
    endDate = Utils.addDays(endDate, endDays * -1)
    // the below overrides the above
    endDate = Utils.newDate(rec.validUpto)
    if (etdDate > endDate && !props.showbooking && !skipValidation) {
        if (loginStore.isClient()) return <></>
        return <div>
            POR = {srec.por}, POL = {srec.pol}, CRD = {cargoReadinessDate.toISOString().substring(0, 10)},
            ETD = {etdDate.toISOString().substring(0, 10)},
            End Date as calculated = {endDate.toISOString().substring(0, 10)}<br />
            CONDITION FAILED = ETD greater than END<hr />
        </div>
    }
    let voldis = 0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
        }
    })
    let usdRate = configStore.getExchangeRate('USD')
    let voldisusd = voldis / usdRate
    if(voldis>0){
        voldis = -(voldis)
        voldisusd = -(voldisusd)
    }
    console.log("+++___",voldis)
    //console.log('LCLCOMPONENT***',srec, srec.por, srec.pol, por.location, pol.location)
    return (
        <div className="box">
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                <div className="column is-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    <img src={logo} />
                </div>
                <div className="column is-9">
                    <table className='schtable' style={{ width: '100%', textAlign: 'center' }}>
                        <tr>
                            <td></td>
                            <td>
                            </td>
                            <td colSpan={2}>
                                <div style={{ color: 'grey' }}>Total Transit Time</div>
                                <div style={{ fontSize: '0.9rem' }}><strong>{days} days</strong></div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Cut-off&nbsp;
                                {Utils.formatDate(cutOffDate)}
                            </td>
                            <td></td>
                            <td>
                                {e['VESSEL NAME']}
                            </td>
                            <td> ETA&nbsp;
                                {Utils.formatDate(etaDate)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {
                                    (showOrigin) ? rec.origin : ''
                                }
                            </td>
                            <td>
                                {
                                    (por.location !== pol.location) ?
                                        por.location : pol.location
                                }

                            </td>
                            <td>
                                {
                                    (por.location !== pol.location) ?
                                        pol.location : <></>
                                }
                            </td>
                            <td>

                            </td>
                            <td>
                                {pod.location}
                            </td>
                            <td>
                                {
                                    (showDestination) ? rec.destination : ''
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>{showOrigin ? <LCL origin='1' /> : <></>}</td>
                            <td>{showOrigin ? <LCL /> : <LCL left='1' />}</td>
                            <td>{por.location !== pol.location ? <LCL /> : <Line />}</td>
                            <td><Line /></td>
                            <td>{showDestination ? <LCL /> : <LCL right='1' />}</td>
                            <td>{showDestination ? <LCL destination='1' /> : <></>}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                {
                                    (por.location === pol.location) ?
                                        'ETD ' + Utils.formatDate(etdDate) : <></>
                                }
                            </td>
                            <td>
                                {
                                    (por.location !== pol.location) ?
                                        'ETD ' + Utils.formatDate(etdDate) : <></>
                                }
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='column is-2' style={{ textAlign: 'center' }}>
                {
                        ((rec.quoteValue+voldis) < 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <>
                            <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue+voldis)}</strong></div>
                            <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD+voldisusd, 'USD')}</div>
                            </>
                    }
                    
                    <div style={{ fontSize: '0.6rem', color: 'grey' }}>(Taxes, as Applicable)</div>
                    {
                        ((!props.viewonly && !loginStore.isClient()) || (!props.viewonly && rec.quoteStatus == 'ACCEPTED' && loginStore.isClient())) ?
                            <button className='button is-rounded is-link is-small' onClick={() => {
                                bookingsStore.initialize()
                                bookingsStore.current.schedule = props.data
                                bookingsStore.current.contract = { ...rec }
                                bookingsStore.current.contractNum = rec._id
                                configStore.scheduleOrCarrierform = 'carrierform'
                            }}>
                                Proceed to Booking
                            </button> : <></>
                    }
                </div>
            </div>
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                {
                    (showCharges) ?
                        <button className='button is-warning is-small is-fullwidth' onClick={() => setShowCharges(false)}>
                            Hide Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> :
                        <button className='button is-info is-small is-fullwidth' onClick={() => setShowCharges(true)}>
                            View Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }

            </div>
            {
                (showCharges) ?
                <div className="columns" style={{
                    fontSize: '0.8rem',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <LCLChargeClientViewv2  />
                </div> : <></>
            }
        </div>
    )
}


export function LCLScheduleActionCompv2(props) {
    const [showCharges, setShowCharges] = useState(false)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    let e = { ...props.data }
    if (!e['POR (CFS / ICD / PORT)']) e['POR (CFS / ICD / PORT)'] = e['POL']
    let srec = {
        por: e['POR (CFS / ICD / PORT)'],
        pol: e['POL'],
        pod: e['POD UNLOC'],
        vessel: e['VESSEL NAME'],
        voyage: e['VOYAGE'],
        cutOffDt: e['STUFFING CUT OFF DATE'],
        etdPol: e['ETD (POL)'],
        etaPod: e['ETA (POD)'],
        coLoader: e['CO-LOADER']
    }
    const [pod, setPod] = useState({})
    const [por, setPor] = useState({})
    const [pol, setPol] = useState({})
    useEffect(() => {
        Api.get4Unloc(srec.pod, setPod)
        Api.get4Unloc(srec.por, setPor)
        Api.get4Unloc(srec.pol, setPol)
    }, [props.data])
    let rec = contractsStore.current
    if (props.contract) rec = props.contract
    if (!rec) return <></>
    console.log('REC INSIDE LCLCOMP=', rec)
    let showOrigin = false
    let showDestination = false
    if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
    if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
    let etaDate = Utils.newDate(srec.etaPod)
    let etdDate = Utils.newDate(srec.etdPol)
    let cutOffDate = Utils.newDate(srec.cutOffDt)
    let days = Utils.getDays(etdDate, etaDate)
    let startDate = Utils.newDate(rec.mailedOn)
    startDate = Utils.addDays(startDate, 3)
    // The below overrides the above startDate and cutoff. The below considers only the current date
    // and not the quote start date
    let currentDate = new Date()
    let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
        new Date(rec.cargoReadinessDate) : currentDate
    if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
    if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
    cutOffDate = Utils.newDate(srec.etdPol)
    let startDays = (srec.pol === srec.por) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
    cutOffDate = Utils.addDays(cutOffDate, startDays * -1)
    startDate = Utils.addDays(cargoReadinessDate, startDays)

    

    let endDate = Utils.newDate(rec.validUpto)
    let endDays = por.location !== pol.location ? 8 : 2
    endDate = Utils.addDays(endDate, endDays * -1)
    // the below overrides the above
    endDate = Utils.newDate(rec.validUpto)
    let voldis = 0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
        }
    })
    let usdRate = configStore.getExchangeRate('USD')
    let voldisusd = voldis / usdRate
    if(voldis>0){
        voldis = -(voldis)
        voldisusd = -(voldisusd)
    }
    //console.log('LCLCOMPONENT***',srec, srec.por, srec.pol, por.location, pol.location)
    return (
        <div className="box">
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                <div className="column is-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    <img src={logo} />
                </div>
                <div className="column is-9">
                    <table className='schtable' style={{ width: '100%', textAlign: 'center' }}>
                        <tr>
                            <td></td>
                            <td>
                            </td>
                            <td colSpan={2}>
                                <div style={{ color: 'grey' }}>Total Transit Time</div>
                                <div style={{ fontSize: '0.9rem' }}><strong>{days} days</strong></div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Cut-off&nbsp;
                                {Utils.formatDate(cutOffDate)}
                            </td>
                            <td></td>
                            <td>
                                {e['VESSEL NAME']}
                            </td>
                            <td> ETA&nbsp;
                                {Utils.formatDate(etaDate)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {
                                    (showOrigin) ? rec.origin : ''
                                }
                            </td>
                            <td>
                                {
                                    (por.location !== pol.location) ?
                                        por.location : pol.location
                                }

                            </td>
                            <td>
                                {
                                    (por.location !== pol.location) ?
                                        pol.location : <></>
                                }
                            </td>
                            <td>

                            </td>
                            <td>
                                {pod.location}
                            </td>
                            <td>
                                {
                                    (showDestination) ? rec.destination : ''
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>{showOrigin ? <LCL origin='1' /> : <></>}</td>
                            <td>{showOrigin ? <LCL /> : <LCL left='1' />}</td>
                            <td>{por.location !== pol.location ? <LCL /> : <Line />}</td>
                            <td><Line /></td>
                            <td>{showDestination ? <LCL /> : <LCL right='1' />}</td>
                            <td>{showDestination ? <LCL destination='1' /> : <></>}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                {
                                    (por.location === pol.location) ?
                                        'ETD ' + Utils.formatDate(etdDate) : <></>
                                }
                            </td>
                            <td>
                                {
                                    (por.location !== pol.location) ?
                                        'ETD ' + Utils.formatDate(etdDate) : <></>
                                }
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='column is-2' style={{ textAlign: 'center' }}>
                {
                        ((rec.quoteValue+voldis) < 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <>
                            <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue+voldis)}</strong></div>
                            <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD+voldisusd, 'USD')}</div>
                            </>
                    }
                    
                    <div style={{ fontSize: '0.6rem', color: 'grey' }}>(Taxes, as Applicable)</div>
                    {
                        ((!props.viewonly && !loginStore.isClient()) || (!props.viewonly && rec.quoteStatus == 'ACCEPTED' && loginStore.isClient())) ?
                            <button className='button is-rounded is-link is-small' onClick={() => {
                                bookingsStore.initialize()
                                bookingsStore.current.schedule = props.data
                                bookingsStore.current.contract = { ...rec }
                                bookingsStore.current.contractNum = rec._id
                                configStore.scheduleOrCarrierform = 'carrierform'
                            }}>
                                Proceed to Booking
                            </button> : <></>
                    }
                </div>
            </div>
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                {
                    (showCharges) ?
                        <button className='button is-warning is-small is-fullwidth' onClick={() => setShowCharges(false)}>
                            Hide Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> :
                        <button className='button is-info is-small is-fullwidth' onClick={() => setShowCharges(true)}>
                            View Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }

            </div>
            {
                (showCharges) ?
                    <div className="columns" style={{
                        fontSize: '0.8rem',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <LCLChargeClientViewv2 /> 
                    </div> : <></>
            }
        </div>
    )
}