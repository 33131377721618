import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { bookingsStore, configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
async function callback(e) {
    taskStore.current.stage = 2
    taskStore.current.pendingWith = 'AGRAGA'
    taskStore.setCustomerComplete()
    await Taskapi.updateTask(taskStore.current)
    taskStore.triggerActionVisible = false
    taskStore.myActionVisible = false
}
export default function Blrelease(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage === 1) {
        return (
            <div className="box">
                <tr>
                    <td style={{ width: '20rem' }}>Type of BL:</td>
                    <td>
                        <select value={task.blType} onChange={(e) => {
                            task.blType = e.target.value
                        }}>
                            <option value='Seaway'>Seaway</option>
                            <option value='Original'>Original</option>
                        </select>
                    </td>
                </tr>
                <Taskfiledisplay />
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={callback}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage >= 2) {
        return (
            <>
                <tr>
                    <td style={{ width: '20rem' }}>Type of BL:</td>
                    <td>
                        <strong>{task.blType}</strong>
                    </td>
                </tr>
                <br/>
                <Taskfiledisplay />
                <p>
                    <span>
                        {
                            (stage === 2) ?
                                <button style={{ float: 'right' }} className="button islink"
                                    onClick={async (e) => {
                                        //let task = taskStore.current
                                        task.stage = 3
                                        taskStore.setComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </>
        )
    }
}
export function BlreleaseTrigger(props) {
    const [depAction, setDepAciton] = useState({})
    useSnapshot(taskStore)
    useSnapshot(bookingsStore)
    useSnapshot(configStore)
    const [disableButton, setDisableButton] = useState(true)
    let task = taskStore.current
    let stage = task.stage
    useEffect(() => {
        let f = async () => {
            let ret = await Taskapi.getTaskForAction(bookingsStore.current._id, 'BL Draft Approval')
            console.log('INSIDE INSIDE', ret)
            if (ret) {
                setDepAciton(ret[0])
            }
        }
        f()
        let unsubscribe = subscribe(taskStore.current, () => {
            if (taskStore.current.dueDate.length > 0) {
                setDisableButton(false)
            }else{
                setDisableButton(true)
            }
        })
        return unsubscribe
    }, [bookingsStore.current, taskStore.current])
    if (!depAction?._id || depAction._id?.bookingNum !== bookingsStore.current._id) {
        return (
            <>
                <br />
                <h1 className="title is-4">BL Draft Approval not found for booking</h1>
            </>
        )
    }
    return (
        <>
            <TaskDatetime />
            <br /><br /><br />
            <p>
                <button className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.files = depAction.files
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}