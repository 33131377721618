import React from 'react'
// import Select from 'react-select'

// const statusOptions = [
//     {'value':'', 'label':'All'},
//     {'value':'Active', 'label':'Active'},
//     {'value':'Inactive', 'label':'Inactive'},
//     {'value':'Pending', 'label':'Pending'},
// ]

const TableFilter = ({onClickFilterCallback, defaultKeyword}) => {
    const [keyword, setKeyword] = React.useState(defaultKeyword)
    const onKeywordChange = ( e ) => {
      setKeyword( e.target.value )
      onClickFilterCallback(e.target.value) 
    }
    // const onClickSearch = () => {
    //     onClickFilterCallback(keyword)
    // }
    return (
        <div className="field has-addons">
      <p className="control">
      <input 
                    className='input'
                        value={keyword}
                        onChange={onKeywordChange}
                        type="text"
                        pattern="^[a-zA-Z0-9]+$"
                        placeholder="Search"
                    />
      </p>
      {/* <p className="control">
        
        <span className="button"  onClick={onClickSearch}>Search</span>
      </p> */}
    </div>
        
    )
}

export default TableFilter;