import { Box, IconButton, Typography } from "@mui/material";
import uploadIcon from "../assets/images/upload.svg";
import { useRef, useState } from "react";
import closecontained from "../assets/images/closecontained.svg";

export function ChooseFile({title}){
    const [fileList, setFileList] = useState([]);
    const fileInputRef = useRef(null);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          setFileList([...fileList, selectedFile]);
        }
      };
      const handleBoxClick = () => {
        fileInputRef.current.click();
      };

      const removeFile = (i)=>{
          setFileList((prev)=>{
              prev.splice(i,1);
              return [...prev];
          })
      }
      
      const handleBoxDrop = (event) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles.length > 0) {
          setFileList([...fileList, droppedFiles[0]]);
        }
      };

    return <Box sx={{display:"flex",flexDirection : "column",gap:1}}>
        <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"12px",color:"black"}}>{title} <span sx={{color:"#555555"}}>(Non-Mandotory)</span></Typography>
        <Box  onDrop={handleBoxDrop}
  onDragOver={(e) => e.preventDefault()} sx={{border:"1px dashed #2A6ED4",borderRadius:"7px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"100%"}}>
            <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
            <IconButton onClick={()=>{
           handleBoxClick();
        }}>
                <img  src={uploadIcon} />
            </IconButton>
            <Box sx={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:"center",padding:"8px"}}>
                <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"#2A6ED4  "}} >Click to upload</Typography>
                <Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"#AAAAAA"}} >or just drag and drop</Typography>
            </Box>
        </Box>
       <Box sx={{flex:1,width:"100%",display:"flex",flexDirection:"column",gap:1}}>
       {fileList?.map((file,i)=>
       <Box sx={{backgroundColor:"#F5F5F5",width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",padding:"8px",alignItems:"center",borderRadius:"5px"}}><Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:'12px'}}>{file.name}</Typography><IconButton sx={{padding:0}} onClick={()=>{
              removeFile(i)
       }}><img src={closecontained} /></IconButton></Box>)}
       </Box>
    </Box>
}