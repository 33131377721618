import Api from "./Api"
import { aguserStore, configStore, loginStore,routerStore } from "./CommonState"
const AGUSERURI = {
    LIST: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser`,
    AUDITLIST: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/get/audit`,
    GET: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/${id}`,
    PUT: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser`,
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser`,
    GETROLES: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/get/roles`,
    GET4ROLE: (role) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/get/role/${role}`,
    UPDATESTATUS: (email, status) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/updatestatus/${email}/${status}`,
    SETPASSWORD: (email, password) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/setnewpassword/${email}/${password}`,
    TEMPUSER:`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/tempregister`,
    REGISTER: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/register`,
    REGISTER2: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/registerv2`,
    FORGOTPASSWORD: (email) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/forgotpassword/${email}`,
    LOGOUT: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/logout`,
    DEVICELIST: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/devicelist/get`,
    UPDATETOUR: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/updatetour`,
    GETPROFILE:`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/profile/getprofile`,
}
export default class AguserApi {
    static getUsers4role(role, callback = f => f) {
        Api.fetch(AGUSERURI.GET4ROLE(role), (data) => {
            console.log('fetched users for role', role)
            data.sort()
            const list = [{ email: '', firstName: '', lastName: '' }, ...data]
            callback(list)
        })
    }
    static getRoles(callback = f => f) {
        Api.fetch(AGUSERURI.GETROLES, (data) => {
            console.log('fetched roles')
            callback(data)
            console.log('done calling callback for roles')
        })
    }
    static getList(callback = f => f) {
        Api.fetch(AGUSERURI.LIST, (data) => {
            aguserStore.list = data
            callback(data)
        })
    }
    static getAuditList(callback = f => f) {
        Api.fetch(AGUSERURI.AUDITLIST, (data) => {
            aguserStore.auditList = data
            callback(data)
        })
    }
    static getUser(id, callback = f => f) {
        Api.fetch(AGUSERURI.GET(id), (data) => {
            aguserStore.aguserRec = data
            callback(data)
        })
    }
    static updateUserStatus(id, status) {
        Api.fetch(AGUSERURI.UPDATESTATUS(id, status))
    }
    static setNewPassword(id, password, callback) {
        Api.fetch(AGUSERURI.SETPASSWORD(id, password), callback)
    }
    static saveUser() {
        console.log('about to call api.put')
        Api.put(AGUSERURI.PUT, aguserStore.aguserRec, (data) => {
            configStore.setModalMessage('User Details Saved')
            routerStore.agusersCurrentView ="list"
        })
    }
    static createUser(callback = f => f) {
        console.log('about to call api.post')
        Api.post(AGUSERURI.POST, aguserStore.aguserRec, (data) => {
            callback(true)
            configStore.setModalMessage('User Details Saved')
            routerStore.agusersCurrentView ="list"
        })
    }
    static registerUser(callback = f => f) {
        console.log('about to call api.post')
        let aguser = aguserStore.aguserRec
        Api.post(AGUSERURI.POST, aguserStore.aguserRec, (data) => {
            let from = `Agraga Admin <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = aguser.email
            let subject = 'User Registration'
            let message = `
                <p>Please click on <<server>> to access the applicaiton.</p>
                <p>Use the following credentials to log on and complete Registration</p>
                <table>
                <tbody>
                <tr>
                <td>User Name</td><td>${aguser.email}</td>
                </tr>
                <tr>
                <td>Password</td><td>${aguser.password}</td>
                </tr>
                </tbody>
                </table>
                <p></p><p>Team Agraga</p>`
            console.log('CALLING REGISTRATION MAIL WITH', from, to, subject, message)
            Api.sendClientMail(from, to, subject, message, 'Registration')
        })
    }

    static registerNewUserV2(reqbody,callback = f => f) {
        Api.post(AGUSERURI.REGISTER2, reqbody , (data) => {
            callback(data)
        })
    }

    static registerNewUser(callback = f => f) {
        Api.post(AGUSERURI.REGISTER, aguserStore.aguserRec, (data) => {
            callback(data)
        })
    }
    static tempUser(callback = f => f) {
        Api.post(AGUSERURI.TEMPUSER, aguserStore.aguserRec, (data) => {
            callback(data)
        })
    }
    static registerExistingUser(aguser, callback = f => f) {
        console.log('about to call api.post')
        Api.put(AGUSERURI.PUT, aguser, (data) => {
            let from = `Agraga Admin <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = aguser.email
            let subject = 'User Registration'
            let message = `
                <p>Please click on <<server>> to access the applicaiton.</p>
                <p>Use the following credentials to log on</p>
                <table>
                <tbody>
                <tr>
                <td>User Name</td><td>${aguser.email}</td>
                </tr>
                <tr>
                <td>Password</td><td>${aguser.password}</td>
                </tr>
                </tbody>
                </table>
                <p></p><p>Team Agraga</p>`
            console.log('CALLING REGISTRATION MAIL WITH', from, to, subject, message)
            Api.sendClientMail(from, to, subject, message, 'Registration')
        })
    }
    static signUpUser(user, callback = f => f) {
        console.log('about to call api.post')
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 12;
        let password = "";
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        user.password = password
        Api.post(AGUSERURI.POST, user, (data) => {
            let u = loginStore.userRec.aguserRec
            let from = u.firstName + ' ' + u.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = user.email
            let subject = 'User Invitation'
            let message = `
            <p>You have been registered by your Administrator</p>
                <p>Please click on <<server>> to access the Application.</p>
                <p>Use the following credentials to log on</p>
                <table>
                <tbody>
                <tr>
                <td>User Name</td><td>${user.email}</td>
                </tr>
                <tr>
                <td>Password</td><td>${user.password}</td>
                </tr>
                </tbody>
                </table>
                <p></p><p>Team Agraga</p>`
            console.log('CALLING REGISTRATION MAIL WITH', from, to, subject, message)
            Api.sendClientMail(from, to, subject, message, 'Registration')
        })
    }

    static resetPasswordAguser(emailId, callback) {
        const adminEmailID = `Agraga Admin <${process.env.REACT_APP_SUPPORTMAIL_ID}>`;
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 12;
        let password = "";
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        Api.fetch(AGUSERURI.SETPASSWORD(emailId, password), (response) => {
            Api.sendClientMail(adminEmailID, emailId, `Reset Password`,
                `<p>Your request for resetting password has been successfully completed. </p>
                <p>Please click on <<server>> to access the Application.</p>
                <p>Use the following credentials to log on</p>
                <table>
                <tbody>
                <tr>
                <td>User Name</td><td>${emailId}</td>
                </tr>
                <tr>
                <td>Password</td><td>${password}</td>
                </tr>
                </tbody>
                </table>
                <p></p><p>Team Agraga</p>`,
                'Reset Password Request');
            callback();
        });
    }

    static forgotPasswordAguser(emailId, callback) {
        Api.fetch(AGUSERURI.FORGOTPASSWORD(emailId), (response) => {
            callback(response)
        });
    }

    static getAguserList() {
        return new Promise((resolve, reject) => {
            Api.fetch(AGUSERURI.LIST, (data) => {
                resolve(data);
            })
        });
    }

    static logout(data, callback = f => f) {
        Api.post(AGUSERURI.LOGOUT,data, (res) => {
            let token = localStorage.getItem("devicetoken")
            if(token == data.token){
                loginStore.reinitialize()
                localStorage.removeItem('ag_email')
                localStorage.removeItem('tour')
                localStorage.removeItem('ag_current_entity')
                localStorage.removeItem('ag_userrec')
                localStorage.removeItem('devicetoken')
            }
            callback(data)
        })
    }

    static devicelist(callback=f=>f){
        Api.fetch(AGUSERURI.DEVICELIST, (data) => {
            callback(data);
        })
    }
    static updatetour(data) {
        Api.post(AGUSERURI.UPDATETOUR,data, (res) => {
            console.log(res)
        })
    }

    static async getManagersForAgusers(role, callback = f => f) {
        const url = `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/list/managers/users`;
        const finalUrl = `${url}?role=${role}`;
        Api.fetch(finalUrl, (result) => {
            callback(result.data)
        })

    }

    static async getbranchesForTypes(aguser, callback = f => f) {
        const url = `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/list/branches/type`;
        const finalUrl = `${url}?vertical=${aguser.vertical}&division=${aguser.division}&role=${aguser.role}`;
        console.log(aguser.role,'final.');
        Api.fetch(finalUrl, (result) => {
            callback(result.data)
        })
    }
    static getProfile(data,callback) {
        Api.post(AGUSERURI.GETPROFILE,data, (res) => {
            callback(res)
            console.log(res)
        })
    }
}
