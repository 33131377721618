import { Box } from "@mui/material";
import logo from "../../src/assets/images/loginlogo.svg";
import image1 from "../../src/assets/images/1.jpg";
import image2 from "../assets/images/2.jpg";
import image3 from "../assets/images/3.jpg"
import { useEffect, useState } from "react";

const images = [image2,image3];


function Carousel(){
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000); // Change slide every 3 seconds
  
      return () => {
        clearInterval(interval);
      };
    }, []);
    return <Box style={{minWidth:"27%",width: "27%", height: "100%", overflow: "hidden" }}>
    <div
      style={{
        height:"100%",
        display: "flex",
        width: `${images.length * 100}%`,
        transform: `translateX(${-currentIndex * (100 / images.length)}%)`,
        transition: "transform 0.5s ease-in-out",
      }}
    >
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Image ${index + 1}`}
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
        />
      ))}
    </div>
  </Box>
}


export function PageLayout({Component}){
    return <Box sx={{height:"100vh",width:"100%",display:"flex",flexDirection:"row"}}>
               <Carousel/>
               <Box sx={{flexGrow:1,height:"100%",overflowY:"auto",alignItems:"center",justifyContent:"center",display:"flex"}}>
                      <Box sx={{width:"60%"}}>
                      {Component}
                      </Box>
               </Box>
    </Box>
}