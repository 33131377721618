import { useLayoutEffect } from "react";
import { useSnapshot } from "valtio";
import { taskStore } from "../mytasks/task-store";
import Taskapi, { Task } from "../mytasks/tasks-api";
import { getPayload } from "../mytasks/tasks-static-data";
import { bookingsStore, configStore, entityStore, multiSelectListStore } from "../state/CommonState";

export function AgMultiSelectWithCheckBoxes({
  label,
  storeKey,
  callback,
  withAllShortcut,
  ..._props
}) {
  useLayoutEffect(() => {
    if (document.querySelector(".is-on") != null) {
      document.addEventListener("click", () => {
        let checkboxes = document.getElementById("checkboxes");
        if (checkboxes != null) {
          checkboxes.style.display = "none";
          expanded = false;
          callback();
        } 
        if (document.querySelector(".is-on") != null)
          document.querySelector(".is-on").classList.remove("is-on");
      });
    } 
  }, []);
  let expanded = false;
  useSnapshot(multiSelectListStore);
  return (
    <div className="field">
      <label className="label is-small" style={{ marginBottom: "0px" }}>
        {label}
      </label>
      <div className="control" onClick={(e) => e.stopPropagation()}>
        <form>
          <div class="multiselect select is-small">
            <div
              class="selectBox"
              onClick={() => {
                let checkboxes = document.getElementById("checkboxes");
                if (!expanded) {
                  checkboxes.style.display = "block";
                  expanded = true;
                } else {
                  checkboxes.style.display = "none";
                  expanded = false;
                  callback();
                }
              }}
            >
              <select>
                <option>
                  {(() => {
                    let selectedLength = multiSelectListStore.selectList[
                      storeKey
                    ]?.filter(({ checked }) => checked).length;
                    return selectedLength ===
                      multiSelectListStore.selectList[storeKey]?.length
                      ? "ALL"
                      : `${selectedLength} selected`;
                  })()}
                </option>
              </select>
              <div class="overSelect"></div>
            </div>
            <div id="checkboxes" className="is-on">
              {withAllShortcut && (
                <>
                  <label
                    for={"all"}
                    class="checkbox"
                    style={{ fontWeight: 900, margin: "5px" }}
                  >
                    <input
                      type="checkbox"
                      checked={multiSelectListStore.selectList[storeKey]?.every(
                        ({ checked }) => checked
                      )}
                      id={"all"}
                      className="checkboxes"
                      onChange={(e) => {
                        multiSelectListStore.selectList[storeKey] =
                          multiSelectListStore.selectList[storeKey]?.map(
                            (selectedData) => ({
                              ...selectedData,
                              checked: e.target.checked,
                            })
                          );
                      }}
                      style={{ marginRight: "7px" }}
                    />
                    {"All"}
                  </label>
                  <hr style={{ marginBottom: "5px", marginTop: "5px" }} />
                </>
              )}
              {multiSelectListStore.selectList[storeKey]?.map(
                ({ id, name, checked }) => (
                  <label for={id} class="checkbox" style={{ margin: "5px" }}>
                    <input
                      type="checkbox"
                      checked={checked}
                      id={id}
                      className="checkboxes"
                      onChange={(e) => {
                        multiSelectListStore.selectList[storeKey] =
                          multiSelectListStore.selectList[storeKey]?.map(
                            (selectedData) => {
                              if (selectedData.id === id)
                                selectedData.checked = e.target.checked;
                              return selectedData;
                            }
                          );
                      }}
                      style={{ marginRight: "7px" }}
                    />
                    {name}
                  </label>
                )
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export function AgSelectWithMap({
  label,
  record,
  name,
  dataMap,
  callback = (selectedValue) => selectedValue,
  isValid = true,
  validationMsg,
}) {
  const imRecord = useSnapshot(record);
  return (
    <div className="field">
      <label className="label is-small" style={{ marginBottom: "0px" }}>
        {label}
      </label>
      <div className="control">
        <div
          className={isValid ? "select is-small" : "select is-small is-danger"}
        >
          <select
            name={name}
            value={imRecord[name]}
            onChange={(e) => {
              record[name] = e.target.value;
              callback(e.target.value);
            }}
          >
            <option selected value={""} hidden>
              Select an option
            </option>
            {dataMap.map(({ key, value }) => (
              <option value={value}>{key}</option>
            ))}
          </select>
        </div>
      </div>
      {!isValid && (
        <p class="help is-danger">
          {" "}
          {validationMsg || `* Please select valid ${label}`}
        </p>
      )}
    </div>
  );
}

export function TableEditWithValue(props) {
  const {
    record,
    name,
    type = "text",
    tabIndex = "0",
    isreadonly = false,
    callback = (f) => f,
    isValid = true,
    isDisabled,
  } = props;
  const imRecord = useSnapshot(record);
  return (
    <div className="control is-fullwidth is-small">
      <input
        style={{ backgroundColor: "white", color: "black" }}
        {...props}
        value={record[name]}
        type={type}
        name={name}
        defaultValue={record[name]}
        onChange={(e) => {
          record[name] = e.target.value;
          callback(e.target.value);
        }}
        className={isValid ? "input is-small" : "input is-small is-danger"}
        inputmode={type}
        {...isDisabled}
        readOnly={isreadonly}
      />
    </div>
  );
}

export function AgWarnPopup({
  callback = (f) => f,
  onClose,
  acceptBtnText = `Accept`,
  ...props
}) {
  useSnapshot(configStore);
  return (
    <div class={configStore.hasToShowAgWarn ? "modal is-active" : "modal"}>
      <div class="modal-background"></div>
      <div class="modal-content">
        <article class="message is-warning">
          <div class="message-body">
            {props.children}
            <div
              className="buttons"
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <button
                type="button"
                className="button is-danger is-small"
                onClick={() => (configStore.hasToShowAgWarn = false)}
              >
                Close
              </button>
              <button
                type="button"
                className="button is-warning is-small"
                onClick={() => {
                  callback();
                  configStore.hasToShowAgWarn = false;
                  props.data()
                }}
              >
                {acceptBtnText}
              </button>
            </div>
          </div>
        </article>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        onClick={() => (configStore.hasToShowAgWarn = false)}
      >
        Close
      </button>
    </div>
  );
}

export function AgWarnPopup1({
  callback = (f) => f,
  onClose,
  acceptBtnText = `Confirm`,
  ...props
}) {
  useSnapshot(configStore);
  useSnapshot(taskStore)
  useSnapshot(bookingsStore)
  useSnapshot(entityStore)
  return (
    <div class={configStore.hasToShowAgWarn ? "modal is-active" : "modal"}>
      <div class="modal-background"></div>
      <div class="modal-content">
        <article class="message is-warning">
          <div class="message-body">
            {props.children}
            <div
              className="buttons"
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <button
                type="button"
                className="button is-danger is-small"
                onClick={() => (configStore.hasToShowAgWarn = false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button is-warning is-small"
                onClick={async (e) => {
                  configStore.hasToShowAgWarn = false;
                  taskStore.current = new Task('')
                  taskStore.current.initialize(bookingsStore.current, entityStore.entityRec)
                  taskStore.current.actionName = "Request for Booking Cancellation"
                  Object.assign(taskStore.current, getPayload("Request for Booking Cancellation"))
                  taskStore.current.reason = bookingsStore.requestCancellationReason
                  // taskStore.setCustomerComplete()
                  taskStore.current.stage = 1
                  taskStore.current.pendingWith = 'AGRAGA'
                  await Taskapi.saveTask(taskStore.current)
                  callback();

                }}
              >
                {acceptBtnText}
              </button>
            </div>
          </div>
        </article>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        onClick={() => (configStore.hasToShowAgWarn = false)}
      >
        Close
      </button>
    </div>
  );
}

export function AgTextShow({ label, value }) {
  return (
    <div className="field">
      <label className="label is-small" style={{ marginBottom: "0px" }}>
        {label}
      </label>
      <div className="control is-fullwidth">
        <p className="is-size-7">{value}</p>
      </div>
    </div>
  );
}
