import { Button, FormLabel, TextField, Typography, Box, Card, CardHeader, CardContent, Divider, Icon, IconButton, useMediaQuery, withStyles } from "@mui/material";
import { AgModal } from "../mui-components/AgModal";
import { useSnapshot } from "valtio";
import { aguserStore, configStore, loginStore } from "../state/CommonState";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AntSwitch } from "../mui-components/AntSwitch";
import { PageLayout } from "../mui-components/Pagelayout";
import { ArrowForwardSharp, Email, Google, LinkedIn, Visibility, VisibilityOff } from "@mui/icons-material";
import LabeledTextField from "../mui-components/LabeledTextField";
import Api from "../state/Api";
import UsersApis from "../state/users-api";



export function Login({callback}){
    const isDesktop = useMediaQuery('(min-width:769px)')
    const [show,showPassword] = useState(false)
    const loginForm = useForm({
        defaultValues:{
            email:"",
            password:"",
            rememberMe : true
        }
    })

    const {handleSubmit,register,formState:{errors},control,getValues,setValue,watch} = loginForm;

    useSnapshot(configStore);

    const isToggled = watch("rememberMe");

    const submit = async (data)=>{
        console.log("The data is ",data)
        try {
            // await Api.getUser(data.email, data.password,(userRec)=>{
            //     if(!userRec?.userFound){
            //         return  configStore.setModalMessage("Invalid username/password")
            //       }
            //       loginStore.isShowClientDashBoard = userRec?.aguserRec?.role
            //       .toLowerCase()
            //       .startsWith("client");
            //       if(userRec?.aguserRec?.token && userRec?.aguserRec?.token.length>0){
            //           localStorage.setItem('devicetoken', userRec.aguserRec.token)
            //           aguserStore.aguserRec.set(userRec.aguserRec);
            //           if(userRec?.aguserRec?.selectgst && userRec?.aguserRec?.selectgst.length>0){
            //             console.log("the selected  gst is ",userRec.aguserRec.selectgst)
            //             console.log("the company list is ",userRec.companyList)
            //               for (let i = 0; i < userRec.companyList.length; i++) {
            //                   if(userRec.companyList[i]['_id'] == userRec.aguserRec.selectgst){
            //                       localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[i]))
            //                       configStore.currentSelectedEntity = userRec.companyList[i];
            //                   }
            //               }
            //           }
            //           if(userRec?.aguserRec?.tour && userRec?.aguserRec?.tour.length>0){
            //               localStorage.setItem('tour', userRec.aguserRec.tour)
            //           }  
            //           const userRecString = JSON.stringify(userRec)
            //           console.log("the rememebere ejenej ",data.rememberMe)
            //           localStorage.setItem('ag_email', data.email)
            //           if (data.rememberMe) {
            //               localStorage.setItem("ag_remember_user",true);
            //               localStorage.setItem('ag_userrec', userRecString)
            //           }else{
            //             localStorage.setItem("ag_remember_user",false);
            //             sessionStorage.setItem('ag_userrec', userRecString)
            //           }

            //           loginStore.email = data.email; 
                      
            //         //   navigate('/',{replace:true})
            //       }
            // })

            await UsersApis.loginUser(data , (userRec)=> {
              console.log(userRec);
              if(!userRec?.userFound){
                return  configStore.setModalMessage("Invalid username/password")
              }
              loginStore.isShowClientDashBoard = userRec?.aguserRec?.role
              .toLowerCase()
              .startsWith("client");  
              if(userRec?.aguserRec?.token && userRec?.aguserRec?.token.length>0){
                          localStorage.setItem('devicetoken', userRec.aguserRec.token)
                          if(userRec?.aguserRec?.selectgst && userRec?.aguserRec?.selectgst.length>0){
                              for (let i = 0; i < userRec.companyList.length; i++) {
                                for(let r = 0 ; r < userRec.companyList[i].branches.length; r++ ){
                                  if(userRec.companyList[i]?.branches[r]?.gst == userRec.aguserRec.selectgst){
                                    localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[i]))
                                    localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[i].branches[r]))
                            configStore.currentSelectedEntity=userRec.companyList[i]
                            configStore.currentSelectedBranch=userRec.companyList[i].branches[r]
                                }
                                }
                                  
                              }
                          }else {
                            if(userRec.companyList.length > 0 ) {
                              localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[0]))
                              localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[0].branches[0]))
                              configStore.currentSelectedEntity=userRec.companyList[0]
                              configStore.currentSelectedBranch=userRec.companyList[0].branches[0]
                            }

                          }
                          // localStorage.setItem('ag_current_entity', JSON.stringify(userRec.[i]))
                          // localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[i]))
                          if(userRec?.aguserRec?.tour && userRec?.aguserRec?.tour.length>0){
                              localStorage.setItem('tour', userRec.aguserRec.tour)
                          }
                          localStorage.setItem('ag_email', data.email)
                          // const rememberMe = localStorage.getItem('ag_remember_user')
                          // if (rememberMe === 'REMEMBER') {
                          //     const userRecString = JSON.stringify(userRec)
                          //     localStorage.setItem('ag_userrec', userRecString)
                          // }
                          const userRecString = JSON.stringify(userRec)
                          if (data.rememberMe) {
                          localStorage.setItem("ag_remember_user",true);
                          localStorage.setItem('ag_userrec', userRecString)
                      }else{
                        localStorage.setItem("ag_remember_user",false);
                        // sessionStorage.setItem('ag_userrec', userRecString)
                      }
                      }
                      
          })
          
        } catch (error) {
           configStore.setModalMessage("Something went wrong")
        }
    }

    return <Box sx={{display:"flex",flexDirection:"column",justifyContent:"space-evenly",alignItems:"start",minHeight:"100%",backgroundColor:'white',padding:"10px 20% 10px 15%"}}>
    {/* <AgModal/> */}
      <form noValidate style={{width:"100%",display:"flex", justifyContent:"start",alignItems:"start",flexDirection:"column"}} onSubmit={handleSubmit(submit)} >
          {/* <Box sx={{margin:"4% 12% 1% 12%"}}> */}
          <Typography sx={{fontWeight:"bolder",fontSize:"30px",textAlign:"start",marginBottom:'4%',fontFamily:"Figtree",color:"black"}}>Sign in to Agraga</Typography>
           <LabeledTextField control={control} width="100%" InputProps={{
            style:{
              fontSize:"80%",
              // borderRadius:0
            }
           }}  size="small" label="Email ID" fullWidth  name="email" rules={{required:"Email is Required",
           pattern:{
            value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message : `Invalid email address`
          }
           }}/>

<span style={{paddingTop:"4%",width:"100%"}}>
<LabeledTextField 
           control={control}
           name={"password"}
           label="Password"
          InputProps={{
              style:{
                fontSize:"80%",
                // borderRadius:0
              },  
            endAdornment: (
                    <IconButton size="small" onClick={()=>{
                        showPassword((prevState)=>!prevState);
                    }}>
                       { show ? <VisibilityOff/> : <Visibility/>
                                  }                        </IconButton>
    ),
  }} type={ show ? "text" : "password"}  autoComplete="off" size="small" fullWidth rules={{required:"Password is Required"}}  />
 
</span>
         
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"start",alignItems:"center",gap:2,alignSelf:"start"}}> 
          <AntSwitch checked={isToggled} {...register("rememberMe")} /><Typography>Remember Me</Typography> </Box>
          <Button  size="small"  onClick={()=>{
              callback('reset');
          }} variant="text" sx={{textTransform:"capitalize",color:"gray",marginTop:'1%',alignSelf:"end"}}>Forgot Password?</Button>
          
          <Button size="medium" fullWidth sx={{
            marginTop:"1%",
            textTransform:"none",
            // borderRadius:0,
            backgroundColor:"#000000",color:"white","&:hover":{
            backgroundColor:"#000000"
          }}} type="submit">Sign in </Button> 
          <Box sx={{width:"100%",display:"flex",flexDirection:"row"}}>
         
          <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",margin:"12px 0px 40px 0px",flexDirection:"row",gap:1}} >
             <Typography sx={{textTransform:"none",color:"gray",fontSize:"12px"}}>Do not have an account?</Typography>
             <Typography onClick={()=>{
            callback('register')
          }} sx={{textTransform:"none",color:"black",fontSize:"12px",fontWeight:"bolder",textDecoration:"underline","&:hover":{cursor:'pointer'}}}>Sign-up</Typography>
           </Box>
          {/* <Button
          size="small" 
          onClick={()=>{
            navigate('/signup')
          }} fullWidth endIcon={<ArrowForwardSharp/>} sx={{
            textTransform:"capitalize",
            fontWeight:"bold",
            backgroundColor:"#d9d9d9",color:"#515151","&:hover":{
            backgroundColor:"#d9d9d9"
          }}} >Create Account</Button>  */}
          </Box>
           {/* <Button onClick={()=>{
              navigate('/resetpassword');
          }} variant="text" sx={{textTransform:"capitalize",color:"gray",marginTop:'3%'}}>Reset Password?</Button> */}
          {/* </Box> */}
         
              {/* <Button startIcon={<Google sx={{color:"#515151"}}/>} fullWidth size="medium" variant="outlined" sx={{
                marginBottom:"15px",
                fontSize:"80%",
                borderRadius:0,
                fontWeight:"bold",
                fontFamily:"Figtree",
                color:"#454545",
                borderColor:"gray",textTransform:"capitalize",
            "&:hover":{
                borderColor:"gray"
              },
            }}>
                        Continue with Google
              </Button>
              <Button startIcon={<LinkedIn sx={{color:"#515151"}}/>} fullWidth size="medium" variant="outlined" sx={{
              marginBottom:"15px",
              fontSize:"80%",
              borderRadius:0,
              fontFamily:"Figtree",
              fontWeight:"bold",
                borderColor:"gray",color:"#454545",textTransform:"capitalize",
            "&:hover":{
                borderColor:"gray"
              },
            }}>
                        Continue with LinkedIn
              </Button>
              <Button startIcon={<Email sx={{color:"#515151"}}/>} fullWidth size="medium" variant="outlined" sx={{
                marginBottom:"15px",
                fontSize:"80%",
                fontFamily:"Figtree",
                borderRadius:0,
                fontWeight:"bold",
                borderColor:"gray",color:"#454545",textTransform:"capitalize",
            "&:hover":{
                borderColor:"gray"
              },
            }}>
                        Continue with Outlook
              </Button> */}
             
          
    
  </form>
</Box> 
    
    // return <Box sx={{display:"flex",flexDirection:"column",justifyContent:"space-evenly",alignItems:"center",minHeight:"100%"}}>
    //     <AgModal/>
    //     <Typography sx={{fontWeight:"bold",fontWeight:"bolder",fontSioutze:"1rem"}}>Log Into Agraga</Typography>
    //       <form noValidate style={{display:"flex",justifyContent:"center",width:"100%"}} onSubmit={handleSubmit(submit)} >
    //         <Card sx={{width:"90%",boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'}}>
    //        <CardContent sx={{display:"flex", justifyContent:"start",alignItems:"center",flexDirection:"column",gap:3}}>
    //           <Box sx={{margin:"4% 12% 1% 12%"}}>
    //            <CustomStyledTextField sx={{marginBottom:"15px"}} InputProps={{
    //             style:{
    //               fontSize:"80%",
    //             }
    //            }} error={!!errors?.email} helperText={errors?.email?.message} size="small" fullWidth placeholder="Email or Mobile number" {...register("email",{required:"Email is Required"})}/>
    //           <CustomStyledTextField InputProps={{
    //               style:{
    //                 fontSize:"80%",
    //               },  
    //             endAdornment: (
    //                     <IconButton size="small" onClick={()=>{
    //                         showPassword((prevState)=>!prevState);
    //                     }}>
    //                        { show ? <VisibilityOff/> : <Visibility/>
    //                                   }                        </IconButton>
    //     ),
    //   }} sx={{marginBottom:"7px"}} type={ show ? "text" : "password"}  autoComplete="off" error={!!errors?.password} helperText={errors?.password?.message} size="small" fullWidth placeholder="Passowrd" {...register("password",{required:"Password is Required"})}  />
    //           <Box sx={{display:"flex",flexDirection:"row",justifyContent:"start",alignItems:"center",gap:2,alignSelf:"start"}}> 
    //           <AntSwitch {...register("rememberMe")}/><Typography>Remember Me</Typography> </Box>
    //           <Button size="medium" fullWidth endIcon={<ArrowForwardSharp/>} sx={{
    //             marginTop:"7%",
    //             textTransform:"capitalize",
    //             backgroundColor:"#2B3688",color:"white","&:hover":{
    //             backgroundColor:"#2B3688"
    //           }}} type="submit">Proceed </Button> 
    //           <Button size="small"  onClick={()=>{
    //               navigate('/forgotpassword');
    //           }} variant="text" sx={{textTransform:"capitalize",color:"gray",marginTop:'3%'}}>Forgot Password?</Button>
    //            {/* <Button onClick={()=>{
    //               navigate('/resetpassword');
    //           }} variant="text" sx={{textTransform:"capitalize",color:"gray",marginTop:'3%'}}>Reset Password?</Button> */}
    //           </Box>
    //           <Box sx={{backgroundColor:"grey",height:"2px",width:"100%"}}></Box>
    //           <Box sx={{margin:"4% 12% 1% 12%"}} >
    //               <Button startIcon={<Google sx={{marginRight:"40px",color:"#515151"}}/>} fullWidth size="medium" variant="outlined" sx={{
    //                 paddingRight:"30px",
    //                 marginBottom:"15px",
    //                 fontSize:"80%",
    //                 borderColor:"gray",color:"grey",textTransform:"capitalize",
    //             "&:hover":{
    //                 borderColor:"gray"
    //               },
    //             }}>
    //                         Continue with Google
    //               </Button>
    //               <Button startIcon={<LinkedIn sx={{marginRight:"40px",color:"#515151"}}/>} fullWidth size="medium" variant="outlined" sx={{
    //                 paddingRight:"30px",
    //                 marginBottom:"15px",
    //                 fontSize:"80%",
    //                 borderColor:"gray",color:"grey",textTransform:"capitalize",
    //             "&:hover":{
    //                 borderColor:"gray"
    //               },
    //             }}>
    //                         Continue with LinkedIn
    //               </Button>
    //               <Button startIcon={<Email sx={{marginRight:"40px",color:"#515151"}}/>} fullWidth size="medium" variant="outlined" sx={{
    //                 paddingRight:"30px",
    //                 marginBottom:"15px",
    //                 fontSize:"80%",
    //                 borderColor:"gray",color:"grey",textTransform:"capitalize",
    //             "&:hover":{
    //                 borderColor:"gray"
    //               },
    //             }}>
    //                         Continue with Outlook
    //               </Button>
                 
    //           <Typography sx={{textTransform:"none",color:"gray",marginTop:'3%',fontSize:"12px"}}>Do not have an account?</Typography>
    //           <Button
    //           size="small" 
    //           onClick={()=>{
    //             navigate('/signup')
    //           }} fullWidth endIcon={<ArrowForwardSharp/>} sx={{
    //             textTransform:"capitalize",
    //             fontWeight:"bold",
    //             backgroundColor:"#d9d9d9",color:"#515151","&:hover":{
    //             backgroundColor:"#d9d9d9"
    //           }}} >Create Account</Button> 
    //           </Box>
    //        </CardContent>
    //      </Card>
    //   </form>
    // </Box> 
}


export function LoginPage({callback}){
  return <PageLayout Component={<Login callback={callback} />} />
}