import { proxy } from "valtio";

let trackMyBookingOceanStore = proxy({
    current: {},
    bookingId:'',
    oceanNumber:'',
    phoneNumberStatus: '',
    carrier: '',
    carrierBookingRefNumber: '',
    carrierMBLNumber: '',
    gst: '',
    containerNumber: '',
    customerReference: '',
    airlineBookingReference: '',
    MAWBNumber: '',
    vendorName: '',
    vehicleNumber: '',
    driverPhoneNumber: '',
    driverName: '',
    status:'active',
    multipickup: "No",
    details: [{
        pickup: "",
        pickup_2: "",
        delivery: [{ address: "" }],

    }],
    valid: false,
    setTrackBooking: () => {
        trackMyBookingOceanStore.bookingId=''
        trackMyBookingOceanStore.oceanNumber =''
        trackMyBookingOceanStore.phoneNumberStatus = ''
        trackMyBookingOceanStore.carrier = ''
        trackMyBookingOceanStore.carrierBookingRefNumber = ''
        trackMyBookingOceanStore.carrierMBLNumber = ''
        trackMyBookingOceanStore.containerNumber = ''
        trackMyBookingOceanStore.customerReference = ''
        trackMyBookingOceanStore.airlineBookingReference = ''
        trackMyBookingOceanStore.MAWBNumber = ''
        trackMyBookingOceanStore.vendorName = ''
        trackMyBookingOceanStore.vehicleNumber = ''
        trackMyBookingOceanStore.driverPhoneNumber = ''
        trackMyBookingOceanStore.driverName = ''
        trackMyBookingOceanStore.multipickup = 'No'
        trackMyBookingOceanStore.details = [
            {
                pickup: "",
                pickup_2: "",
                delivery: [{ address: "" }],
            }
        ]
    }
})

export default trackMyBookingOceanStore