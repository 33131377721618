import { useSnapshot } from "valtio"
import { useState } from "react"
import {
    contractsStore, configStore
} from '../state/CommonState'
import Utils from "../util/Utils"

export default function FCLChargeClientViewv2(props) {
    useSnapshot(contractsStore)
    const rec = contractsStore.current
    const containerNames = rec.containerList.map(e => e.containerType)


    const Tn = ({ i, name }) => {
        const e = props.data[i]
        let num = e[name]
        num = isNaN(num) ? 0.0 : Number(num)
        return num > 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
    }

    const displayRec = (e) => {
        for (let i = 0; i < containerNames.length; i++) {
            const c = containerNames[i]
            const wmKey = c + 'perWM'
            const shipmentKey = c + 'perShipment'
            const totalKey = c + 'total'
            const perWM = parseFloat(e[wmKey])
            const perShipment = parseFloat(e[shipmentKey])
            const total = parseFloat(e[totalKey])
            console.log(perWM, perShipment, total)
            if (perWM > 0 || perShipment > 0 || total > 0) return true
        }
        return false
    }

    if (!props.data) {
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < rec.chargesList.length; i++) {
            let e = rec.chargesList[i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        let size = 12
        if(listc.length && lists.length){
            size = 4
        }else if(listc.length){
            size = 6
        }else if(lists.length){
            size = 6
        }
        return <>
        <div class="columns">
  <div class={"column is-"+size}><FCLChargeClientViewv2 data={listo} chargeType='On Your Account' /></div>
            {
                (listc.length)?<div class={"column is-"+size}><FCLChargeClientViewv2 data={listc} 
                chargeType='On Consignee Account' /></div>:""
            }
            {
                (lists.length)?<div class={"column is-"+size}><FCLChargeClientViewv2 data={lists} chargeType='On Shipper Account' /></div>:""
            }
            </div>
        </>
    }


    let grandTotal = {}
    let grantTotaloftotals = 0.0
    rec.containerList.map(c => {
        grandTotal[c.containerType] = 0.0
    })
    props.data.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        rec.containerList.map(c => {
            let totalField = c.containerType + 'total'
            let value = e[totalField] * erate
            grandTotal[c.containerType] += value
            grantTotaloftotals += value
        })
    })
    let counter = 1
    
    return (
        <>
            <hr />
            <div className="table-container">
            <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='18'>
                                <h3 className="is-size-5 has-text-centered">{props.chargeType}</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            {containerNames.map(e => (
                                <td className="has-text-centered has-text-weight-bold" colSpan={3}>{e}</td>
                            ))}
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th>Charge Description</th>
                            <th>Currency</th>
                            {containerNames.map(e => (
                                <>
                                    <th style={{ textAlign: 'center' }}>Per Container</th>
                                    <th style={{ textAlign: 'center' }}>Per BL</th>
                                    <th style={{ textAlign: 'right' }}>Total</th>
                                </>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.map((e, i) => (
                                displayRec(e) ?
                                    <tr key={e.id}>
                                        <td >{counter++}</td>
                                        <td>{e.chargeDescription}</td>
                                        <td>{e.currency}</td>
                                        {containerNames.map(e1 => (
                                            <>
                                                <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perWM'} /></td>
                                                <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perShipment'} /></td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {Utils.N(e[e1 + 'total'], e.currency)}
                                                </td>
                                            </>
                                        ))}
                                    </tr> :
                                    <></>
                            )
                            )
                        }
                        <tr>
                            <td colSpan={3}></td>
                            {
                                rec.containerList.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                    {Utils.N(grandTotal[c.containerType])}
                                                </strong>
                                            </td>
                                        </>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td colSpan={3 + rec.containerList.length * 3}
                                style={{ textAlign: 'right', fontSize: '1.1rem' }}>
                                <strong>
                                    {Utils.N(grantTotaloftotals)}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3 + rec.containerList.length * 3} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}
