import { useState } from 'react'
import { useSnapshot } from 'valtio'
import { bookingsStore, configStore, contractsStore, loginStore } from '../state/CommonState'
import Utils from '../util/Utils'
import { Line,  CustomProgressDottedBar } from '../components/circle-line'
import airlineCodes from '../static-data/airlines'
import airlogoMap from '../enquiry/air-logo'
import AirChargesClientViewv2 from './AirChargesClientView-v2'
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";

export default function AirScheduleCompv2(props) {
    const [showCharges, setShowCharges] = useState(false)
    useSnapshot(contractsStore)
    useSnapshot(bookingsStore)
    let brec = bookingsStore.current
    let client = loginStore.isClient();
    const rec = contractsStore.current
    if (!rec) return <></>
    let airlogo = airlogoMap[rec.airline.trim()]
    if(rec && rec.airline && rec.airline.trim().length>0){
        if(rec.airline.trim().length>2){
          airlogo = airlogoMap[rec.airline
            .substring(
              rec.airline.lastIndexOf("(") + 1,
              rec.airline.lastIndexOf(")")
            )
            .trim()]
        }
      }
      if(brec && brec.airline && brec.airline.trim().length>0){
        if(brec.airline.trim().length>2){
          airlogo = airlogoMap[brec.airline
            .substring(
                brec.airline.lastIndexOf("(") + 1,
                brec.airline.lastIndexOf(")")
            )
            .trim()]
        }else if(brec.airline.trim().length==2) {
            airlogo = airlogoMap[brec.airline]
        }
      }
    // ADDED FOR AIR PRICING
    if (brec.iata) airlogo = airlogoMap[brec.iata]
    let voldis = 0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
        }
    })
    let usdRate = configStore.getExchangeRate('USD')
    let voldisusd = voldis / usdRate
    if(voldis>0){
        voldis = -(voldis)
        voldisusd = -(voldisusd)
    }
    return (
        <div className="box">
            {/* <h1>OVER HERE</h1> */}
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                <div className="column is-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    {
                        airlogo ? <img src={airlogo} /> : <h3 className="is-uppercase has-text-weight-bold has-text-centered">{(airlineCodes.find(({ code }) => code === rec.airline)?.name || rec.airline)}</h3>
                    }

                </div>
                <div className="column is-9">
                    <table className='schtable' style={{ width: '100%', textAlign: 'center' }}>
                        <tr>
                            <td></td>
                            <td>
                            </td>
                            <td colSpan={2}>
                                <div style={{ color: 'grey' }}>Air Transit Time</div>
                                <div style={{ fontSize: '0.9rem' }}><strong>{rec.transitTime} days</strong></div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', }}>
                                {rec.origin}
                            </td>
                            {['Door-to-Port', 'Door-to-Door'].includes(rec.shipmentScope) ? (<td style={{ textAlign: 'center' }}>
                                {rec.originAirport || rec.portOfLoading}
                            </td>) : <td></td>}
                            <td>
                            </td>
                            <td>
                            </td>
                            {['Port-to-Door', 'Door-to-Door'].includes(rec.shipmentScope) ? (<td style={{ textAlign: 'center' }}>
                                {rec.destinationAirport || rec.portOfDischarge}
                            </td>) : <td></td>}
                            <td style={{ textAlign: 'center' }}>{rec.destination}
                            </td>
                        </tr>
                        <tr>
                            <td><CustomProgressDottedBar setStyle={(dotted, hide) => [hide, ['Door-to-Port', 'Door-to-Door'].includes(rec.shipmentScope) ? dotted : false]} /></td>
                            <td>{['Door-to-Port', 'Door-to-Door'].includes(rec.shipmentScope) ? <CustomProgressDottedBar setStyle={(dotted, hide) => [dotted, false]} /> : <Line />}</td>
                            <td><Line /></td>
                            <td><Line /></td>
                            <td>{['Port-to-Door', 'Door-to-Door'].includes(rec.shipmentScope) ? <CustomProgressDottedBar setStyle={(dotted, hide) => [false, dotted]} /> : <Line />}</td>
                            <td><CustomProgressDottedBar setStyle={(dotted, hide) => [['Port-to-Door', 'Door-to-Door'].includes(rec.shipmentScope) ? dotted : false, hide]} /></td>
                        </tr>
                    </table>
                </div>
                <div className='column is-2' style={{ textAlign: 'center' }}>
                {
                        ((rec.quoteValue+voldis) < 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <>
                            <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue+voldis)}</strong></div>
                            <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD+voldisusd, 'USD')}</div>
                            </>
                    }
                    
                    <div style={{ fontSize: '0.6rem', color: 'grey' }}>(Taxes, as Applicable)</div>
                    {
                        (contractsStore.current['quoteStatus'] === "ACCEPTED" && ((!props.viewonly && !loginStore.isClient()) || (!props.viewonly && rec.quoteStatus == 'ACCEPTED' && loginStore.isClient())) && (RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.BOOKING) || client)) ?
                            <button className={configStore.scheduleOrCarrierform.toString() === 'carrierform' ? "button is-rounded is-link is-small is-hidden" : "button is-rounded is-link is-small"} onClick={() => {
                                bookingsStore.initialize()
                                bookingsStore.current.schedule = props.data
                                bookingsStore.current.contract = { ...rec }
                                bookingsStore.current.contractNum = rec._id
                                configStore.scheduleOrCarrierform = 'carrierform'
                            }}>
                                Proceed to Booking
                            </button> : <></>
                    }
                </div>
            </div>
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                {
                    (showCharges) ?
                        <button className='button is-warning is-small is-fullwidth' onClick={() => setShowCharges(false)}>
                            Hide Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> :
                        <button className='button is-info is-small is-fullwidth' onClick={() => setShowCharges(true)}>
                            View Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }

            </div>
            {
                (showCharges) ?
                    <div className="columns" style={{
                        fontSize: '0.8rem',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <AirChargesClientViewv2 /> 
                    </div> : <></>
            }
        </div>
    )
}