import { useEffect, useRef, useState } from "react"
import { useSnapshot } from "valtio"
import { configStore, fclChargesStore } from "../state/CommonState"
import Utils from "../util/Utils"
import Api from "../state/Api"
import { commodityList, hazardousList, ServerLists } from "../StaticData"
import airlineCodes from "../static-data/airlines"
import { taskStore } from "../mytasks/task-store"
import { myactionsstore } from "../my-actions/store-myactions"
import { enquiryStore } from "../enquiry/enquiry-data"



const $c = (props) => {
    const classname = `column is-${props.n}`
    return (
        <div className={classname} {...props}>{props.children}</div>
    )
}
const $cr = (props) => {
    const classname = `column is-${props.n} is-small`
    return (
        <div style={{ textAlign: 'right', paddingRight: '1rem' }} className={classname} {...props}>{props.children}</div>
    )
}
const $cg = (props) => {
    const classname = `column is-${props.n} is-small`
    return (
        <div style={{ backgroundColor: 'white', border: '1px solid #efefef' }} className={classname} {...props}>{props.children}</div>
    )
}
export {
    $c,
    $cg,
    $cr
}

export function TableEdit(props) {
    const { record, name, type = 'text', tabIndex = '0', isreadonly = false, callback = f => f, isValid = true, isDisabled } = props
    const imRecord = useSnapshot(record)
    console.log(name, record[name], record,  'record[name]chekkkkkkkkkkkkkkk');
    const handleInputChange = (e) => {
        const newValue = e.target.value
        record[name] = newValue; // Update the Valtio state
        callback(newValue);
    };
    return (
        <div className="control is-fullwidth is-small">
            <input
                style={{ color: 'black' }}
                {...props}
                type={type}
                name={name}
                value={imRecord[name]}
                // onKeyDown={(e) =>
                //     ["e", "E", "+"].includes(e.key) && e.preventDefault()
                // }
                onChange={handleInputChange}
                // value={imRecord[name]}
                className={isValid ? "input is-small" : "input is-small is-danger"}
                inputmode={type}
                {...isDisabled}
                readOnly={isreadonly}
            />
        </div>
    )
}

export function TableEditCharges(props) {
    const { record, name, type, isreadonly = false, callback = f => f, isValid = true, isDisabled } = props;
    const imRecord = useSnapshot(record);
    const [val, setVal] = useState(imRecord[name] || ''); // Initialize with the value from imRecord if available

    useEffect(() => {
        setVal(imRecord[name] || ''); 
    }, [imRecord, name]);

    const handleInputChange = (e) => {
        setVal(e.target.value);
    };

    const handleBlur = () => {
        record[name] = val;
    };

    return (
        <div className="control is-fullwidth is-small">
            <input
                style={{ color: 'black' }}
                {...props}
                type={type}
                name={name}
                value={val}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={isValid ? "input is-small" : "input is-small is-danger"}
                {...isDisabled}
                readOnly={isreadonly}
            />
        </div>
    );
}


export function TableLabel({ record, name }) {
    return (
        <div className="control is-fullwidth">
            <input name={name} defaultValue={record[name]} disabled={true}
                className="input is-small" />
        </div>
    )
}
export function TableSelect(props) {
    const { record, name, dataList, tabIndex = 0, callback = f => f, isValid = true, isDisabled } = props
    const imRecord = useSnapshot(record)
    let isonaccountvalid = true
    if(name=="onaccount" && isValid==false){
        isonaccountvalid = true
    }else{
        isonaccountvalid = isValid
    }
    if(name === 'onaccount' && (record[name] === undefined || record[name].trim().length==0)){
        record[name] = 'On Your Account'
    }

    console.log("props-------------->",props,record[name])
    // const packageTypeList = ['', 'Pallet', 'Drum', 'Crate', 'Skid', 'Loose Cartons', 'Box', 'Bales', 'Rolls']
    return (
        <div className={isValid || isonaccountvalid ? "select is-small" : "select is-small is-danger"} >
            <select style={{ color: 'black' }} {...props} tabIndex={tabIndex} name={name}
                value={imRecord[name]} onChange={(e) => {
                    record[name] = e.target.value
                    if(name === 'containerType'){
                        fclChargesStore.defaultValueCalc() // Need to calculate for validation
                    }
                    callback(e.target.value)
                }}
                {...isDisabled}>
                <option selected={!isDisabled} value={''} hidden>
                    {''}
                </option>
                {dataList.filter(val => val !== '').map(val => <option selected={val === record[name]} key={val}>{val}</option>)}
            </select>
        </div>
    )
}
export function TableSelect7(props) {
    const { record, name, dataList, tabIndex = 0, callback = f => f, isValid = true, isDisabled } = props
    const imRecord = useSnapshot(record)
    // console.log(">>>>",record[name])
    // const packageTypeList = ['', 'Pallet', 'Drum', 'Crate', 'Skid', 'Loose Cartons', 'Box', 'Bales', 'Rolls']
    if(record[name] == undefined || record[name] == 'CBM'){
        // record[name] == 'CBM'
        configStore.updateUnitDropdown = 'CBM'
    }else{
        // record[name] == 'cu ft'
        configStore.updateUnitDropdown = 'cu ft'

    }
    return (
        <div className={isValid ? "select is-small" : "select is-small is-danger"} >
            <select style={{ color: 'black' }} {...props} tabIndex={tabIndex} name={name}
                value={record[name]} onChange={(e) => {
                    record[name] = e.target.value
                    if(name === 'containerType'){
                        fclChargesStore.defaultValueCalc() // Need to calculate for validation
                    }
                    callback(e.target.value)
                }}
                {...isDisabled}>
                {/* <option selected={!isDisabled} value={''} hidden>
                    {''}
                </option> */}
                {dataList.filter(val => val !== '').map(val => <option selected={val === record[name]} key={val}>{val}</option>)}
            </select>
        </div>
    )
}
export function TableSelect1(props) {
    const { record, name, dataList, tabIndex = 0, callback = f => f, isValid = true, isDisabled } = props
    const imRecord = useSnapshot(record)
    useSnapshot(taskStore)
    // const packageTypeList = ['', 'Pallet', 'Drum', 'Crate', 'Skid', 'Loose Cartons', 'Box', 'Bales', 'Rolls']
    return (
        <div
            className={`input is-small ${(!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) != -1) ? "is-danger" : ""} `}
        >
            <select style={{ backgroundColor: 'white', color: 'black' }} {...props} tabIndex={tabIndex} name={name}
                // className={`input is-small ${ (!taskStore.current.isValid && taskStore.current.isValidfield.indexOf(name) != -1)  ? "is-danger" : ""} `}
                value={imRecord[name]} onChange={(e) => {
                    record[name] = e.target.value
                    callback(e.target.value)
                }}
                {...isDisabled}>
                <option selected value={''} hidden>
                    {''}
                </option>
                {dataList.filter(val => val !== '').map(val => <option key={val}>{val}</option>)}
            </select>
        </div>
    )
}
export function TableSelect5(props) {
    const { record, name, label, dataList, tabIndex = 0, callback = f => f, isValid = true, isDisabled } = props
    return (
        <>
            <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{label}</div>
                </div>
                <div className="column is-6 py-2">
                    <select style={{ backgroundColor: 'white', color: 'black', width: "20em" }} {...props} tabIndex={tabIndex} name={name}
                        value={record[name]} onChange={(e) => {
                            record[name] = e.target.value
                            callback(e.target.value)
                        }}
                        {...isDisabled}>
                        <option selected value={''} hidden>
                            {''}
                        </option>{
                            (dataList) ? dataList.map(({ key, value }) => (
                                <option value={value}>{key}</option>
                            )) : ""
                        }
                    </select>

                </div>
            </div>
        </>
    )
}
export function AgSelect(props) {
    const { label, record, name, dataList, callback = f => f, isValid = true, validationMsg, isDisabled } = props
    const imRecord = useSnapshot(record)
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control">
                <div className={isValid ? "select is-small" : "select is-small is-danger"}>
                    <select {...props} name={name} value={imRecord[name]}
                        {...isDisabled}
                        onChange={(e) => {
                            console.log('FROM INSIDE AgSelect=', e.target.value)
                            record[name] = e.target.value
                            callback(e.target.value)

                        }} >
                        <option selected value={''} hidden>
                            {''}
                        </option>
                        {dataList.filter(val => val !== '').map(val => <option key={val}>{val}</option>)}
                    </select>
                </div>
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please select valid ${label}`}</p>}
        </div>
    )
}
export function AgSelectCountry(props) {
    const { label, record, name, dataList, callback = f => f, isValid = true, validationMsg, isDisabled } = props
    useSnapshot(enquiryStore)

    if(enquiryStore.current.countryFromEnquiry == undefined){
        enquiryStore.current.countryFromEnquiry =  "India"
    }
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control">
                <div className={isValid ? "select is-small" : "select is-small is-danger"}>
                    <select {...props} name={name} value={enquiryStore.current.countryFromEnquiry}
                        {...isDisabled}
                        onChange={(e) => {
                            console.log('FROM INSIDE AgSelect=', e.target.value)
                            enquiryStore.current.countryFromEnquiry = e.target.value
                            callback(e.target.value)

                        }} >
                        {dataList.filter(val => val !== '').map(val => <option key={val}>{val}</option>)}
                    </select>
                </div>
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please select valid ${label}`}</p>}
        </div>
    )
}
export function AgSelect1(props) {
    const { label, record, name, dataList, callback = f => f, isValid = true, validationMsg, isDisabled } = props
    const imRecord = useSnapshot(record)
    console.log("check name------->", name, imRecord[name])
    console.log("output", name === 'onaccount' ? imRecord[name] === undefined ? dataList[0] : "On Your Account"  : imRecord[name])
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control">
                <div className={isValid ? "select is-small" : "select is-small is-danger"}>
                    <select {...props} name={name} value={imRecord[name]}
                        {...isDisabled}
                        onChange={(e) => {
                            //console.log('FROM INSIDE AgSelect=', record, e.target.value)
                            record[name] = e.target.value
                            callback(e.target.value)
                        }} >
                        <option selected value={''} hidden>
                            {''}
                        </option>
                        {
                            Object.entries(dataList).map(([key, value]) => (
                                <option value={value}>{key}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please select valid ${label}`}</p>}
        </div>
    )
}
export function AgAirline({ label, record, name, callback = f => f, isValid = true, validationMsg }) {
    const imRecord = useSnapshot(record)
    const getcode=(d)=>{
        var regex = /\(([^)]+)\)/;
        var result = d.match(regex);
        return result && result[1] ? result[1] : null;
    }
    if(imRecord[name]?.length>2){
        record[name] = getcode(imRecord[name])
    }
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control">
                <div className={isValid ? "select is-small" : "select is-small is-danger"}>
                    <select name={name} value={imRecord[name]}
                        onChange={(e) => {
                            record[name] = e.target.value
                            callback(e.target.value)
                        }} >
                        <option value=''></option>
                        {airlineCodes.map(val => <option value={val.code}>{val.name}</option>)}
                    </select>
                </div>
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please select valid ${label}`}</p>}
        </div>
    )
}
export function AgInput(props) {

    const { placeholder, label, record, name, type = 'text', validate = f => f, callback = f => f, isValid = true, validationMsg } = props
    let { isDisabled } = props
    const imRecord = useSnapshot(record)
    const [cursor, setCursor] = useState(0)

    let min = ''
    let max = '2120-01-01'
    if (type === 'date') {
        min = Utils.getCurrentDateString()
        if (label == '* Valid Upto') {
            if (record['cargoReadinessDate'].length == 0) {
                isDisabled = { disabled: true }
            }
            min = record['cargoReadinessDate']
            //max = Utils.getMaxDateString(record['cargoReadinessDate'])
        }
        // else{
        //     max = '2120-01-01'
        // }



    }
    function trimStr(str) {
        if (!str) return str;
        return str.replace(/^\s+/g, '');
    }

    const handleChange = (e, name) => {



        setCursor(e.target.selectionStart)
        if (name == 'origin' || name == 'destination') {
            record[name] = trimStr(e.target.value)
        } else if (name === 'firstName' || name === 'lastName') {
            record[name] = e.target.value
        } else {
            record[name] = e.target.value.trim()
        }
        if (name === 'cargoReadinessDate') {
            record['validUpto'] = ''
        }
        // record[r] = trimStr(e.target.value)    

        if (props.handleChange) {
            console.log(props.handleChange, "testtest", e.target.value);
            props.handleChange[name] = e.target.value.trim() === '';
            console.log(props.handleChange, "testtest1");
        }

    }


    let align = {}
    if (type === 'number') align = { align: 'right' }
    if (type === 'text') align = { align: 'right' }
    return (
        <div className="field">

            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth">
                {
                    (name === 'customerReferenceNum') ? <input style={{ ...align, borderColor: props.isInvalid ? 'red' : '' }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                        // record[name] = e.target.value

                        handleChange(e, name)
                        callback(e.target.value)
                        console.log(e.target.value, "check1");
                        // props.handleChange(e)
                    }}
                        onInput={(e) => {
                            if (name == 'cargoValue') {
                                console.log('testing');
                                e.target.value = e.target.value.replace(/\D/g, '').slice(0, 5);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (type !== 'text') {
                                return ["e", "E", "+"].includes(e.key) && e.preventDefault()
                            }
                        }

                        }
                        {...isDisabled}
                        className={isValid ? "input is-small" : "input is-small is-danger"}
                        type={type} min={min} max={max} onFocus={(e) => {
                            document.getElementById(name).showPicker()
                        }}
                        placeholder={label} onBlur={(e) => {
                            validate(e.target.value)
                        }} /> : <input style={{ ...align, borderColor: props.isInvalid ? 'red' : '' }} {...props} id={name} name={name} value={imRecord[name]}
                            onInput={(e) => {
                                if (name == 'cargoValue') {
                                    console.log('testing');
                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 5);
                                }
                            }}

                            onChange={(e) => {
                                record[name] = e.target.value
                                callback(e.target.value)
                                console.log(e.target.value, "check2");
                                handleChange(e, name)
                                // props.handleChange(e)
                            }}
                            onKeyDown={(e) => {
                                if (type !== 'text') {
                                    return ["e", "E", "+"].includes(e.key) && e.preventDefault()
                                }
                            }
                            }

                            {...isDisabled}
                            className={isValid ? "input is-small" : "input is-small is-danger"}
                            type={type} min={min} max={max} onFocus={(e) => {
                                document.getElementById(name).showPicker()
                            }}
                            placeholder={placeholder} onBlur={(e) => {
                                validate(e.target.value)
                            }} />
                }

            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please enter valid ${label}`}</p>}
        </div>
    )
}


export function AgReportInput(props) {
    const { label, record, name, type = 'text', validate = f => f, callback = f => f, isValid = true, validationMsg } = props
    let { isDisabled } = props
    const imRecord = useSnapshot(record)
    let min = ''
    let max = ''
    if (type === 'date') {
        max = Utils.getCurrentDateString()
        //max = Utils.getMaxDateString
    }
    let align = {}
    if (type === 'number') align = { align: 'right' }
    if (type === 'text') align = { align: 'right' }
    return (
        <div className="field">

            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth">
                <input style={{ ...align }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                    record[name] = e.target.value
                    callback(e.target.value)
                }}
                    {...isDisabled}
                    className={isValid ? "input is-small" : "input is-small is-danger"}
                    type={type} min={min} max={max} onFocus={(e) => {
                        document.getElementById(name).showPicker()
                    }}
                    placeholder={label} onBlur={(e) => {
                        validate(e.target.value)
                    }} />
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please enter valid ${label}`}</p>}
        </div>
    )
}


export function AgVehicletInput(props) {
    const { label, record, name, type = 'text', validate = f => f, callback = f => f, isValid = true, validationMsg } = props
    let { isDisabled } = props
    const imRecord = useSnapshot(record)
    let min = ''
    let max = ''
    if (type === 'date') {
        //   max = Utils.getCurrentDateString() 
        max = Utils.getMaxDateString
    }
    let align = {}
    if (type === 'number') align = { align: 'right' }
    if (type === 'text') align = { align: 'right' }
    return (
        <div className="field">

            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth">
                <input style={{ ...align }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                    record[name] = e.target.value
                    callback(e.target.value)
                }}
                    {...isDisabled}
                    className={isValid ? "input is-small" : "input is-small is-danger"}
                    type={type} min={min} max={max} onFocus={(e) => {
                        document.getElementById(name).showPicker()
                    }}
                    placeholder={label} onBlur={(e) => {
                        validate(e.target.value)
                    }} />
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please enter valid ${label}`}</p>}
        </div>
    )
}
export function AgPhoneNew({ label, record, name, type = 'text', validate = f => f }) {
    const imRecord = useSnapshot(record)
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth">
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
                    <span style={{ paddingRight: '3px' }}><strong>{record['phonePrefix']}</strong></span>
                    <input id={name} name={name} value={imRecord[name]} onChange={(e) => record[name] = e.target.value}
                        className="input is-small"
                        onKeyDown={(e) =>
                            ["e", "E", "+"].includes(e.key) && e.preventDefault()
                        }
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        }}
                        type={type}
                        placeholder={label} onBlur={(e) => {
                            validate(e.target.value)
                        }} />

                </div>
            </div>
        </div>
    )
}
export function AgDisplay({ label, value }) {
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth" style={{ color: '#004433', fontSize: '0.85rem' }}>
                {value}
            </div>
        </div>
    )
}
export function Agpassword(props) {
    const [ptype, setptype] = useState('password')
    const [icon, setIcon] = useState('fas fa-eye-slash')
    let callback = props.callback
    if (!callback) callback = f => f
    let txtPassword = useRef()

    if (props.myref) {
        console.log('props has ref')
        txtPassword = props.myref
    }
    let label = 'Password'
    if (props.label) label = props.label
    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className='field has-addons' style={{ justifyContent: 'stretch', flexGrow: 1 }}>
                <div className="control" style={{ width: '90%' }}>
                    <input className="input" type={ptype} defaultValue={props.value}
                        ref={txtPassword} placeholder="********" required />
                </div>
                <div class="control">
                    <button type="button" class="button is-flat" onClick={(e) => {
                        e.preventDefault()
                        if (ptype === 'password') {
                            setptype('text')
                            setIcon('fas fa-eye')
                        } else {
                            setptype('password')
                            setIcon('fas fa-eye-slash')
                        }
                    }}>
                        <span className="icon is-small">
                            <i className={icon}></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}
export function AgpasswordSmall(props) {
    const [ptype, setptype] = useState('password')
    const [icon, setIcon] = useState('fas fa-eye-slash')
    let callback = props.callback
    if (!callback) callback = f => f
    let txtPassword = useRef()

    if (props.myref) {
        txtPassword = props.myref
    }
    let label = 'Password'
    if (props.label) label = props.label
    return (
        <div className="field">
            <label className="label is-small">{label}</label>
            <div className='field has-addons' style={{ justifyContent: 'stretch', flexGrow: 1 }}>
                <div className="control" style={{ width: '90%' }}>
                    <input className="input is-small" type={ptype} defaultValue={props.value}
                        ref={txtPassword} placeholder="********" required />
                </div>
                <div class="control">
                    <button type="button" class="button is-flat is-small" onClick={(e) => {
                        e.preventDefault()
                        if (ptype === 'password') {
                            setptype('text')
                            setIcon('fas fa-eye')
                        } else {
                            setptype('password')
                            setIcon('fas fa-eye-slash')
                        }
                    }}>
                        <span className="icon is-small">
                            <i className={icon}></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}
export function AgNumber({ label, value, callback = f => f }) {
    const [val, setVal] = useState(value)
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth">
                <input value={val} onChange={(e) => setVal(e.target.value)}
                    className="input is-small"
                    type='number'
                    placeholder={label} onBlur={(e) => {
                        callback(val)
                    }} />
            </div>
        </div>
    )
}
export function AgPhone(props) {
    return (
        <div class="field has-addons">
            <p class="control">
                <input style={{ width: '4rem' }} class="input" type="text" placeholder=""
                    value={props.record['phonePrefix']} onChange={(e) => {
                        props.record['phonePrefix'] = e.target.value
                    }} />
            </p>
            <p class="control">
                <input class="input is-fullwidth" type="text" placeholder="Phone"
                    value={props.record['phone']} onChange={(e) => {
                        props.record['phone'] = e.target.value
                    }} />
            </p>
        </div>
    )
}
export function AgUnloc(props) {
    const { label, record, name, callback = f => f, isValid = true, validationMsg, isDisabled } = props
    const imRecord = useSnapshot(record)
    const [list, setList] = useState([])
    // console.log("console.log record",record.shipmentType)
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth">
                <input {...props} name={name} value={imRecord[name]} list={name}
                    {...isDisabled}
                    onChange={(e) => {
                        record[name] = e.target.value
                        Api.getMapUnloc({ descriptions: [e.target.value, record.shipmentType] }, setList)
                        callback(e.target.value);   // TODO handle
                    }}
                    className={isValid ? "input is-small" : "input is-small is-danger"} />
                <datalist id={name}>
                    {
                        list.map(e => <option value={e} />)
                    }
                </ datalist>
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please select an UN location`}</p>}
        </div>
    )
}
export function AgUnloc1(props) {
    let { label, record, name } = props
    const [list, setList] = useState([])
    const getUNCodeFromLocationString = (locString) => {
        if (locString.indexOf('(') != -1 && locString.indexOf(')') != -1) {
            return locString
                .trim()
                .slice(locString.lastIndexOf("(") + 1, locString.length - 1);
        } else {
            return locString.trim()
        }
    }

    function change() {
        for (let i = 0; i < record[name].length; i++) {
            let a = getUNCodeFromLocationString(record[name][i])
            if (a.length == 5) {
                if (record[`ts${i + 1}_port`] != a) {
                    record[`ts${i + 1}_port`] = a
                }
            } else {
                if (record[`ts${i + 1}_port`] != undefined) {
                    record[`ts${i + 1}_port`] = undefined
                }
            }
        }
    }

    function bing() {
        if (record[name].length < 3) {
            record[name].push("")
        }
    }

    function del(ind) {
        console.log(ind)
        let a = []
        for (let i = 0; i < record[name].length; i++) {
            if (i != ind) {
                a.push(record[name][i])
            }
        }
        for (let i = 0; i < a.length; i++) {
            record[`ts${i + 1}_port`] = a[i]
        }
        record[name] = a
        record[`ts${a.length + 1}_port`] = undefined
        change()
    }

    return (
        <div className="field">
            <div style={{ display: "flex" }}>
                <label className="label is-small" style={{ marginBottom: '0px' }}>{label} </label>
                {
                    (record && record[name].length < 3) ? <button style={{ position: 'relative', left: '18.35em', bottom: "0.5em" }} onClick={bing} className="button is-success is-inverted">
                        <span className="icon is-small">
                            <i className="fas fa-plus fas fa-lg"></i>
                        </span>
                    </button> : ""
                }

            </div>
            <div >
                {
                    (record[name]) ? record[name].map((e, i) => {
                        return <div className="control is-fullwidth" style={{ display: "flex" }}>
                            <input {...props} name={name} value={record[name][i]} list={i}
                                onChange={(e) => {
                                    record[name][i] = e.target.value
                                    change()
                                    Api.getMapUnloc({ descriptions: [e.target.value] }, setList)
                                }} style={{ width: "100%" }}
                            />
                            <datalist id={i}>
                                {
                                    list.map(e => <option value={e} />)
                                }
                            </ datalist>
                            {
                                (record && record[name][0].length != 0) ? <div className="field has-addons" >
                                    <p className="control">
                                        <button className="button is-success is-inverted" onClick={() => del(i)} style={{ position: "relative", bottom: "0.2em" }}>
                                            <span class="icon-text has-text-danger">
                                                <span class="icon">
                                                    <i class="fas fa-trash fas fa-lg"></i>
                                                </span>
                                            </span>
                                        </button>
                                    </p>
                                </div> : ""
                            }

                        </div>
                    }) : ""
                }

            </div>
        </div>
    )
}

export function TsUnloc(props) {
    let { label, record, name } = props
    const [list, setList] = useState([])
    const getUNCodeFromLocationString = (locString) => {
        console.log("<<<<", locString)
        if (locString.indexOf('(') != -1 && locString.indexOf(')') != -1) {
            return locString
                .trim()
                .slice(locString.lastIndexOf("(") + 1, locString.length - 1);
        } else {
            return locString.trim()
        }
    }
    // console.log(">>>>test", record.uploadShipmentType)
    return (
        <div className="field">
            <div className="columns">
                <div class="column is-half">
                    <label className="label is-small" style={{ marginBottom: '0px' }}>{label} </label>
                </div>
                <div class="column is-half">
                    <div className="control is-fullwidth" style={{ display: "flex" }}>
                        <input {...props} name={name} value={record[name]} list={name}
                            onChange={(e) => {
                                record[name] = getUNCodeFromLocationString(e.target.value)
                                console.log(e.target.value)
                                Api.getMapUnloc({ descriptions: [e.target.value, record.uploadShipmentType] }, setList)
                            }} style={{ width: "100%" }}
                        />
                        <datalist id={name} >
                            {
                                list.map(e => <option value={e} />)
                            }
                        </ datalist>


                    </div>

                </div>
            </div>
            {myactionsstore.isvalid && <div className="columns">
                <div class="column is-half"></div>
                <div class="column is-half">
                    <p class="help is-danger"> {`* Please enter valid unloc`}</p>
                </div>
            </div>}
        </div>
    )
}
export function AgUnlocdsr(props) {
    const { label, name, record, ...otherProps } = props
    if (record && record[name] == undefined) {
        record[name] = ""
    }
    const [list, setList] = useState([])
    const getUNCodeFromLocationString = (locString) => {
        if (locString.indexOf('(') != -1 && locString.indexOf(')') != -1) {
            return locString
                .trim()
                .slice(locString.lastIndexOf("(") + 1, locString.length - 1);
        } else {
            return locString.substring(0, 5).trim()
        }
    }
    return (
        <>
            <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{label}</div>
                </div>
                <div className="column is-6 py-2">
                    {(record) ? <input {...props} name={name} value={record[name]} list={name} style={{ width: "100%" }}
                        onChange={(e) => {
                            record[name] = getUNCodeFromLocationString(e.target.value)
                            Api.getMapUnloc({ descriptions: [e.target.value.toLowerCase()] }, setList)
                        }}
                    /> : <></>
                    }
                    <datalist id={name}>
                        {
                            list.map(e => <option value={e} />)
                        }
                    </ datalist>
                </div>
            </div>
        </>
    )
}


export function AgCommodity({ label, record, name, h }) {
    const imRecord = useSnapshot(record)
    let list = commodityList
    if (h === 'Yes') list = hazardousList
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth">
                <input name={name} defaultValue={imRecord[name]} list='commodities'
                    onBlur={(e) => {
                        record[name] = e.target.value
                    }}
                    className="input is-small" />
                <datalist id='commodities'>
                    {
                        list.map(e => <option value={e} />)
                    }
                </ datalist>
            </div>
        </div>
    )
}
export function Email({ email, callback = f => f }) {
    return (
        <span className="tag is-link is-medium">
            {email}
            <button className="delete is-small" onClick={() => callback(email)}></button>
        </span>
    )
}
export function EmailListComp({ label, emailStore }) {
    useSnapshot(emailStore)
    const deleteEmail = (email) => {
        console.log(JSON.stringify(emailStore))
        let data = [...emailStore.emails]
        const index = data.findIndex(e => e === email)
        data.splice(index, 1)
        emailStore.emails = data
        console.log(JSON.stringify(emailStore))
    }
    let txtEmail = useRef()
    return (
        <div className="box">
            <div className="field has-addons">
                <div className="control">
                    <h6 class="title is-6" style={{ marginRight: '1rem' }}>{label}</h6>
                </div>
                <div className="control">
                    <input autoComplete="on" ref={txtEmail} onKeyUp={(e) => {
                        e.target.value = e.target.value.replace(/ /g, '')
                        // console.log(e.target.value)
                    }} name="email"
                        className="input is-small" type="email" placeholder="cust@mail.com" />
                </div>
                <div className="control">
                    <button className="button is-small is-success" onClick={
                        () => {
                            if (Utils.isEmail(txtEmail.current.value)) {
                                emailStore.emails.push(txtEmail.current.value)
                                txtEmail.current.value = ''
                            }
                        }}>
                        <span className="icon is-small">
                            <i className="fas fa-plus"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div className="tags">
                {emailStore.emails.map(e => <Email email={e} callback={deleteEmail} />)}
            </div>
        </div>
    )
}
export function VanishMessage() {
    const imConfigStore = useSnapshot(configStore)
    setTimeout(() => {
        configStore.vanishingMessage = ''
    }, 1000)
    return <span style={{ color: 'red', fontSize: '14px' }}>{configStore.vanishingMessage}</span>
}

export function QuoteSavedMessage({ quotationRefNum }) {
    return (
        <>
            <section class="hero is-small has-text-centered">
                <div class="hero-body">
                    <p class="title">Quote Saved!</p>
                    <p class="subtitle">
                        <b>#</b> {quotationRefNum}
                        <br />
                        <br />
                        <button
                            class="button is-success"
                            //   style={{ textDecoration: "underline" }}
                            onClick={() => {
                                Api.getQuote(quotationRefNum);
                                configStore.quoteCurrentView = "view";
                                configStore.modalVisible = false;
                            }}
                        >
                            View Quotation
                        </button>
                    </p>
                </div>
            </section>
        </>
    );
}
export function QuoteSavedMessage1({ quotationRefNum }) {
    return (
        <>
            <section class="hero is-small has-text-centered">
                <div class="hero-body">
                    <p class="title">Quote Saved!</p>
                    <p class="subtitle">
                        <b>#</b> {quotationRefNum}
                        <br />
                        <br />
                    </p>
                </div>
            </section>
        </>
    );
}
export function PricingQuoteSaved({ quotationRefNum }) {
    return (
        <>
            <section class="hero is-small has-text-centered">
                <div class="hero-body">
                    <p class="title">Quote Saved!</p>
                    <p class="subtitle">
                        <b>#</b> {quotationRefNum}
                        <br />
                    </p>
                    <p>You can now Edit the quote using the Quotation tool</p>
                </div>
            </section>
        </>
    );
}
export function PricingContractSaved({ contractNum }) {
    return (
        <>
            <section class="hero is-small has-text-centered">
                <div class="hero-body">
                    <p class="title">Contract Created!</p>
                    <p class="subtitle">
                        <b>#</b> {contractNum}
                        <br />
                        <br />
                    </p>
                    <p>You can now place bookings against this Contract from the Bookings page</p>
                </div>
            </section>
        </>
    );
}