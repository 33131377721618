import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { Agyesno } from "../components/Agyesno";
import { configStore } from "../state/CommonState";
import Utils from "../util/Utils";
import { OneInput, OneInput1, OneLabel, OneLabel1 } from "./act-common";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { invoicePayment } from "./tasks-static-data";
import { getAction } from "./tasks-static-data"

export default function InvoiceAcceptancepayment(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    const [disableButton, setDisableButton] = useState(true)
    if(stage==10){
        stage = 5
    }
    function check(){
        
        let disable = false
        let currentPayment = task.currentPayment
        let ret = validateFileApproval(task)
        if (!ret.rejected){
            disable = true
            if(currentPayment.transAmount && currentPayment.transDate && currentPayment.transNum){
                if(Number(currentPayment.transAmount)==0 || currentPayment.transDate.length == 0 || currentPayment.transNum.length == 0){
                    disable = true
                }else{
                    disable = false
                }
               }
               setDisableButton(disable)
        }else{
            setDisableButton(disable)
        }
        
       
       
       
        
        
    }
    useEffect(() => {
        check()        
        let unsubscribe = subscribe(taskStore.current, () => {
            
                check()
            
            
        })
        return unsubscribe
    }, [taskStore.current])
    if (stage === 1) {
        return (
            <div className="" style={{ width: '70%' }}>
                <p>
                    Please Approve the attached invoice by <strong>{Utils.formatToDateTime1(task.dueDate, task.dueTime)}</strong>
                </p>
                {
                    (task.remarks) ?
                        <p>Remarks : {task.remarks}</p>
                        : <></>
                }
                <hr />
                <Taskfileapproval />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />'
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                </table>
                {
                    (task.files[0].approved === 'Approve') ?
                        <div>
                            <hr />
                            <strong>Enter the details below</strong>
                            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                                <tr>
                                    <OneInput label='NEFT/ RTGS Transaction Reference #'
                                        record={task.currentPayment} name='transNum' />'
                                </tr>
                                <tr>
                                    <OneInput type='date' label='Transaction Date'
                                        record={task.currentPayment} name='transDate' />
                                </tr>
                                <tr>
                                    <OneInput type='number' label='Transaction Amount'
                                        record={task.currentPayment} name='transAmount' />'
                                </tr>
                                <tr>
                                    <OneInput label='Any Deductions?'
                                        record={task.currentPayment} name='deductions' />'
                                </tr>
                            </table>
                        </div>
                        : <></>
                }
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                            onClick={async (e) => {
                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected)
                                    [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                else {
                                    [task.stage, task.pendingWith] = [3, 'AGRAGA']
                                    //task.previousPayments.push(task.currentPayment)
                                }
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 2) {
        return (
            <>
                <p><strong>The Invoice was rejected.</strong></p>
                <Taskfiletable showRejectReason={true} />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneInput label='Net Invoice Amount' name='netAmount' />'
                    </tr>
                    <tr>
                        <OneInput label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel1 label='Total Amount' name='totalAmount' />
                    </tr>
                </table>
                <TaskDatetime />
                <p>
                    <span><strong>Remarks</strong></span>
                    <br />
                    <span><textarea cols='50'
                        value={taskStore.current.remarks} onChange={(e) => {
                            taskStore.current.remarks = e.target.value
                        }} /></span>
                </p>
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 1
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.customerStatus = 'PENDING'
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                    </span>
                </p>
            </>
        )
    }
    if (stage === 3) {
        return (
            <div style={{ width: '70%' }}>
                <p>The Invoice was approved.</p>
                <hr />
                <Taskfiledisplay />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />'
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Due Date' name='dueDate' />
                    </tr>
                </table>
                <div>
                    <hr />
                    <strong>Payment Details are as below1</strong>
                    <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                        <tr>
                            <OneLabel label='NEFT/ RTGS Transaction Reference #'
                                record={task.currentPayment} name='transNum' />'
                        </tr>
                        <tr>
                            <OneLabel type='date' label='Transaction Date'
                                record={task.currentPayment} name='transDate' />
                        </tr>
                        <tr>
                            <OneLabel type='number' label='Transaction Amount'
                                record={task.currentPayment} name='transAmount' />'
                        </tr>
                        <tr>
                            <OneLabel label='Any Deductions?'
                                record={task.currentPayment} name='deductions' />'
                        </tr>
                    </table>
                </div>
                <div>
                    <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                        <tr>
                            <td></td>
                            <td>
                                <Agyesno label='Full Payment Received?' record={task} name='isFullPayment' />
                            </td>
                        </tr>
                        <tr>
                        {
                        (task.isFullPayment === 'Yes') ? <td style={{width:"70%"}}>
                            <td>
                        <div 
                        style={{ marginRight: '5px', marginTop: '3px' }}
                        >Amount Received</div>
                    </td>
                    <td >
                        <input style={{position:"relative",left:"25rem"}} className={`input is-small ${ (!taskStore.current.isValid && taskStore.current.isValidfield.indexOf("Amount Received") != -1)  ? "is-danger" : ""} `} value={task["Amount Received"]}  defaultValue={task.totalAmount}
                            onChange={(e) => {
                                task["Amount Received"] = e.target.value
                                console.log('changed value =', task["Amount Received"])
                            }} />
                    </td></td> : <OneInput label='Amount Received' type='number' name='paymentReceived' />
                        }
                        </tr>
                    </table>
                    
                    {/* <div>{task.totalAmount}</div> */}
                </div>
                {
                    (stage === 3) ?
                        <p>
                            <button style={{ float: 'left' }} className="button islink"
                                onClick={async (e) => {
                                    if (task.isFullPayment === 'No') {
                                        taskStore.current.stage = 4
                                        taskStore.current.previousPayments.push(taskStore.current.currentPayment)
                                        taskStore.current.currentPayment = { ...invoicePayment }
                                        taskStore.current.pendingWith = 'CUSTOMER'
                                        taskStore.current.customerStatus = 'PENDING'
                                        await Taskapi.updateTask(taskStore.current)
                                    } else {
                                        taskStore.current.stage = 5
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                    }
                                    taskStore.triggerActionVisible = false
                                    taskStore.myActionVisible = false
                                }}>Submit</button>

                        </p>
                        : <></>
                }
            </div>
        )
    }
    if (stage === 4) {
        return (
            <div className="" style={{ width: '70%' }}>
                <p>
                    <strong>Please complete your payment</strong>
                </p>
                <hr />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />'
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Payment Received' name='paymentReceived' />
                    </tr>
                    <tr>
                        <td>Balance to be paid</td>
                        <td style={{color:"red",fontWeight:"bold"}}>{Number(task.totalAmount) - Number(task.paymentReceived)}</td>
                    </tr>
                </table>
                <hr />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    {
                        task.previousPayments.map(e => {
                            return <tr>
                                <td>{e.transNum}</td>
                                <td>{Utils.formatToDateTime1(e.transDate, '')}</td>
                                <td>{e.transAmount}</td>
                            </tr>
                        })
                    }
                </table>
                <hr />
                <strong>Enter payment details below</strong>
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneInput label='NEFT/ RTGS Transaction Reference #'
                            record={task.currentPayment} name='transNum' />'
                    </tr>
                    <tr>
                        <OneInput type='date' label='Transaction Date'
                            record={task.currentPayment} name='transDate' />
                    </tr>
                    <tr>
                        <OneInput type='number' label='Transaction Amount'
                            record={task.currentPayment} name='transAmount' />'
                    </tr>
                    <tr>
                        <OneInput label='Any Deductions?'
                            record={task.currentPayment} name='deductions' />'
                    </tr>
                </table>

                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                            onClick={async (e) => {
                                let task = taskStore.current
                                task.stage = 3
                                task.pendingWith = 'AGRAGA'
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 5) {
        return (
            <div className="" style={{ width: '70%' }}>
                <p>
                    <strong>Inoice and payment details</strong>
                </p>
                <Taskfiledisplay />
                <hr />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />'
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Payment Received' name='paymentReceived' />
                    </tr>
                </table>
                <hr />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    {
                        task.previousPayments.map(e => {
                            return <tr>
                                <td>{e.transNum}</td>
                                <td>{Utils.formatToDateTime1(e.transDate, '')}</td>
                                <td>{e.transAmount}</td>
                            </tr>
                        })
                    }
                </table>
            </div>
        )
    }
}
export function InvoiceAcceptancepaymentTrigger(props) {
    useSnapshot(taskStore)
    const [disableButton, setDisableButton] = useState(true)
    let task = taskStore.current
    function checkfloat(val){
        let a = val.replace(/[^0-9.]/g, '').split('.');
        if(a.length>1){
            a = a[0]+'.'+a[1];
        }else{
            a = a[0]
        }
        return a
    }
    function check(){
        let disable = false
        if(task.netAmount) task.netAmount = checkfloat(task.netAmount)
        if(task.gstAmount) task.gstAmount = checkfloat(task.gstAmount)
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        if(Number(task.netAmount)==0 || Number(task.gstAmount)<0 || taskStore.current.dueDate.length == 0){
            disable = true
        }
        task.totalAmount = Number(task.netAmount) + Number(task.gstAmount)
        setDisableButton(disable)
    }
    useEffect(() => {
        check()        
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr />
            <Taskfiletable />
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    <OneInput label='Net Invoice Amount' name='netAmount' />'
                </tr>
                <tr>
                    <OneInput label='GST Amount' name='gstAmount' />
                </tr>
                <tr>
                    <OneLabel label='Total Amount' name='totalAmount' />
                </tr>
            </table>
            {/* <TaskDatetime /> */}

            <p>
                <span>
                    <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                        onClick={async (e) => {
                            taskStore.current.stage = 1
                            taskStore.current.currentPayment = { ...taskStore.current.payment }
                            taskStore.current.pendingWith = 'CUSTOMER'
                            await Taskapi.saveTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                        }}>Trigger Action</button>
                </span>
            </p>
        </>
    )
}