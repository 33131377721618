import React, { useState, useEffect } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query"

import {
  EntityApprovalColumn,
  EntityApprovalColumnCom,
  EntityColumnnonagraga,
} from "../components/ag-table/columns"
import ReactTablePagination from "../components/ag-table/ReactTablePagination"
import TableFilter from "../components/ag-table/TableFilter"
import { Sorting } from "../components/ag-table/ag-sorting"
import {
  reducer,
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  PAGE_FILTER_CHANGED,
  TOTAL_COUNT_CHANGED,
  PAYLOAD_CHANGED,
} from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table"
import {
  loginStore,
  routerStore,
  DriverStore,
  EntityV2Store,
  creditAppStore,
  creditformStore,
} from "../state/CommonState"
import { useSnapshot } from "valtio"
import { DriverType, EntityTypeV2, CreditAappFormType } from "../state/Types"
import InviteUser from "../agusers/invite-user"
import CreditsApis from "../state/credit-api"
import { CREDIT_STATUS, PERMISSIONS } from "../util/constants"
import RoleUtils from "../util/RoleUtils"
import '../entity-v2/styles/global.styles.css'
import Approval_Apis from "../state/approval-api"


const queryClient = new QueryClient()

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: 0,
  queryPageFilter: "",
  queryPageSortBy: [],
  queryPayload: {},
}

const DataTable = (props) => {
  useSnapshot(DriverStore)
  useSnapshot(creditAppStore)
  const domain = loginStore.email.split("@")[1]
  const filterStatus = {
    Pending: "PENDING",
    Verified: "VERIFIED",
    Rejected: "REJECTED",
    DlExpired: "DL-EXPIRED",
  }

  // const [selectedFilter, SetSelectedFilter] = useState(`all`);

  const [keyword, setKeyword] = useState("")
  const [inviteGst, setInviteGst] = useState("")
  const [data1, setData1] = useState({ results: [] })
  const [userRole, setuserRole] = useState()
  const [filStatus, setfilStatus] = useState("Pending")
  const [style, setStyle] = useState("radio_btn btn_selected")
  const [column, setcolumns] = useState(EntityApprovalColumn)

  const onClickFilterCallback = (filter) => {
    setKeyword(filter)
  }
  // let column = {};
  if (domain === "agraga.com") {
    // column = EntityApprovalColumn;
  }

  useEffect(() => {
    if (isAgragaSales()) {
      setuserRole("SALES")
    }
    if (isAgragaFinance()) {
      setuserRole("FINANCE")
    }
    if (isAgragaAdmin()) {
      setuserRole("FINANCE")
    }
    if (isCentralOperations()) {
      setuserRole("Operations")
    }
  }, [])

  // useEffect(()=> {
  // if(filStatus === "Completed"){
  //   setcolumns(EntityApprovalColumnCom)
  // //  columns =  
  // }
  // },[])
  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryPayload,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState)

  useEffect(() => {
    if (userRole !== undefined && queryPayload.userRole !== undefined) {
      Approval_Apis.approvalsGet(
        queryPageIndex,
        queryPageSize,
        queryPageFilter,
        queryPageSortBy,
        queryPayload).then((res) => {
          res
            .clone()
            .json()
            .then(
              (res) => {
                if (JSON.stringify(data1?.results?.results) !== JSON.stringify(res?.results?.results)) {
                  if (JSON.stringify(data1) !== JSON.stringify(res?.results?.results)) {
  
                    setData1(res?.results)
                    console.log(res);
  
                  }
                }
              },
              (err) => {
                console.log(err)
              }
            )
        })
  
    }

  }, [
    queryPayload,
    queryPageIndex,
    queryPageSize,
    queryPageFilter,
    queryPageSortBy,
  ])

  const totalPageCount = Math.ceil(totalCount / queryPageSize)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns: column,
      data: data1?.results || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data1 ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  )
  const manualPageSize = []

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex })
  }, [pageIndex,gotoPage])

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize })
    gotoPage(0)
  }, [pageSize, gotoPage])

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy })
    gotoPage(0)
  }, [sortBy, gotoPage])

  useEffect(() => {
    dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword })
    gotoPage(0)
  }, [keyword, gotoPage])
  useEffect(() => {
    dispatch({
      type: PAYLOAD_CHANGED,
      payload: {
        userRole: userRole,
        status: filStatus || "Pending",
      },
    })
    gotoPage(0)
  }, [userRole, filStatus])


  // useEffect(() => {
  //   dispatch({ type: PAYLOAD_CHANGED, payload: { filter: selectedFilter } });
  //   gotoPage(0);
  // }, [selectedFilter]);

  React.useEffect(() => {
    if (data1?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data1.count,
      })
    }
  }, [data1?.count])

  const isPendingStatus = () => {
    if (filStatus === "pending") {
      return true
    }
    return false
  }

  function isAgragaAdmin() {
    // return RoleUtils.getUserRole().toLowerCase() === "agraga admin"
    console.log("AG DMIn: ", RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.ALL_ACCESS))
    return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.ALL_ACCESS)

  }
  function isAgragaSales() {
    // return RoleUtils.getUserRole().toLowerCase() === "sales"
    console.log("Sales: ", RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.APPROVALS_SALES))
    return RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.APPROVALS_SALES)
  }
  function isCentralOperations() {
    // return RoleUtils.getUserRole().toLowerCase() === "sales"
    console.log("Central Operations: ", RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.APPROVALS_OPERATIONS))
    return RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.APPROVALS_OPERATIONS)
  }

  function isAgragaFinance() {
    // return RoleUtils.getUserRole().toLowerCase() === "finance"
    console.log("Finance: ", RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.APPROVALS_FINANCE))
    return RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.APPROVALS_FINANCE)
  }
  // function isAgragaAdmin() {
  //   return RoleUtils.getUserRole().toLowerCase() === "agraga admin"
  // }
  // function isAgragaSales() {
  //   return RoleUtils.getUserRole().toLowerCase() === "sales"
  // }
  // function isAgragaFinance() {
  //   return RoleUtils.getUserRole().toLowerCase() === "finance"
  // }


  const setStyleBtn = () => {
    setStyle()
  }

  function isUserRoleFinance() {
    return userRole === "FINANCE"
  }

  function isUserRoleSales() {
    return userRole === "SALES"
  }
  function isUserCentralOperations () {
    return userRole === "Operations"
  }
  function isFilterStatusPen() {
    return filStatus === "Pending"
  }

  function isFilterStatusCom() {
    return filStatus === "Completed"
  }



  return (
    <>
      <h1 className="title">Approvals</h1>
      <nav className="level">
        <div className="level-left">
          {/* <div className="level-item">
            <p className="subtitle is-5">{data1?.count} Drivers</p>
          </div> */}
          <p className="level-item">Approvals</p>
          {/* <button
            onClick={() => {
              EntityV2Store.EntityRec.set(new CreditAappFormType());
              routerStore.entityCurrentViewNew = "new";
            }}>
            New
          </button> */}
          <div className="level-item is-hidden-tablet-only">
            <TableFilter
              onClickFilterCallback={onClickFilterCallback}
              defaultKeyword={keyword}
            />
          </div>
          <div className="is-flex ml-6">
            <div className="is-flex">
              <>{(isAgragaFinance() || isAgragaAdmin()) &&
                <><button 
                  {...(isUserRoleFinance()
                    ? { className: "role-badge user-roles-badge-selected" }
                    : { className: "role-badge" })}
                  onClick={() => setuserRole("FINANCE")}>
                  Finance
                </button>
                </>
              }
                {(isAgragaAdmin() || isAgragaSales()) &&
                  <><button 
                    {...(isUserRoleSales()
                      ? { className: "role-badge user-roles-badge-selected" }
                      : { className: "role-badge" })}
                    onClick={() => setuserRole("SALES")}>
                    Sales
                  </button>
                  </>
                }
                 {(isAgragaAdmin() || isCentralOperations()) &&
                  <><button 
                    {...(isUserCentralOperations()
                      ? { className: " role-badge user-roles-badge-selected" }
                      : { className: " role-badge " })}
                    onClick={() => setuserRole("Operations")}>
                     Operations
                  </button>
                  </>
                } 
                </>

            </div>

            {/* <div class="field">
              <p class="control has-icons-left has-icons-right">
                <span class="select">
                  <select
                    name="status"
                    id="status"
                    value={selectedFilter}
                    onChange={(e) => SetSelectedFilter(e.target.value)}
                  >
                    <option value="all">ALL</option>
                    {Object.entries(filterStatus).map(([key, value]) => (
                      <option value={key}>{value}</option>
                    ))}
                  </select>
                  <span class="icon">
                    <i class="fa-solid fa-filter"></i>
                  </span>
                </span>
              </p>
            </div> */}
          </div>
        </div>
        <div className="is-flex level-right">
          <button
            {...(isFilterStatusPen()
              ? { className: "role-badge user-roles-badge-selected" }
              : { className: "role-badge" })}
            onClick={() => {
              setfilStatus("Pending")
              setcolumns(EntityApprovalColumn)
            }}>
            Pending
          </button>
          <button
            {...(isFilterStatusCom()
              ? { className: "role-badge user-roles-badge-selected" }
              : { className: "role-badge" })}
            onClick={() => {
              setfilStatus("Completed")
              setcolumns(EntityApprovalColumnCom)
            }}>
            Completed
          </button>
        </div>
      </nav>

      {
        <>
          {(typeof data1?.count === "undefined" || data1?.count === 0) && (
            <p>No results found</p>
          )}
          {data1?.count > 0 && (
            <>
              <div className="table-container">

                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        <th>S.No</th>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}>
                            {column.render("Header")}
                            <Sorting column={column} />
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className="listentityapprovaltable">
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <>
                          <tr
                            {...row.getRowProps()}
                            onClick={()=> PageRedirect(data1,i,userRole,row.original)}>
                            <td>{pageIndex * pageSize + i + 1}</td>
                            {row.cells.map((cell) => {
                              {
                                let e = cell["row"]["original"]
                                switch (cell["column"]["Header"]) {
                                  case "Completed Date":
                                    return (
                                      <td>
                                        <span> {new Date(e.updatedAt).toLocaleDateString()}</span>
                                      </td>
                                    )

                                  default:
                                    return (
                                      <td {...cell.getCellProps()}>
                                        <span>{cell.render("Cell")}</span>
                                      </td>
                                    )
                                }
                              }
                            })}
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>


                {rows.length > 0 && (
                  <>
                    <div className="columns" style={{ width: "100%" }}>
                      <div className="column is-6">
                        <ReactTablePagination
                          page={page}
                          gotoPage={gotoPage}
                          previousPage={previousPage}
                          nextPage={nextPage}
                          canPreviousPage={canPreviousPage}
                          canNextPage={canNextPage}
                          pageOptions={pageOptions}
                          pageSize={pageSize}
                          pageIndex={pageIndex}
                          pageCount={pageCount}
                          setPageSize={setPageSize}
                          manualPageSize={manualPageSize}
                          dataLength={totalCount}
                        />
                      </div>
                      <div
                        className="column is-6"
                        style={{ textAlign: "right" }}>
                        <div className="select">
                          <select
                            value={pageSize}
                            onChange={(e) => {
                              setPageSize(Number(e.target.value))
                            }}>
                            {[20, 50, 100].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      }
    </>
  )
}

const ListEntityApprovals = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable />
    </QueryClientProvider>
  )
}

const PageRedirect =async (data1,i,userRole,data)=>{
    if(data?.type === "Credit Application"){
      await Approval_Apis.viewCaf(data.id, userRole,data.action)
    }
    else if (data?.type === "Entity Application") {
      await Approval_Apis.viewEnt(data.id, userRole, data.action,data)
    }
    else if (data?.type === "Driver Application") {
      await Approval_Apis.viewDriver(data.OBJID, userRole, data.action,data)
    }
    else if (data?.type === "Vehicle Application") {
      await Approval_Apis.viewVehicle(data.id, userRole, data.action,data)
    }
    else if (data?.type === "Branch Application") {
      await Approval_Apis.viewBranch(data.id, userRole, data.action,data)
    }
  
}

export default ListEntityApprovals
