import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { configStore } from "../state/CommonState";
import { OneDatetable, OneInput5table } from "../mytasks/act-common";
import { useEffect, useState } from "react";

export function Vviewmodal() {
    useSnapshot(myactionsstore)
    useSnapshot(configStore)
    const [ch, setCh] = useState(0)
    const [orgin, setOrgin] = useState([])
    const [des, setDes] = useState([])
    // let orgin = []
    // let des = []
    let ret = configStore.modalvisibledata
    let st = ret.shipmentType
    let sc = ret.shipmentScope

    const seorde = () => {
        if (sc == "Port-to-Door" || sc == "Door-to-Door") {
            if (ret.vdes != undefined && ret.vdes.length > 0) {
                setDes(ret.vdes)
            }
            
        }
        if (sc == "Door-to-Port" || sc == "Door-to-Door") {
            if (ret.vorgin != undefined && ret.vorgin.length > 0) {
                setOrgin(ret.vorgin)
            }
        }
        changech()
    }
    

    const changech = ()=>{
        if(ch==0){
            setCh(1)
        }else{
            setCh(0)
        }
    }

    const add1 = () => {
        let o = orgin
        if (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) {
            o.push({
                vehicle: "",
                arrival_factory: "",
                cargo_pickup: "",
                cargo_arrival_cfs: ""
            })
        } else {
            o.push({
                vehicle: "",
                arrival_factory: "",
                cargo_pickup: "",
                arrival_dispatch_airport: ""
            })
        }
        setOrgin(o)
        changech()

    }

    const del1 = (id) =>{
        let l = []
        for (let i = 0; i < orgin.length; i++) {
            if(i!=id){
                l.push(orgin[i])
            }
        }
        setOrgin(l)
    }

    const del2 = (id) =>{
        let l = []
        for (let i = 0; i < des.length; i++) {
            if(i!=id){
                l.push(des[i])
            }
        }
        setDes(l)
    }

    const add2 = () => {
        let d = des
        if (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) {
            d.push({
                vehicle: "",
                loaded_cfs: "",
                atdfrompod: "",

                estimate_delivery_date_to_consignee: "",
                actual_delivery_date:""
            })
        } else {
            d.push({
                vehicle: "",
                dispatched_from_arrival_airport: "",

                estimate_delivery_date_to_consignee: "",
                actual_delivery_date:""
            })
        }
        setDes(d)
        changech()
    }

    useEffect(()=>{
        if(myactionsstore.vviewModalVisible==true){
            seorde()
        }
    },[myactionsstore.vviewModalVisible])

    const onsubmit2 = () =>{
        if(orgin.length>0){
            ret.vorgin = orgin
        }else{
            ret.vorgin = []
        }
        if(des.length>0){
            ret.vdes = des
        }else{
            ret.vdes = []
        }
        configStore.modalvisibledata = ret
        myactionsstore.vviewModalVisible = false
    }
    let modalClass = 'modal'
    if (myactionsstore.vviewModalVisible) modalClass += ' is-active'
    return (
        <>
            {
                (myactionsstore.vviewModalVisible) ? <div className={modalClass} onClick={() => { myactionsstore.vviewModalVisible = false }}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px', width: "90%" }} >
                        <section className="modal-card-body"  >
                            <div className="table-container" onClick={(e) => e.stopPropagation()}>

                                {
                                    ((sc == "Door-to-Port" || sc == "Door-to-Door") && st!='FCL (Factory/Dock)') ?
                                        <>
                                            <h1>Origin Pick-up</h1>
                                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                                <thead>
                                                    <th>S.No</th>
                                                    <th>Vehicle#</th>
                                                    <th>Arrival @ Factory</th>
                                                    <th>Cargo Pickup</th>
                                                    {
                                                        (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                            <th>Cargo arrival at CFS</th>
                                                        </> : <>
                                                            <th>Arrival @ Dispatch Airport</th>
                                                        </>
                                                    }

                                                   
                                                </thead>
                                                <tbody>
                                                    {
                                                        orgin.map((e, i) => {
                                                           return <tr key={i}>
                                                                <td>{i+1}</td> 
                                                                <td>{(e["vehicle"]?e["vehicle"]:"")}</td>
                                                                <td>{(e["arrival_factory"]?e["arrival_factory"]:"")}</td>
                                                                <td>{(e["cargo_pickup"]?e["cargo_pickup"]:"")}</td>
                                                                {
                                                                    (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                                        <td>{(e["cargo_arrival_cfs"]?e["cargo_arrival_cfs"]:"")}</td>
                                                                    </> : <>
                                                                        <td>{(e["arrival_dispatch_airport"]?e["arrival_dispatch_airport"]:"")}</td>
                                                                    </>
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>

                                            </table></> : ""
                                }


                                {
                                    ((sc == "Port-to-Door" || sc == "Door-to-Door") && st!='FCL (Dock/Factory)') ? <>
                                        <h1>Destination Delivery</h1>
                                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                            <thead>
                                                <th>S.No</th>
                                                <th>Vehicle#</th>
                                                
                                                {
                                                    (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                        <th>Loaded @ CFS</th>
                                                    {
                                                        (st.toLowerCase().indexOf("lcl") != -1)?<th>Cargo Picked-up for Delivery</th>:<th>Dispatched from CFS</th>
                                                    }
                                                        
                                                    </> : <>
                                                        <th>Dispatched from Arrival Airport</th>
                                                    </>
                                                }
                                                <th>Estimated Delivery Date to Consignee</th>
                                                <th>Actual Delivery Date</th>

                                              
                                            </thead>
                                            <tbody>
                                                {
                                                    des.map((e, i) => {
                                                        return <tr>
                                                            <td>{i+1}</td>
                                                            <td>{(e["vehicle"]?e["vehicle"]:"")}</td>
                                                            {
                                                                (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                                
                                                
                                                                    <td>{(e["loaded_cfs"]?e["loaded_cfs"]:"")}</td>
                                                                    <td>{(e["atdfrompod"]?e["atdfrompod"]:"")}</td>
                                                                </> : <>
                                                                    <th>{(e["dispatched_from_arrival_airport"]?e["dispatched_from_arrival_airport"]:"")}</th>
                                                                </>


                                                            }
                                                            <td>{(e["estimate_delivery_date_to_consignee"]?e["estimate_delivery_date_to_consignee"]:"")}</td>
                                                            <td>{(e["actual_delivery_date"]?e["actual_delivery_date"]:"")}</td>
                                                         
                                                        </tr>
                                                    })
                                                }
                                            </tbody>

                                        </table></> : ""
                                }

                            </div>


                            <button class="button is-warning" onClick={() => myactionsstore.vviewModalVisible = false} >cancel</button>

                        </section>
                    </div>
                </div> : <></>
            }
        </>
    )
}