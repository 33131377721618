import { Box, Button, IconButton, Typography } from "@mui/material";
import profileIcon from "../assets/images/profile2.svg";
import password from "../assets/images/password.svg";
import company from "../assets/images/company.svg";
import app from "../assets/images/app.svg";
import report from "../assets/images/report.svg";
import calendar from "../assets/images/calendar2.svg";
import access from "../assets/images/lock-access.svg";
import desktop from "../assets/images/desktop.svg";
import { useState } from "react";
import LabeledTextField from "../mui-components/LabeledTextField";
import { useForm } from "react-hook-form";
import { aguserStore } from "../state/CommonState";
import { Visibility, VisibilityOff } from "@mui/icons-material";

let menus = [
    { title: "Profile", icon: profileIcon, component: <ProfilePage />, handler: () => { }, showSave: true },
    { title: "Password", icon: password, showSave: true, component: <ChangePassword /> },
    { title: "Entity", icon: company, showSave: true },
    { title: "App Settings", icon: app, showSave: true },
    { title: "DSR Settings", icon: report, showSave: true },
    { title: "DSR Scheduler", icon: calendar, showSave: true },
    { title: "Access Control", icon: access, component: <AccessControlPage />, showSave: false }
]

function Menu({ isActive, item, onClick }) {
    return <Box onClick={() => {
        if (onClick) {
            onClick();
        }
    }} sx={{
        "&:hover": {
            cursor: "pointer"
        },
        fontFamily: "Figtree",
        textAlign: "start",
        wordBreak: "break-word",
        whiteSpace: "normal",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        backgroundColor: isActive ? "#2A6ED4" : "white",
        width: "100%", borderRadius: "4px",
        color: isActive ? "white" : "#555555",
        "& img": {
            filter: isActive ? 'brightness(0) invert(1)' : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(22%) hue-rotate(49deg) brightness(98%) contrast(85%)"
        },
    }}><img src={item.icon} /> <span style={{ paddingLeft: "8px" }}>{item.title}</span></Box>
}


function PasswordText({ control, name, rules, label, placeholder }) {
    const [show, showPassword] = useState(false)
    return <LabeledTextField labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} placeholder={placeholder} control={control} InputProps={{
        style: {
            // borderRadius:0
        },
        endAdornment: (
            <IconButton size="small" onClick={() => {
                showPassword((prevState) => !prevState);
            }}>
                {show ? <VisibilityOff /> : <Visibility />
                }                        </IconButton>
        ),
    }} type={show ? "text" : "password"} autoComplete="off" size="small" fullWidth label={label} name={name} rules={rules} />
}

function DeviceDetail({ iscurrentDevice = false, device }) {
    return <Box sx={{ display: "flex", flexDirection: "row", borderRadius: "7px", border: "1px solid #E8E8E8", padding: "10px", alignItems: "center", gap: 1 }}>
        <img width="25px" height="25px" src={desktop} />
        <Typography sx={{ fontFamily: "Figtree", marginRight: "auto" }}>Desktop / Windows-10 / Microsoft Edge-119.0.0.0 - Chennai</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, width: "150px" }}>
            <Box sx={{ height: "8px", width: "8px", borderRadius: "50%", backgroundColor: "#71A282" }}></Box>
            <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black" }}>Active</Typography>
        </Box>
        <Box sx={{ width: "100px" }}>
           { !iscurrentDevice && <Button variant="contained" sx={{
                borderRadius: "5px", backgroundColor: "#2B6ED4", "&:hover": {
                    backgroundColor: "#2B6ED4"
                }
            }} >Log Out</Button>}
        </Box>
    </Box>
}


function AccessControlPage() {
    return <Box sx={{ flex: 1, display: "flex", flex: 1, flexDirection: "column", gap: 3 }}>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize:"18px" }}>Access Control</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }} >
            <Typography sx={{ fontFamily: "Figtree", color: "black" }}>This Device:</Typography>
            <DeviceDetail iscurrentDevice={true} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography sx={{ fontFamily: "Figtree", color: "black" }}>Other Devices:</Typography>
        <DeviceDetail />
            <DeviceDetail />
            <DeviceDetail />
            <DeviceDetail />
            <DeviceDetail />
            <DeviceDetail />
            <DeviceDetail />
            <DeviceDetail />
            <DeviceDetail />
        </Box>
    </Box>
}


function ChangePassword() {
    const defaultForm = useForm({
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
    });

    const { control } = defaultForm

    return <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize:"18px" }}>Change Password</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: "14px" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ width: "300px" }}>
                    <PasswordText control={control} name="oldPassword" label="Old Password" placeholder="Enter your old password" />
                </Box>
                <Box sx={{ width: "300px" }}>
                    <PasswordText control={control} name="newPassword" label="New Password" placeholder="Enter your new password" />
                </Box>
                <Box sx={{ width: "300px" }}>
                    <PasswordText control={control} name="confirmPassword" label="Confirm Password" placeholder="Enter your confirm password" />
                </Box>
            </Box>
        </Box>
    </Box>
}


function ProfilePage() {
    const defaultForm = useForm({
        defaultValues: {
            firstName: aguserStore.aguserRec.firstName,
            lastName: aguserStore.aguserRec.lastName,
            email: aguserStore.aguserRec.email,
            phone: aguserStore.aguserRec.phone,
            password: "",
            confirmPassword: "",
            country: "",
            gst: "",
            role: aguserStore.aguserRec.role
        }
    });

    const { control } = defaultForm;

    return <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black" ,fontSize:"18px"}}>Profile</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: "14px" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ width: "300px" }}>
                    <LabeledTextField labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="First Name" control={control} name="firstName" />
                </Box>
                <Box sx={{ flexBasis: "300px" }}>
                    <LabeledTextField labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="Last Name" control={control} name="lastName" />
                </Box>
                <Box sx={{ flexBasis: "300px" }}>
                    <LabeledTextField labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="Phone Number" control={control} name="phone" />
                </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ flexBasis: "300px" }}>
                    <LabeledTextField disabled={true} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="Designation" control={control} name="role" />
                </Box>
                <Box sx={{ flexBasis: "300px" }}>
                    <LabeledTextField disabled={true} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="Email" control={control} name="email" />
                </Box>
                <Box sx={{ flexBasis: "300px" }}></Box>
            </Box>
        </Box>
    </Box>
}

export function SettingsPage() {
    const [selectedMenu, setMenu] = useState(0);
    return <Box sx={{ height: "100%", display: "flex", flexDirection: "row", width: "100%", gap: 1 }} >
        <Box sx={{ backgroundColor: "white", borderRadius: "10px", width: "180px", display: "flex", flexDirection: "column", gap: 1, padding: "12px" }}>
            {menus.map((menu, i) => <Menu onClick={() => {
                setMenu(i);
            }} isActive={selectedMenu === i} item={menu} />)}
        </Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{
                flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px", overflowY: "auto", "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#2A6ED4 !important"
                }
            }}>
                {menus[selectedMenu].component}
            </Box>
            {menus[selectedMenu].showSave && <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "6%", justifyContent: "end", gap: 2 }}>
                <Button size="small"
                    // startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} 
                    sx={{ textTransform: "capitalize", backgroundColor: "white" }}>Cancel</Button>
                <Button size="small"
                    // startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} 
                    variant="contained" sx={{ textTransform: "capitalize" }}>Save Changes</Button>
            </Box>}
        </Box>
    </Box>
}