import {subscribe, useSnapshot } from "valtio";
import { useEffect,useState } from "react";
import { configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Awbrelease(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage == 1) {
        return (
            <div className="box">
                {/* <p>Your AWB is ready for releasing</p> */}
                <p>AWB is ready for releasing (Agraga)</p>
                <hr />
                <Taskfiledisplay />
                <hr />
                {
                    (stage === 1) ?
                        <p>
                            <span>
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                    }}>Submit</button>
                            </span>
                        </p>
                        : <></>
                }
            </div>
        )
    }
    if (stage >= 2) {
        return (
            <>
                <p>AWB Verified by Customer</p>
                <hr />
                <Taskfiledisplay />
                <hr />
                <p>
                    <span>
                        {
                            (stage === 2) ?
                                <button style={{ float: 'right' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 3
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </>
        )
    }
}
export function AwbreleaseTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [disableButton,setDisableButton] = useState(true)
    let task = taskStore.current
    let stage = task.stage
    function check(){
        let disable = false
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr />
            <Taskfiletable />

            <p>
                <button className="button islink"
                disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}