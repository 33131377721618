import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { configStore } from "../state/CommonState";
import Utils from "../util/Utils";
import { OneInput, OneLabel } from "./act-common";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function InvoiceAcceptance(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    function checkfloat(val){
        let a = val.replace(/[^0-9.]/g, '').split('.');
        if(a.length>1){
            a = a[0]+'.'+a[1];
        }else{
            a = a[0]
        }
        return a
    }
    let stage = task.stage
    const [disableButton, setDisableButton] = useState(true)
    function check(){
        if(task.netAmount) task.netAmount = checkfloat(task.netAmount)
        if(task.gstAmount) task.gstAmount = checkfloat(task.gstAmount)
        let disable = false
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        if(Number(task.netAmount)==0 || Number(task.gstAmount)<0 ){
            disable = true
        }
        task.totalAmount = Number(task.netAmount) + Number(task.gstAmount)
        setDisableButton(disable)
    }
    useEffect(() => {
        check()     
        let unsubscribe = subscribe(taskStore.current, () => {
            
            check()
        })
        return unsubscribe
    }, [taskStore.current])

    if (stage === 1) {
        return (
            <div className="box">
                <p>
                    Please Approve the attached invoice by <strong>{Utils.formatToDateTime1(task.dueDate, task.dueTime)}</strong>
                </p>
                {
                    (task.remarks) ?
                        <p>Remarks : {task.remarks}</p>
                        : <></>
                }
                <hr />
                <Taskfileapproval />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />'
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                </table>
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                            onClick={async (e) => {
                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected)
                                    [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                else
                                    [task.stage, task.pendingWith] = [3, 'AGRAGA']
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 2) {
        return (
            <>
                <p><strong>The Invoice was rejected.</strong></p>
                <Taskfiletable showRejectReason={true} />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneInput label='Net Invoice Amount' name='netAmount' />'
                    </tr>
                    <tr>
                        <OneInput label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                </table>
                <TaskDatetime />
                <p>
                    <span><strong>Remarks</strong></span>
                    <br />
                    <span><textarea cols='50'
                        value={taskStore.current.remarks} onChange={(e) => {
                            taskStore.current.remarks = e.target.value
                        }} /></span>
                </p>
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                            onClick={async (e) => {
                                taskStore.current.stage = 1
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.customerStatus = 'PENDING'
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                    </span>
                </p>
            </>
        )
    }
    if (stage >= 3) {
        return (
            <>
                <p>The Invoice was approved.</p>
                <hr />
                <Taskfiledisplay />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />'
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Due Date' name='dueDate' />
                    </tr>
                </table>
                {
                    (stage === 3) ?
                        <p>
                            <button style={{ float: 'right' }} className="button islink" disabled={disableButton}
                                onClick={async (e) => {
                                    taskStore.current.stage = 4
                                    taskStore.current.pendingWith = 'AGRAGA'
                                    taskStore.setComplete()
                                    await Taskapi.updateTask(taskStore.current)
                                    taskStore.triggerActionVisible = false
                                    taskStore.myActionVisible = false
                                }}>Acknowledge</button>

                        </p>
                        : <></>
                }
            </>
        )
    }
}
export function InvoiceAcceptanceTrigger(props) {
    useSnapshot(taskStore)
    const [disableButton, setDisableButton] = useState(true)
    let task = taskStore.current

    function checkfloat(val){
        let a = val.replace(/[^0-9.]/g, '').split('.');
        if(a.length>1){
            a = a[0]+'.'+a[1];
        }else{
            a = a[0]
        }
        return a
    }
    function check(){
        let disable = false
        if(task.netAmount) task.netAmount = checkfloat(task.netAmount)
        if(task.gstAmount) task.gstAmount = checkfloat(task.gstAmount)
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })

        

        if(Number(task.netAmount)==0 || Number(task.gstAmount)<0 || taskStore.current.dueDate.length == 0){
            disable = true
        }
        task.totalAmount = Number(task.netAmount) + Number(task.gstAmount)
        setDisableButton(disable)
    }
    useEffect(() => {
        check()        
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr />
            <Taskfiletable />
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    <OneInput label='Net Invoice Amount' name='netAmount' />'
                </tr>
                <tr>
                    <OneInput label='GST Amount' name='gstAmount' />
                </tr>
                <tr>
                    <OneLabel label='Total Amount' name='totalAmount' />
                </tr>
            </table>
            {/* <TaskDatetime /> */}

            <p>
                <span>
                    <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                        onClick={async (e) => {
                            taskStore.current.stage = 1
                            taskStore.current.pendingWith = 'CUSTOMER'
                            await Taskapi.saveTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                        }}>Trigger Action</button>
                </span>
            </p>
        </>
    )
}