import { Box, Button, Card, CardContent, Grid, Typography, useMediaQuery } from "@mui/material";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
import { AgMuiSelect } from "../mui-components/AgMuiSelect";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { configStore, entityStore, loginStore } from "../state/CommonState";
import { ServerLists } from "../StaticData";
import { AgModal } from "../mui-components/AgModal";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import { useSnapshot } from "valtio";
import LabeledTextField from "../mui-components/LabeledTextField";
import { PageLayout } from "../mui-components/Pagelayout";
import Api from "../state/Api";
import AguserApi from "../state/AguserApi";
import stateList from "../static-data/states";
import { ArrowBack } from "@mui/icons-material";
import { TbLogin } from "react-icons/tb";



export function EntityCreation({ isNewUser, callback }) {
    const isDesktop = useMediaQuery('(min-width:769px)');
    const defaultForm = useForm({
        defaultValues: {
            entityName: "",
            gst: "",
            country: "",
            pan: "",
            industry: "",
            state: "",
            city: "",
            pin: ""
        }
    })

    const { register, formState: { errors }, handleSubmit, control, watch } = defaultForm;

    const selectedCountry = watch("country");


useEffect(()=>{
    Api.loadCountryList()
},[])





    const submitHandler = (data) => {
        console.log("The submitted data ", data)

        let email = loginStore.userRec.email;
        if (isNewUser) {
            email = loginStore.email;
        }
        // makeApiCall({...data,email,isEntity:true});
        AguserApi.registerNewUserV2({ ...data, email, isEntity: true }, (data) => {
            if (isNewUser) {
                configStore.setModalMessage("Your Account successfully created. Please Login");
                callback('login');
            } else {
                if (data?.companyList) {
                    let isRememberedUser = localStorage.getItem("ag_remember_user");
                    let agUser = localStorage.getItem("ag_userrec") || sessionStorage.getItem("ag_userrec");
                    agUser = JSON.parse(agUser);
                    agUser.companyList = [...data?.companyList];
                    if (isRememberedUser) {
                        localStorage.setItem("ag_userrec", JSON.stringify(agUser));
                    } else {
                        sessionStorage.setItem("ag_userrec", JSON.stringify(agUser));
                    }
                    localStorage.setItem('ag_current_entity', JSON.stringify(data.companyList[0]))
                    loginStore.userRec.companyList = agUser.companyList;
                    entityStore.list = agUser.companyList;
                    loginStore.userRec.aguserRec.selectgst = data.companyList[0]["_id"];
                    configStore.currentSelectedEntity = data.companyList[0];
                }
            }
        })
    }

    return <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", minHeight: "100%", padding: "10px 20% 10px 15%" }}>

        {/* <AgModal/> */}
        <form noValidate onSubmit={handleSubmit(submitHandler)} style={{ width: "100%" }}>

            <Grid container rowSpacing={2} >
                <Grid >
                    <Button
                        onClick={() => {
                            callback('login');
                        }}
                        fullWidth
                        sx={{
                            textTransform: 'capitalize',
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: '#e35959',
                            },
                        }}
                        type="button"
                        startIcon={<TbLogin/>}
                    > Back to login
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bolder", fontSize: "30px", alignSelf: "start", fontWeight: "bold", color: "black", fontFamily: "Figtree" }}>Entity Creation</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LabeledTextField fullWidth control={control} label="Entity Name*" name="entityName" rules={{ required: "Entity name is required" }} size="small" />
                </Grid>
                <Grid item xs={12}>
                    <LabeledTextField fullWidth label="Industry" name="industry" control={control} size="small" />
                </Grid>
                <Grid container rowSpacing={2} columnSpacing={2} style={{ marginTop: "4px" }}>
                    <Grid item xs={12} sm={12} md={6} >
                        <Typography sx={{ textAlign: "start", fontFamily: "Figtree" }}>Country *</Typography>
                        <AgMuiSelect2 rules={{ required: "Country is Required" }} control={control} name="country"
                            // options={["India","Australia"]}
                            options={ServerLists.countryNameList}
                            width="100%" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <Typography sx={{ textAlign: "start", fontFamily: "Figtree" }}>State</Typography>
                        {selectedCountry === "India" ? <AgMuiSelect2 name="state" control={control} options={stateList} width="100%" /> : <CustomStyledTextField size="small" fullWidth {...register("state")} />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <LabeledTextField fullWidth label="City" name="city" control={control} size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <LabeledTextField fullWidth label="Pin/Zip Code" name="pin" control={control} size="small" />
                    </Grid>
                </Grid>
                <Grid item xs={12}>

                    <LabeledTextField fullWidth label="Pan*" name="pan" rules={{
                        required: "Pan is required", pattern: {
                            value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                            message: "Invalid pan number"
                        }
                    }} control={control} size="small" />
                </Grid>
                <Grid item xs={12}>
                    {/* <Typography sx={{textAlign:"start",marginBottom:"4px"}}>GST/TIN *</Typography> */}
                    <LabeledTextField fullWidth label="GST/TIN *" name="gst" rules={{
                        minLength: {
                            value: 15,
                            message: "GST must be 15 characters"
                        },
                        maxLength: {
                            value: 15,
                            message: "GST must be 15 characters"
                        },
                        required: "GST is required",
                        //     pattern:{
                        //     value : /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                        //     message : "Inavlid GST Number"
                        //   }
                    }
                    } control={control} size="small" />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#2B3688", color: "white", "&:hover": {
                            backgroundColor: "#2B3688"
                        }
                    }} type="submit">Create</Button>
                </Grid>
            </Grid>
            {/* <AgAutoComplete options={ServerLists.countryNameList}/> */}
        </form>
    </Box>
}


export function EntityCreationPage({ isNewUser, callback }) {
    return <PageLayout Component={<EntityCreation isNewUser={isNewUser} callback={callback} />} />
}